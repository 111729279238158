import React from 'react';
import ButtonDrop from '../ButtonDrop';
import PropTypes from 'prop-types';
import './index.sass';

export const ButtonMessageSave = ({ isDraftSaveAllowed, onDraftSave, onTemplateSave }) => (
  <ButtonDrop buttonStyle="stroke" listAlign="right" className="button-save" title="Save">
    <ul>
      <li
        className={isDraftSaveAllowed ? null : 'disabled'}
        onKeyPress={isDraftSaveAllowed ? onDraftSave : null}
        role="menuitem"
        tabIndex="0"
        onClick={isDraftSaveAllowed ? onDraftSave : null}
      >
        As a draft
      </li>
      <li
        onKeyPress={onTemplateSave}
        role="menuitem"
        tabIndex="0"
        onClick={onTemplateSave}
      >
        As a template
      </li>
    </ul>
  </ButtonDrop>
);

ButtonMessageSave.propTypes = {
  isDraftSaveAllowed: PropTypes.bool.isRequired,
  onDraftSave: PropTypes.func.isRequired,
  onTemplateSave: PropTypes.func.isRequired,
};

export default ButtonMessageSave;
