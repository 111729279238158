export const mbscResponsiveOptions = {
  xsmall: {
    touchUi: true,
    display: 'center',
  },
  large: {
    touchUi: false,
  },
};

export const shortDayNames = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
