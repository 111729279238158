import React from 'react';
import ContentEditable from 'react-contenteditable';
import SmsPostviewPropType from '../../PropTypes/SmsPostviewPropType';
import './index.sass';

const SmsPostview = ({ content }) => (
  <div className="message-sms-preview-container post-view">
    <div className="send-sms-container">
      <div className="info-message">
        <ContentEditable
          html={content}
          className="message"
          tagName="pre"
          disabled
        />
      </div>
    </div>
  </div>
);

SmsPostview.propTypes = SmsPostviewPropType;

export default SmsPostview;
