import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Notifications from '../Notifications';
import ButtonStandard from '../Button';
import { changeTab } from '../../Actions/middleMenuActions';
import { clearFormGroup } from '../../Actions/groupsActions';
import GroupInviteMembers from '../../Containers/GroupInviteMembers';
import { update } from '../../Actions/groupInviteMemberActions';
import './index.sass';

class Groups extends React.Component {
  handleFormSubGroup = event => {
    event.preventDefault();
    this.props.clearFormGroup();
    this.props.update({ groups: [this.props.selectedGroup.public_id] });
    this.props.changeTab(<GroupInviteMembers />, 1);
  };

  render() {
    const { selectedGroup } = this.props;
    const name = selectedGroup.name ? selectedGroup.name : '';
    return (
      <Grid container spacing={0} id="groups-eva">
        <Grid item xs={12}>
          <h3 className="title-eva">{name}</h3>
          <Notifications title="This group needs some members before you can send any messages." />
          {selectedGroup.type !== 'camino' && <ButtonStandard title="Add members" onClick={this.handleFormSubGroup} />}
        </Grid>
      </Grid>
    );
  }
}

const mS = state => ({
  selectedGroup: state.groupsReducer.selectedGroup,
});

const mD = {
  changeTab,
  clearFormGroup,
  update,
};

export default connect(
  mS,
  mD,
)(Groups);
