import PropTypes from 'prop-types';

const GroupCardPropType = {
  data: PropTypes.instanceOf(Object).isRequired,
  parentOfParent: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onSettingsClick: PropTypes.func,
  onNewMessage: PropTypes.func.isRequired,
  onAddMember: PropTypes.func.isRequired,
};

export default GroupCardPropType;
