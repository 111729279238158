import AXIOS from '../Config/AxiosConfig';
import BaseURL from '../Helpers/BaseUrl';
import headers from '../Api/Helper';

const Members = {
  MPALogin(group) {
    const ssoToken = localStorage.getItem('sso_token');
    return AXIOS.post(
      `${BaseURL.EvaGroup}/${group}/my-parish-app/sso-token/login`,
      {
        ssoToken,
      },
      {
        headers: headers(),
      },
    );
  },
  MPALoginCheck(group) {
    return AXIOS.get(`${BaseURL.EvaGroup}/${group}/my-parish-app/login/check`, {
      headers: headers(),
    });
  },
};

export default Members;
