import Groups from '../Api/Groups';

export const ACTIONS = {
  ACCEPT_GROUP_PRIVACY_POLICY: 'privacyPolicy/acceptPrivacyPolicy',
  ERROR: 'privacyPolicy/error',
  GET_GROUP_PRIVACY_POLICY: 'privacyPolicy/getPrivacyPolicyOfAGroup',
  TOGGLE_CHECK: 'privacyPolicy/togglePrivacyPolicyCheck',
};

export const getGroupPrivacyPolicy = groupId => async dispatch => {
  try {
    const response = await Groups.getPrivacyPolicy(groupId);
    if (response.status === 200) {
      dispatch({
        type: ACTIONS.GET_GROUP_PRIVACY_POLICY,
        payload: response.data.data,
      });
    }
    return response;
  } catch (error) {
    dispatch({ type: ACTIONS.ERROR, payload: error });
    return error;
  }
};

export const togglePrivacyPolicyCheck = value => dispatch => {
  dispatch({ type: ACTIONS.TOGGLE_CHECK, ...value });
};
