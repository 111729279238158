import Colors from '../../Theme/Colors';

export const memberFoundStyles = {
  addMembers: {
    color: Colors.grey,
    '&.active': {
      color: Colors.amethyst,
    },
  },
  matchFound: {
    textAlign: 'center',
  },
  matchFoundText: {
    color: Colors.grey,
    fontFamily: 'GothamSSm-Book',
    fontSize: 16,
    fontWeight: 600,
  },
  permissionsCheck: {
    float: 'left',
  },
  permissionsText: {
    color: Colors.grey,
    fontFamily: 'GothamSSm-Book',
    fontSize: 14,
    lineHeight: '20px',
    marginTop: 0,
    textAlign: 'center',
  },
  actions: {
    marginTop: 29,
    textAlign: 'center',
  },
  noMatch: {
    marginTop: 43,
  },
};

export const memberGridStyles = {
  root: {
    position: 'relative',
    textAlign: 'center',
  },
  checkbox: {
    position: 'absolute',
    right: '50%',
    top: 9,
    transform: 'translateX(-45px)',
  },
  name: {
    color: Colors.evaGrey,
    fontFamily: 'GothamSSm-Book',
    fontSize: 16,
    padding: '0 5px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

export const noMatchButtonStyles = {
  root: {
    fontSize: '16px',
    fontFamily: 'GothamSSm-Book',
    color: Colors.amethyst,
    background: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    outline: 'inherit',
    width: '100%',
  },
};
