import Templates from '../Api/Templates';
import snackBarStatus from './snackbarActions';
import Helpers from '../Helpers';
import { getSelectedLongestGroupName } from '../Helpers/Message/getSelectedLongestGroupName';
import { getPreviewPrefix } from '../Helpers/Message/getPreviewPrefix';
import { TEMPLATE_PAGE_SIZE_GRID } from '../Config/Constants';

export const ACTIONS = {
  CHANGE_STATE: '[Template] Change state',
  DELETE_FOLDER: '[Template] Delete folder',
  GET_TEMPLATE_SETTINGS: '[Template] Get template settings',
  GET_FOLDERS: '[Template] Get template folders list',
  GET_ALL_FOLDER_TEMPLATE_LIST: '[Template] Get all template folders',
  GET_FOLDER_TEMPLATE_ID: '[Template] Get folder template id',
  GET_FOLDER_TEMPLATE_ID_PAGED: '[Template] Get fodler template id paged',
  GET_FOLDER_TEMPLATE: '[Template] Get folder template',
  UPDATE_TEMPLATE: '[Template] Update template',
  SAVE_TEMPLATE: '[Template] Save template',
  DELETE_TEMPLATES: '[Template] Delete templates',
  SELECT_FOLDER: '[Template] Select folder',
  SELECT_TEMPLATE: '[Template] Select template',
  SELECTED_TEMPLATE: '[Template] Selected template',
  SAVE_AND_VIEW_FOLDER: '[Template] Save and view folder',
  SET_TREE: '[Template] Set tree',
  UPDATE_DIALOG: '[Template] Update dialog',
  UPDATE_DIALOG_TEMPLATE: '[Template] Update dialog template',
  UPDATE_SHARE_ALL_ORG: '[Template] Update share all org',
  UPDATE_SHARE_ALL_GROUP: '[Template] Update share all group',
  UPDATE_SHARE_WITH_ALL_GROUPS: '[Template] Update share with all groups',
  TEMPLATE_SELECTED: '[Template] Template selected',
  CLEAR_TEMPLATE_SELECTED_PROPS: '[Template] Clear template selected props',
  CLEAR_SELECTED_TEMPLATE: '[Template] Clear selected template',
  DISPLAY: '[Template] Display',
  GET_TEMPLATE_LIST: '[Template] Get template list',
  SAVE_FEATURED_DATA: '[Template] Save featured data',
  SHOW_BUTTON_ANIMATION: '[Template] Show button animation',
  TEMPLATES_LOADED: '[Template] Templates loaded',
  TEMPLATES_LOADING: '[Template] Templates loading',
  EDIT_TEMPLATE_FLAG: '[Template] Edit template flag',
  MOVE_TEMPLATES: '[Template] Move templates',
  SET_FOLDER_MOVE_LIST: '[Template] Set folder move templates',
  TEMPLATE_SHOWN_STATUS: '[Template] Update template shown status',
  UPDATE_FOLDER_NAME: '[Template] Update folder name',
  CLEAR_TEMPLATES: '[Template] Clear templates',
};

const formatFolderRequest = (folder) => ({
  name: folder.name,
  parent_folder: folder.parent,
  shared: folder.shared,
  shared_with: folder.sharesArray,
  shareAllGrp: folder.shareAllGrp,
  shareAllOrg: folder.shareAllOrg,
  shareWithAllGroups: folder.shareWithAllGroups,
  comments: folder.comments,
});

const formatTemplateRequest = (settings, message) => {

  return {
    ...settings,
    message,
  };
};

export const changeState = publicId => ({
  type: ACTIONS.CHANGE_STATE,
  payload: publicId,
});

export const createFolder = folder => async dispatch => {
  try {
    const response = await Templates.createFolder(formatFolderRequest(folder));
    let createFolderResponse = [];
    if (response.status === 200) {
      createFolderResponse = response.data;
    }
    return createFolderResponse;
  } catch (error) {
    if (
      error &&
      error.data &&
      error.data.data &&
      error.data.data.errors &&
      error.data.data.errors.folder &&
      error.data.data.errors.folder.name &&
      error.data.data.errors.folder.name.errors &&
      error.data.data.errors.folder.name.errors.length
    ) {
      snackBarStatus({
        payload: {
          title: `Folder name: ${error.data.data.errors.folder.name.errors[0]}`,
          type: 'error',
          enable: true,
        },
      })(dispatch);
    } else {
      // eslint-disable-next-line no-console
      console.log(error.message);
    }
    return error;
  }
};

export const getFoldersList = () => async dispatch => {
  try {
    const response = await Templates.getFoldersList();
    let foldersList = [];
    if (response.status === 200) {
      foldersList = response.data.data;
      updateFolderList(foldersList)(dispatch);
    }
    return foldersList;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const getAllFoldersList = () => async dispatch => {
  try {
    const response = await Templates.getFoldersList(true);

    const allFolders = [];

    response.data.data.forEach(folder => {
      allFolders.push(folder);
      folder.subFolders.forEach(subFolder => {
        allFolders.push({
          ...subFolder,
          name: `${folder.name} / ${subFolder.name}`,
          parentFolder: {
            name: folder.name,
            publicId: folder.publicId,
          }
        });
      })
    });

    let templateResponse = [];
    if (response.status === 200) {
      templateResponse = response;
      dispatch({
        type: ACTIONS.GET_ALL_FOLDER_TEMPLATE_LIST,
        payload: allFolders,
      });
    }
    return templateResponse;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const getFolderTemplateById = publicId => async dispatch => {
  try {
    const response = await Templates.getFolderTemplateById(publicId);
    let templates = [];
    if (response.status === 200) {
      templates = response.data.data;
      dispatch({
        type: ACTIONS.GET_FOLDER_TEMPLATE,
        payload: templates,
      });
    }
    return templates;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};
export const updateFolderTemplate = templates => ({
  type: ACTIONS.GET_FOLDER_TEMPLATE_ID,
  payload: templates,
});
// eslint-disable-next-line
export const getFolderByIdPaged = (
  publicId,
  sort,
  term,
  page,
  pageSize,
  oldState = [],
  fireActions = true,
) => async dispatch => {
  try {
    // search term update case
    if (page === 1) {
      updateLoadedStatus(false)(dispatch);
    }
    updateLoadingStatus(true)(dispatch);
    const response = await Templates.getFolderById(publicId, sort, term, page || 1, pageSize || TEMPLATE_PAGE_SIZE_GRID);
    let templates = [];
    if (response.status === 200) {
      const {
        data: {
          data: { data: templates__, numberOfItems, totalPages, nextPage, previousPage },
        },
      } = response;
      templates = page === 1 ? templates__ : [...oldState, ...templates__];
      if (fireActions) {
        dispatch({
          type: ACTIONS.GET_FOLDER_TEMPLATE_ID_PAGED,
          payload: {
            templates,
            numberOfItems,
            totalPages,
            nextPage,
            previousPage,
            page,
            pageSize,
          },
        });
        updateLoadingStatus(false)(dispatch);
        updateLoadedStatus(true)(dispatch);
      }
    }
    return templates;
  } catch (error) {
    updateLoadingStatus(false)(dispatch);

    if (fireActions) {
      updateLoadedStatus(false)(dispatch);
    }
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const getTemplateById = templateId => async dispatch => {
  try {
    const response = await Templates.getTemplate(templateId);
    if (response.status === 200) {
      // dispatch({
      //   type: ACTIONS.SELECT_TEMPLATE,
      //   payload: response.data.data,
      // });
    }

    return response.data;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const getFolderById = (publicId, sort, term, page, pageSize) => async dispatch => {
  try {
    updateLoadingStatus(true)(dispatch);

    const response = await Templates.getFolderById(publicId, sort, term, page || 1, pageSize || TEMPLATE_PAGE_SIZE_GRID);
    let templates = [];
    if (response.status === 200) {
      templates = response.data.data;
      dispatch({
        type: ACTIONS.GET_FOLDER_TEMPLATE_ID,
        payload: templates,
      });

      updateLoadingStatus(false)(dispatch);

      updateLoadedStatus(true)(dispatch);
    }
    return templates;
  } catch (error) {
    updateLoadingStatus(false)(dispatch);
    updateLoadedStatus(true)(dispatch);

    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const moveTemplatesToFolder = (templates, currentFolder, moveTo) => async dispatch => {
  try {
    const response = await Templates.moveTemplatesToFolder({
      templates,
      current_folder: currentFolder,
      move_to: moveTo,
    });
    if (response.status === 200) {
      dispatch({
        type: ACTIONS.MOVE_TEMPLATES,
        payload: response,
      });
      snackBarStatus({
        payload: {
          title: 'The templates have been moved successfully',
          type: 'success',
          enable: true,
        },
      })(dispatch);
    }
    return response;
  } catch (error) {
    // eslint-disable-next-line no-console
    return error;
  }
};

export const updateFolderListMove = (rawFoldersList, userMainRole) => async dispatch => {
  const foldersList = Helpers.getSelectableFolders(
    rawFoldersList,
    userMainRole,
  ).map(({ name, publicId }) => ({ text: name, value: publicId }));

  dispatch({
    type: ACTIONS.SET_FOLDER_MOVE_LIST,
    payload: foldersList,
  });
};

export const deleteFolder = folderPublicId => async dispatch => {
  try {
    const response = await Templates.deleteTemplateFolder({
      folderPublicId,
    });
    let removeResponse = [];
    if (response.status === 200) {
      removeResponse = response.data;
      dispatch({
        type: ACTIONS.DELETE_FOLDER,
        payload: folderPublicId,
      });
    }
    return removeResponse;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};
/* eslint-disable */
export const getTemplateSettings = templateId => async dispatch => {
  try {
    const response = await Templates.getTemplate(templateId);
    let templateSettings = {};
    if (response.status === 200) {
      templateSettings = response.data.data;
      dispatch({
        type: ACTIONS.GET_TEMPLATE_SETTINGS,
        payload: templateSettings,
      });
    }
    return templateSettings;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};
/* eslint-enable */
export const updateFolder = folder => async dispatch => {
  try {
    const response = await Templates.updateFolder(folder.publicId, formatFolderRequest(folder));
    let updateFolderResponse = [];
    if (response.status === 200) {
      updateFolderResponse = response.data;
    }
    return updateFolderResponse;
  } catch (error) {
    if (
      error &&
      error.data &&
      error.data.data &&
      error.data.data.errors &&
      error.data.data.errors.folder &&
      error.data.data.errors.folder.name &&
      error.data.data.errors.folder.name.errors &&
      error.data.data.errors.folder.name.errors.length
    ) {
      snackBarStatus({
        payload: {
          title: `Folder name: ${error.data.data.errors.folder.name.errors[0]}`,
          type: 'error',
          enable: true,
        },
      })(dispatch);
    } else if (error && error.data && error.data.message) {
      snackBarStatus({
        payload: {
          title: error.data.message,
          type: 'error',
          enable: true,
        },
      })(dispatch);
    } else if (error && error.message) {
      snackBarStatus({
        payload: {
          title: error.message,
          type: 'error',
          enable: true,
        },
      })(dispatch);
    }
    return error;
  }
};

export const saveTemplate = settings => async (dispatch, getState) => {
  const { messageReducer: { messageContent, files } } = getState();
  try {
    const response = await Templates.saveTemplate({ ...settings, messageContent, files });
    let saveTemplateResponse = [];
    if (response.status === 200) {
      saveTemplateResponse = response.data;
      dispatch({
        type: ACTIONS.SAVE_TEMPLATE,
        payload: {
          windowParams: {
            show: true,
            titleMessage: 'Template saved successfully',
            type: 'success',
          },
        },
      });
    }
    return saveTemplateResponse;
  } catch (error) {
    console.error(error);
    if (error && error.data && error.data.data) {
      if (error.data.data && error.data.data.errors.template.title) {
        snackBarStatus({
          payload: {
            title: `template name: ${error.data.data.errors.template.title.errors[0]}`,
            type: 'error',
            enable: true,
          },
        })(dispatch);
      } else {
        snackBarStatus({
          payload: {
            title: error.data.message,
            type: 'error',
            enable: true,
          },
        })(dispatch);
      }
    } else if (error && error.data) {
      snackBarStatus({
        payload: {
          title: error.data.message,
          type: 'error',
          enable: true,
        },
      })(dispatch);

      return error;
    } else {
      snackBarStatus({
        payload: {
          title: error.message,
          type: 'error',
          enable: true,
        },
      })(dispatch);
    }
    return error;
  }
};

export const updateTemplate = settings => async (dispatch, getState) => {
  const { messageReducer: { messageContent } } = getState();

  try {
    const response = await Templates.updateTemplate({ ...settings, messageContent });
    let updateTemplateResponse = [];
    if (response.status === 200) {
      updateTemplateResponse = response.data;
      dispatch({
        type: ACTIONS.UPDATE_TEMPLATE,
        payload: {
          windowParams: {
            show: true,
            titleMessage: 'Template updated successfully',
            type: 'success',
          },
        },
      });
    }
    return updateTemplateResponse;
  } catch (error) {
    if (error && error.data) {
      if (error.data.data && error.data.data.errors.template.title) {
        snackBarStatus({
          payload: {
            title: `template name: ${error.data.data.errors.template.title.errors[0]}`,
            type: 'error',
            enable: true,
          },
        })(dispatch);
      } else {
        snackBarStatus({
          payload: {
            title: error.data.message,
            type: 'error',
            enable: true,
          },
        })(dispatch);
      }
    } else {
      snackBarStatus({
        payload: {
          title: error.message,
          type: 'error',
          enable: true,
        },
      })(dispatch);
    }
    return error;
  }
};

export const deleteTemplate = (templatesIds) => async dispatch => {
  const isMultiple = templatesIds.length > 1;
  try {
    const response = await Templates.deleteTemplate({
      templates: templatesIds,
    });
    if (response.status === 200) {
      dispatch({
        type: ACTIONS.DELETE_TEMPLATES,
        payload: templatesIds,
      });
    }
    snackBarStatus({
      payload: {
        title: `Template${isMultiple ? 's' : ''} has been deleted successfully`,
        type: 'success',
        enable: true,
      },
    })(dispatch);

    return response;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: `Error: Unable to delete template${isMultiple ? 's' : ''}`,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const updateTemplateList = templateListSelected => dispatch => {
  dispatch({
    type: ACTIONS.GET_TEMPLATE_LIST,
    payload: templateListSelected,
  });
};

export const updateFolderList = templateList => dispatch => {
  dispatch({
    type: ACTIONS.GET_FOLDERS,
    payload: templateList,
  });
};

export const selectTemplate = template => (dispatch, getState) => {
  const {
    messageReducer: { isAllMemberGroupSelected, recipientGroups },
    organizationReducer: { organization },
    groupsDictionaryReducer: { list },
  } = getState();

  const organizationName = organization && organization.short_name ? organization.short_name : 'Some Organization';
  const groupName = isAllMemberGroupSelected ? '' : getSelectedLongestGroupName(list, recipientGroups);
  const prefix = getPreviewPrefix(organizationName, groupName);

  const payload = {
    ...template,
    messageContent: {
      ...template.messageContent,

      mpaContent: {
        ...template.messageContent.mpaContent,
        prefix: !isAllMemberGroupSelected ? prefix : '',
      },
      smsContent: {
        ...template.messageContent.smsContent,
        prefix: prefix,
      },
    }
  };

  dispatch({
    type: ACTIONS.SELECT_TEMPLATE,
    payload,
  });
};

export const selectFolder = folder => dispatch => {
  dispatch({
    type: ACTIONS.SELECT_FOLDER,
    payload: folder,
  });
};

export const setSaveAndViewFolder = folder => dispatch => {
  dispatch({
    type: ACTIONS.SAVE_AND_VIEW_FOLDER,
    payload: folder,
  });
};

export const setTree = tree => dispatch => {
  dispatch({
    type: ACTIONS.SET_TREE,
    payload: tree,
  });
  return tree;
};

export const changeFormValue = (change, form, field, value) => dispatch => {
  dispatch(change(form, field, value));
  return value;
};

export const updateDialog = value => dispatch =>
  dispatch({
    type: ACTIONS.UPDATE_DIALOG,
    payload: value,
  });

export const updateDialogTemplate = value => dispatch =>
  dispatch({
    type: ACTIONS.UPDATE_DIALOG_TEMPLATE,
    payload: value,
  });

export const updateShareAllOrg = value => dispatch =>
  dispatch({
    type: ACTIONS.UPDATE_SHARE_ALL_ORG,
    payload: value,
  });

export const updateShareAllGroup = value => dispatch =>
  dispatch({
    type: ACTIONS.UPDATE_SHARE_ALL_GROUP,
    payload: value,
  });

export const updateShareWithAllGroup = value => dispatch =>
  dispatch({
    type: ACTIONS.UPDATE_SHARE_WITH_ALL_GROUPS,
    payload: value,
  });

export function templateSelection(value) {
  const list = value.length;
  let display = false;
  return dispatch => {
    dispatch({
      type: ACTIONS.TEMPLATE_SELECTED,
      payload: value,
    });
    if (list > 0) {
      display = true;
    } else {
      display = false;
    }
    dispatch({
      type: ACTIONS.DISPLAY,
      payload: display,
    });
  };
}

export const clearSelectedTemplate = () => dispatch => {
  dispatch({
    type: ACTIONS.CLEAR_SELECTED_TEMPLATE,
  });
  updateLoadedStatus(false)(dispatch);
};

export const clearTemplateSelectedProps = () => dispatch => {
  dispatch({
    type: ACTIONS.CLEAR_TEMPLATE_SELECTED_PROPS,
  });
  updateLoadedStatus(false)(dispatch);
};

export const saveFeaturedData = data => dispatch => {
  dispatch({
    type: ACTIONS.SAVE_FEATURED_DATA,
    payload: data,
  });
};

export const showButtonAnimation = data => dispatch => {
  dispatch({
    type: ACTIONS.SHOW_BUTTON_ANIMATION,
    payload: data,
  });
};

export const updateSelectedTemplate = tem => ({
  type: ACTIONS.SELECTED_TEMPLATE,
  payload: tem,
});

export const changeEditTemplateFlag = condition => ({
  type: ACTIONS.EDIT_TEMPLATE_FLAG,
  payload: condition,
});

/**
 * Updates the value that indicates if a template preview was shown
 * (used when the route /templates/{templateId} is activated.)
 * @param {boolean} value
 */
export function updateTemplateShownStatus(value) {
  return {
    type: ACTIONS.TEMPLATE_SHOWN_STATUS,
    payload: value,
  };
}

export function updateFolderName(value) {
  return {
    type: ACTIONS.UPDATE_FOLDER_NAME,
    payload: value,
  };
}

export const clearTemplates = () => ({
  type: ACTIONS.CLEAR_TEMPLATES,
});

const updateLoadingStatus = bool => dispatch =>
  dispatch({ type: ACTIONS.TEMPLATES_LOADING, payload: bool });

const updateLoadedStatus = bool => dispatch =>
  dispatch({ type: ACTIONS.TEMPLATES_LOADED, payload: bool });

