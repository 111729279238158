// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../Theme/Icons/email.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".added-members-container{margin-top:20px;margin-bottom:20px}.checkbox-outline{width:20px;height:20px;border-radius:5px;border:solid 1px #d8d8d8;display:-ms-inline-flexbox;display:inline-flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center}.align-checkbox-match{width:5%;padding:0 !important;float:left}.input-div{border-bottom:2px solid #d9d9d9}.input-div i.input-eva-email-icon{width:90%;padding:20px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:left;background-color:transparent}.input-div input.input-eva-border-bottom.input-eva-email-icon{margin-bottom:0;background-color:transparent;border:none}\n", ""]);
// Exports
module.exports = exports;
