import React, { Component } from 'react';

import { ListItemText, ListItemIcon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';

import TreeNavigatorSingleItemPropTypes from '../../PropTypes/TreeNavigatorSingleItemPropTypes';

const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
});

class TreeNavigatorSingleItem extends Component {
  handleClick = () => {
    if (this.props.handleTreeItemClick) {
      this.props.handleTreeItemClick(this.props.treeItem);
    }
  };

  render = () => {
    const { treeItem, classes, selectedTreeItem } = this.props;
    let selected = false;

    if (!selectedTreeItem && treeItem.label === 'Featured') {
      selected = true;
    } else {
      const { isSelected } = treeItem;
      selected = isSelected;
    }

    return (
      <ListItem button className="tree-navigator-item" onClick={this.handleClick}>
        <ListItemIcon className="tree-navigator-item-icon-wrapper">
          <i className={!selected ? treeItem.iconDefault : treeItem.iconActive} />
        </ListItemIcon>

        {treeItem.label && (
          <ListItemText
            primary={treeItem.label}
            className={`tree-navigator-item-label ${selected ? 'selected' : ''}`}
          />
        )}

        {treeItem.summary && (
          <ListItemText
            className={`tree-navigator-item-summary ${classes.secondaryHeading}`}
            primary={treeItem.summary}
          />
        )}
        <div className="border-footer" />
      </ListItem>
    );
  };
}

TreeNavigatorSingleItem.propTypes = TreeNavigatorSingleItemPropTypes;

export default withStyles(styles)(TreeNavigatorSingleItem);
