import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import TextareaAutosize from 'react-textarea-autosize';
import publicIcon from '../../Theme/Icons/public.svg';
import moreIcon from '../../Theme/Icons/more_horiz.svg';
import closeIcon from '../../Theme/Icons/quick-action.svg';
import likeIcon from '../../Theme/Icons/thumb_up.svg';
import commentIcon from '../../Theme/Icons/chat_bubble.svg';
import shareIcon from '../../Theme/Icons/share.svg';
import Helpers from '../../Helpers';
import MessageTwitterFbPreviewPropType from '../../PropTypes/MessageTwitterFbPreviewPropType';
import MessagePreviewToolbar from '../MessagePreviewToolbar';
import './index.sass';

class MessageFacebookPreview extends Component {
  state = {
    html: '',
  };

  componentDidMount() {
    this.setState({ html: this.props.content, image: this.props.image });
  }

  render() {
    const { allowed, enabled, profilePic, profileName, image, previewImages, selectedPreviewImage,
      onPreviewChange, onSendByChange, onChange } = this.props;
    return (
      <div>
        {allowed ? (
          <div className="send-facebook-container">
            <MessagePreviewToolbar
              imagePicker
              text="Send to Facebook"
              enabled={enabled}
              selectedPreviewImage={selectedPreviewImage}
              previewImages={previewImages}
              onImageSelect={onPreviewChange}
              onChange={onSendByChange}
            />
            <div className="message-container">
              <div className="facebook-content" id="scroll">
                <div className={`facebook-message ${enabled ? 'show' : 'blur'}`}>
                  <div className="facebook-header">
                    <Grid item xs={1} className="member-message-facebook">
                      <img src={profilePic} alt="member" />
                    </Grid>
                    <div className="facebook-header-text">
                      <p className="facebook-name">{profileName}</p>
                      <div className="facebook-lower-header">
                        <p className="facebook-date"> · {moment().format('MMM. D')}</p>
                        <img src={publicIcon} alt="public" className="facebook-public" />
                      </div>
                    </div>
                    <img src={moreIcon} alt="more" className="facebook-more" />
                  </div>
                  <TextareaAutosize
                    className="facebook-body-text"
                    id="scroll"
                    disabled={!enabled}
                    defaultValue={this.state.html}
                    onChange={(e) => onChange({text: e.target.value})}
                    onPaste={Helpers.handleOnlyTextPreviewPaste}
                  />

                  <div className="facebook-images">
                    {image && (
                      <img
                        src={image}
                        alt="gallery"
                        className="gallery-image"
                      />
                    )}
                    {enabled && (
                      <Tooltip title="Remove image" placement="right">
                        <button
                          className="facebook-remove"
                          onClick={() => onChange({ image: '' })}
                          onKeyPress={() => {}}
                        >
                          <img src={closeIcon} alt="facebook-remove" />
                        </button>
                      </Tooltip>
                    )}
                  </div>

                  <hr />
                  <div className="facebook-buttons">
                    <div>
                      <img src={likeIcon} alt="like" />
                      <div>Like</div>
                    </div>
                    <div>
                      <img src={commentIcon} alt="comment" />
                      Comment
                    </div>
                    <div>
                      <img src={shareIcon} alt="share" />
                      Share
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="not-available">
            <p>
              Posting to Facebook is not currently enabled for this group(s).
              <br />
              Please check the group(s) settings. Be sure to save this message as a draft to resume
              sending it at a later time.
            </p>
          </div>
        )}
      </div>
    );
  }
}

MessageFacebookPreview.defaultProps = {
  image: null,
};

MessageFacebookPreview.propTypes = MessageTwitterFbPreviewPropType;

export default (MessageFacebookPreview);
