const drawerWidth = window.innerWidth > 768 ? 670 : window.innerWidth;

const drawerStyles = theme => ({
  paper: {
    position: 'absolute',
    width: drawerWidth,
  },
  [theme.breakpoints.only('xs')]: {
    paper: {
      width: '100% !important',
      height: '100vh',
    },
  },
  [theme.breakpoints.only('sm')]: {
    paper: {
      width: '100% !important',
      height: '100% !important',
    },
  },

  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    minHeight: 40,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  'content-left': {
    marginLeft: -drawerWidth,
  },
  'content-right': {
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  'contentShift-left': {
    marginLeft: 0,
  },
  'contentShift-right': {
    marginRight: 0,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
  toolTipPlacementLeft: {
    margin: '0 8px',
  },
  toolTipPlacementRight: {
    margin: '0 8px',
  },
  toolTipPlacementTop: {
    margin: '8px 0',
  },
  toolTipPlacementBottom: {
    margin: '8px 0',
  },
  titleBox: {
    display: 'inline-flex',
    alignItems: 'center',
  },
});

export default drawerStyles;
