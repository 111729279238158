import React, { Component } from 'react';
import { connect } from 'react-redux';
import posed from 'react-pose';
import { enableRightDrawerMessage } from '../../Actions/rightDrawerMessageActions';
import UserSettingsMessage from '../UserSettingsMessage';
import ActionButtonPropType from '../../PropTypes/ActionButtonPropType';
import { closeCustomModal, openCustomModal } from '../../Actions/customModalActions';
import './index.sass';
import plusIcon from '../../Theme/Icons/plus.svg';
import { withRouter } from 'react-router-dom';
import GroupInviteMembers from '../../Containers/GroupInviteMembers';
import MessageModalManager, { MESSAGE_MODAL_MANAGER_VIEWS } from '../../Containers/MessageModalManager';

const Options = posed.ul({
  open: {
    staggerChildren: 50,
    staggerDirection: -1,
  },
  closed: {
    staggerChildren: 50,
    staggerDirection: -1,
  },
});

const Option = posed.li({
  open: {
    y: 0,
    opacity: 1,
    transition: { duration: 200 },
  },
  closed: {
    y: 20,
    opacity: 0,
    transition: { duration: 200 },
  },
});

class ActionButton extends Component {
  state = {
    active: false,
    showList: false,
  };

  handleClick = () => {
    const { active } = this.state;
    let { showList } = this.state;

    if (!active) {
      showList = true;
    }

    this.setState({ active: !active, showList });
  };

  handleDesigner = e => {
    e.preventDefault();
    const { history } = this.props;
    history.push('/messages/new');
    this.props.closeCustomModal();
  };

  handleRenderComponent = () => {
    this.props.enableRightDrawerMessage({
      payload: {
        enable: true,
        element: <UserSettingsMessage/>,
        title: 'New Message',
        secondTitle: false,
      },
    });
  };

  handleMessage = () =>
    this.props.openCustomModal({ content: <MessageModalManager view={MESSAGE_MODAL_MANAGER_VIEWS.EDITOR} /> });

  handleInvite = event => {
    event.preventDefault();
    this.props.openCustomModal({
      content: <GroupInviteMembers mobile/>,
      title: 'Invite Members',
    });
  };

  toggleList = () => {
    const { active } = this.state;

    if (!active) {
      this.setState({ showList: false });
    }
  };

  render() {
    const { active, showList } = this.state;
    return (
      <React.Fragment>
        <button
          className={`float ${active ? 'menu-share-active' : 'menu-share-inactive'}`}
          id="menu-share"
          onClick={this.handleClick}
        >
          <img alt="plus sign" className="button-action" src={plusIcon}/>
        </button>
        <Options
          className={`action-menu ${!showList && 'closed'}`}
          pose={active ? 'open' : 'closed'}
          onPoseComplete={this.toggleList}
        >
          <React.Fragment>
            <Option key="help" onKeyPress={this.handleOnKeyPress} role="menuitem" tabIndex="0">
              <a
                href="https://www.diocesan.com/quicksupport"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="help-action"/>
              </a>
            </Option>
            <Option key="designer" onClick={this.handleDesigner}>
              <a href="/messages/new">
                <i className="designer-action"/>
              </a>
            </Option>
            <Option
              key="add-members"
              onKeyPress={this.handleOnKeyPress}
              role="menuitem"
              tabIndex="0"
              onClick={this.handleInvite}
            >
              <i className="add-action"/>
            </Option>
            <Option
              key="composer"
              onKeyPress={this.handleOnKeyPress}
              role="menuitem"
              tabIndex="0"
              onClick={this.handleMessage}
            >
              <span>
                <i className="message-action"/>
              </span>
            </Option>
          </React.Fragment>
        </Options>
      </React.Fragment>
    );
  }
}

const mD = {
  enableRightDrawerMessage,
  closeCustomModal,
  openCustomModal,
};

ActionButton.propTypes = ActionButtonPropType;

export default withRouter(connect(null, mD)(ActionButton));
