import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import deleteIcon from '../../Theme/Icons/trash-icon.svg';

const styles = {
  icon: {
    width: '32px',
    height: '32px',
    marginTop: '5px',
    padding: '0',
    '&>img': {
      height: '100%',
    },
  },
};

const DeleteButton = ({ classes, onClick }) => (
  <button
    className={classes.icon}
    onClick={onClick}
  >
    <img src={deleteIcon} alt="" />
  </button>
);

DeleteButton.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(DeleteButton);
