import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import style from '../EditMember/styles';
import { getStates } from '../../Actions/statesDictionaryActions';
import { getCountries } from '../../Actions/countriesDictionaryActions';

const StatePreview = ({ countryId, value, statesHash, isLoading, countiesIsLoading, countiesIsLoaded, getStates, getCountries }) => {

  const [name, setName] = useState('');

  useEffect(() => {
    if (value && !countiesIsLoaded && !countiesIsLoading) {
      getCountries();
    }
  }, [value, countryId, countiesIsLoaded, countiesIsLoading]);

  useEffect(() => {
    if (value && countiesIsLoaded && countryId) {
      getStates(countryId);
    }
  }, [value, countiesIsLoaded, countryId]);

  useEffect(() => {
    if (!value) {
      setName('');
      return;
    }

    const item = statesHash[countryId]?.find((i) => i.value === value);
    if (item) {
      setName(item.text);
    } else {
      setName('');
    }
  }, [value, statesHash]);

  return (
    <>{isLoading ? 'Loading...' : name}</>
  );
};

const mS = state => ({
  countiesIsLoading: state.countriesDictionaryReducer.isLoading,
  countiesIsLoaded: state.countriesDictionaryReducer.isLoaded,
  statesHash: state.statesDictionaryReducer.hash,
  isLoading: state.statesDictionaryReducer.isLoading,

});

const mD = {
  getStates,
  getCountries,
};

export default connect(mS, mD)(withStyles(style)(StatePreview));
