export const ACTIONS = {
  ENABLE_MESSAGE: 'rightDrawerMessage/enable',
  SET_ENFORCE_FOCUS: 'rightDrawerMessage/set_enforce_focus',
};

export const enableRightDrawerMessage = valueObj => dispatch => {
  dispatch({ type: ACTIONS.ENABLE_MESSAGE, ...valueObj });
  if (window.innerWidth >= 1024) {
    document.querySelector(
      '.right-drawer.drawer-message > .drawer-md-up',
    ).style.display = 'block';
  }
};
export const setEnforceFocus = enforceFocus => ({
  type: ACTIONS.SET_ENFORCE_FOCUS,
  payload: { enforceFocus },
});
