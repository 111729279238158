import React from 'react';
import ContentEditable from 'react-contenteditable';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import TemplateMessagePreviewPropType from '../../PropTypes/TemplateMessagePreviewPropType';
import './index.sass';

const styles = theme => ({
  paperStyle: {
    width: 600,
    height: 500,
    paddingTop: theme.spacing(1),
    marginTop: 15,
  },
});

const TemplateSmsPreview = ({ content, classes }) => (
  <div className="message-sms-preview-container">
    <div className="send-sms-container">
      <div className="switch-container" />
      <Paper className={classes.paperStyle} elevation={1}>
        <div className="info-message">
          <ContentEditable
            className="message"
            html={content} // innerHTML of the editable div
            disabled
          />
        </div>
      </Paper>
    </div>
  </div>
);

TemplateSmsPreview.propTypes = TemplateMessagePreviewPropType;

export default withStyles(styles)(TemplateSmsPreview);
