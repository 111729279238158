import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import evangelusLogo from '../../Theme/Icons/evangelusLogo.svg';
import { SUPPORT_PHONE_NUMBER } from '../../Config/env';

const styles = () => ({
  logoEva: {
    textAlign: 'center',
    marginTop: '100px',
  },
  messageDeleteAccount: {
    textAlign: 'center',
    marginTop: '50px',
  },
  text: {
    fontFamily: 'GothamSSm-Book',
    color: '#a9a9a9',
    fontSize: '16px',
  },
});

const DeleteAccount = ({ classes }) => (
  <Grid container alignItems="center" justify="center">
    <Grid item xs={12} alignItems="center">
      <div className={classes.logoEva}>
        <img src={evangelusLogo} alt="Evangelus Logo" />
      </div>
    </Grid>
    <Grid item xs={12}>
      <div className={classes.messageDeleteAccount}>
        <p className={classes.text}>
          Your account has been deleted. <br />
          <br />
          <br />
          If this was done in error, please call {SUPPORT_PHONE_NUMBER} for assistance.
        </p>
      </div>
    </Grid>
  </Grid>
);

DeleteAccount.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(DeleteAccount);
