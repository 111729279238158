import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { getThread } from '../../Actions/threadActions';
import {
  addImageInComposer,
  eraseEditorContent,
  initReplyMessage,
  sendDirectMessage,
  sendThreadMessage,
  updateEditorContent,
} from '../../Actions/messageActions';
import { displayGallery } from '../../Actions/imageGalleryActions';
import { mainStatusLoading } from '../../Actions/mainLoadingActions';
import snackBarStatus from '../../Actions/snackbarActions';
import MessageThreadItem from '../MessageThreadItem';
import { isOrgAdmin } from '../../Helpers/Messages';
import LoaderOverlay from '../LoaderOverlay';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import getMemberInfo from '../../Helpers/getMemberInfo';
import { updateComposerState } from '../../Actions/composeActions';
import { openCustomModal, closeCustomModal } from '../../Actions/customModalActions';
import { getDateInTimezone } from '../../Helpers/date';
import GalleryOptions from '../../Containers/GalleryOptions';
import MessageEditor from '../MessageEditor';
import './index.sass';

/* eslint-disable */

const getFullName = (member) => {
  if (!member) {
    return ''
  }
  const firstName = member.firstName ? member.firstName : '';
  const lastName = member.lastName ? member.lastName : '';
  const spacing = lastName && firstName ? ' ' : '';
  return lastName + spacing + firstName;
};

class ReplyThread extends Component {
  state = {
    editable: true,
    firstRun: true,
    sendConfirmDialogOpen: false,
  };

  toggleConfirmDialog = (bool) => {
    this.setState({ sendConfirmDialogOpen: bool })
  }

  handleGalleryOpen = () => {
    this.props.displayGallery(true); // TODO: remove(?)
    this.props.openCustomModal({
      content: <GalleryOptions onImagePick={src => {
        this.props.addImageInComposer(src);
        this.props.closeCustomModal()
        this.props.displayGallery(false); // TODO: remove(?)
      }}/>,
      title: 'Gallery'
    });
  }

  renderThread() {
    const { selectedThread, timeZone } = this.props;
    const messages = _.sortBy(selectedThread.responseMessages, obj => obj.createdAt);
    const render = selectedThread.responseMessages && selectedThread.responseMessages.length > 0;
    if (render) {
      return (
        <Grid
          item
          xs={12}
          container
          spacing={0}
          className="scrollable-area"
        >
          {messages.map((element) => {
            const isAdmin = isOrgAdmin(element.sentByMember.organizationRole);
            return (
              <MessageThreadItem
                key={element.publicId}
                highlighted={isAdmin}
                rtl={isAdmin}
                messageHtml={element.webContent}
                userImage={element.sentByMember.profilePictureUrl}
                userName={getFullName(element.sentByMember)}
                date={getDateInTimezone(element.createdAt, timeZone, 'MM/DD/YY h:mm A')}
              />
            )
          })}
        </Grid>
      );
    } else {
      return null;
    }
  }
  componentDidMount() {
    this.props.updateComposerState({ thread: true });
    this.setState({ firstRun: false });
  }

  componentWillUnmount() {
    this.props.initReplyMessage();
    this.props.updateComposerState({ selectedMessage: true });
  }

  handleSend = async () => {
    const { messageContent: { webContent } } = this.props;
    if (!(webContent.text).trim()) {
      this.props.snackBarStatus({
        payload: {
          title: 'You must write something to send',
          type: 'error',
          enable: true,
        },
      });
    } else {
      this.toggleConfirmDialog(true);
    }
  };

  handleSendConfirm = () => {
    this.sendReply();
    this.toggleConfirmDialog(false);
  }

  sendReply = () => {
    const { organizationPublicId, recipientGroups, selectedMessage, selectedThread } = this.props;

    if (recipientGroups.length > 0) {
      // a common message
      this.props.sendThreadMessage(true).then(() => {
        this.props.eraseEditorContent()
        this.props.getThread(
          selectedMessage.publicId,
          organizationPublicId,
          selectedThread.publicId,
        );
      });
    } else {
      const group = selectedMessage.sendToMembers ? selectedMessage.sendToMembers[0].group : null;
      if (group) {
        this.props.sendDirectMessage(group.publicId, true);
      }
    }

    this.props.mainStatusLoading(false);
  };

  renderDialog = () => {
    // TODO: unify
    const fullName = getMemberInfo(this.props.selectedThread.sentByMember).displayedFullName;
    const confirmationMessage = `ATTENTION: You are about to send a message to  <b>${fullName}</b>. Would you like to proceed?`;
    return (
      <div>
        <Dialog
          open={this.state.sendConfirmDialogOpen}
          onClose={() => this.toggleConfirmDialog(false)}
          className="return-to-edit"
          style={{
            backgroundColor: 'transparent',
            borderRadius: '15px',
            zIndex: '1300000',
          }}
        >
          <DialogContent className="return-to-edit-modal-message">
            <div className="attention-Settings" dangerouslySetInnerHTML={{ __html: confirmationMessage  }} />
            <div className="modal-actions">
              <button onClick={this.handleSendConfirm} className="button">
                Yes
              </button>
              <button onClick={() => this.toggleConfirmDialog(false)} className="button">
                No
              </button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    )
  };

  render() {
    const {
      messageContent: { editorContent: { html, text }},
      selectedThread, isLoading, updateEditorContent, isMessageSending,
      timeZone
    } = this.props;

    const sentByMember = getMemberInfo(selectedThread.sentByMember);
    return (
      <div id="reply-selected" className="content-inner-wrapper">
        <MessageThreadItem
          messageHtml={selectedThread.webContent}
          userImage={selectedThread.sentByMember.profilePictureUrl}
          userName={sentByMember.displayedFullName}
          date={getDateInTimezone(selectedThread.createdAt, timeZone, 'MM/DD/YY h:mm A')}
        />
        {isLoading ? <LoaderOverlay /> : this.renderThread()}

        {isMessageSending && <LoaderOverlay />}

        {!isMessageSending && (
          <MessageEditor
            option="thread"
            placeholder={`Reply to ${sentByMember.displayedFullName}`}
            nextButtonText="Send now"
            html={html}
            contentLength={text.length}
            onGalleryOpen={this.handleGalleryOpen}
            onNextButtonClick={this.handleSend}
            onHtmlSet={c => updateEditorContent(c, null, true)}
          />
        )}
        {this.renderDialog()}
      </div>
    );
  }
}

const mS = state => ({
  messageContent: state.messageReducer.messageContent,
  recipientGroups: state.messageReducer.recipientGroups,
  isMessageSending: state.messageReducer.isMessageSending,

  selectedMessage: state.messagesReducer.selectedMessage,

  organizationPublicId: state.organizationReducer.organization.public_id,
  timeZone: state.organizationReducer.organization.time_zone,

  isLoading: state.threadReducer.isLoading,
  selectedThread: state.threadReducer.selectedThread,
});

const mD = {
  addImageInComposer,
  closeCustomModal,
  displayGallery,
  eraseEditorContent,
  getThread,
  initReplyMessage,
  mainStatusLoading,
  openCustomModal,
  sendThreadMessage,
  sendDirectMessage,
  snackBarStatus,
  updateComposerState,
  updateEditorContent,
};

/* eslint-enable */
export default connect(
  mS,
  mD,
)(ReplyThread);
