import PropTypes from 'prop-types';

const MessageEditorPropType = {
  option: PropTypes.oneOf(['simple', 'selected', 'thread']).isRequired,
  placeholder: PropTypes.string,
  nextButtonText: PropTypes.string,
  content: PropTypes.string,
  contentLength: PropTypes.number,
  loadingMessage: PropTypes.bool,
  html: PropTypes.string,
  onNextButtonClick: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onGalleryOpen: PropTypes.func.isRequired,
  onTemplatesOpen: PropTypes.func,
  onHtmlSet: PropTypes.func.isRequired,
};

export default MessageEditorPropType;
