import { ACTIONS } from '../Actions/groupsFiltersActions';
import { SORT_ACTIVE } from '../Config/Constants';

const initialState = {
  isFiltersOpen: false,
  filters: {
    privacyLevel: [],
    activity: [],
    shareOptions: [],
    groupType: [],
  },
  sort: SORT_ACTIVE[0].value,
  searchTerm: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.TOGGLE:
      return { ...state, isFiltersOpen: action.payload };

    case ACTIONS.CHANGE:
      return { ...state, filters: action.payload };

    case ACTIONS.SORT_CHANGE:
      return { ...state, sort: action.payload };

    case ACTIONS.SEARCH_TERM_CHANGE:
      return { ...state, searchTerm: action.payload };

    case ACTIONS.RESET:
      return { ...initialState };

    default:
      return state;
  }
};
