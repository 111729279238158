import posed from 'react-pose';

const AnimatedText = posed.span({
  fullHeight: {
    height: 'auto',
    applyAtStart: {
      whiteSpace: 'normal',
    },
  },
  visible: {
    height: '20px',
    width: '100%',
  },
  appearing: {
    height: '20px',
  },
  hidden: {
    width: '0%',
    heigth: '20px',
  },
});

export default AnimatedText;

