import PropTypes from 'prop-types';

const CustomMessagePropType = {
  hideMessage: PropTypes.func,
  handleClickAction: PropTypes.object,
  buttonMessageAction: PropTypes.string,
  isButton: PropTypes.bool,
};

export default CustomMessagePropType;
