const styles = {
  root: {
    '&.take-all-available-space': {
      width: '100%',
    },
    display: 'flex',
    alignItems: 'flex-end',
    borderBottom: '2px solid #cfcfcf',
    '&.has-errors': {
      borderBottom: '2px solid #ea5858',
    },
    '& input': {
      fontFamily: 'GothamSSm-Medium',
      fontSize: '16px',
      color: '#4a4a4a',
      width: '100%',
      outline: '0',
      borderWidth: '0 0 2px',
      borderColor: '#d9d9d9',
      textAlign: 'center',
      height: '40px',
      backgroundColor: 'transparent',
    },
    '& input::placeholder': {
      color: '#e3e3e3',
      padding: '0px !important',
    },
  },
  voidInputBox: {
    width: '40px',
    height: '40px',
  },
  passwordView: {
    borderStyle: 'none',
    backgroundColor: 'transparent',
    '& img.password-visibility': {
      height: '100%',
    },
  },
  iconContainer: {
    width: '45px',
    height: '45px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    '& i': {
      padding: '20px',
      backgroundSize: 'contain',
      backgroundColor: 'transparent',
    },
  },
};

export default styles;
