import React from 'react';
import PropTypes from 'prop-types';
import './index.sass';

const SharesList = ({ items, onRemove }) => (
  <div className="shares-list">
    {items.map(share => (
      <div key={share.publicId} className="share-item">
        <div className="share-inner-wrapper">
          <div className="title">{share.title}</div>
          <div className="description">{share.description} </div>
        </div>
        <button onClick={() => onRemove(share.publicId)} />
      </div>
    ))}
  </div>
);

export default SharesList;

SharesList.defaultProps = {
  items: [],
}

SharesList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  onRemove: PropTypes.func.isRequired,
};
