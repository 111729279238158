import React from 'react';
import ErrorIcon from '../../Theme/Icons/error.svg';
import ErrorMessagePropType from '../../PropTypes/ErrorMessagePropType';
import './index.sass';

const ErrorMessage = props => (
  <div className="form__error-message">
    <p className="form__error-text">{props.error}</p>
    <button
      onClick={() => {
        props.hideMessage();
      }}
      className="form_error-close"
      type="button"
      aria-label="Close"
    >
      <img src={ErrorIcon} className="Error-icon" alt="ErrorIcon" />
    </button>
  </div>
);

ErrorMessage.propTypes = ErrorMessagePropType;

export default ErrorMessage;
