import React from 'react';
import Grid from '@material-ui/core/Grid';
import ContentEditable from 'react-contenteditable';
import moment from 'moment';
import favoriteIcon from '../../Theme/Icons/favorite.svg';
import commentIcon from '../../Theme/Icons/chat_bubble_twitter.svg';
import emailIcon from '../../Theme/Icons/email_twitter.svg';
import retweetIcon from '../../Theme/Icons/repeat.svg';
import arrowIcon from '../../Theme/Icons/arrow_down.svg';
import memberImg from '../../Theme/Icons/member.svg';
import TemplateMessagePreviewPropType from '../../PropTypes/TemplateMessagePreviewPropType';
import './index.sass';

const TemplateTwitterPreview = ({ content, picture, profileName, profilePicture }) => {
  return (
    <div className="send-twitter-container">
      <div className="message-container">
        <div className="twitter-content" id="scroll">
          <div className="twitter-message show">
            <Grid item xs={1} className="member-message-twitter">
              <img src={profilePicture || memberImg} alt={profileName} />
            </Grid>
            <div className="twitter-body">
              <div className="twitter-header">
                <div>
                  <p className="twitter-name">{profileName}</p>
                </div>
                <div className="twitter-lower-header">
                  <p className="twitter-handle">@{profileName}</p>
                  <p className="twitter-date"> · {moment().format('MMM. D')} </p>
                </div>
                <img src={arrowIcon} className="twitter-arrow" alt="arrow_down" />
              </div>
              <ContentEditable
                className="twitter-body-text"
                tagName="p"
                disabled
                html={content}
              />
              {!!picture && (
                <div className="twitter-images">
                  <img src={picture} alt="gallery" className="gallery-image" />
                </div>
              )}
              <div className="twitter-buttons">
                <img src={commentIcon} alt="comment" />
                <img src={retweetIcon} alt="retweet" />
                <img src={favoriteIcon} alt="favorite" />
                <img src={emailIcon} alt="email " />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TemplateTwitterPreview.propTypes = TemplateMessagePreviewPropType;

export default TemplateTwitterPreview;
