import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import defaultImg from '../../Theme/Icons/member.svg';
import evaLogo from '../../Theme/Icons/eva.svg';
import IconButton from '@material-ui/core/IconButton';
import { KeyboardArrowLeft } from '@material-ui/icons';
import EvaTooltip from '../../Components/EvaTooltip';
import switchImg from '../../Theme/Icons/switch-icon.svg';
import { redirectToAdmin } from '../../Helpers/redirectToAdmin';
import AdminEvaSwitchButton from '../../Components/MainMenu/AdminEvaSwitchButton';
import { navigationControllerRedirect } from '../../Actions/navigationControllerActions';
import './index.sass';

/* eslint-disable no-underscore-dangle */
class AppBarMobile extends PureComponent {
  constructor(props) {
    super(props);
  }

  renderUserImage = () => (
    <div className="user-image">
      <img src={this.props.profilePicture || defaultImg} alt={this.props.profileName}/>
    </div>
  );

  renderLogoMini = () => (
    <button className="logo-mini" onClick={() => this.props.navigationControllerRedirect(`/`)}>
      <img src={evaLogo} alt="Evangelus"/>
    </button>
  );


  renderPageType() {
    const { title, onBackClick } = this.props;
    return (
      <React.Fragment>
        <IconButton className="back-button" onClick={onBackClick}>
          <KeyboardArrowLeft/>
        </IconButton>

        <div className="page-title">{title}</div>

        <div className="user-controls-pad">
          {this.renderLogoMini()}
          {this.renderUserImage()}
        </div>
      </React.Fragment>
    )
  }

  // TODO: implement
  renderDefaultType() {
    const { isSuperAdmin } = this.props;

    return (
        <React.Fragment>

          <div>Evangelus logo</div>

          <div className="user-controls-pad">

            {isSuperAdmin && (
              <EvaTooltip title="Super Admin">
                <AdminEvaSwitchButton
                  handleAction={() => redirectToAdmin()}
                  icon={switchImg}
                />
              </EvaTooltip>
            )}

            {!isSuperAdmin && this.renderLogoMini()}

            {this.renderUserImage()}
          </div>
        </React.Fragment>
      )
  }

  render() {
    const { type } = this.props;
    return (
      <div className={`app-bar-mobile type-${type}`}>
        {type === 'page' ? this.renderPageType() : this.renderDefaultType()}
      </div>
    );
  }
}

AppBarMobile.propTypes = {
  title: PropTypes.string,
  type: PropTypes.oneOf(['default', 'page']),
};

AppBarMobile.defaultProps = {
  title: null,
  type: 'default',
  onBackClick: () => {
  }
};

const mD = {
  navigationControllerRedirect,
};
/* eslint-disable */
const mS = state => ({
  profilePicture: state.userProfileReducer.member.profile_picture_url,
  profileName: state.userProfileReducer.display_name,
  isSuperAdmin: state.userProfileReducer.isSuperAdmin,
});
/* eslint-enable */
export default connect(mS, mD)(AppBarMobile);
