import React from 'react';
import PropTypes from 'prop-types';
import { SMS_TEXT_LENGTH_LIMIT } from '../../Config/Constants';
import './index.sass';
import EvaTooltip from '../EvaTooltip';

const LettersCounter = ({ length, limit, tooltip }) => {
  const counterBody = <span><span className={length > limit ? 'invalid' : ''}>{length}</span> / {limit}</span>;
  return (
    <div className="letters-counter">
      {tooltip ? <EvaTooltip title={tooltip}>{counterBody}</EvaTooltip> : counterBody}
    </div>
  )
}

LettersCounter.propTypes = {
  length: PropTypes.number.isRequired,
  limit: PropTypes.number,
  tooltip: PropTypes.string,
};

LettersCounter.defaultProps = {
  limit: SMS_TEXT_LENGTH_LIMIT,
  tooltip: null,
};

export default LettersCounter;
