import { ACTIONS } from '../Actions/invitationActions';

const initialState = {
  member: {},
  organization: {},
  group: {},
  notFound: false,
  invitationError: null,
  loading: false,
};

const invitationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_ORGANIZATION:
      return { ...state, organization: action.payload };
    case ACTIONS.GET_ORGANIZATION_ADMIN_PROMOTION:
    case ACTIONS.GET_ORGANIZATION_SUPER_ADMIN_PROMOTION:
      return { ...state,
        organization: action.payload.organization,
        member: action.payload.member,
      };
    case ACTIONS.GET_GROUP:
      return { ...state, group: action.payload };
    case ACTIONS.GET_GROUP_ADMIN_PROMOTION:
      return { ...state,
        group: action.payload.group,
        member: action.payload.member,
      };
    case ACTIONS.NOT_FOUND:
      return { ...state, notFound: true, invitationError: action.payload };
    case ACTIONS.CLEAR_INVITATION:
      return { ...state, group: {}, organization: {} };
    case ACTIONS.LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

export default invitationReducer;
