import AXIOS from '../Config/AxiosConfig';
import headers from '../Api/Helper';

const Member = {
  createMember({
    firstName,
    lastName,
    birthday,
    phoneNumber,
    email,
    groupOrSubGroup,
  }) {
    const number = [
      {
        description: 'Main',
        country_code: '',
        phone_number: phoneNumber,
        is_default: true,
      },
    ];
    return AXIOS.post(
      `/eva/groups/${groupOrSubGroup.public_id}/members/invitation`,
      {
        first_name: firstName,
        last_name: lastName,
        birth_date: birthday,
        status: 'active',
        phones: phoneNumber !== '' ? number : [],
        user: {
          email,
          username: email,
          plain_password: '1234',
        },
      },
      { headers: headers() },
    );
  },
  editMember({
    publicId,
    firstName,
    lastName,
    birthday,
    email,
    groups,
    phoneNumber,
    postalCode,
    cityText,
    referenceAddress,
    countryPublicId,
    statePublicId,
  }) {
    const publicIdGroup =
      groups && groups.publicId ? groups.publicId : groups.public_id;
    return AXIOS.put(
      `/eva/groups/${publicIdGroup}/members/${publicId}`,
      {
        firstName,
        lastName,
        birthDate: birthday,
        countryPublicId,
        statePublicId,
        cityText,
        postalCode,
        referenceAddress,
        email,
        mainPhone: phoneNumber,
      },
      { headers: headers() },
    );
  },

  getProfileInformation(memberId, organizationId) {
    return AXIOS.get(`/v2/eva/member/${memberId}/profile-information${organizationId ? '?org=' + organizationId : ''}`, {
      headers: headers(),
    });
  },
  updateNotificationPreferences(notificationOpts, groupId, memberId) {
    return AXIOS.put(
      `/eva/group-member/${groupId}/${memberId}/notification-preference`,
      notificationOpts,
      { headers: headers() },
    );
  },
  removeMemberFromWideCommunication(groupId, memberId) {
    return AXIOS.put(
      `/eva/group-member/${groupId}/${memberId}/notification-preference`,
      { isNotifyBySms: false, isNotifyByEmail: false },
      { headers: headers() },
    );
  },
  updateProfileImage(groupId, memberId, img) {
    let url = `eva/groups/${groupId}/members/${memberId}/profile-picture`;
    if (!groupId) url = `eva/member/${memberId}/profile-picture`;
    return AXIOS.put(url, { img }, { headers: headers() });
  },
  getMember() {
    return AXIOS.get('/member/profile-information', { headers: headers() });
  },
  sendInvitation(groupId, member) {
    return AXIOS.post(
      `/eva/groups/${groupId}/member/${member}`,
      {},
      { headers: headers() },
    );
  },
  clearBounce(memberPublicId) {
    return AXIOS.post(`/eva/member/${memberPublicId}/clear-bounce`, {}, { headers: headers() });
  },
  joinRequest(groupId) {
    return AXIOS.post(
      `/eva/group/${groupId}/request/join`,
      {},
      { headers: headers() },
    );
  },
  revokeTemporaryAdminPrivileges(member, organization) {
    return AXIOS.delete(
      `/admin-panel/organization/${organization.public_id}/member/${member.public_id}/remove-admin-temporary`,
      { headers: headers() },
    );
  },
};

export default Member;
