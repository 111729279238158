import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import {
  navigationControllerRedirect,
  __NavigationControllerState,
} from '../../Actions/navigationControllerActions';

import NavigationControllerPropTypes from '../../PropTypes/NavigationControllerPropType';

class NavigationController extends Component {
  state = {
    redirect: false,
    url: null,
    toUpdate: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.redirect) {
      /* eslint-disable */
      nextProps.__NavigationControllerState(null, false);
      /* eslint-enable */
      return { redirect: true, url: nextProps.goToURL, toUpdate: true };
    } else if (prevState.toUpdate) {
      return { redirect: false, url: null, toUpdate: false };
    } else {
      return null;
    }
  }

  render() {
    return (
      this.state.redirect && this.state.url ?
        <Redirect to={this.state.url} />
        : null
    );
  }
}

NavigationController.propTypes = NavigationControllerPropTypes;

const mS = state => ({
  redirect: state.navigationControllerReducer.redirect,
  goToURL: state.navigationControllerReducer.goToURL,
});

const mD = {
  navigationControllerRedirect,
  __NavigationControllerState,
};

export default connect(mS, mD)(NavigationController);
