import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import ContentEditable from 'react-contenteditable';
import moment from 'moment';
import publicIcon from '../../Theme/Icons/public.svg';
import moreIcon from '../../Theme/Icons/more_horiz.svg';
import likeIcon from '../../Theme/Icons/thumb_up.svg';
import commentIcon from '../../Theme/Icons/chat_bubble.svg';
import shareIcon from '../../Theme/Icons/share.svg';
import memberImage from '../../Theme/Icons/member.svg';
import Color from '../../Theme/Colors';
import FacebookPostviewPropType from '../../PropTypes/FacebookPostviewPropType';
import './../../Components/MessageFacebookPreview/index.sass';

const styles = theme => ({
  colorBar: {},
  colorChecked: {},
  tabsIndicator: {
    background: 'linear-gradient(to right, #9966bf 0%, #6174c9 98%)',
  },
  tabsRoot: {
    textTransform: 'initial',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'GothamSSm-Book',
    color: Color.grey,

    '&:focus': {
      color: Color.purple,
    },
  },
});

const FacebookPostview = ({ enabled, profileName, profilePic, content, image }) => (
  <div>
    <div className="send-facebook-container post-view">
      <div className="message-container">
        <div className="facebook-content" id="scroll">
          <div
            className={`facebook-message ${
              enabled ? 'show' : 'blur'
            }`}
          >
            <div className="facebook-header">
              <Grid item xs={1} className="member-message-facebook">
                <img src={profilePic || memberImage} alt="member"/>
              </Grid>
              <div className="facebook-header-text">
                <p className="facebook-name">{profileName}</p>
                <div className="facebook-lower-header">
                  <p className="facebook-date">
                    {' '}
                    · {moment().format('MMM. D')}
                  </p>
                  <img
                    src={publicIcon}
                    alt="public"
                    className="facebook-public"
                  />
                </div>
              </div>
              <img src={moreIcon} alt="more" className="facebook-more"/>
            </div>
            <ContentEditable
              className="facebook-body-text"
              id="scroll"
              tagName="p"
              disabled
              html={content}
            />
            {image && (
              <div className="facebook-images">
                <img
                  src={image}
                  alt="gallery"
                  className="gallery-image"
                />
              </div>
            )}
            <hr/>
            <div className="facebook-buttons">
              <div>
                <img src={likeIcon} alt="like"/>
                <div>Like</div>
              </div>
              <div>
                <img src={commentIcon} alt="comment"/>
                Comment
              </div>
              <div>
                <img src={shareIcon} alt="share"/>
                Share
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

FacebookPostview.propTypes = FacebookPostviewPropType;

export default withStyles(styles)(FacebookPostview);
