import React, { Component } from 'react';
// import Tab from '@material-ui/core/Tab';
import ContentEditable from 'react-contenteditable';
import { withStyles } from '@material-ui/core/styles';
import MessageWebPreviewPropType from '../../PropTypes/MessageWebPreviewPropType';
import MessagePreviewToolbar from '../MessagePreviewToolbar';
import './index.sass';

const styles = theme => ({
  tabsIndicator: {
    background: 'linear-gradient(to right, #9966bf 0%, #6174c9 98%)',
  },
  tabsRoot: {
    textTransform: 'initial',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'GothamSSm-Book',
    color: '#a9a9a9',

    '&:focus': {
      color: '#9a66bf',
    },
  },
});

class MessageWebPreview extends Component {
  state = {
    value: 0,
    html: '',
  };

  componentDidMount() {
    this.setState({ html: this.props.content });
  }

  handleChange = evt => {
    this.setState({ html: evt.target.value });
    this.props.onChange(evt.target.value);
  };
  render() {
    const { html } = this.state;
    const { allowed, enabled, previewImages, selectedPreviewImage, onPreviewChange, onSendByChange } = this.props;

    return (
      <div>
        {allowed ? (
          <div className="message-email-container" id="web-page-container">
            <div className="send-email-container">
              <MessagePreviewToolbar
                imagePicker
                text="Send to web"
                enabled={enabled}
                previewImages={previewImages}
                selectedPreviewImage={selectedPreviewImage}
                onImageSelect={onPreviewChange}
                onChange={onSendByChange}
              />
            </div>
            <div className="page-container designerContent compose">
              <ContentEditable
                className="content"
                id="scroll"
                tagName="p"
                html={html} // innerHTML of the editable div
                onChange={this.handleChange} // handle innerHTML change
              />
            </div>
          </div>
        ) : (
          <div className="not-available">
            <p>
              Posting to Web is not currently enabled for this group(s).
              <br />
              Please check the group(s) settings. Be sure to save this message
              as a draft to resume sending it at a later time.
            </p>
          </div>
        )}
      </div>
    );
  }
}

MessageWebPreview.propTypes = MessageWebPreviewPropType;

export default withStyles(styles)(MessageWebPreview);
