import React, { Component } from 'react';
import { RadioGroup } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import './index.sass';

class MessageImagePicker extends Component {
  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {
      isOpen: false,
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (this.state.isOpen) {
        this.handleClose();
      }
    }
  }

 handleOpen() {
    this.setState({ isOpen: true });
  }

  handleClose() {
    this.setState({ isOpen: false });
  }

  renderOption = (url) => {
    return (
      <div key={url} className="image-preview-item" style={{ backgroundImage: `url(${url})` }}>
        <FormControlLabel className="eva-colored-radio" value={url} control={<Radio />} />
      </div>
    );
  };

  render() {
    const { isOpen } = this.state;
    const { previewImages, selectedPreviewImage, onSelect } = this.props;
    const count = previewImages.length;
    return (
      <div className="message-image-picker" ref={this.setWrapperRef}>
        <button
          className="image-pick-button"
          disabled={count < 2}
          onClick={() => isOpen ? this.handleClose() : this.handleOpen()}
        >
          Set Preview Image
        </button>
        {isOpen && <div className="previews-popup">
          <RadioGroup
            className={`image-preview-list count-${count < 3 ? count : 'more'}`}
            aria-label="preview-images"
            name="previews"
            value={selectedPreviewImage}
            onChange={(event) => onSelect(event.target.value)}>
            {previewImages.map(url => this.renderOption(url))}
          </RadioGroup>
        </div>}
      </div>
    );
  }
}
MessageImagePicker.propTypes = {

};

export default MessageImagePicker;
