import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../Avatar';
import EvaTooltip from '../EvaTooltip';

const NotificationCard = props => (
  <div
    className={`notification-card-container type-${props.type}`}
    data-created-at={props.createdAt}
  >
    <div className="notification-image-container">
      <Avatar borderless className="notification-profile-image" image={props.image} />
    </div>
    <div className={`notification-data ${props.notificationType}`}>
      <div className="notification-profile-name" title={props.name}>{props.name}</div>
      <div className={`notification-text ${props.notificationType}-text`}>
        <span className="notification-info" dangerouslySetInnerHTML={{ __html: props.text }} />
        <b>{props.group}</b>
      </div>
    </div>
    <div className="notification-controls-mary">
      {props.notificationType === 'joinRequest' && (
        <div className="notifications-buttons">
          <EvaTooltip title="Reject">
            <button onClick={props.deny} className="notification-deny" />
          </EvaTooltip>
          <EvaTooltip title="Approve">
            <button onClick={props.accept} className="notification-accept" />
          </EvaTooltip>
        </div>
      )}
      {props.notificationType === 'replyRequest' && (
        <button onClick={props.dismiss} className="notification-clear" />
      )}
      {props.notificationType === 'memberJoined' && (
        <button onClick={props.dismiss} className="notification-clear" />
      )}
      {props.notificationType === 'sharedRequest' && (
        <button onClick={props.dismiss} className="notification-arrow" />
      )}
    </div>
  </div>
);

NotificationCard.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  notificationType: PropTypes.string.isRequired,
  group: PropTypes.string,
  dismiss: PropTypes.func,
  accept: PropTypes.func,
  deny: PropTypes.func,
};

NotificationCard.defaultProps = {
  image: '',
  group: '',
  accept: () => {},
  deny: () => {},
  dismiss: () => {},
};


export default NotificationCard;
