/* eslint-disable */
const preserveCamelCase = string => {
  let isLastCharLower = false;
  let isLastCharUpper = false;
  let isLastLastCharUpper = false;

  for (let i = 0; i < string.length; i++) {
    const character = string[i];

    if (isLastCharLower && /[a-zA-Z]/.test(character) && character.toUpperCase() === character) {
      string = string.slice(0, i) + '-' + string.slice(i);
      isLastCharLower = false;
      isLastLastCharUpper = isLastCharUpper;
      isLastCharUpper = true;
      i++;
    } else if (
      isLastCharUpper &&
      isLastLastCharUpper &&
      /[a-zA-Z]/.test(character) &&
      character.toLowerCase() === character
    ) {
      string = string.slice(0, i - 1) + '-' + string.slice(i - 1);
      isLastLastCharUpper = isLastCharUpper;
      isLastCharUpper = false;
      isLastCharLower = true;
    } else {
      isLastCharLower =
        character.toLowerCase() === character && character.toUpperCase() !== character;
      isLastLastCharUpper = isLastCharUpper;
      isLastCharUpper =
        character.toUpperCase() === character && character.toLowerCase() !== character;
    }
  }

  return string;
};

const camelCase = (input, options) => {
  if (!(typeof input === 'string' || Array.isArray(input))) {
    throw new TypeError('Expected the input to be `string | string[]`');
  }

  options = Object.assign(
    {
      pascalCase: false,
    },
    options,
  );

  const postProcess = x => (options.pascalCase ? x.charAt(0).toUpperCase() + x.slice(1) : x);

  if (Array.isArray(input)) {
    input = input
      .map(x => x.trim())
      .filter(x => x.length)
      .join('-');
  } else {
    input = input.trim();
  }

  if (input.length === 0) {
    return '';
  }

  if (input.length === 1) {
    return options.pascalCase ? input.toUpperCase() : input.toLowerCase();
  }

  const hasUpperCase = input !== input.toLowerCase();

  if (hasUpperCase) {
    input = preserveCamelCase(input);
  }

  input = input
    .replace(/^[_.\- ]+/, '')
    .toLowerCase()
    .replace(/[_.\- ]+(\w|$)/g, (_, p1) => p1.toUpperCase())
    .replace(/\d+(\w|$)/g, m => m.toUpperCase());

  return postProcess(input);
};
/* eslint-enable */
export const copyToClipboard = el => {
  const input = el;
  if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
    // save current contentEditable/readOnly status
    const { contentEditable, readOnly } = input;

    // convert to editable with readonly to stop iOS keyboard opening
    input.contentEditable = true;
    input.readOnly = true;

    // create a selectable range
    const range = document.createRange();
    range.selectNodeContents(input);

    // select the range
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    input.setSelectionRange(0, 999999);

    // restore contentEditable/readOnly to original state
    input.contentEditable = contentEditable;
    input.readOnly = readOnly;
  } else {
    input.select();
  }
  document.execCommand('copy');
  input.blur();
};

export const getDictionary = alphabet =>
  alphabet.reduce((dict, letter) => {
    // eslint-disable-next-line no-param-reassign
    dict[letter] = [];
    return dict;
  }, {});

/**
 * Transforms the Objects keys into camel case keys if they are in a different format
 * @param {Object} object The object whose keys are not in camel case
 */
export function camelCaseObjects(object) {
  if (object === null || object === undefined) {
    return object;
  }
  if (typeof object === 'object' && !(object instanceof Array)) {
    const objectKeys = Object.keys(object);
    const newObject = {};
    objectKeys.forEach(key => {
      newObject[camelCase(key)] = camelCaseObjects(object[key]);
    });
    return newObject;
  } else if (typeof object === 'object' && object instanceof Array) {
    return object.map(camelCaseObjects);
  }
  return object;
}
