export const ACTIONS = {
  UPDATE_COMPOSER_STATE: '[Composer] Update composer state',
  UPDATE_MPA_LOGIN_STATUS: '[Composer] Update MPA login status',
  CLEAR: '[Composer] Clear',
};

export const updateComposerState = obj => dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_COMPOSER_STATE,
    payload: obj,
  });
}

export const updateIsMpaLogin = bool => dispatch =>
  dispatch({ action: ACTIONS.UPDATE_MPA_LOGIN_STATUS, payload: bool })


export const clearCompose = () => ({ type: ACTIONS.CLEAR });
