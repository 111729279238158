import React from 'react';
import InputPropType from '../../PropTypes/InputPropType';
import './index.sass';
import EvaTooltip from '../EvaTooltip';

const InputStandard = ({
  className,
  name,
  type,
  placeholder,
  value,
  onChange,
  icon,
  focused,
  maxLength,
  onBlur,
  disable,
  color,
  tooltipTitle,
  useId,
  useDivContainer = false,
  readOnly = false,
}) => {
  const iconClass = icon ? `input-eva-${icon}-icon` : '';
  let inputIcon;
  if (tooltipTitle) {
    inputIcon = useDivContainer ? (
      <div className={`${iconClass}`} />
    ) : (
      <i className={`${iconClass}`} alt="" />
    );
    inputIcon = <EvaTooltip title={tooltipTitle}>{inputIcon}</EvaTooltip>;
  }
  const inputField = (
    <input
      readOnly={readOnly}
      name={name}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={event => (onChange ? onChange(event, name) : () => {})}
      onBlur={onBlur}
      className={`form-field ${className} ${iconClass} ${focused ||
        ''} input-eva-border-bottom input-eva`}
      maxLength={maxLength}
      disabled={disable ? 'disabled' : ''}
      style={{ backgroundColor: color || '#FFF' }}
      autoComplete="off"
    />
  );
  if (useId !== undefined) {
    return (
      <div id={useId}>
        {inputIcon}
        {inputField}
      </div>
    );
  }
  return (
    <div>
      {inputIcon}
      <input
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={event => (onChange ? onChange(event, name) : () => {})}
        onBlur={onBlur}
        className={`form-field ${className} ${iconClass} ${focused ||
          ''} input-eva-border-bottom input-eva`}
        maxLength={maxLength}
        disabled={disable ? 'disabled' : ''}
        autoComplete="off"
      />
    </div>
  );
};

InputStandard.propTypes = InputPropType;

export default InputStandard;
