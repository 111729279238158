import AXIOS from '../Config/AxiosConfig';
import headers from './Helper';

const URL_BASE = '/eva';
const URL_BASE_v2 = '/v2/eva';

const Templates = {
  /* endpoints for folders */
  createFolder(data) {
    return AXIOS.post(`${URL_BASE}/templateFolder`, data, { headers: headers() },);
  },

  getFoldersList(isAllFolders) {
    return AXIOS.get(`${URL_BASE_v2}/folder/template${isAllFolders ? '?allFolders=true' : ''}`, {
      headers: headers(),
    });
  },

  getFolderTemplateById(publicId) {
    return AXIOS.get(`/eva/templateFolder/${publicId}`, {
      headers: headers(),
    });
  },

  getFolderById(publicId, sort, term, page, limit) {
    const sortFilter = sort === undefined || sort === 'sort' ? 'recent' : sort;
    const searchSort = {
      'a-z': 'asc',
      'z-a': 'desc',
    };
    let queryParams = [];

    if (sortFilter && sortFilter !== 'recent') {
      const sortType = searchSort[sortFilter] ? searchSort[sortFilter] : sortFilter;
      queryParams.push(`sortBy=title&sortOrder=${sortType}`);
    } else {
      queryParams.push('sortBy=createdAt');
    }

    if (term) {
      queryParams.push(`term=${term}`);
    }

    if (page) {
      queryParams.push(`page=${page}`);
      queryParams.push(`limit=${limit}`);
    }

    queryParams = queryParams
      .filter(v => !!v)
      .toString()
      .replace(/,/g, '&');

    return AXIOS.get(`${URL_BASE_v2}/folder/${publicId}/template?${queryParams}`, {
      headers: headers(),
    });
  },

  getTemplate(templateId) {
    return AXIOS.get(`${URL_BASE}/folder/template/${templateId}`, {
      headers: headers(),
    });
  },

  saveTemplate(data) {
    return AXIOS.post(`${URL_BASE}/template`, data, {
      headers: headers(),
    });
  },
  updateTemplate(data) {
    return AXIOS.put(`${URL_BASE}/template/${data.publicId}`, data, {
      headers: headers(),
    });
  },

  deleteTemplate(templates) {
    const body = templates;
    return AXIOS.post(`${URL_BASE}/template/delete`, body, { headers: headers() });
  },

  updateFolder(publicId, data) {
    return AXIOS.put(
      `${URL_BASE}/templateFolder/${publicId}`,
      data,
      {
        headers: headers(),
      },
    );
  },

  deleteTemplateFolder(data) {
    const { folderPublicId } = data;
    return AXIOS.delete(`${URL_BASE}/template-folder/${folderPublicId}`, {
      headers: headers(),
    });
  },

  moveTemplatesToFolder(data) {
    return AXIOS.post(`${URL_BASE}/templates-move`, data, {
      headers: headers(),
    });
  },
};

export default Templates;
