import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import Input from '@material-ui/core/Input';
import { withStyles } from '@material-ui/core/styles';
import { Scrollbars } from 'react-custom-scrollbars';
import ButtonStandard from '../Button';
import ScheduleMessagePropType from '../../PropTypes/ScheduleMessagePropType';
import root from './style';
import './index.sass';

const styles = theme => root(theme);

class TimeZonePicker extends Component {
  state = {
    list: localStorage.getItem('timeZoneList') !== null ? localStorage.getItem('timeZoneList') : [],
    filterList:
      localStorage.getItem('timeZoneList') !== null ? localStorage.getItem('timeZoneList') : [],
    selectedTimeZone: this.props.selected,
    selectedGMT: '',
  };

  getTimeZoneFormat = () => {
    const { selectedTimeZone, list } = this.state;
    if (selectedTimeZone && selectedTimeZone !== 'inactive') {
      const filter = JSON.parse(list).filter(
        zone => zone.name.toLowerCase().indexOf(selectedTimeZone.toLowerCase()) >= 0,
      );
      return `(${filter[0].GMT}) - ${filter[0].name}`;
    }
    return null;
  };

  selectTimeZone = e =>
    this.setState({ selectedTimeZone: e.name, selectedGMT: e.GMT });

  saveTimeZone = () => {
    const { selectedTimeZone, selectedGMT } = this.state;
    this.props.onTimeZoneSave({ timeZone: selectedTimeZone, gmt: selectedGMT });
  };

  searchTimeZone = e => {
    const { list } = this.state;
    const searchPattern = e.target.value;
    const filter = JSON.parse(list).filter(
      zone =>
        zone.name.toLowerCase().indexOf(searchPattern.toLowerCase()) >= 0 ||
        zone.GMT.toLowerCase().indexOf(searchPattern.toLowerCase()) >= 0,
    );
    this.setState({ filterList: JSON.stringify(filter) });
  };

  renderTimeZoneList = () => {
    const { filterList, selectedTimeZone } = this.state;
    return JSON.parse(filterList).map(zone => (
      <button
        className={`timezone ${
          selectedTimeZone === zone.name ? 'timezone-selected' : 'styless-button'
        }`}
        onClick={() => this.selectTimeZone(zone)}
      >
        ({zone.GMT}) - {zone.name}
      </button>
    ));
  };

  render() {
    const { classes } = this.props;
    const { filterList, searchTimeZone } = this.state;

    return (
      <Grid container spacing={0} id="time-zone-container" className="form-create-group">
        <Grid item xs={12} md={12} className="input-timezone">
          <Input
            className={`${classes.inputStyle} current-time-zone`}
            name="currentTimeZone"
            type="text"
            value={this.getTimeZoneFormat()}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={12} className="input-timezone search-input">
          <Input
            className={classes.inputStyle}
            name="search"
            icon="search"
            tooltipTitle="search"
            placeholder="Search time zone"
            type="text"
            maxLength="150"
            value={searchTimeZone}
            onChange={this.searchTimeZone}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} className="detail-time-zone-container">
          <Scrollbars autoHeight autoHide autoHeightMin={280} autoHeightMax={280}>
            {filterList.length > 0 && this.renderTimeZoneList()}
          </Scrollbars>
        </Grid>

        <Grid item xs={12} className="input-eva">
          <div className="button-send-message">
            <ButtonStandard title="Save" onClick={this.saveTimeZone}/>
          </div>
        </Grid>
      </Grid>
    );
  }
}

TimeZonePicker.propTypes = ScheduleMessagePropType;

export default withRouter(withStyles(styles)(TimeZonePicker));
