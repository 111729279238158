import React from 'react';
import InputPropType from '../../PropTypes/InputPropType';
import clearSearch from '../../Theme/Icons/cancel-circle.svg';
import './index.sass';

const InputForSearch = React.forwardRef(({
  name,
  onChange,
  onClear,
  disabled = false,
  clear = true,
  ...inputProps
}, ref) => {
  const handleKeyPress = event => {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  };
  const inputChange = event => {
    onChange(event.target.value, name);
  };

  return (
    <div className="input-group">
      <form onReset={() => onChange('', name)}>
        {
          clear &&
          <button
            onClick={onClear}
            className="button-icon-search"
            type="reset"
          >
            <img alt="Clear search" className="close-search-img" src={clearSearch} />
          </button>
        }
        <input
          autoComplete="off"
          className="input-search-box input-eva search-box"
          name={name}
          ref={ref}
          onChange={inputChange}
          onKeyPress={handleKeyPress}
          disabled={disabled}
          {...inputProps}
        />
      </form>
    </div>
  );
});

InputForSearch.propTypes = InputPropType;

export default InputForSearch;
