const tooltipStyle = theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
  tooltipPlacementLeft: {
    margin: '0 8px',
  },
  tooltipPlacementRight: {
    margin: '0 8px',
  },
  tooltipPlacementTop: {
    margin: '8px 0',
  },
  tooltipPlacementBottom: {
    margin: '8px 0',
  },
});

export default tooltipStyle;
