import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { changeLanguageToEnglish, changeLanguageToSpanish } from '../../Actions/localeActions';
import './index.sass';

const LanguageSwitcher = (props) => (
  <div className={classNames('language-switcher', props.className || '' )}>
    <button className={classNames({ 'active': props.locale === 'en' })} onClick={() => props.changeLanguageToEnglish()}>English</button>
    <button className={classNames({ 'active': props.locale === 'es' })} onClick={() => props.changeLanguageToSpanish()}>Spanish</button>
  </div>
);

const mS = state => ({ locale: state.localeReducer.locale });

const mD = { changeLanguageToEnglish, changeLanguageToSpanish };

export default connect(mS, mD)(injectIntl(LanguageSwitcher));

