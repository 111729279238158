import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ifIsImage from 'if-is-image';
import Input from '../../Components/Input';
import {
  uploadPicture,
  getFolderImage,
  createFolder,
  getFoldersContent,
  updateSelectedFolder,
  updateSelectedImage,
} from '../../Actions/imageGalleryActions';
import LinkGalleryPropType from '../../PropTypes/LinkGalleryProptype';
import snackBarStatus from '../../Actions/snackbarActions';
import Helpers from '../../Helpers';
import { Select } from '@mobiscroll/react';
import { mbscResponsiveOptions } from '../../Config/Constants/mobiscroll-props';
import './index.sass';

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderColor: 'red',
  },
  customSelect: {
    '&>.custom-select-container': {
      marginBottom: '10px',
      '&>.mbsc-custom-select-input': {
        paddingLeft: '7px',
      },
      '&>.right-icon>img': {
        height: '17%',
      },
    },
  },
});

class LinkGallery extends Component {
  state = {
    url: '',
    folderName: 'My Images',
    displayCreateFolder: false,
  };

  handleChangeFolder = ({ value }) => {
    if (value !== 'newFolder') {
      this.props.getFolderImage(value, 1);
      this.props.updateSelectedFolder(value);
    } else {
      this.props.updateSelectedFolder('My Images');
    }
  };

  /* eslint-disable */
  isValidUrl = str =>
    str.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
    ) != null;
  /* eslint-enable */

  isImage = str => str.match(/\.(jpeg|jpg|gif|png)$/) != null;

  createFolder = () => {
    this.setState({ displayCreateFolder: !this.state.displayCreateFolder });
  };

  uploadImage = () => {
    const { url, folderName, displayCreateFolder } = this.state;
    const { selectedFolder, selectedImage } = this.props;
    const folderValidation = displayCreateFolder ? 'newFolder' : selectedFolder;
    const data = {
      name: '',
      image: url,
      folderId: folderValidation,
    };
    if (folderName !== '' && displayCreateFolder) {
      this.props.createFolder(folderName, folderValidation).then(createFolderResponse => {
        if (createFolderResponse === 200) {
          this.props.getFoldersContent();
          this.setState({ displayCreateFolder: false });
        }
      });
    } else if (this.isValidUrl(url)) {
      Helpers.isUrlImage(url).then(res => {
        if (res) {
          this.props.uploadPicture(data).then(uploadPictureResponse => {
            if (uploadPictureResponse.status === 200) {
              this.props.getFolderImage(selectedFolder, 1);
              selectedImage.push(uploadPictureResponse.data.data.public_id);
              this.props.updateSelectedImage(selectedImage);
              this.props.openGallery();
            }
          });
        } else if (ifIsImage(url)) {
          this.props.uploadPicture(data).then(uploadPictureResponse => {
            if (uploadPictureResponse.status === 200) {
              this.props.getFolderImage(selectedFolder, 1);
              selectedImage.push(uploadPictureResponse.data.data.public_id);
              this.props.updateSelectedImage(selectedImage);
              this.props.openGallery();
            }
          });
        } else {
          this.props.snackBarStatus({
            payload: {
              title: 'The image is invalid',
              type: 'error',
              enable: true,
            },
          });
        }
      });
    } else {
      this.props.snackBarStatus({
        payload: {
          title: 'The url is invalid',
          type: 'error',
          enable: true,
        },
      });
    }

    this.setState({
      url: '',
    });
  };

  handleChange = value => {
    this.setState({
      url: value,
    });
  };

  handleFolderName = e => {
    e.preventDefault();
    this.setState({ folderName: e.target.value });
  };

  formatFolderOptions = (folders) => {
    const folderOptions = [];
    folders.forEach(folder => {
      const permissionBasicUpload = !folder.global;
      const permissionRootUpload = folder.global && this.props.isSuperAdmin;

      if (permissionBasicUpload || permissionRootUpload) {
        folderOptions.push({ text: folder.name, value: folder.publicId });
      }
    });
    return folderOptions;
  }

  /* eslint-disable */
  render() {
    const { folder, selectedFolder, isSuperAdmin, classes } = this.props;
    let idFolderSelected = selectedFolder;
    if (folder){
      const findSelectedFolder = folder.filter( fold => fold.publicId === idFolderSelected);
      if(findSelectedFolder && findSelectedFolder.length > 0) {
        if(!isSuperAdmin) {
          idFolderSelected = folder.find( fold => fold.name === 'My Images');
          if ('publicId' in idFolderSelected) this.props.updateSelectedFolder(idFolderSelected.publicId);
        }
      } else {
        const FolderSelected = folder.find( fold => fold.name === 'My Images');
        if ('publicId' in FolderSelected){
          idFolderSelected = FolderSelected.publicId;
          this.props.updateSelectedFolder(FolderSelected.publicId);
        }
      }
    }
    const { url, displayCreateFolder, folderName } = this.state;

    const folderOptions = Array.isArray(folder) ?
      this.formatFolderOptions(folder) :
      [];
      
    return (
      <div id="link-gallery">
        <div className="input-container">
          <span>
            <i className="link-icon" />
          </span>
          <input
            name="url"
            type="text"
            value={url}
            placeholder="Image web url"
            onChange={event => this.handleChange(event.target.value)}
            className="input-gallery-border search-box"
          />
        </div>
        {folder && (
          <div>
            <Grid container id="image-gallery-container">
              <Grid item xs={11} className={classes.customSelect}>
                {!displayCreateFolder && (
                  <>
                    <Select
                      data={folderOptions}
                      value={idFolderSelected}
                      onChange={this.handleChangeFolder}
                      responsive={mbscResponsiveOptions}
                      filter
                      placeholder="Select folder"
                      filterPlaceholderText="Search"
                      buttons={['cancel']}
                    />
                  </>
                )}
              </Grid>
              <Grid
                item
                xs={1}
                className="new-folder-container"
              >
                <div
                  className="new-folder-container"
                  onClick={this.createFolder}
                >
                  <i
                    className={`${
                      displayCreateFolder ? 'new-folder-active' : ''
                    } new-folder icon-add`}
                  />
                </div>
              </Grid>
              {displayCreateFolder && (
                <Grid item sm={12} md={10}>
                  <Input
                    type="text"
                    name="newFolder"
                    value={folderName}
                    placeholder="New Folder"
                    onChange={this.handleFolderName}
                  />
                </Grid>
              )}
            </Grid>
          </div>
        )}
        <button
          type="button"
          className="styless-button button-eva button-link-gallery"
          onClick={this.uploadImage}
        >
          Continue
        </button>
      </div>
    );
  }
  /* eslint-enable */
}

LinkGallery.propTypes = LinkGalleryPropType;

const mS = ({
  imageGalleryReducer: { folder, galleryImages, selectedFolder, selectedImage },
  userProfileReducer: { isSuperAdmin },
}) => ({ folder, galleryImages, selectedFolder, selectedImage, isSuperAdmin });

const mD = {
  uploadPicture,
  getFolderImage,
  createFolder,
  getFoldersContent,
  snackBarStatus,
  updateSelectedFolder,
  updateSelectedImage,
};

export default connect(
  mS,
  mD,
)(withStyles(styles)(LinkGallery));
