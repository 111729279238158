import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { enableRightDrawerMessage } from '../../Actions/rightDrawerMessageActions';
import { closeCustomModal, openCustomModal } from '../../Actions/customModalActions';
import {
  clearMessage,
  deleteDraftMessage,
  saveDraftMessage,
  statusWindowsMessage,
  updateEditorContent,
} from '../../Actions/messageActions';
import snackBarStatus from '../../Actions/snackbarActions';
import { navigationControllerRedirect } from '../../Actions/navigationControllerActions';
import { enableRightDrawer } from '../../Actions/rightDrawerActions';
import { getContentWithReplacedUrlsLength } from '../../Helpers/getContentWithReplacedUrlsLength';
import MessageHeadSettings from '../../Containers/MessageHeadSettings';
import './index.sass';
import MessageEditor from '../MessageEditor';

const styles = () => ({
  textArea: {
    backgroundColor: '#c2c2c2',
  },
});

class SimpleMessage extends Component {
  constructor(props) {
    super(props);
    this.counter = 0;
  }

  UNSAFE_componentWillMount() {
    this.props.statusWindowsMessage({ show: false });
  }

  handleDelete = () => {
    const { publicId, draftMessageReferent } = this.props;
    this.props.deleteDraftMessage(draftMessageReferent || publicId || '');
    this.props.clearMessage();
    this.props.enableRightDrawerMessage({
      payload: { enable: false },
    });
  };

  handleDesigner = () => {
    const {
      isResendUnopened, isTemplate,
      publicId, recipientGroups, selectedMember, selectedMessage, sentToMembers, templatePublicId
    } = this.props;
    let url = '/';
    if (isTemplate) {
      url = this.props.templatePublicId ? `/templates/edit/${templatePublicId}` : '/templates/new';
    } else if (selectedMember || (sentToMembers && sentToMembers[0])) { // Direct message
      url = publicId ? `/messages/edit/${publicId}` : `/messages/new?to=${selectedMember.publicId}&group=${recipientGroups[0]}`;
    } else if (isResendUnopened && selectedMessage) { // Resend unopened
      url = `/messages/new?fromMsg=${selectedMessage.publicId}&resend=true`;
    } else {
      url = publicId ? `/messages/edit/${publicId}` : '/messages/new';
    }

    this.props.closeCustomModal();

    this.props.navigationControllerRedirect(url);
  };

  handleComposerChange = (t) => {
    console.log('handle composer change', t);
  };

  render() {
    const {
      messageContent: { smsContent: { prefix, text }, editorContent: { html } },
      onGalleryOpen, onNextButtonClick, onTemplatesOpen
    } = this.props;
    const contentLengthWithShortLinks = text ? getContentWithReplacedUrlsLength(text) : 0;
    const realContentLength = (prefix ? prefix.length : 0) + contentLengthWithShortLinks;

    return (
      <div>
        <div className="designer-container-flexed">
          <div
            className="designer-container-title"
            onClick={this.handleDesigner}
            onKeyPress={this.handleDesigner}
            role="button"
            tabIndex="0"
          >
            <span>Use Designer</span>
            <div className="designer-container-icon"/>
          </div>
        </div>

        <MessageHeadSettings/>

        <div id="compose-container">
          <div className="text-area-container">
            <MessageEditor
              option="simple"
              placeholder="Type message here"
              html={html}
              contentLength={realContentLength}
              counterTooltip="The character count includes required text such as your group name."
              onNextButtonClick={onNextButtonClick}
              onGalleryOpen={onGalleryOpen}
              onTemplatesOpen={onTemplatesOpen}
              onChange={this.handleComposerChange}
              onHtmlSet={this.props.updateEditorContent}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mS = state => ({
  secondTitle: state.rightDrawerMessageReducer.secondTitle,
  messageContent: state.messageReducer.messageContent,

  messageReducer: state.messageReducer,
  subject: state.messageReducer.subject,
  publicId: state.messageReducer.publicId,
  draftMessageReferent: state.messageReducer.draftMessageReferent,
  isResendUnopened: state.messageReducer.isResendUnopened,
  isTemplate: state.messageReducer.isTemplate,
  recipientGroups: state.messageReducer.recipientGroups,
  recipientType: state.messageReducer.recipientType,
  selectedMember: state.messageReducer.selectedMember,
  sentToMembers: state.messageReducer.sentToMembers,

  selectedMessage: state.messagesReducer.selectedMessage,

  templatePublicId: state.templateReducer.template.publicId,

  roles: state.userProfileReducer.member.roles,
});

const mD = {
  clearMessage,
  closeCustomModal,
  deleteDraftMessage,
  enableRightDrawer,
  enableRightDrawerMessage,
  navigationControllerRedirect,
  openCustomModal,
  saveDraftMessage,
  snackBarStatus,
  statusWindowsMessage,
  updateEditorContent,
};

// TODO: remove withRouter (?)
export default withRouter(withStyles(styles)(connect(mS, mD)(SimpleMessage)));
