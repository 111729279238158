import PropTypes from 'prop-types';

const ComposerToolbarPropType = {
  contentLength: PropTypes.number.isRequired,
  counterTooltip: PropTypes.string,
  isLoading: PropTypes.bool,
  isTextSelected: PropTypes.bool.isRequired,
  nextButtonText: PropTypes.string,
  onTemplateClick: PropTypes.func,
  onGalleryClick: PropTypes.func.isRequired,
  onEmojiSelect: PropTypes.func.isRequired,
  onNextButtonClick: PropTypes.func.isRequired,
  limit: PropTypes.number,
};

export default ComposerToolbarPropType;
