export const ACTIONS = {
  TOGGLE: '[Messages Filters] Toggle',
  CHANGE: '[Messages Filters] Change',
  SORT_CHANGE: '[Messages Filters] Change sort',
  SEARCH_TERM_CHANGE: '[Messages Filters] Change search term',
  RESET: '[Messages Filters] Reset',
  RESET_TYPE: '[Messages Filters] Reset type',
  RESET_DATE: '[Messages Filters] Reset date',
};

export const toggleMessagesFilters = (value) => dispatch => {
  dispatch({
    type: ACTIONS.TOGGLE,
    payload: value,
  });
};

export const changeMessagesFilters = (value) => dispatch => {
  dispatch({
    type: ACTIONS.CHANGE,
    payload: value,
  });
  return Promise.resolve();
};

export const changeMessagesSort = value => dispatch => {
  dispatch({
    type: ACTIONS.SORT_CHANGE,
    payload: value,
  });
  return Promise.resolve();
};

export const changeMessagesSearchTerm = value => dispatch => {
  dispatch({
    type: ACTIONS.SEARCH_TERM_CHANGE,
    payload: value,
  });
  return Promise.resolve();
};

export const resetMessagesFilters = () => dispatch => {
  dispatch({
    type: ACTIONS.RESET,
  });
};

