import React from 'react';
import PropTypes from 'prop-types';
import NotificationsChannelPicker from '../NotificationsChannelPicker';
import './index.sass';

const GroupsChannelsList = ({ groups, onChange, onRemove, isEditMode }) => {
  return (
    <ul className="groups-channels-list">
      {groups.map(group => (
        <li key={group.publicId}>
          {isEditMode && (<button className="group-remove-btn" onClick={() => onRemove(group.publicId)}></button>)}
          <div className="group-name">{group.name}</div>
          <NotificationsChannelPicker
            className="notifications-channel"
            isEditMode={isEditMode}
            isNotifyByEmail={group.isNotifyByEmail}
            isNotifyBySms={group.isNotifyBySms}
            onChange={(opts) => onChange(group, opts)} />
        </li>
      ))}
    </ul>
  );
}

GroupsChannelsList.defaultProps = {
  isEditMode: false,
  onChange: () => {},
  onRemove: () => {},
}

GroupsChannelsList.propTypes = {
  isEditMode: PropTypes.bool,
  groups: PropTypes.arrayOf(PropTypes.object).isRequired, // TODO: provide group object
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};

export default GroupsChannelsList;
