/* eslint-disable */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import ContentEditable from 'react-contenteditable';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import EvaTooltip from '../EvaTooltip';
import FolderSettingsFormPropType from '../../PropTypes/FolderSettingsFormPropType';
import SearchOrganization from './SearchOrganization';
import Button from '../Button';
import colors from '../../Theme/Colors'
import { DESKTOP } from '../../Config/Constants/Responsive';
import Input from '../../Components/Input';
import LoaderOverlay from '../LoaderOverlay';
import { switchStyles } from '../../Theme/switchStyles';
import SharesList from '../SharesList';
import { mbscResponsiveOptions } from '../../Config/Constants/mobiscroll-props';
import { Select } from '@mobiscroll/react';
import './index.sass';

const styles = theme => ({
  rootMain: {
    flexGrow: 1,
    width: '100%',
  },
  colorBar: {},
  colorChecked: {},
  iconStyle: {
    position: 'absolute',
    top: 13,
    right: 0,
    display: 'inline-block',
    width: 24,
    height: 24,
  },
  ...switchStyles,
  folderSelect: {
    '& .right-icon': {
      position: 'relative',
    },
  },
  organizationSelect: {
    marginTop: '20px',
  },
  groupSelect: {
    marginLeft: '25px',
  },
  commentsTitle: {
    fontFamily: 'GothamSSm-Medium',
    fontSize: '20px',
    color: colors.evaGrey,
    margin: '35px 0 16px 0',
    textAlign: 'left',
  },
  ...switchStyles,
});

export const FolderSettingsForm = props => {
  const {
    classes,
    viewType,
    create,
    folderName,
    parentFolderOptions,
    characters,
    share,
    comments,
    selectedGroup,
    selectedParent,
    groupOptions,
    handleChange,
    handleChangeParentFolder,
    handleChangeSelectedGroup,
    selectedOrganization,
    handleChangeOrg,
    addShareEnabled,
    shareAllOrg,
    loadingGroups,
    handleShareAction,
    handleSaveSettings,
    onRemoveShare,
    shares,
    handleShareTemplate,
    handleDelete,
  } = props;
  return (
    <Grid container spacing={0} id="settings-folder">
      {(viewType !== DESKTOP && !create) &&
      <Grid item xs={3} className="icons-trash">
        <EvaTooltip title="Delete Folder and Contents">
          <button
            className="icons trash-icon"
            onClick={handleDelete}
          />
        </EvaTooltip>
      </Grid>
      }
      <Grid item xs={12}>
        <Input
          name="folder_name"
          title="Folder name"
          type="text"
          placeholder="Folder name"
          value={folderName}
          icon=""
          focused="folder-input"
          onChange={handleChange}
        />
      </Grid>

      <Grid item xs={12} className={classes.folderSelect}>
        <Select
          data={parentFolderOptions}
          value={selectedParent}
          name="parent_folder"
          onChange={handleChangeParentFolder}
          responsive={mbscResponsiveOptions}
          filter
          placeholder="Select a parent folder"
          filterPlaceholderText="Search"
          buttons={['cancel']}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container className="share-container" spacing={40}>
          <Grid className="share" item xs={2}>
            <b className="share-title">Share</b>
          </Grid>
          <Grid item xs={10}>
            <Switch
              onChange={handleShareAction}
              checked={share}
              classes={{
                switchBase: classes.switchBase,
                colorSecondary: classes.switchColorSecondary,
                track: classes.track,
                thumb: classes.thumb,
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      {share ? (
        <React.Fragment>
          {!shareAllOrg &&
          <React.Fragment>
            <Grid item xs={12} className={classes.organizationSelect}>
              <SearchOrganization
                value={selectedOrganization}
                onChange={handleChangeOrg}
              />
            </Grid>
            <Grid item xs={12} className={classes.groupSelect}>
              {loadingGroups && <LoaderOverlay className="settings-loader" />}
              {groupOptions.length ?
                <Select
                  data={groupOptions}
                  value={selectedGroup}
                  onChange={handleChangeSelectedGroup}
                  responsive={mbscResponsiveOptions}
                  placeholder="Select a Group"
                  filterPlaceholderText="Search"
                  buttons={['cancel']}
                />
                : null
              }
            </Grid>
            <Grid
              justify="center"
              container
              spacing={0}
              alignItems="center"
              direction="column"
              item
              xs={12}
              onClick={handleShareTemplate}
            >
              {addShareEnabled &&
              <div className="plus-icon"/>
              }
            </Grid>
          </React.Fragment>
          }
          <Grid item xs={12}>
            <SharesList items={shares} onRemove={onRemoveShare} />
          </Grid>

        </React.Fragment>
      ) : null}

      <Grid item xs={12} className="folder-settings-textarea">
        <h5 className={classes.commentsTitle}>Comments</h5>
        <div className="composer">
          <ContentEditable
            className="editable"
            tagName="p"
            name="comments"
            placeholder="Information about this Folder"
            html={comments}
            disabled={false}
            onChange={handleChange}
          />
        </div>
      </Grid>

      <Grid
        justify="flex-end"
        container
        spacing={0}
        alignItems="flex-end"
        direction="column"
        item
        xs={11}
        sm={11}
        md={11}
        className="characters-left"
      >
        <div>{`${characters} characters left`}</div>
      </Grid>

      <Grid
        justify="center"
        container
        spacing={0}
        alignItems="center"
        direction="column"
        item
        xs={12}
        sm={12}
        md={12}
      >
        {create ? (
          <Button onClick={handleSaveSettings} title="Create"/>
        ) : (
          <Button onClick={handleSaveSettings} title="Save"/>
        )}
      </Grid>
    </Grid>
  );
};

FolderSettingsForm.propTypes = FolderSettingsFormPropType;

export default withStyles(styles)(FolderSettingsForm);
