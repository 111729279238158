// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../Theme/Icons/remove-icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".groups-channels-list{margin:0;padding:0}.groups-channels-list li{display:-ms-flexbox;display:flex;list-style:none;-ms-flex-align:center;align-items:center;height:40px}.groups-channels-list li:not(:last-child){margin-bottom:12px}.groups-channels-list li .group-name{margin-right:20px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;font-family:\"GothamSSm-Book\",Arial,sans-serif;font-size:16px}.groups-channels-list li .group-remove-btn{margin-left:-12px;width:40px;height:40px;-ms-flex-negative:0;flex-shrink:0;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center}.groups-channels-list li .notifications-channel{margin-left:auto}\n", ""]);
// Exports
module.exports = exports;
