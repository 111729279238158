import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  margin: {
    width: '100%',
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: 'transparent',
    },
  },
  notchedOutline: {},
});

const CustomField = ({
  label,
  input,
  classes,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    className={classes.margin}
    label={label}
    error={touched && error}
    {...input}
    {...custom}
    variant="filled"
    InputProps={{
      classes: {
        focused: classes.cssFocused,
        notchedOutline: classes.notchedOutline,
        underline: classes.cssUnderline,
      },
    }}
  />
);

export default withStyles(styles)(CustomField);
