import React from 'react';
import MainContainerPropType from '../../PropTypes/MainContainerPropType';
import NavigationController from '../../Components/NavigationController';
import { connect } from 'react-redux';
import './index.sass';
import LoaderOverlay from '../../Components/LoaderOverlay';

const Main = ({ children, isLoaderVisible }) => (
  <div className="main-eva">
    <NavigationController/>
    {children}
    {isLoaderVisible && <LoaderOverlay />}
  </div>
);

Main.propTypes = MainContainerPropType;

const mS = state => ({
  isLoaderVisible: state.loaderReducer.isVisible,
});

export default connect(mS, null)(Main);
