import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './index.sass';

class ButtonDrop extends Component {
  state = {
    display: false,
  };

  hide = () => {
    this.setState({ display: false });
  };

  submit = e => {
    e.preventDefault();
    const { display } = this.state;
    this.setState({ display: !display });
  };

  render() {
    const { display } = this.state;
    return (
      <div className={`button-drop ${this.props.buttonStyle} ${this.props.className}`} onMouseLeave={this.hide}>
        <div className="buttons-wrapper">
          <button
            className={`btn-title ${!this.props.onClick ? 'no-action' : ''}`}
            onClick={this.props.onClick ? this.props.onClick : this.submit}
          >
            {this.props.title}
          </button>
          <button className="btn-action" onClick={this.submit}>
            <div className="dropdown"/>
          </button>
        </div>
        <div className={`button-drop-list list-align-${this.props.listAlign} ${display ? 'active' : ''}`}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

ButtonDrop.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  buttonStyle: PropTypes.oneOf(['plain', 'separated', 'fill', 'stroke']),
  listAlign: PropTypes.oneOf(['left', 'right']),
};

ButtonDrop.defaultProps = {
  className: '',
  onClick: null,
  buttonStyle: 'stroke',
  listAlign: 'left',
};

export default ButtonDrop;
