import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import tooltipStyle from './tooltipStyle';

const styles = theme => tooltipStyle(theme);

// TODO: pass 'interactive' to the tooltip after material update
// https://material-ui.com/api/tooltip/
const EvaTooltip = ({ children, title, placement = 'bottom', interactive = false }) =>
  (interactive ? children : <Tooltip title={title} placement={placement}>{children}</Tooltip>);

EvaTooltip.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  interactive: PropTypes.bool,
};

EvaTooltip.defaultProps = {
  interactive: false,
};

export default withStyles(styles)(EvaTooltip);
