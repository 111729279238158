import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import colors from '../../Theme/Colors';

const style = theme => ({
  customChip: {
    margin: `${theme.spacing(0.25)}px`,
    backgroundColor: colors.evaSoft,
    color: colors.amethyst,
    border: `solid 2px ${colors.amethyst}`,
    fontFamily: 'GothamSSm-Book',
    fontSize: '16px',
    height: '26px',
    '&:focus': {
      backgroundColor: colors.evaSoft,
    },
  },
  disabledChip: {
    backgroundColor: colors.lightGrey,
    borderColor: colors.grey,
    color: colors.grey,
    '&:focus': {
      backgroundColor: colors.lightGrey,
    },
  },
  customIcon: {
    color: colors.amethyst,
    width: '20px',
    height: '20px',
  },
});

const GroupChip = ({
  classes,
  text,
  groupId,
  onDelete,
  disabled,
}) => (
  <Chip
    tabIndex={-1}
    label={text}
    disabled={disabled}
    className={`${classes.customChip} ${disabled ? classes.disabledChip : ''}`}
    onDelete={onDelete}
    deleteIcon={<CloseIcon id={groupId} className={classes.customIcon} />}
  />
);

GroupChip.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  text: PropTypes.string.isRequired,
  groupId: PropTypes.string,
  onDelete: PropTypes.func,
  disabled: PropTypes.bool,
};

GroupChip.defaultProps = {
  disabled: false,
  groupId: null,
  onDelete: () => {},
};

export default withStyles(style)(GroupChip);
