// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../Theme/Icons/resend-unopened.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../Theme/Icons/forward-icon.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../Theme/Icons/history-icon.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../Theme/Icons/inactive-icon.svg");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../Theme/Icons/statistics.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, ".message-view-buttons{padding:10px;text-align:right}.message-view-buttons button{padding:19px 20px 10px 10px;border:none;cursor:pointer;overflow:hidden;outline:none;background:no-repeat center}.message-view-buttons button:not(:last-child){margin-right:15px}.message-view-buttons .button-resend{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.message-view-buttons .button-forward{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.message-view-buttons .button-history{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.message-view-buttons .button-inactive{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}.message-view-buttons .button-statistics{background-color:#a9a9a9;mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") no-repeat;-webkit-mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") no-repeat}.message-view-buttons .button-statistics.active{background-color:#9a66bf}\n", ""]);
// Exports
module.exports = exports;
