import React, { Component } from 'react';
import { connect } from 'react-redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import { Scrollbars } from 'react-custom-scrollbars';
import { withRouter } from 'react-router-dom';
import NotificationCard from './NotificationCard';
import {
  acceptJoinRequest,
  denyJoinRequest,
  dismissNotifications,
  getNotificationsMessages,
  clearNotifications,
} from '../../Actions/notificationsActions';
import { updateTemplateShownStatus } from '../../Actions/templateActions';
import { getGroupsAsync, getOrgAsync } from '../../Actions/groupsActions';
import { updateSuggestionsSettings } from '../../Actions/userActions';
import iconTemplate from '../../Theme/Icons/icon-templates.svg';
import Avatar from '../Avatar';
import SettingsPropTypes from '../../PropTypes/SettingsProptypes';
import { navigationControllerRedirect } from '../../Actions/navigationControllerActions';
import { MOBILE, TABLET } from '../../Config/Constants/Responsive';
import LoaderOverlay from '../LoaderOverlay';
import './index.sass';
import { switchStyles } from '../../Theme/switchStyles';

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    position: 'absolute',
    marginTop: 10,
    width: 410,
    zIndex: 1300,
    right: 42,
    top: 49.9,
    borderRadius: 5,
    '& .settings-tabs': {
      height: 'auto',
    },
  },
  tab: {
    color: theme.palette.background.paper,
  },
  tabsIndicator: {
    borderBottom: '2px solid #9a66bf',
    backgroundColor: 'transparent',
  },
  tabRoot: {
    width: '50%',
  },
  tabsRoot: {
    textTransform: 'initial',
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'GothamSSm-Book',
    color: '#a9a9a9',
    '&:focus': {
      color: '#9a66bf',
    },
  },
  tabSelected: { color: '#9a66bf' },
  '@media (max-width: 767px)': {
    root: {
      '#settings-container&': {
        top: 0,
      },
    },
  },
  '@media (min-width: 768px) and (max-width: 1023px)': {
    root: {
      '#settings-container&': {
        top: 0,
        '& .settings-tabs': {
          minWidth: '66%',
          width: '66%',
          margin: '0 auto',
        },
      },
    },
    tabsRoot: {
      width: '66%',
      margin: '0 auto',
    },
  },
  progress: {
    margin: theme.spacing(2),
  },
  ...switchStyles,
});

class Settings extends Component {
  state = {
    value: 0,
  };

  // TODO: unify
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.organizationId && !nextProps.isLoaded && !nextProps.isLoading) {
      this.props.getNotificationsMessages();
    }
  }

  handleChange = (event, value) => this.setState({ value });

  handleDismiss = notificationID => {
    this.props.dismissNotifications(notificationID);
  };
  handleAcceptJoin = (notificationId, groupId, memberId) => {
    const data = {
      notificationId,
      groupId,
      memberId,
    };
    this.props.acceptJoinRequest(data).then(() => {
      this.props.getGroupsAsync();
    });
  };
  handleDenyJoin = (notificationId, groupId, memberId) => {
    const data = {
      notificationId,
      groupId,
      memberId,
    };
    this.props.denyJoinRequest(data).then(() => {
      this.props.getGroupsAsync();
    });
  };

  handleClickTemplate = publicId => {
    this.props.updateTemplateShownStatus(false);
    this.props.history.push(`/templates/${publicId}`);
  };

  handleClickFolder = publicId => {
    this.props.history.push({ pathname: '/templates/', state: { folder: publicId } });
  };

  handleGetMoreNotifications() {
    const { notifications, notificationsTotal, isLoaded } = this.props;

    if (!isLoaded || notificationsTotal > notifications.length) {
      this.props.getNotificationsMessages(notifications.length);
    }
  }

  renderTemplateOrFolderNotification = (notification) => {
    /* eslint-disable camelcase */
    const { member, type, created_at, template, folder, public_id } = notification;
    const textNotification = `<b>${member.name}</b> has shared a ${type}`;
    const notificationName = `${type === 'template' ? 'Template' : 'Folder'} shared with you`;
    return (
      <NotificationCard
        key={public_id}
        image={iconTemplate}
        type={type}
        createdAt={created_at}
        name={notificationName}
        text={textNotification}
        notificationType="sharedRequest"
        dismiss={() => {
          if (type === 'template') {
            this.handleClickTemplate(template.public_id);
          } else {
            this.handleClickFolder(folder.public_id);
          }
          this.handleDismiss(public_id);
        }}
        className={`notification-template-container type-${type}`}
      />
    );
    /* eslint-enable camelcase */
  };

  renderNotification = (notification) => {
    /* eslint-disable camelcase */
    const { member, group, type, message, created_at, public_id } = notification;

    switch (type) {
      case 'pending':
        return (
          <NotificationCard
            key={public_id}
            image={`${member.profile_picture_url}`}
            group={`${group.name}`}
            text="Wants to join the "
            type={type}
            name={member.name}
            notificationType="joinRequest"
            createdAt={created_at}
            accept={() =>
              this.handleAcceptJoin(
                public_id,
                group.public_id,
                member.public_id,
              )
            }
            deny={() =>
              this.handleDenyJoin(
                public_id,
                group.public_id,
                member.public_id,
              )
            }
          />
        );

      case 'join':
        return (
          <NotificationCard
            key={public_id}
            image={member.profile_picture_url}
            type={type}
            createdAt={created_at}
            text="Has joined "
            name={member.name}
            group={group.name}
            notificationType="memberJoined"
            dismiss={() => this.handleDismiss(public_id)}
          />
        );

      case 'message':
        if (message) {
          const memberDisplayName = message.sent_by_member.member.name;
          return (
            <NotificationCard
              key={public_id}
              image={message.sent_by_member.profile_picture_url}
              type={type}
              createdAt={created_at}
              name={memberDisplayName}
              text={` ${memberDisplayName} sent a reply to the `}
              group={group.name}
              notificationType="replyRequest"
              dismiss={() => this.handleDismiss(public_id)}
            />
          );
        }
        break;
      case 'folder':
        return this.renderTemplateOrFolderNotification(notification);
      case 'template':
        return this.renderTemplateOrFolderNotification(notification);
      default:
        return null;
    }
    return null;
    /* eslint-enable camelcase */
  };

  handleChangeSuggestions = event => {
    const { userProfile } = this.props;
    userProfile[event.target.id] = event.target.checked;
    this.props.updateSuggestionsSettings(userProfile);
  };

  render() {
    const {
      classes,
      notifications,
      isLoading,
      isLastChunk,
      userProfile,
      displaySettingsView,
      viewType,
      windowHeight,
      notificationsTotal,
      isRemoving,
    } = this.props;
    const { value } = this.state;
    let containerHeight;
    let SettingsTabsMaxHeight;
    // styles had to be applied using style attr because this version of
    // materialUI's withStyle does not accept dynamic styles based on props
    if (viewType === MOBILE) {
      containerHeight = windowHeight - 79;
      SettingsTabsMaxHeight = windowHeight - 169;
    } else if (viewType === TABLET) {
      containerHeight = windowHeight - 129;
      SettingsTabsMaxHeight = windowHeight - 215;
    } else {
      containerHeight = 'auto';
      SettingsTabsMaxHeight = windowHeight - 140;
    }
    const roleNames = {
      ROLE_SUPER_ADMIN: 'Super Admin',
      ROLE_ORGANIZATION_ADMIN: 'Organization Admin',
      ROLE_EVA_GROUP_ADMIN: 'Group Admin',
      ROLE_MEMBER: 'Member',
    };

    return (
      <div
        id="settings-container"
        className={`settings-container ${classes.root}`}
        style={{ height: containerHeight }}
      >
        <button
          className="settings-close"
          onClick={() => displaySettingsView(false)}
          onKeyPress={this.handlePressKey}
        />
        <Tabs
          classes={{
            root: classes.tabsRoot,
            indicator: classes.tabsIndicator,
          }}
          value={value}
          onChange={this.handleChange}
        >
          <Tab
            classes={{
              root: classes.tabRoot,
              selected: classes.tabSelected,
            }}
            className="settings-tab-title"
            label="Notifications"
          />
          <Tab
            classes={{
              root: classes.tabRoot,
              selected: classes.tabSelected,
            }}
            className="settings-tab-title"
            label="My account"
          />
        </Tabs>
        { /* Notifications tab */ }
        {value === 0 && (
          <React.Fragment>
            {notificationsTotal > 0 && (
              <div className="notification-actions">
                <button disabled={isRemoving} onClick={this.props.clearNotifications}>Clear All Notifications</button>
              </div>
            )}
            <div className="settings-tabs">
              <Scrollbars
                autoHeight
                autoHide
                autoHeightMin={'70vh'}
                autoHeightMax={'70vh'}
                onUpdate={({ top }) => {
                  if (top > 0.99 && !isLoading && !isLastChunk) {
                    this.handleGetMoreNotifications();
                  }
                }}
              >
                {notifications.map(request => this.renderNotification(request))}
                {notifications.length === 0 && <div className='no-notifications'>No notifications here...</div>}
                {isLoading ? <LoaderOverlay /> : null}
              </Scrollbars>
            </div>
          </React.Fragment>
        )}
        { /* My account tab */ }
        {value === 1 && (
          <div className="settings-tabs">
            <Scrollbars
              autoHeight
              autoHide
              autoHeightMin={'70vh'}
              autoHeightMax={'70vh'}
            >
              <Grid container spacing={0} className="my-account-list">
                <Grid item xs={12} className="my-account-info">
                  <Avatar
                    borderless
                    className="user-eva"
                    image={userProfile.member.profile_picture_url}
                  />
                  <p className="name-account">
                    {userProfile.member.first_name || ''} {userProfile.member.last_name || ''}
                  </p>
                  <p className="email-account">
                    {userProfile.email ? userProfile.email : (userProfile.main_phone ? userProfile.main_phone.phone_number : '')}
                  </p>
                  <p className="group-account">
                    {roleNames[userProfile.organization_role]
                      ? roleNames[userProfile.organization_role]
                      : ''}
                  </p>
                  <button
                    className="link-account"
                    onClick={() => this.props.navigationControllerRedirect('/my-profile')}
                  >
                    View my profile
                  </button>
                </Grid>
                <Grid item xs={12} className="suggestions-account">
                  <div className="title-suggestions">EVA suggestions</div>
                  <div>
                    <div className="title-switch">New message replies</div>
                    <div className="icon-switch">
                      <Switch
                        onChange={event => this.handleChangeSuggestions(event)}
                        checked={userProfile.new_message_replies}
                        classes={{
                          switchBase: classes.switchBase,
                          colorSecondary: classes.switchColorSecondary,
                          track: classes.track,
                          thumb: classes.thumb,
                        }}
                        id="new_message_replies"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="title-switch">Group activity status</div>
                    <div className="icon-switch">
                      <Switch
                        onChange={event => this.handleChangeSuggestions(event)}
                        checked={userProfile.group_activity_status}
                        classes={{
                          switchBase: classes.switchBase,
                          colorSecondary: classes.switchColorSecondary,
                          track: classes.track,
                          thumb: classes.thumb,
                        }}
                        id="group_activity_status"
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} className="subscription-account">
                  <div className="title-subscription">Subscription</div>
                  <p>
                    To manage your subscription please contact Evangelus support at <br />
                    <b>1 (800) 994-9817.</b>
                  </p>
                </Grid>
              </Grid>
            </Scrollbars>
          </div>
        )}
      </div>
    );
  }
}

Settings.propTypes = SettingsPropTypes;

const mS = state => ({
  notifications: state.notificationsReducer.notifications,
  notificationsTotal: state.notificationsReducer.total,
  isLoaded: state.notificationsReducer.isLoaded,
  isLoading: state.notificationsReducer.isLoading,
  isRemoving: state.notificationsReducer.isRemoving,
  isLastChunk: state.notificationsReducer.isLastChunk,
  userProfile: state.userProfileReducer,
  viewType: state.responsiveReducer.viewType,
  windowHeight: state.responsiveReducer.windowHeight,
  organizationId: state.organizationReducer.organization.public_id,
});

const mD = {
  getNotificationsMessages,
  dismissNotifications,
  acceptJoinRequest,
  getGroupsAsync,
  getOrgAsync,
  denyJoinRequest,
  navigationControllerRedirect,
  updateSuggestionsSettings,
  updateTemplateShownStatus,
  clearNotifications,
};

export default withRouter(
  connect(
    mS,
    mD,
  )(withStyles(styles)(Settings)),
);
