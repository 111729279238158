import React from 'react';
import PropTypes from 'prop-types';
import './index.sass';

const MemberPictureEdit = ({ isNoImage, imageUrl, onChange }) => (
  <div className="member-picture-edit">
    <figure
      role="button"
      tabIndex="0"
      onClick={onChange}
      onKeyPress={onChange}
    >
      <img className={`${isNoImage ? 'no-image' : ''}`} src={imageUrl} alt="..."/>
      <figcaption>Click or tap <br/>to add a photo</figcaption>
    </figure>
  </div>
);

export default MemberPictureEdit;

MemberPictureEdit.propTypes = {
  isNoImage: PropTypes.bool.isRequired,
  imageUrl: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
