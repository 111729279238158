const drawerWidth = window.innerWidth > 768 ? 705 : window.innerWidth;

const styles = theme => ({
  [theme.breakpoints.only('sm')]: {
    drawerPaper: {
      width: '100% !important',
      height: '100% !important',
    },
    drawerHeader: {
      marginBottom: 0,
      padding: '0px !important',
    },
    iconButtonContainer: {
      backgroundColor: '#f3f3f3',
      height: 66,
      borderRadius: 0,
      paddingLeft: '30px',
    },
  },
  [theme.breakpoints.only('xs')]: {
    drawerPaper: {
      width: '100% !important',
      height: '100% !important',
      overflowX: 'hidden',
    },
    drawerHeader: {
      marginBottom: 0,
      padding: '0px !important',
    },
    iconButtonContainer: {
      backgroundColor: '#f3f3f3',
      height: 52,
      borderRadius: 0,
    },
  },
  [theme.breakpoints.up('md')]: {
    drawerPaper: {
      marginRight: 0,
    },
  },
  drawerPaper: {
    position: 'absolute',
    width: drawerWidth,
    overflowX: 'hidden',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0p 20px 0px 20px',
    ...theme.mixins.toolbar,
    minHeight: 52,
    '& .flexed-elements': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '& .abs': {
      position: 'absolute',
    },
    '& .full-width': {
      width: '100% !important',

    },
    '& .abs.transparent': {
      backgroundColor: 'transparent',
    },
    '& .abs.stack-top': {
      zIndex: 3,
    },
  },
  drawerTitle: {
    width: '100%',
    paddingTop: '20px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 500,
    fontFamily: 'GothamSSm-Medium',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#4a4a4a',
    whiteSpace: 'nowrap',
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  'content-left': {
    marginLeft: -drawerWidth,
  },
  'content-right': {
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  'contentShift-left': {
    marginLeft: 0,
  },
  'contentShift-right': {
    marginRight: 0,
  },
  titleBox: {
    display: 'inline-flex',
    alignItems: 'flex-end',
    maxWidth: '45%',
  },
  tabletTemplateHeader: {
    '@media only screen and (min-width: 600px) and (max-width: 960px)': {
      marginRight: '91px',
      marginLeft: '92px',
    },
    '@media only screen and (max-width: 599px)': {
      marginRight: '15px',
      marginLeft: '19px',
    },
    '& .title': {
      maxWidth: '100%',
      display: 'block',
      '@media only screen and (max-width: 599px)': {
        marginBottom: '20px',
      },
    },
  },
});

export default styles;
