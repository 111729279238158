import color from '../../Theme/Colors';
import active from '../../Theme/Icons/check.svg';

export const styles = {
  root: {
    height: '42px',
    borderBottom: `2px solid ${color.evaPrimaryWhite}`,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&.has-errors': {
      borderBottom: '2px solid  #ea5858',
    },
    margin: '8px 0px 0px',
    '@media only screen and (min-width: 768px) and (max-width: 1023px)': {
      maxWidth: '703px',
    },
    width: '100%',
    '& .icon': {
      height: '40px',
      width: '50px',
    },
    '& .input-v3': {
      width: 'calc(100% - 22px)',
      marginRight: '0px',
    },
    '& .leading-icon>img': {
      height: '100%',
    },
    '& .react-phone-number-input__country.react-phone-number-input__country--native': {
      padding: '0',
      borderStyle: 'none !important',
    },
    '& input.react-phone-number-input__input.react-phone-number-input__phone': {
      fontFamily: 'GothamSSm-Medium',
      fontSize: '16px',
      color: color.evaGrey,
      height: '40px',
      border: 'none',
      textAlign: 'center',
      margin: '0 !important',
      padding: '0 !important',
      '&::placeholder': {
        padding: '0 !important',
      },
    },
    '& .react-phone-number-input .react-phone-number-input__row .react-phone-number-input__country--native .react-phone-number-input__icon': {
      marginTop: '0',
    },
    '& .react-phone-number-input .react-phone-number-input__row .react-phone-number-input__country--native .react-phone-number-input__country-select-arrow': {
      marginTop: '0.3rem',
    },
    '& div.react-phone-number-input__icon': {
      borderStyle: 'none',
      width: '28px',
      height: '20px',
    },
    '& div.react-phone-number-input__icon > img.react-phone-number-input__icon-image': {
      borderRadius: '5px',
    },
    '& .valid-phone': {
      backgroundImage: `url('${active}')`,
      backgroundRepeat: 'no-repeat',
      // padding: '6.5px 7.9px 6.5px 7.9px',
      backgroundSize: 'contain',
      backgroundPosition: 'center center',
      marginRight: '21px',
      height: '13px',
      width: '15.8px',
    },
    '& .valid-phone-hidden': {
      visibility: 'hidden',
    },
  },
  rootV3: {
    height: '42px',
    borderBottom: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '8px 0px 0px',
    '@media only screen and (min-width: 768px) and (max-width: 1023px)': {
      margin: '23px 0px 0px',
      maxWidth: '703px',
    },
    width: '100%',
    '& .icon': {
      height: '40px',
      width: '50px',
    },
    '& .input': {
      width: 'calc(100% - 22px)',
      marginRight: '50px',
    },
    '& .input-v3': {
      width: '100%',
      marginRight: '0px',
      position: 'relative',
    },
    '& .leading-icon>img': {
      height: '100%',
    },
    '& .react-phone-number-input__country.react-phone-number-input__country--native': {
      padding: '0',
      borderStyle: 'none !important',
    },
    '& input.react-phone-number-input__input.react-phone-number-input__phone': {
      fontFamily: 'GothamSSm-Medium',
      fontSize: '16px',
      color: color.evaGrey,
      height: '40px',
      border: 'none',
      textAlign: 'center',
      margin: '0 !important',
      padding: '0 !important',
      maxWidth: 'calc(100% - 76px)',
      '&::placeholder': {
        padding: '0 !important',
      },
    },
    '& .react-phone-number-input .react-phone-number-input__row .react-phone-number-input__country--native .react-phone-number-input__icon': {
      marginTop: '0',
    },
    '& .react-phone-number-input .react-phone-number-input__row .react-phone-number-input__country--native .react-phone-number-input__country-select-arrow': {
      marginTop: '0.3rem',
    },
    '& div.react-phone-number-input__icon': {
      borderStyle: 'none',
      width: '28px',
      height: '20px',
    },
    '& div.react-phone-number-input__icon > img.react-phone-number-input__icon-image': {
      borderRadius: '5px',
    },
    '& .valid-phone': {
      backgroundImage: `url('${active}')`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center center',
      marginRight: '21px',
      height: '13px',
      width: '15.8px',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: '21px',
    },
    '& .valid-phone-hidden': {
      visibility: 'hidden',
    },
    '& input::placeholder': {
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
    },
  },
};
