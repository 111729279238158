import React from 'react';
import Grid from '@material-ui/core/Grid';
import TemplatePageSidePanelPropTypes from '../../PropTypes/TemplatePageSidePanelPropTypes';

const TemplatePageSidePanel = props => {
  const { children, header } = props;

  return (
    <Grid {...props}>
      <React.Fragment>
        {header}
        {children}
      </React.Fragment>
    </Grid>
  );
};

TemplatePageSidePanel.propTypes = TemplatePageSidePanelPropTypes;

export default TemplatePageSidePanel;
