import AXIOS from '../Config/AxiosConfig';
import headers from './Helper';

const Organizations = {
  get() {
    return AXIOS.get('eva/member/all/organizations/menu', {
      headers: headers(),
    });
  },
  getOrganizationsLookup() {
    return AXIOS.get('v2/eva/organizations-lookup', {
      headers: headers(),
    });
  },
  getOrg(organizationId) {
    return AXIOS.get(`/eva/organization/${organizationId}`, {
      headers: headers(),
    });
  },
  getGroup(organizationId, groupId) {
    return AXIOS.get(`/eva/organization/${organizationId}/group/${groupId}`, {
      headers: headers(),
    });
  },
  resyncCaminoGroups(organizationId) {
    return AXIOS.post(
      `/eva/organization/${organizationId}/resync-camino`,
        {},
        { headers: headers() },
      );
  },
  getOrganizationMember(organization, member) {
    return AXIOS.get(`/eva/organization/${organization}/member/${member}/organization-member`, {
      headers: headers(),
    });
  },
};

export default Organizations;
