import PropTypes from 'prop-types';

const MessageWebPreviewPropType = {
  allowed: PropTypes.bool.isRequired,
  enabled: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired,
  previewImages: PropTypes.arrayOf(PropTypes.string),
  selectedPreviewImage: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSendByChange: PropTypes.func.isRequired,
  onPreviewChange: PropTypes.func.isRequired,
};

export default MessageWebPreviewPropType;
