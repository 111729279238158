import PropTypes from 'prop-types';

const MessageSelectedHeaderPropType = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onArchive: PropTypes.func.isRequired,
  onUnArchive: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isArchived: PropTypes.bool,
};

export default MessageSelectedHeaderPropType;
