import Auth from '../Api/Auth';
import snackBarStatus from '../Actions/snackbarActions';

export const ACTIONS = {
  CLEAR: 'password/clear',
  ERROR: 'password/error',
  REQUEST: 'password/request',
  RESET: 'password/reset',
  UPDATE: 'password/update',
  UPDATE_USER: 'password/update_user',
  RESET_USER: 'password/reset_user',
};

export const resetPassword = (
  token,
  plainPassword,
  phone = null,
) => async dispatch => {
  try {
    const response = await Auth.resetPassword(token, plainPassword, phone);
    return dispatch({ type: ACTIONS.RESET, payload: response.data });
  } catch (error) {
    if (error.status === 401) {
      dispatch({
        type: ACTIONS.ERROR,
        payload: { error: 'Email or Password are incorrect' },
      });
    } else {
      dispatch({
        type: ACTIONS.ERROR,
        payload: { error: error.message },
      });
    }
    return error;
  }
};

export const resetPasswordUser = data => async dispatch => {
  try {
    const response = await Auth.resetPasswordUser(data);
    if (response.data && response.data.code === 200) {
      snackBarStatus({
        payload: {
          title: 'Password has been changed',
          type: 'success',
          enable: true,
        },
      })(dispatch);
    }
    return dispatch({ type: ACTIONS.RESET_USER, payload: response.data });
  } catch (error) {
    if (error.message) {
      snackBarStatus({
        payload: {
          title: error.message,
          type: 'error',
          enable: true,
        },
      })(dispatch);
    }
    dispatch({
      type: ACTIONS.ERROR,
      payload: { error: error.message },
    });
    return error;
  }
};

export const requestPassword = payload => async dispatch => {
  try {
    const response = await Auth.requestPassword(payload);
    return dispatch({ type: ACTIONS.REQUEST, payload: response.data });
  } catch (error) {
    if (error.status === 401) {
      dispatch({
        type: ACTIONS.ERROR,
        payload: { error: 'Unauthorized' },
      });
    } else {
      dispatch({
        type: ACTIONS.ERROR,
        payload: { error: error.message },
      });
    }
    return error;
  }
};

export const updatePassword = values => dispatch => {
  dispatch({ type: ACTIONS.UPDATE, ...values });
};

export const updatePasswordUser = values => dispatch => {
  dispatch({ type: ACTIONS.UPDATE_USER, ...values });
};

export const clearPassword = () => dispatch => {
  dispatch({ type: ACTIONS.CLEAR });
};

export const checkPinCode = (phone, token) => async dispatch => {
  try {
    const response = await Auth.checkPin(phone, token);
    dispatch({ type: ACTIONS.UPDATE, payload: { checked: true } });
    return response;
  } catch (error) {
    dispatch({
      type: ACTIONS.ERROR,
      checked: false,
      payload: { error: error.message },
    });
    return error;
  }
};
