import { ACTIONS } from '../Actions/rightDrawerShortActions';

const initialState = {
  enable: false,
  element: null,
  title: 'Drawer Title',
  secondTitle: 'Second Title',
  cleaner: null,
};

const rightDrawerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.ENABLE_SHORT:
      return {
        ...state,
        ...action.payload,
      };
    case ACTIONS.DISABLE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default rightDrawerReducer;
