// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../Theme/Icons/filter.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".btn-filter{position:relative;width:40px;height:40px}.btn-filter i{display:block;width:100%;height:100%;-webkit-mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center center;mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center center;background-color:#a9a9a9}.btn-filter.active i{background-color:#9a66bf}.btn-filter.filters-selected:after{content:'';display:block;position:absolute;right:4px;top:-5px;width:10px;height:10px;border-radius:100%;background-color:#9a66bf}\n", ""]);
// Exports
module.exports = exports;
