import PropTypes from 'prop-types';

const TemplateMessagePreviewPropType = {
  content: PropTypes.string.isRequired,
  picture: PropTypes.string,
  profileName: PropTypes.string,
  profilePicture: PropTypes.string,
};

export default TemplateMessagePreviewPropType;
