import React, { Component } from 'react';
import NotificationsChannelPicker from '../NotificationsChannelPicker';
import PropTypes from 'prop-types';
import './index.sass';

class MyGroupItem extends Component {

  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {
      isOpen: false,
      isClickable: false,
    };

  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (this.state.isOpen) {
        this.hidePopup();
      }
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  showPopup = (isClickable) => {
    this.setState({
      isClickable,
      isOpen: true
    })
  }

  hidePopup = () => {
    this.setState({
      isOpen: false
    })
  }

  togglePopup = (isClickable) => {
    const { isOpen } = this.state;
    if (isOpen) {
      this.hidePopup();
    } else {
      this.showPopup(isClickable);
    }
  }

  renderPopup = () => {
    const { isOrg, publicId, onFromOrgRemoveClick, onOpenDialog, allMembersGroupId,  userId } = this.props;
    const { isClickable } = this.state;

    return (
      <div className="item-popup">
        {isClickable && <button onClick={() => onFromOrgRemoveClick(publicId)}>Remove me from this organization</button>}
        {!isClickable && (isOrg ? 'Contact Evangelus support to be removed from this organization.'
          : 'Contact the Group or Org Admin to be removed from this group.')}
        {isOrg && (
          <React.Fragment>
            <hr/>
            <div>
              <button
                onClick={() => onOpenDialog(allMembersGroupId, userId)}
              >Remove from parish wide communication</button>
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }

  render() {
    const {
      name, description, publicId, isNotifyOnlyByEmail,
      isNotifyByEmail, isNotifyBySms, isPending, isAdmin, isOrg,
      onFromGroupRemoveClick, onChannelChange,
    } = this.props;

    const { isOpen } = this.state;

    return (
      <div className={`my-group-item ${isOrg ? 'type-org' : 'type-group'}`}>
        {isAdmin && <div className="group-role">{isAdmin ? (isOrg ? 'Org Admin' : 'Group Admin') : null}</div>}
        <div className="group-inner-wrapper">
          <div className="group-common-fields">
            <div className="group-name" ref={this.setWrapperRef}>
              <div className="overflow-wrapper">{name}</div>

              {isAdmin && <button
                className="button-item-action btn-group-star"
                onClick={(e) => this.togglePopup(false)}
              />}

              {!isAdmin && !isOrg && <button
                className="button-item-action btn-group-remove"
                onClick={() => onFromGroupRemoveClick(publicId)}
              />}

              {!isAdmin && isOrg && <button
                className={`button-item-action btn-dots ${isOpen ? 'active' : ''}`}
                onClick={() => this.togglePopup(true)}
              >
                <i></i>
              </button>}

              {isOpen && this.renderPopup()}
            </div>
            <div className="group-description">{description}</div>
            {isPending && <div className="pending-approval">Pending Approval</div>}
          </div>

          <NotificationsChannelPicker
            isEditMode
            isNotifyOnlyByEmail={isNotifyOnlyByEmail}
            isNotifyByEmail={isNotifyByEmail}
            isNotifyBySms={isNotifyBySms}
            onChange={onChannelChange}
          />
        </div>
      </div>
    );

  }
}

MyGroupItem.propTypes = {
  publicId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  isOrg: PropTypes.bool,
  isNotifyOnlyByEmail: PropTypes.bool,
  isNotifyByEmail: PropTypes.bool,
  isNotifyBySms: PropTypes.bool,
  isPending: PropTypes.bool,
  onFromOrgRemoveClick: PropTypes.func,
  onFromGroupRemoveClick: PropTypes.func.isRequired,
};

MyGroupItem.defaultProps = {
  description: null,
  isOrg: false,
  isPending: false,
  isNotifyOnlyByEmail: false,
  onFromOrgRemoveClick: () => {},
};

export default MyGroupItem;
