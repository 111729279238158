import PropTypes from 'prop-types';
import React from 'react';
import { CONSTANTS } from '../Actions/messageActions';

const MessageSmsPreviewPropType = {
  allowed: PropTypes.bool.isRequired,
  enabled: PropTypes.bool.isRequired,
  isPreventChange: PropTypes.bool,
  content: PropTypes.string,
  prefix: PropTypes.string.isRequired,
  recipientType: PropTypes.oneOf([
    CONSTANTS.RECIPIENT_TYPE_MEMBER,
    CONSTANTS.RECIPIENT_TYPE_GROUP,
  ]),
  preference: PropTypes.oneOf([
    CONSTANTS.PREFERENCE_EMAIL,
    CONSTANTS.PREFERENCE_SMS,
  ]),
  onSendByChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MessageSmsPreviewPropType;
