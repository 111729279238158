import Groups from '../Api/Groups';
import { camelCaseObjects } from '../Helpers/Utility';

export const ACTIONS = {
  LOAD: '[Groups Dictionary] List load',
  LOADING: '[Groups Dictionary] List loading',
  LOADED: '[Groups Dictionary] List loaded',
  CLEAR: '[Groups Dictionary] List clear',
  ADD: '[Groups Dictionary] Add',
  UPDATE: '[Groups Dictionary] Update',
};

export const getGroupsDictionary = (value) => async (dispatch, getState) => {
  const {
    groupsDictionaryReducer: { isLoaded, isLoading },
    organizationReducer: { organization: { public_id } },
    userProfileReducer: { main_role },
  } = getState();

  // Load data if not loaded before.
  if (!isLoaded && !isLoading) {
    dispatch({ type: ACTIONS.LOADING, payload: true });
    const response = await Groups.getGroupsDictionary(public_id, value, main_role);
    if (response.status === 200) {
      // TODO: refactor response on the server.
      dispatch({
        type: ACTIONS.LOAD,
        payload: response.data.data.data
          .map(group => ({
            publicId: group.public_id,
            name: group.name,
            value: group.public_id, // TODO: remove
            label: group.name, // TODO: remove
            group: group,
            groupParent: group.group_parent ? camelCaseObjects(group.group_parent) : null,
            organizationGeneralGroup: group.organization_general_group,
          }))
      });
    } else {
      dispatch({
        type: ACTIONS.LOAD,
        payload: [],
      });
    }
    dispatch({ type: ACTIONS.LOADING, payload: false });
    dispatch({ type: ACTIONS.LOADED, payload: true });

    return response;
  }

  return Promise.resolve();
};

export const clearGroupsDictionary = () => dispatch => dispatch({ type: ACTIONS.CLEAR });

export const addGroupToDictionary = (group) => dispatch => dispatch({ type: ACTIONS.ADD, payload: group });

export const updateGroupFromDictionary = (group) => dispatch => dispatch({ type: ACTIONS.UPDATE, payload: group });
