import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { selectOrganizationById } from '../../Actions/organizationActions';
import { getUserProfile } from '../../Actions/userActions';
import { navigationControllerRedirect } from '../../Actions/navigationControllerActions';

class SelectOrganization extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { organizations, onOrgSelected } = this.props;
    if (organizations && organizations.length) {
      this.props.selectOrganizationById(this.props.match.params.orgId);
      this.props.getUserProfile(this.props.match.params.orgId).then(() => {
        onOrgSelected();
        this.props.navigationControllerRedirect('/');
      });
    } else {
      this.props.navigationControllerRedirect('/');
    }
  }

  render() {
    return null;
  }
}

const mS = state => ({
  organizations: state.organizationReducer.organizations,
});

const mD = {
  selectOrganizationById,
  navigationControllerRedirect,
  getUserProfile,
};

export default withRouter(connect(
  mS,
  mD,
)(SelectOrganization));
