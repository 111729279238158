import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonPropType from '../../PropTypes/ButtonPropType';
import './index.sass';

// TODO: remove
const ButtonStandard = ({ title, onClick, subtitle, className, disabled }) => (
  <div className={`button-eva-container${className ? ` ${className}` : ''}`}>
    <Button className="button-eva-less-margin" type="submit" disabled={disabled} onClick={onClick}>
      {title}
      {subtitle ? <span className="button-subtitle">&nbsp;{subtitle}</span> : null}
    </Button>
  </div>
);

ButtonStandard.propTypes = ButtonPropType;

export default ButtonStandard;
