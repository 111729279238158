import { debounce } from 'lodash';
import {
  MOBILE,
  TABLET,
  DESKTOP,
  MIN_TABLET_WIDTH,
  MIN_DESKTOP_WIDTH,
} from '../Config/Constants/Responsive';

export const ACTIONS = {
  UPDATE_LAYOUT_RESPONSIVE_DATA: 'responsive/update_laoyout_responsive_data',
};

const RESPONSIVE_UPDATE_DELAY = 600;

const debouncedDispatch = debounce(dispatch => {
  const windowHeight = window.innerHeight;
  const windowWidth = window.innerWidth;
  let viewType = '';
  if (windowWidth < MIN_TABLET_WIDTH) {
    viewType = MOBILE;
  } else if (windowWidth < MIN_DESKTOP_WIDTH) {
    viewType = TABLET;
  } else {
    viewType = DESKTOP;
  }
  const { orientation } = window.screen;

  dispatch({
    type: ACTIONS.UPDATE_LAYOUT_RESPONSIVE_DATA,
    payload: {
      layoutData: {
        windowHeight,
        windowWidth,
        viewType,
        orientation,
      },
    },
  });
}, RESPONSIVE_UPDATE_DELAY);

export const updateLayoutData = () => dispatch => {
  debouncedDispatch(dispatch);
};
