import React, { Component } from 'react';
import MemberAdminAdd from '../../Components/MemberAdminAdd';
import SettingsGroup from '../../Components/SettingsGroup';

class EditGroup extends Component {
  state = {
    showSettingsSearch: false,
  };
  handleOpen = () => {
    this.setState({ showSettingsSearch: true });
  };
  handleClose = () => {
    this.setState({ showSettingsSearch: false });
  };

  render() {
    const { showSettingsSearch } = this.state;

    return (
      <React.Fragment>
        {showSettingsSearch ? (
          <MemberAdminAdd handleClose={this.handleClose} />
        ) : (
          <SettingsGroup handleAdminAdd={this.handleOpen} />
        )}
      </React.Fragment>
    );
  }
}

export default EditGroup;
