import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Scrollbars from 'react-custom-scrollbars';
import { withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import SimpleModalPropType from '../../PropTypes/SimpleModalPropType';
import { styles } from './styles';
import './index.sass';

const SimpleModal = ({
  title,
  content,
  open,
  handleClose,
  className,
  scroll,
  classes,
}) => (
  <React.Fragment>
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={scroll}
      maxWidth="md"
      labelledby="scroll-dialog-title"
      className={className}
    >
      <div className="close-block-icon">
        <IconButton onClick={handleClose}>
          <ClearIcon />
        </IconButton>
      </div>
      <DialogTitle
        id="scroll-dialog-title"
        className="modal-title title-modal-eva"
      >
        {title}
      </DialogTitle>
      <Scrollbars
        autoHeight
        autoHide
        autoHeightMin={10}
        autoHeightMax="100vh - 220px"
      >
        <DialogContent>
          <DialogContentText className={`modal-content modal-content-info ${classes.root}`}>
            {content}
          </DialogContentText>
        </DialogContent>
      </Scrollbars>
    </Dialog>
  </React.Fragment>
);

SimpleModal.propTypes = SimpleModalPropType;

export default withStyles(styles)(SimpleModal);
