import PropTypes from 'prop-types';
import React from 'react';

const MessageTwitterFbPreviewPropType = {
  allowed: PropTypes.bool.isRequired,
  enabled: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired,
  image: PropTypes.string,
  previewImages: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedPreviewImage: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSendByChange: PropTypes.func.isRequired,
  onPreviewChange: PropTypes.func.isRequired,
};

export default MessageTwitterFbPreviewPropType;
