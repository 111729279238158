import React, { Component } from 'react';
import SentMessagePropType from '../../PropTypes/SentMessagePropType';
import ButtonSimple from '../ButtonSimple';
import EvaAnimation from '../EvaAnimation';
import { ScheduleMessageAnimation, SentMessageAnimation } from '../../Animations';
import './index.sass';

class MessageSentNotification extends Component {

  renderSuccessCase = () => (
    <React.Fragment>
      <EvaAnimation animation={this.props.isScheduled ? ScheduleMessageAnimation : SentMessageAnimation}/>
      {!this.props.isScheduled && (
        <div className="sent-notification-body">
          Great Job! <br/>
          Your Message has <br/> been sent successfully.
        </div>)}
    </React.Fragment>
  )

  render = () => {
    const { isSuccess, onNewMessageClick } = this.props;

    return (
      <div id="message-sent-notification">

        {isSuccess ? this.renderSuccessCase() :
          <div className="sent-notification-body">Internal Error! please try again</div>}

        <ButtonSimple text="Create a new message" onClick={onNewMessageClick}/>
      </div>
    )
  }
}


MessageSentNotification.propTypes = SentMessagePropType;

export default MessageSentNotification;
