import React, { Component } from 'react';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import ClearIcon from '@material-ui/icons/Clear';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { KeyboardArrowLeft } from '@material-ui/icons';
import { enableRightDrawerTemplates } from '../../Actions/rightDrawerTemplatesActions';
import RightDrawerPropType from '../../PropTypes/RightDrawerPropType';
import { changeTab } from '../../Actions/middleMenuActions';
import EvaTooltip from '../EvaTooltip';
import './index.sass';

const drawerWidth = window.innerWidth > 768 ? 529 : window.innerWidth;

const styles = theme => ({
  [theme.breakpoints.only('sm')]: {
    drawerPaper: {
      width: '100% !important',
      height: '100% !important',
    },
    drawerHeader: {
      marginBottom: 0,
      padding: '0px !important',
    },
    iconButtonContainer: {
      backgroundColor: '#f3f3f3',
      height: 66,
      borderRadius: 0,
      paddingLeft: '30px',
    },
  },
  [theme.breakpoints.only('xs')]: {
    drawerPaper: {
      width: '100% !important',
      height: '100% !important',
      overflowX: 'hidden',
    },
    drawerHeader: {
      marginBottom: 0,
      padding: '0px !important',
    },
    iconButtonContainer: {
      backgroundColor: '#f3f3f3',
      height: 52,
      borderRadius: 0,
    },
  },
  drawerPaper: {
    position: 'absolute',
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-left',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    minHeight: 40,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  'content-left': {
    marginLeft: -drawerWidth,
  },
  'content-right': {
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  'contentShift-left': {
    marginLeft: 0,
  },
  'contentShift-right': {
    marginRight: 0,
  },
  '@media (min-width: 1024px)': {
    drawerHeader: {
      justifyContent: 'space-between',
      '&>.MuiIconButton-root': {
        flexShrink: '0',
      },
      '&>.drawer-title': {
        flexGrow: '1',
      },
      '&>.drawer-second-title': {
        display: 'flex',
        flexShrink: '0',
        width: '100px',
        alignItems: 'center',
      },
    },
  },
});

class RightDrawerTemplates extends Component {
  state = {
    side: 'right',
  };

  handleDrawerClose = () => {
    if (this.props.cleaner) {
      this.props.cleaner();
    }
    this.props.enableRightDrawerTemplates({
      payload: { enable: false, element: <div /> },
    });
    if (window.innerWidth >= 1024) {
      document.querySelector('.right-drawer.drawer-templates > .drawer-md-up').style.display =
        'none';
    }
  };

  handleChangeAnchor = event => {
    this.setState({
      side: event.target.value,
    });
  };

  render() {
    const {
      classes,
      element,
      status,
      title,
      secondTitle,
      isBack,
      disableEnforceFocus,
    } = this.props;
    const { side } = this.state;
    return (
      <div className={`right-drawer drawer-templates ${!status ? 'disable' : ''}`}>
        <Hidden mdUp>
          <Drawer
            anchor={side}
            open={status}
            classes={{
              paper: classes.drawerPaper,
            }}
            disableEnforceFocus={disableEnforceFocus}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={this.handleDrawerClose} className={classes.iconButtonContainer}>
                <EvaTooltip title="Back">
                  <KeyboardArrowLeft />
                </EvaTooltip>
              </IconButton>
              <div className="drawer-template-title">{title}</div>
              {secondTitle && <div className="drawer-secondTemplate-title">{secondTitle}</div>}
            </div>
            <div className="right-drawer-content">{element}</div>
          </Drawer>
        </Hidden>
        <Hidden smDown>
          <div className="drawer-md-up">
            <Drawer
              anchor={side}
              open={status}
              classes={{
                paper: classes.drawerPaper,
              }}
              disableEnforceFocus={disableEnforceFocus}
            >
              <div className={classes.drawerHeader}>
                {!isBack && (
                  <IconButton onClick={this.handleDrawerClose}>
                    <EvaTooltip title="Close">
                      <ClearIcon />
                    </EvaTooltip>
                  </IconButton>
                )}
                {isBack && (
                  <IconButton onClick={this.handleDrawerClose}>
                    <EvaTooltip title="Back">
                      <KeyboardArrowLeft />
                    </EvaTooltip>
                  </IconButton>
                )}
                <div className="drawer-title">{title}</div>
                {secondTitle && <div className="drawer-second-title">{secondTitle}</div>}
              </div>
              <div className="right-drawer-content">{element}</div>
            </Drawer>
          </div>
        </Hidden>
      </div>
    );
  }
}

const mS = state => ({
  status: state.rightDrawerTemplatesReducer.enable,
  element: state.rightDrawerTemplatesReducer.element,
  isBack: state.rightDrawerTemplatesReducer.isBack,
  title: state.rightDrawerTemplatesReducer.title,
  secondTitle: state.rightDrawerTemplatesReducer.secondTitle,
  cleaner: state.rightDrawerTemplatesReducer.cleaner,
  disableEnforceFocus: state.rightDrawerTemplatesReducer.disableEnforceFocus,
});

const mD = {
  enableRightDrawerTemplates,
  changeTab,
};

RightDrawerTemplates.propTypes = RightDrawerPropType;

export default connect(
  mS,
  mD,
)(withStyles(styles, { withTheme: true })(RightDrawerTemplates));
