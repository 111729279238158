import Invitation from '../Api/Invitation';
import snackBarStatus from './snackbarActions';
import Members from '../Api/Members';

export const ACTIONS = {
  CREATE: '[Group Invite Member] Create',
  CLEAR: '[Group Invite Member] Clear',
  UPDATE: '[Group Invite Member] Update',
  UPDATE_GROUPS: '[Group Invite Member] Update groups',
  REMOVE_GROUP: '[Group Invite Member] Remove group',
  SELECT_MEMBER: '[Group Invite Member] Select member',
  SEARCH_MEMBERS_MULTI_ORG: '[Group Invite Member] Search members multi org',
};

export const create = state => ({ type: ACTIONS.CREATE, payload: state });

export const update = state => ({ type: ACTIONS.UPDATE, payload: state });

export const selectMember = publicId => ({ type: ACTIONS.SELECT_MEMBER, payload: publicId });

export const updateInviteDestinationGroups = state => ({ type: ACTIONS.UPDATE_GROUPS, payload: state });

export const removeInviteDestinationGroup = state => ({ type: ACTIONS.REMOVE_GROUP, payload: state });

export const clear = () => dispatch => dispatch({ type: ACTIONS.CLEAR });
/* eslint-disable */
export const searchMemberAction = memberInfo => async dispatch => {
  try {
    const { email, phone } = memberInfo;
    const reqData = {
      isNotBelongToGroup: true,
    };

    if (email) {
      reqData.email = email;
    }

    if (phone) {
      reqData.phone = phone;
    }

    const response = await Members.getMembers(reqData);
    const { status, data: { data: { data: membersList } } } = response;

    if (status === 200 && Array.isArray(membersList)) {
      const stepTwo = [
        memberInfo,
        { ...memberInfo, firstName: '', lastName: '' },
        {},
      ];
      const dataSpecial = membersList.length === 1 ? [{ ...membersList.shift(), ...memberInfo },] : membersList;
      const stepThree = [memberInfo, {}, { members: dataSpecial, permissionToAdd: false }];
      dispatch(
        update({
          steps: dataSpecial.length ? stepThree : stepTwo,
          currentStep: dataSpecial.length ? 2 : 1,
        }),
      );
    }
  } catch (error) {
    console.log(error, 'error');
  }
};

export const submitMembersActions = (groups, members, isMobile) => async dispatch => {
  let groupsList = groups.map(group => ({ public_id: isMobile ? group.value : group }));

  const membersList = members.map(member => {
    if (typeof member === 'string') {
      return {
        public_id: member,
      };
    }
    return {
      first_Name: member.firstName && member.firstName,
      last_name: member.lastName && member.lastName,
      birth_date: member.birthday && member.birthday,
      phones:
        member.phone !== ''
          ? [
            {
              description: 'main',
              country_code: '',
              phone_number: member.phone,
              is_default: true,
            },
          ]
          : [],
      status: 'active',
      user: {
        email: member.email && member.email,
        username: member.email && member.email,
        plain_password: '1234',
      },
    };
  });
  const membersInfo = { groups: groupsList, members: membersList };
  try {
    const response = await Invitation.inviteMembers(membersInfo);

    if (response.status === 200) {
      snackBarStatus({
        payload: {
          title: response.data.message,
          type: 'success',
          enable: true,
        },
      })(dispatch);
    }

    return response;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
  }
};
/* eslint-enable */
