// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../Theme/Icons/reply.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".thread-reply{display:-ms-flexbox;display:flex;padding:0 7% 0 47px;-ms-flex-pack:justify;justify-content:space-between;font-family:\"GothamSSm-Book\",Arial,sans-serif;font-size:12px}.thread-reply .left-side{margin-right:20px}.thread-reply .right-side{-ms-flex-positive:1;flex-grow:1;text-align:right}.thread-reply .date-message-threads{color:#a9a9a9;padding:0 10px 0}.thread-reply .counter{text-align:left;color:#9a66bf;padding-left:45px;cursor:pointer}.thread-reply .reply-img-threads{margin-left:0;margin-top:0;position:absolute;width:25px;height:25px}.thread-reply .button-reply{padding:19px 20px 10px 1px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;border:none;cursor:pointer;overflow:hidden;outline:none}\n", ""]);
// Exports
module.exports = exports;
