import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import defaultImg from '../../Theme/Icons/member.svg';
import evaLogo from '../../Theme/Icons/eva.svg';
import { styles } from './styles';

const CustomRighHeader = ({ memberImage, classes }) => (
  <div className={classes.customRightDrawerHeader}>
    <img src={evaLogo} alt="Evangelus" />
    <div className="member-img">
      <img src={memberImage || defaultImg} alt="member" />
    </div>
  </div>
);

CustomRighHeader.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  memberImage: PropTypes.string,
};

CustomRighHeader.defaultProps = {
  memberImage: '',
};

export default withStyles(styles)(CustomRighHeader);
