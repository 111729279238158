import PropTypes from 'prop-types';

const TemplatePropType = {
  getFoldersList: PropTypes.func.isRequired,
  userProfile: PropTypes.shape({
    profile_picture_url: PropTypes.string,
  }).isRequired,
};

export default TemplatePropType;
