import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import TextareaAutosize from 'react-textarea-autosize';
import favoriteIcon from '../../Theme/Icons/favorite.svg';
import commentIcon from '../../Theme/Icons/chat_bubble_twitter.svg';
import emailIcon from '../../Theme/Icons/email_twitter.svg';
import retweetIcon from '../../Theme/Icons/repeat.svg';
import arrowIcon from '../../Theme/Icons/arrow_down.svg';
import memberImage from '../../Theme/Icons/member.svg';
import TwitterPostviewPropType from '../../PropTypes/TwitterPostviewPropType';
import { MESSAGE_DESIGNER_TYPE } from '../../Config/Constants';
import '../../Components/MessageTwitterPreview/index.sass';
import './index.sass';

const styles = theme => ({
  colorBar: {},
  colorChecked: {},
  tabsIndicator: {
    background: 'linear-gradient(to right, #9966bf 0%, #6174c9 98%)',
  },
  tabsRoot: {
    textTransform: 'initial',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'GothamSSm-Book',
    color: '#a9a9a9',

    '&:focus': {
      color: '#9a66bf',
    },
  },
});

const TwitterPostPostview = ({ enabled, content, profilePic, profileName, twitterProfileName, image, date, type }) => (
  <div className="send-twitter-container post-view">
    {enabled && content ? (
      <div>
        <div className="message-container">
          <div
            className={`twitter-content ${type === MESSAGE_DESIGNER_TYPE ? 'designerContent' : ''}`}
            id="scroll"
          >
            <div className={`twitter-message ${enabled ? 'show' : 'blur'}`}>
              <Grid item xs={1} className="member-message-twitter">
                <img src={profilePic || memberImage}/>
              </Grid>
              <div className="twitter-body">
                <div className="twitter-header">
                  <p className="twitter-name">
                    {profileName}
                  </p>
                  <div className="twitter-lower-header">
                    <p className="twitter-handle">{twitterProfileName}</p>
                    <p className="twitter-date">{date}</p>
                  </div>
                  <img src={arrowIcon} className="twitter-arrow" alt="arrow_down"/>
                </div>
                <TextareaAutosize
                  className="twitter-body-text"
                  value={content}
                  disabled
                />
                {image && (
                  <div className="twitter-images">
                    <img src={image} alt="gallery" className="gallery-image"/>
                  </div>
                )}
                <div className="twitter-buttons">
                  <img src={commentIcon} alt="comment"/>
                  <img src={retweetIcon} alt="retweet"/>
                  <img src={favoriteIcon} alt="favorite"/>
                  <img src={emailIcon} alt="email "/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="not-available">
        <p>
          You have not setup Twitter Publishing for this group.{' '}
          <Link to="/groups">Click here</Link> to configure. Once you are done, you will need to
          open the draft of this message to continue.
        </p>
      </div>
    )}
  </div>
)
TwitterPostPostview.propTypes = TwitterPostviewPropType;

export default withStyles(styles)(TwitterPostPostview);
