import AXIOS from '../Config/AxiosConfig';
import headers from './Helper';

const Invitation = {
  inviteMembers(data) {
    return AXIOS.post('/eva/invitation/multiple-groups-and-members', data, {
      headers: headers(),
    });
  },
  requestOrganization(organizationId) {
    const url = `/public/eva/organization/${organizationId}`;
    return AXIOS.get(url, {
      headers: headers(),
    });
  },
  requestPublicGroupInfo(groupId) {
    const url = `/public/eva/group/${groupId}`;
    return AXIOS.get(url, {
      headers: headers(),
    });
  },
  requestPublicGroupInfoAdminPromotion(groupId, token) {
    const url = `/public/eva/group/${groupId}/${token}/validate/group-admin-promotion`;
    return AXIOS.get(url, {
      headers: headers(),
    });
  },
  requestPublicOrganizationInfoAdminPromotion(organizationId, token) {
    const url = `/public/eva/organization/${organizationId}/${token}/validate/org-admin-promotion`;
    return AXIOS.get(url, {
      headers: headers(),
    });
  },
  requestPublicOrganizationInfoSuperAdminPromotion(organizationId, token) {
    const url = `/public/eva/organization/${organizationId}/${token}/validate/org-superadmin-promotion`;
    return AXIOS.get(url, {
      headers: headers(),
    });
  },
  invitationJoin(invitationType, publicId) {
    const url = `/eva/${invitationType}/${publicId}/join`;
    return AXIOS.post(url, {}, {
      headers: headers(),
    });
  },
};

export default Invitation;
