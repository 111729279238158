// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../Theme/Icons/password.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".password-change-pad{max-width:400px;margin:0 auto;border:2px solid #9a66bf;border-radius:14px;padding:30px 17px}.password-change-pad .current-password{margin-bottom:30px}.password-change-pad .input-eva-border-bottom{padding-left:0;margin-bottom:0}.password-change-pad .input-eva-border-bottom::before{content:'';background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-color:white;background-repeat:no-repeat;width:40px;height:40px;display:block;position:absolute;top:0;left:5px;border:none}.password-change-pad .input-eva-border-bottom::-webkit-input-placeholder{padding:0 !important;text-transform:capitalize}.password-change-pad i.input-eva-current-password-icon,.password-change-pad i.input-eva-new-password-icon,.password-change-pad i.input-eva-confirm-password-icon{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-color:white;background-repeat:no-repeat;width:40px;height:40px;display:block;position:absolute;top:0;left:5px;border:none}.password-change-pad .button-eva-container{margin-top:30px}.password-change-pad .input-div{overflow:hidden;border-width:0 0 2px;margin-bottom:15px}.password-change-pad button{min-width:200px}\n", ""]);
// Exports
module.exports = exports;
