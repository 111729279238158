import React, { Component } from 'react';

import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import ContentEditable from 'react-contenteditable';
import { withStyles } from '@material-ui/core/styles';
import { switchStyles } from '../../Theme/switchStyles';
import TemplateMessagePreviewPropType from '../../PropTypes/TemplateMessagePreviewPropType';
import './index.sass';

const styles = theme => ({
  colorBar: {},
  colorChecked: {},
  ...switchStyles,
  tabsIndicator: {
    background: 'linear-gradient(to right, #9966bf 0%, #6174c9 98%)',
  },
  tabsRoot: {
    textTransform: 'initial',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'GothamSSm-Book',
    color: '#a9a9a9',

    '&:focus': {
      color: '#9a66bf',
    },
  },
});

class TemplateWebPreview extends Component {
  state = {
    value: 0,
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { value } = this.state;
    const { classes, content } = this.props;
    return (
      <div className="message-email-container">
        <div className="send-web-container">
          <div className="switch-container template-switch-container">
            <div className="responsive-preview-icons">
              <Tabs
                classes={{
                  root: classes.tabsRoot,
                  indicator: classes.tabsIndicator,
                }}
                value={value}
                onChange={this.handleChange}
              >
                {/*
                <Tab
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                  }}
                  label=""
                  icon={<i className="discover-mass-icon" />}
                />
                <Tab
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                  }}
                  label=""
                  icon={<i className="computer-icon" />}
                />
                <Tab
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                  }}
                  label=""
                  icon={<i className="rss-icon" />}
                /> */}
              </Tabs>
            </div>
          </div>
        </div>
        <div className="page-container designerContent">
          <ContentEditable
            className="content"
            id="scroll"
            tagName="p"
            html={content} // innerHTML of the editable div
            disabled
          />
        </div>
      </div>
    );
  }
}

TemplateWebPreview.propTypes = TemplateMessagePreviewPropType;

export default withStyles(styles)(TemplateWebPreview);
