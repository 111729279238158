import React from 'react';
import Grid from '@material-ui/core/Grid';
import memberImg from '../../Theme/Icons/eva-avatar.svg';
import NotificationsPropType from '../../PropTypes/NotificationsPropType';
import './index.sass';

const Notifications = ({ title, username }) => (
  <Grid container spacing={0} className="notifications-eva">
    <Grid item xs={1} className="avatar-notifications"><img src={memberImg} alt="member" /></Grid>
    <Grid item xs={11} className="message-notifications">
      <p>{title} {username}</p>
    </Grid>
  </Grid>
);

Notifications.propTypes = NotificationsPropType;

export default Notifications;
