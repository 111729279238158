import ACTIONS from '../Actions/AvatarActions';

// eslint-disable-next-line
const randomValues = crypto.getRandomValues(new Uint32Array(1));

const initialState = {
  forceReload: randomValues[0],
};

export default function AvatarReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.FORCE_RELOAD: {
      return { ...state, forceReload: action.payload };
    }
    default: {
      return state;
    }
  }
}
