import React from 'react';
import Grid from '@material-ui/core/Grid';
import './index.sass';

const message = 'A previous session already exists.';

const Unauthorized = () => (
  <Grid container spacing={0} className="main-content">
    <Grid item xs={12} className="title description-section-content">
      EVANGELUS
    </Grid>
    <Grid item xs={12} className=" subtitle description-section-content">
      {message}
    </Grid>
  </Grid>
);

export default Unauthorized;
