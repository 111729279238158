import { EDITABLE_FOLDERS, EDITABLE_FOLDERS_SETTINGS } from '../Config/Constants';

export const getEditableFoldersByRole = role =>
  (role && EDITABLE_FOLDERS[role]) || EDITABLE_FOLDERS.defaultFolders;

export const getEditableFolderSettingsByRole = role =>
  (role && EDITABLE_FOLDERS_SETTINGS[role]) || EDITABLE_FOLDERS_SETTINGS.defaultFolders;

export const getSelectableFolders = (folders, role) => {
  if (!Array.isArray(folders)) {
    return [];
  }

  const editableFolders = getEditableFoldersByRole(role);
  const mainSelectableFolders = folders.filter(folder =>
    editableFolders.includes(folder.name),
  );
  const selectableFolders = [];

  mainSelectableFolders.forEach(folderData => {
    if (
      typeof folderData === 'object' &&
      Array.isArray(folderData.subFolders)
    ) {
      const { ...folder } = folderData;
      let { subFolders } = folderData;

      selectableFolders.push(folder);
      subFolders = subFolders.map(({ name, publicId, folderParent }) => ({
        name: `${folderParent.name} / ${name}`,
        publicId,
      }));
      selectableFolders.push(...subFolders);
    }
  });

  return selectableFolders;
};

export const getSelectedTemplates = (
  templates,
  extractAttr = 'publicId',
  resultAttr = 'public_id',
) => {
  const selectedTemplatesIds = [];

  if (!Array.isArray(templates)) {
    return selectedTemplatesIds;
  }

  templates.forEach(template => {
    if (template.statusCheck) {
      selectedTemplatesIds.push({
        [resultAttr]: template[extractAttr],
      });
    }
  });

  return selectedTemplatesIds;
};
