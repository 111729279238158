import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import './index.sass';
import EvaTooltip from '../EvaTooltip';
import styles from './InputIconGridlessStyles';
import noSee from '../../Theme/Icons/no-see-group-icon.svg';
import visibility from '../../Theme/Icons/visibility.svg';

export class InputPasswordIcon extends React.Component {
  state = {
    passwordVisible: false,
  };

  displayPassword = passwordVisible => this.setState({ passwordVisible });

  render() {
    const {
      className,
      name,
      placeholder,
      value,
      onChange,
      maxLength,
      onBlur,
      disable,
      tooltipTitle,
      classes,
    } = this.props;
    const icon = 'password';
    const iconClass = icon ? `input-eva-${icon}-icon` : 'iconless';
    /* eslint-disable */
    const tooltipedElement = tooltipTitle ? (
      <EvaTooltip title={tooltipTitle}>
        <div className={classes.iconContainer}>
          <i className={iconClass} />
        </div>
      </EvaTooltip>
    ) : (
      <div className={classes.iconContainer}>
        <i className={iconClass} />
      </div>
    );
    /* eslint-enable */
    return (
      <div className={`input-box ${className} ${classes.root}`}>
        {tooltipedElement}
        <input
          name={name}
          type={this.state.passwordVisible ? 'text' : 'password'}
          placeholder={placeholder}
          value={value}
          onChange={event => onChange(event, name)}
          onBlur={onBlur}
          maxLength={maxLength}
          disabled={disable ? 'disabled' : ''}
          autoComplete="off"
        />
        <button
          className={classes.passwordView}
          onClick={event => {
            event.preventDefault();
            this.displayPassword(!this.state.passwordVisible);
          }}
        >
          <img
            src={this.state.passwordVisible ? noSee : visibility}
            alt="Visibility"
            className="password-visibility"
          />
        </button>
      </div>
    );
  }
}

export default withStyles(styles)(InputPasswordIcon);
