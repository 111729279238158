// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../Theme/Icons/cancel-copy.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".shares-list{display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap}.shares-list .share-item{display:-ms-inline-flexbox;display:inline-flex;-ms-flex-align:center;align-items:center;margin-bottom:20px;background-color:#efe3f7;border:2px solid #9a66bf;border-radius:20px;font-family:\"GothamSSm-Book\",Arial,sans-serif;line-height:normal;color:#9a66bf}.shares-list .share-item:not(:last-child){margin-right:20px}.shares-list .share-inner-wrapper{padding:5px 10px 5px 20px;overflow:hidden;max-width:100%}.shares-list .share-inner-wrapper .title,.shares-list .share-inner-wrapper .description{white-space:nowrap;text-overflow:ellipsis}.shares-list .share-inner-wrapper .description{opacity:0.5;font-size:12px;font-weight:bold}.shares-list button{width:40px;height:100%;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center 10px;color:#9a66bf}\n", ""]);
// Exports
module.exports = exports;
