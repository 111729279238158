import PropTypes from 'prop-types';

const TemplateSettingsPropType = {
  folder: PropTypes.string,
  isFeatured: PropTypes.bool,
  featuredSubFolder: PropTypes.string,
  shareEmail: PropTypes.string,
  shareOrg: PropTypes.string,
  shareGroup: PropTypes.string,
  comments: PropTypes.string,
  getFoldersList: PropTypes.func.isRequired,
  publicId: PropTypes.string,
  updateTemplate: PropTypes.func.isRequired,
  saveTemplate: PropTypes.func.isRequired,
  afterUpdate: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default TemplateSettingsPropType;
