import React from 'react';
import PropTypes from 'prop-types';
import MessageHeadSettings from '../../Containers/MessageHeadSettings';
import ButtonSimple from '../ButtonSimple';
import './index.sass';

const SettingsModalContent = (props) => (
  <div id="settings-modal-content">
    <MessageHeadSettings/>
    <div className="settings-buttons" style={{ textAlign: 'center' }}>
      <ButtonSimple
        text="Continue"
        className="button-continue"
        onClick={props.onContinueClick}
      />
      <br/>
      <ButtonSimple text="Cancel" buttonStyle="transparent" onClick={props.onCancelClick}/>
    </div>
  </div>
);

SettingsModalContent.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
  onContinueClick: PropTypes.func.isRequired,
};

export default SettingsModalContent;
