/* eslint-disable */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import LinkGallery from '../../Components/LinkGallery';
import UploadGallery from '../../Components/UploadGallery';
import { enableRightDrawer } from '../../Actions/rightDrawerActions';
import {
  getFoldersContent,
  getFolderImage,
  displayGallery,
} from '../../Actions/imageGalleryActions';
import GalleryOptionsProptypes from '../../PropTypes/GalleryOptionsProptype';
import './index.sass';
import ImageGallery from '../ImageGallery';
import { updateCustomModal } from '../../Actions/customModalActions';
import EvaTooltip from '../../Components/EvaTooltip';
import LoaderOverlay from '../../Components/LoaderOverlay';

const drawerWidth = window.innerWidth > 768 ? '414px !important' : window.innerWidth;

const styles = theme => ({
  drawerPaper: {
    width: drawerWidth,
    height: '118%',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    minHeight: 40,
  },
});

class GalleryOptions extends Component {
  state = {
    component: null,
    selectedOption: '',
  };
  componentDidMount = () => {
    this.props.getFoldersContent();
    this.selectComponent(this.props.openTab === 'url' ? 'link' : 'gallery');
  };

  selectComponent = componentType => {
    const { inputField, onImagePick } = this.props;
    const { selectedOption } = this.state;
    const openGallery = () => {
      this.selectComponent('gallery')
    };

    switch (componentType) {
      case 'gallery':
        this.setState({
          component: <ImageGallery onChange={onImagePick} />,
          selectedOption: 'gallery',
        });
        break;
      case 'link':
        this.setState({ component: <LinkGallery openGallery={openGallery} />, selectedOption: 'link' });
        break;
      case 'upload':
        if (selectedOption === componentType) {
          inputField.click();
          return;
        }
        this.setState({
          component: <UploadGallery openGallery={openGallery} />,
          selectedOption: 'upload',
        });
        break;
      default:
        break;
    }
  };

  render() {
    const { component, selectedOption } = this.state;
    const { isLoading } = this.props;

    return (
      <div className="gallery-container">
        <Grid container spacing={0} className="gallery-container__tools">
          <Grid item xs={12}>
            <EvaTooltip title="Gallery">
              <button
                className={`mountain-icon styless-button ${
                  selectedOption === 'gallery' ? 'icon-selected' : ''
                  }`}
                onClick={() => this.selectComponent('gallery')}
              />
            </EvaTooltip>
            <EvaTooltip title="Upload picture">
              <button
                className={`upload-icon styless-button ${
                  selectedOption === 'upload' ? 'icon-selected' : ''
                  }`}
                onClick={() => this.selectComponent('upload')}
              />
            </EvaTooltip>
            <EvaTooltip title="Url web image">
              <button
                className={`link-icon styless-button ${
                  selectedOption === 'link' ? 'icon-selected' : ''
                  }`}
                onClick={() => this.selectComponent('link')}
              />
            </EvaTooltip>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <div className="right-drawer-content">{component}</div>
        </Grid>

        {isLoading && <LoaderOverlay /> }
      </div>
    );
  }
}

GalleryOptions.propTypes = GalleryOptionsProptypes;

const mS = state => ({
  isLoading: state.imageGalleryReducer.isLoading,
  inputField: state.imageGalleryReducer.inputField,
});

const mD = {
  enableRightDrawer,
  getFoldersContent,
  getFolderImage,
  displayGallery,
  updateCustomModal,
};

export default withStyles(styles, { withTheme: true })(
  connect(
    mS,
    mD,
  )(GalleryOptions),
);
