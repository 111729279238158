import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { debounce } from '../../Helpers/debounce';
import MessageSelectedContainer from '../../Containers/MessageSelectedContainer';
import { handleMiddleMenuLeft } from '../../Actions/groupsActions';
import { openCustomModal } from '../../Actions/customModalActions';
import {
  archiveMessages,
  checkMessage,
  clearCheckedMessages,
  clearMessagePagination,
  getMessages,
  getMoreMessages,
  unArchiveMessages
} from '../../Actions/messagesActions';
import { clearMessage, getMessage, initDraftMessage } from '../../Actions/messageActions';
import snackBarStatus from '../../Actions/snackbarActions';
import { enableRightDrawerGroupMessage } from '../../Actions/rightDrawerGroupMessagesActions';
import {
  changeMessagesFilters,
  changeMessagesSearchTerm,
  changeMessagesSort,
  resetMessagesFilters,
  toggleMessagesFilters,
} from '../../Actions/messagesFiltersActions';
import { navigationControllerRedirect } from '../../Actions/navigationControllerActions';
import LoaderOverlay from '../LoaderOverlay';
import trans from '../../Translations/translation.service';
import SearchFiltersPad from '../SearchFiltersPad';
import MessagesFilters from '../MessagesFilters';
import MessageSearchDesktop from '../MessageSearchDesktop';
import { MessagesList } from '../MessagesList';
import './index.sass';
import { Scrollbars } from 'react-custom-scrollbars';
import { camelCaseObjects } from '../../Helpers/Utility';
import { MESSAGE_DESIGNER_TYPE } from '../../Config/Constants';
import MessageModalManager, { MESSAGE_MODAL_MANAGER_VIEWS } from '../../Containers/MessageModalManager';


class MessagesGroups extends React.Component {

  debouncedSearch = null;

  constructor(props) {
    super(props);
    this.debouncedSearch = debounce(this.handleSearchTermChange, 800);
  }

  componentDidMount() {
    this.loadMessages(this.resetMessages());
  }

  componentWillUnmount() {
    this.props.clearCheckedMessages();
    this.props.resetMessagesFilters();
  }

  resetMessages = () => {
    this.props.clearMessagePagination(); // TODO: remove(?)
    return 1;
  };

  resetPagination = () => {
    this.props.clearMessagePagination(); // TODO: remove(?)
    return 1;
  };

  handleSearchTermChange = value => {
    this.props.changeMessagesSearchTerm(value).then(() => {
      this.loadMessages(this.resetPagination())
    });
  };

  handleFiltersChange = filters => {
    this.props.changeMessagesFilters(filters).then(() => {
      this.loadMessages(this.resetMessages());
    });
  };

  handleSort = value => this.props.changeMessagesSort(value)
    .then(() => this.loadMessages(this.resetMessages()))

  handleCheckItem = publicId => this.props.checkMessage(publicId);

  handleArchive = publicIds => this.props.archiveMessages(publicIds)
    .then(() => this.loadMessages(this.resetMessages()));

  handleUnArchive = publicIds => this.props.unArchiveMessages(publicIds)
    .then(() => this.loadMessages(this.resetMessages()));

  // TODO: refactor
  handleSelectEvaCardMessage = async messagePublicId => {
    const { isMessageLoading, messages } = this.props;

    if (this.props.isMessageLoading) {
      return;
    }

    const messageLite = messages.find(m => m.publicId === messagePublicId);

    this.props.clearMessage();

    try {
      if (messageLite.status === 'draft') {
        if (messageLite.type === MESSAGE_DESIGNER_TYPE) {

          this.props.navigationControllerRedirect(`/messages/edit/${messageLite.publicId}`);
        } else {
          const response = await this.props.getMessage(messageLite.publicId);

          if (response.code === 200) {
            const message = camelCaseObjects(response.data);

            this.props.handleMiddleMenuLeft({
              status: '',
              dinamicGrid: 7,
              dinamicGridMiddleMenu: 12,
            });

            this.props.initDraftMessage(message);

            this.props.openCustomModal({
              content: <MessageModalManager view={MESSAGE_MODAL_MANAGER_VIEWS.EDITOR} />
            });
          }
        }
      } else {
        // TODO: use same component for mobile/desktop versions instead
        if (this.props.windowWidth <= 768) {
          this.props.enableRightDrawerGroupMessage({
            payload: {
              enable: true,
              isBackButton: true,
              backTitle: 'Groups',
              element: (
                <React.Fragment>
                  <MessageSelectedContainer messageId={messageLite.publicId} onArchive={this.handleArchive} onUnArchive={this.handleUnArchive}/>
                </React.Fragment>
              ),
              title: 'Groups',
              secondTitle: <div className="eva-custom-logo"/>,
            },
          });
        } else {
          this.props.handleMiddleMenuLeft({
            status: 'animation-left-on',
            dinamicGrid: 12,
            dinamicGridMiddleMenu: 6,
            messageId: messageLite.publicId,
          });
        }
      }
    } catch (err) {
      console.error(err);
      this.props.snackBarStatus({
        payload: {
          enable: true,
          type: 'error',
          title: trans('message.getCompleteDataFailed'),
        },
      });
    }
  };
  /* eslint-enable */

  updateMessageList = () => {
    this.loadMessages();
  };

  loadMessages = nextPage => {
    const {
      messagesPagination: { currentPage }, filters: { type = 'message', date }, sort, searchTerm
    } = this.props;
    const pagination = nextPage ? nextPage : currentPage;
    this.props.getMessages(
      type,
      searchTerm,
      sort,
      date,
      pagination,
    );
  };

  handleScrollFrame = () => {
    if (!this.props.isMessagesLoading && !this.props.isLoadingMoreMessages) {
      this.props.getMoreMessages(this.props.selectedGroup.public_id);
    }
  };

  render() {
    /* eslint-disable no-shadow */
    const { isMessagesLoading, messages, filters, selectedMessage, messagesListSelected, isFiltersOpen } = this.props;

    const isAnyFilterSelected = !!(filters.type || filters.date.from || filters.date.to);
    const WindowHeight = window.innerHeight - 140;
    /* eslint-enable no-shadow */

    return (
      <Scrollbars
        renderThumbVertical={this.renderView}
        onUpdate={({ top }) => {
          if (top > 0.99 && !isMessagesLoading) {
            this.handleScrollFrame();
          }
        }}
        autoHeight
        autoHide
        autoHeightMin={WindowHeight}
        autoHeightMax={WindowHeight}
        className="custom-scrollbar"
      >
        <div className="filters-column">
          <SearchFiltersPad
            isFiltersSelected={isAnyFilterSelected}
            title="Messages"
            onChange={this.handleSearchTermChange}
            onToggle={this.props.toggleMessagesFilters}
          />

          <MessagesFilters
            filters={filters}
            isOpen={isFiltersOpen}
            onFiltersChange={this.handleFiltersChange}
            onSelectionErase={this.props.clearCheckedMessages}
          />

          <MessageSearchDesktop
            onSort={this.handleSort}
            onArchive={this.handleArchive}
            onUnArchive={this.handleUnArchive}
            messages={messages}
            updateMessageList={this.updateMessageList}
          />

          <div className="fake-button-div message-content-div">
            <MessagesList
              messages={this.props.messages}
              timezone={this.props.organization.time_zone}
              selectedMessagePublicId={selectedMessage ? selectedMessage.publicId : null}
              checkedMessages={messagesListSelected}
              onSelect={this.handleSelectEvaCardMessage}
              onCheck={this.handleCheckItem}
            />

            {isMessagesLoading && <LoaderOverlay/>}
          </div>
        </div>
      </Scrollbars>
    )
  }
}

const mS = state => ({
  organization: state.organizationReducer.organization,
  selectedGroup: state.groupsReducer.selectedGroup,
  isMessageLoading: state.messagesReducer.isMessageLoading,
  isMessagesLoading: state.messagesReducer.isMessagesLoading,
  isLoadingMoreMessages: state.messagesReducer.isLoadingMoreMessages,
  searchedMessages: state.messagesReducer.searchedMessages,
  messages: state.messagesReducer.messages,
  selectedMessage: state.messagesReducer.selectedMessage,
  messagesListSelected: state.messagesReducer.messagesListSelected,
  middleMenuLeft: state.groupsReducer.middleMenuLeft,
  windowWidth: state.responsiveReducer.windowWidth,
  messagesPagination: state.messagesReducer.messagesPagination,
  isFiltersOpen: state.messagesFiltersReducer.isFiltersOpen,

  filters: state.messagesFiltersReducer.filters,
  sort: state.messagesFiltersReducer.sort,
  searchTerm: state.messagesFiltersReducer.searchTerm,
});

const mD = {
  archiveMessages,
  clearMessagePagination,
  changeMessagesSearchTerm,
  clearCheckedMessages,
  clearMessage,
  enableRightDrawerGroupMessage,
  getMessage,
  getMessages,
  getMoreMessages,
  handleMiddleMenuLeft,
  initDraftMessage,
  changeMessagesFilters,
  changeMessagesSort,
  checkMessage,
  navigationControllerRedirect,
  snackBarStatus,
  toggleMessagesFilters,
  resetMessagesFilters,
  unArchiveMessages,
  openCustomModal,
};

export default withRouter((connect(mS, mD)(MessagesGroups)));
