import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Helpers from '../../Helpers';
import ActionButton from './ActionButton';
import TopBarPropTypes from '../../PropTypes/ScheduledMessage/TopBarPropTypes';
import cancelCircleIcon from '../../Theme/Icons/cancel-circle.svg';
import editIcon from '../../Theme/Icons/edit.svg';
import { topBarStyles } from './styles';

const TopBar = ({
  classes,
  groups,
  subject,
  onCancelClick,
  onEditClick,
  cancel = false,
  edit = false,
}) => (
  <div className={classes.root}>
    <div className={classes.info}>
      <h1 className={`${classes.infoSubject} ${classes.infoContent}`}>{subject}</h1>
      <h2 className={`${classes.infoGroups} ${classes.infoContent}`}>
        {Helpers.getGroupsNames(groups)}
      </h2>
    </div>
    <div className={classes.actions}>
      {cancel && (
        <ActionButton
          tooltipTitle="Cancel Message"
          classes={classes}
          icon={cancelCircleIcon}
          onClick={onCancelClick}
          size="md"
        />
      )}
      {edit && <ActionButton classes={classes} icon={editIcon} onClick={onEditClick} />}
    </div>
  </div>
);

TopBar.propTypes = TopBarPropTypes;

export default withStyles(topBarStyles)(TopBar);
