import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import EvaTooltip from '../EvaTooltip';
import TreeNavigatorActionsPropTypes from '../../PropTypes/TreeNavigatorActionsPropTypes';

const styles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
});

class TreeNavigatorActions extends Component {
  handleClick = event => {
    if (this.props.onActionTriggered) {
      this.props.onActionTriggered(event.target.value);
    }
    this.props.onNewTemplateClick();
  };
  render = () => {
    const { classes } = this.props;

    return (
      <Grid item xs={12}>
        <EvaTooltip title="New Folder" alignItems="flex-start">
          <IconButton
            className={`new-folder-icon styless-button ${classes.button}`}
            aria-label="New Folder"
            value="new-folder"
            onClick={this.handleClick}
          />
        </EvaTooltip>
        <EvaTooltip title="New Template" alignItems="flex-start">
          <IconButton
            className={`folder-settings-icon styless-button ${classes.button}`}
            aria-label="Settings"
            value="settings"
            onClick={this.handleClick}
          />
        </EvaTooltip>
      </Grid>
    );
  };
}

TreeNavigatorActions.propTypes = TreeNavigatorActionsPropTypes;

export default withStyles(styles)(TreeNavigatorActions);
