import React from 'react';
import queryString from 'query-string';
import { SSO_MENU_URL } from '../../Config/env';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import SideMenuPropType from '../../PropTypes/SideMenuPropType';
import './index.sass';

const getMenuUrl = (authToken, env, orgId) =>
  `${SSO_MENU_URL}?${queryString.stringify({ authToken, env, selected: orgId })}`;

const SideMenu = ({ authToken, env, org, active = false, onClose }) => (
  <div id="side-menu" className={active ? 'active' : ''}>
    <iframe
      src={getMenuUrl(authToken, env, org.id)}
      title="Diocesan Side Menu"
    />
    {/*<IconButton className="menu-close-button" onClick={onClose}>*/}
    {/*  <ClearIcon />*/}
    {/*</IconButton>*/}
  </div>
);

SideMenu.propTypes = SideMenuPropType;

export default SideMenu;
