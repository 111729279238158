/* eslint-disable */
import Auth from '../Api/Auth';
import Invitation from '../Api/Invitation';
import snackBarStatus, { clearSnackBarStatus } from './snackbarActions';
import { redirectToLogin } from '../Helpers/redirectToLogin';

export const ACTIONS = {
  CLEAR: '[Login] Clear',
  ERROR: '[Login] Error',
  LOG_IN: '[Login] Login',
  SSO_LOG_IN: '[Login] Sso login',
  LOG_OUT: '[Login] Logout',
  UPDATE: '[Login] Update',
  INIT_TOKEN_CHECK: '[Login] Init token check',
  UPDATE_TOKEN: '[Login] Update token',
};

export const removeStorageData = () => {
  localStorage.removeItem('token');
  sessionStorage.removeItem('sso_token');
  localStorage.removeItem('expiration');
  localStorage.removeItem('roles');
  localStorage.removeItem('userId');
  localStorage.clear();
};

export function clearLogin() {
  removeStorageData();
  return dispatch => {
    dispatch({ type: ACTIONS.CLEAR });
  };
}

export const checkRole = () => async dispatch => {
  try {
    const {
      status,
      data: { data },
    } = await Auth.getRoles();
    if (status === 200) {
      dispatch({ type: ACTIONS.UPDATE, payload: data });
    }
    return data;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const login = ({ username, password }) => async dispatch => {
  try {
    const response = await Auth.login(username, password);
    if (response.status === 200) {
      dispatch({ type: ACTIONS.LOG_IN, payload: response.data });
      clearSnackBarStatus()(dispatch);
    }

    return response.data.data;
  } catch (error) {
    if (error.status === 401) {
      dispatch({
        type: ACTIONS.ERROR,
        payload: {
          error: 'Email or Password are incorrect',
          message: error.message,
        },
      });
    } else {
      dispatch({ type: ACTIONS.ERROR, payload: { error: error.message } });
    }
    return error;
  }
};

export const getAuthDataFromStorage = () => dispatch => {
  const { token, expiration } = localStorage;
  let roles;
  try {
    roles = JSON.parse(localStorage.roles);
  } catch (error) {
    roles = null;
  }
  if (token && expiration && roles) {
    dispatch({
      type: ACTIONS.UPDATE,
      payload: { token, expiration, roles },
    });
  }
};

export const joinOrganizationGroup = (invitationType, publicId) => async dispatch => {
  try {
    const response = await Invitation.invitationJoin(invitationType, publicId);
    return response;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const refreshDataFromSso = token => async dispatch => {
  try {
    const response = await Auth.refreshDataFromSso(token);
    if (response.status === 200) {
      dispatch({ type: ACTIONS.SSO_LOG_IN, payload: { data: response.data.data, token } });
      // dispatch(navigationControllerRedirect(url));
    }

    return response;
  } catch (error) {
    removeStorageData();

    return error;
  }
};

export const checkSsoLoginIsAlive = () => async dispatch => {
  const sso_token = localStorage.getItem('sso_token');
  if (sso_token) {
    try {
      const resp = await Auth.checkSsoLoginIsAlive(sso_token);
      dispatch({ type: ACTIONS.INIT_TOKEN_CHECK });
      return resp;
    } catch (error) {
      // if (Helpers.existObject(error.response, 'status') && error.response.status === 401) {
      //   // removeStorageData();
      // }
      snackBarStatus({
        payload: {
          title: error.message,
          type: 'error',
          enable: true,
        },
      })(dispatch);
      removeStorageData();
      dispatch({ type: ACTIONS.INIT_TOKEN_CHECK });
      return error;
    }
  } else {
    // removeStorageData();
    dispatch({ type: ACTIONS.INIT_TOKEN_CHECK });
    return null;
  }
};

export const logout = (source = null) => async dispatch => {
  const token = localStorage.getItem('sso_token');
  try {
    if (token) {
      const { data } = await Auth.logout();
      dispatch({ type: ACTIONS.LOG_OUT });

      removeStorageData();

      clearSnackBarStatus()(dispatch);

      redirectToLogin(source);

      return data;
    }
    return Promise.resolve();

  } catch (err) {
    if (err.response) {
      dispatch({
        type: ACTIONS.ERROR,
        payload: { error: err.response.message },
      });
    } else {
      dispatch({ type: ACTIONS.ERROR, payload: { error: err.message } });
    }

    removeStorageData();
    redirectToLogin(source);

    return err;
  }
};

export const updateToken = token => dispatch => {
  dispatch({ type: ACTIONS.UPDATE_TOKEN, payload: token });
};

