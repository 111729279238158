import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import download from 'downloadjs';
import { changeRenderView as changeView, importFile, updateImportMessage } from '../../Actions/membersActions';
import ButtonStandard from '../Button';
import MembersImportList from './MembersImportList';
import snackBarStatus from '../../Actions/snackbarActions';
import { AMAZON_S3_URL } from '../../Config/Constants';
import { SUPPORT_PHONE_NUMBER, SUPPORT_EMAIL} from '../../Config/env';
import LoaderOverlay from '../LoaderOverlay';
import './index.sass';

const validSize = value => (value === 0 ? true : value < 102400); // Limit file size to 100 Kb

const styles = theme => ({
  progress: {
    margin: theme.spacing(2),
  },
});
class CsvImport extends Component {
  state = {
    fileInputField: '',
  };

  UNSAFE_componentWillMount() {
    this.props.changeView('buttonImport');
  }

  handleUploadFile = e => {
    const input = e.target;
    if (input && input.files[0]) {
      if(validSize(input.files[0].size)) {
        const reader = new window.FileReader();
        reader.onload = () => {
          const file = input.files[0];
          let fileExt = '';
          if (file && file.name) {
            fileExt = file.name.substr(file.name.lastIndexOf('.') + 1);
          }
          if (
            file.type === 'text/csv' ||
            file.type === 'application/vnd.ms-excel' ||
            (!file.type && fileExt === 'csv')
          ) {
            this.props.importFile(input.files[0]);
          } else {
            this.props.updateImportMessage({
              show: true,
              type: 'danger',
              message: 'Error: Invalid file. Could not be imported. Please upload a new file.',
            });
          }
        };
        reader.readAsDataURL(input.files[0]);
      
      } else {
        this.props.updateImportMessage({
          show: true,
          type: 'warning',
          message: `<p>Thank you for your interest in adding so many users to your Evangelus account. To ensure   you the best experience possible we at Diocesan would love to assist you with this import. Please contact us via email at <a class="support-email" href="mailto:${SUPPORT_EMAIL}">${SUPPORT_EMAIL}</a> or call us at <a class="support-phone-number" href="tel:+${SUPPORT_PHONE_NUMBER}">${SUPPORT_PHONE_NUMBER}</a> during normal business hours and one of our friendly staff will import your users for you.</p>`,
        });
      }
    }
  };

  triggerClick = fileInputField => {
    if (fileInputField) {
      this.setState({ fileInputField });
    }
  };

  handleImage = e => {
    const { fileInputField } = this.state;
    e.preventDefault();
    fileInputField.click();
  };

  downloadTemplate = () => {
    download(`${AMAZON_S3_URL}templates/members-import-template.csv`);
  };

  showButtonImport = () => (
    <div id="csv-buttons-import-container">
      <div className="button-import-container">
        {this.props.loading ? <LoaderOverlay /> : (
          <form encType="multipart/form-data">
            <input
              style={{ display: 'none' }}
              className="input-file"
              type="file"
              id="load_image"
              accept=".csv"
              ref={this.triggerClick}
              onChange={this.handleUploadFile}
            />
            <ButtonStandard title="Select a .CSV file to import" onClick={this.handleImage} />
          </form>
        )}
      </div>
      <div className="link-container">
        <button onClick={this.downloadTemplate}>
          <p>Download this .CSV template</p>
          <p>as a guide for your member import.</p>
        </button>
      </div>
    </div>
  );

  changeView = key => {
    switch (key) {
      case 'membersList':
        return <MembersImportList importFromModal={this.props.importFromModal} />;
      default:
        return this.showButtonImport();
    }
  };

  render() {
    const { changeRenderView } = this.props;
    return <div>{this.changeView(changeRenderView)}</div>;
  }
}

const mS = ({
  membersReducer: {
    memberImportList,
    loading,
    changeRenderView,
    importProcessDetail,
    total,
  },
  messageReducer: { recipientGroups },
  groupInviteMemberReducer: { groups },
}) => ({
  members: memberImportList,
  loading,
  changeRenderView,
  importProcessDetail,
  recipientGroups,
  total,
  groups,
});

const mD = {
  importFile,
  snackBarStatus,
  changeView,
  updateImportMessage,
};

export default connect(
  mS,
  mD,
)(withStyles(styles)(CsvImport));
