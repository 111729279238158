import AXIOS from '../Config/AxiosConfig';
import headers from './Helper';

const BASE_API_SEARCH_MESSAGES_URL = 'v2/search/message?';

const Messages = {
  getMessages(filterParameters) {
    const { type, searchTerm, sort, date, group, page } = filterParameters;
    const sortBy = sort === 'recent' ? 'updatedAt' : 'subject';
    const sortOrder = sort === 'asc' || sort ==='desc' ? sort : 'desc';

    const organizationId = localStorage.getItem('selectedOrgPublicId');
    let queryParams = [
      `sortOrder=${sortOrder}`,
      `sortBy=${sortBy}`,
    ];

    if (group) {
      queryParams.push(`group=${group}`);
    }

    if (type && Array.isArray(type)) {
      type.forEach(item => queryParams.push(`type=${item}`));
    } else if (type) {
      queryParams.push(`type=${type}`);
    }

    if (searchTerm && searchTerm !== '') {
      queryParams.push(`term=${searchTerm}`);
    }

    if (date && date.from && date.to) {
      queryParams.push(`from=${date.from}&to=${date.to}`);
    }

    if (page) {
      const pagination = `page=${page}&limit=10`;
      queryParams.push(pagination);
    } else if (page === 0) {
      // temporary solution
      const pagination = 'page=1&limit=500';
      queryParams.push(pagination);
    }

    queryParams = queryParams
      .filter(v => !!v)
      .toString()
      .replace(/,/g, '&');

    return AXIOS.get(`${BASE_API_SEARCH_MESSAGES_URL}${queryParams}&org=${organizationId}`, {
      headers: headers(),
    });
  },
  getThread(id, org) {
    return AXIOS.get(`/v2/eva/${org}/messages/${id}/thread`, { headers: headers() });
  },
  archiveMessages(publicIds) {
    return AXIOS.post('/eva/archive-message', { messages: publicIds }, { headers: headers() });
  },
  unArchiveMessages(publicIds) {
    return AXIOS.post('/eva/unarchive-message', { messages: publicIds }, { headers: headers() });
  },
  deleteMessages(publicIds) {
    return AXIOS.post('/eva/remove-message', { messages: publicIds }, { headers: headers() });
  },
  saveTemplate(data) {
    const { description, title, content, privacyLevel } = data;
    const body = {
      description,
      main_picture: null,
      privacyLevel,
      title,
      content,
      folder: null,
    };
    return AXIOS.post('/eva/template', body, { headers: headers() });
  },

  getPendingReplies(organization) {
    return AXIOS.get(`v2/eva/metrics/message/organization/${organization}/replies`, {
      headers: headers(),
    });
  },

  lastSevenDaysMessage(organization) {
    return AXIOS.get(`/eva/member/has-sent-message-last-7-days/organization/${organization}`, {
      headers: headers(),
    });
  },

  getStatisticsMessages(organization, message) {
    return AXIOS.get(`eva/organization/${organization}/message/${message}/metrics`, {
      headers: headers(),
    });
  },
};

export default Messages;
