import AXIOS from '../Config/AxiosConfig';
import headers from './Helper';

const Notifications = {
  getNotifications(offset = 0, limit = 10, orgId) {
    return AXIOS.get(`/v2/notifications/sorted/${orgId}?offset=${offset}&limit=${limit}`, { headers: headers() });
  },
  dismissNotification(notifications) {
    return AXIOS.post('/notifications/dismiss', notifications, { headers: headers() });
  },
  acceptJoinRequest(data) {
    const petition = {
      group: data.groupId,
      member: data.memberId,
      response: true,
    };
    return AXIOS.put('/eva/groups/join', petition, { headers: headers() });
  },
  denyJoinRequest(data) {
    const petition = {
      group: data.groupId,
      member: data.memberId,
      response: false,
    };
    return AXIOS.put('/eva/groups/join', petition, { headers: headers() });
  },
  clearNotifications(orgId) {
    return AXIOS.post(`/notifications/dismiss/all?org=${orgId}`, {}, { headers: headers() });
  },
};

export default Notifications;
