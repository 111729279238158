import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import EmailEditor from 'react-email-editor';
import PropTypes from 'prop-types';
import ButtonSimple from '../../Components/ButtonSimple';
import {
  applySelectedTemplate,
  clearMessage,
  formatMessageToDesigner,
  getMessage,
  initDirectMessage,
  initForwardedMessage,
  initResendUnopenedMessage,
  notifyIfDeprecated,
  saveDraftMessage,
  saveDraftMessageWithDebounce,
  setEditorType,
  setMessage,
  updateEditorContent,
  updateIsTemplateFlag,
  releaseMessageLockedMember,
} from '../../Actions/messageActions';

import { displayGallery } from '../../Actions/imageGalleryActions';
import { getOrganizationFacebookLinking } from '../../Actions/organizationActions';
import { changeEditTemplateFlag, getTemplateById, selectTemplate } from '../../Actions/templateActions';
import { closeCustomModal, openCustomModal, updateCustomModal } from '../../Actions/customModalActions';
import { navigationControllerRedirect } from '../../Actions/navigationControllerActions';
import LoadingScreen from '../../Components/LoadingScreen';
import { camelCaseObjects } from '../../Helpers/Utility';
import './index.sass';
import { enableRightDrawer } from '../../Actions/rightDrawerActions';
import TemplateSelect from '../../Components/TemplateSelect';
import RightDrawerMessage from '../../Components/RightDrawerMessage';
import RightDrawer from '../../Components/RightDrawer';
import { MESSAGE_DESIGNER_TYPE } from '../../Config/Constants';
import SnackBar from '../../Components/Snackbar';
import SettingsModalContent from '../../Components/SettingsModalContent';
import GalleryOptions from '../GalleryOptions';
import MessageModalManager, { MESSAGE_MODAL_MANAGER_VIEWS } from '../MessageModalManager';

class DesignerPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false
    }

    this.emailEditorRef = React.createRef();
  }

  initNewMessage = async () => {
    const queryParams = queryString.parse(this.props.location.search);
    // New message cases
    if (queryParams.fromTpl) { // Message based on template
      await this.initMessageFromTemplate(queryParams.fromTpl)
    } else if (queryParams.fromMsg) { // Forward message
      await this.initMessageFromMessage(queryParams.fromMsg, queryParams.resend === 'true')
    } else if (queryParams.to && queryParams.group) { // Direct message
      await this.initMessageToUser(queryParams.to, queryParams.group)
    }
  }

  initNewTemplate = () =>
    this.props.updateIsTemplateFlag(true)

  initMessageEdit = async publicId => {
    const queryParams = queryString.parse(this.props.location.search);
    const response = await this.props.getMessage(publicId);
    if (response.code !== 200) {
      this.navigateToRootPage();
      return;
    }

    this.props.setMessage(camelCaseObjects(response.data));

    if (queryParams.fromTpl) {
      await this.initMessageFromTemplate(queryParams.fromTpl)
    } else {
      this.props.notifyIfDeprecated('message');
    }
  }

  initTemplateEdit = async publicId => {
    const response = await this.props.getTemplateById(publicId);
    if (response.code !== 200) {
      this.navigateToRootPage();
    }

    this.props.selectTemplate(response.data);
    this.props.applySelectedTemplate();
    this.props.changeEditTemplateFlag(true);
    this.props.notifyIfDeprecated('template');
  }

  initMessageFromTemplate = async publicId => {
    const response = await this.props.getTemplateById(publicId);
    if (response.code !== 200) {
      this.props.navigationControllerRedirect('/messages/new');
    }

    this.props.selectTemplate(response.data);
    this.props.applySelectedTemplate();
    this.props.saveDraftMessageWithDebounce();
    this.props.notifyIfDeprecated('message');
  }

  // Forward message
  initMessageFromMessage = async (forwardPublicId, isResendUnopened) => {
    const response = await this.props.getMessage(forwardPublicId);
    if (response.code !== 200) {
      this.navigateToRootPage();
    }

    await isResendUnopened ? this.props.initResendUnopenedMessage(camelCaseObjects(response.data))
      : this.props.initForwardedMessage(camelCaseObjects(response.data));
    this.props.notifyIfDeprecated('message');
  }

  // Direct message
  initMessageToUser = async (userPublicId, groupPublicId) =>
    await this.props.initDirectMessage(userPublicId, groupPublicId);

  normalizeContent = () => {
    if (this.props.messageType !== MESSAGE_DESIGNER_TYPE) {
      if (this.props.editorContent.html) {
        this.props.formatMessageToDesigner();
      }
      this.props.setEditorType(MESSAGE_DESIGNER_TYPE);
    }
  }

  async componentDidMount() {
    this.props.getOrganizationFacebookLinking(); // TODO: verify is necessary

    const { publicId } = this.props.match.params;

    if (publicId) {
      // Edit template/message
      this.props.contentType === 'message' ? await this.initMessageEdit(publicId) : await this.initTemplateEdit(publicId);
    } else if (this.props.contentType === 'message') {
      await this.initNewMessage();
    }

    this.normalizeContent();

    if (this.props.contentType === 'template') {
      this.initNewTemplate();
    }

    this.setState({ loaded: true });

    this.handleOpenSettings();
  }

  navigateToRootPage = () =>
    this.props.navigationControllerRedirect(this.props.contentType === 'message' ? '/messages' : '/templates');

  handleGalleryOpen = callback => {
    this.props.displayGallery(true); // TODO: remove(?)
    this.props.openCustomModal({
      content: <GalleryOptions onImagePick={src => {
        callback({ url: src })
        this.props.closeCustomModal()
        this.props.displayGallery(false); // TODO: remove(?)
      }} />,
      title: 'Gallery'
    });
  }

  handleEditorLoad() {
    const { editorContent: { design } } = this.props;

    if (!this.emailEditorRef || !this.emailEditorRef.current || !this.emailEditorRef.current.editor) {
      setTimeout(this.handleEditorLoad.bind(this), 100); // Sometimes unlayer not loading properly, library issue
      return;
    }

    const editor = this.emailEditorRef.current.editor;

    if (design) {
      editor.loadDesign(design);
    } else {
      // Set bg color as white (because of issue with loadBlank
      editor.loadDesign({
        body: {
          rows: [{ cells: [1], columns: [{ contents: [] }] }],
          values: { backgroundColor: '#ffffff' }
        }
      })
    }

    editor.addEventListener('design:updated', () => {
      editor.exportHtml(data => {
        const { design, html } = data;
        this.props.updateEditorContent(html, design);
      })
    })

    editor.registerCallback('selectImage', (data, done) => this.handleGalleryOpen(done));
  }

  handleOpenSettings = () => {
    this.props.openCustomModal({
      content: (
        <SettingsModalContent
          onCancelClick={this.handleReturnBack}
          onContinueClick={this.props.closeCustomModal}
        />
      ),
      title: 'Settings',
      size: 'small'
    });
  };

  handleReturnBack = () => {
    this.props.closeCustomModal();
    this.props.releaseMessageLockedMember();
    this.props.clearMessage();
    this.navigateToRootPage();
  };

  handleTemplatesClick = () =>
    this.props.openCustomModal({
      content: <TemplateSelect onTemplatePick={this.handleTemplatePick} />,
      title: 'Select Template'
    });

  handleTemplatePick = () => {
    this.props.applySelectedTemplate();
    const { editorContent: { design }, isTemplate } = this.props;
    const editor = this.emailEditorRef.current.editor;
    this.normalizeContent();
    editor.loadDesign(design);
    if (!isTemplate) {
      this.props.saveDraftMessageWithDebounce();
    }
    this.props.closeCustomModal();
  }

  handlePreview = () =>
    this.props.openCustomModal({
      content: <MessageModalManager view={MESSAGE_MODAL_MANAGER_VIEWS.PREVIEW} />
    })

  getDesignerTitle = () => (this.props.match.params.publicId ? 'Edit ' : 'New ') + this.props.contentType;

  render() {
    const { profilePublicId, profileName, profileEmail } = this.props;

    const options = {
      mergeTags: {
        first_name: {
          name: 'First Name',
          value: '{{first_name}}',
          sample: 'John'
        },
        last_name: {
          name: 'Last Name',
          value: '{{last_name}}',
          sample: 'Smith',
        },
        birth_date: {
          name: 'Birthdate',
          value: '{{birth_date}}',
          sample: 'January 1, 1990'
        },
        phone_number: {
          name: 'Phone Number',
          value: '{{phone_number}}',
          sample: '(555) 555-5555',
        }
      },
      user: { id: profilePublicId, name: profileName, email: profileEmail },
      features: {
        pageAnchors: true,
        stockImages: {
          enabled: true,
          safeSearch: true,
          defaultSearchTerm: 'christian'
        },
        textEditor: {
          cleanPaste: false,
          inlineFontControls: true,
        },
      },
      customCSS: '.design-web p { margin-top: 1em; margin-bottom: 1em; }'
    };

    const tools = {
      social: {
        enabled: true,
        properties: {
          icons: {
            value: {
              editor: {
                data: {
                  showDefaultIcons: true,
                }
              },
              icons: [
                { name: 'Facebook', url: 'https://facebook.com/' },
                { name: 'Twitter', url: 'https://twitter.com/' },
                { name: 'Instagram', url: 'https://instagram.com/' },
              ],
            }
          }
        }
      },
      timer: { enabled: true },
      video: { enabled: true }
    };

    if (!this.state.loaded) {
      return <LoadingScreen loadingText={`Loading ${this.props.contentType}...`} />
    }

    return (
      <React.Fragment>
        <div id="page-designer">
          <header className="designer-header">
            <div className="left-header-wrapper">
              <button className="btn-designer-close" onClick={this.handleReturnBack} />
              <div className="title">{this.getDesignerTitle()}</div>
            </div>
            <div className="right-header-wrapper">
              <ButtonSimple text="Templates" onClick={this.handleTemplatesClick} />
              <ButtonSimple text="Settings" onClick={this.handleOpenSettings} />
              <button className="icon-button next" onClick={this.handlePreview} />
            </div>
          </header>
          <div className="unlayer-wrapper">
            <EmailEditor projectId={14988}
                         tools={tools} ref={this.emailEditorRef}
                         options={options}
                         onLoad={this.handleEditorLoad.bind(this)} />
          </div>
        </div>
        <RightDrawer />
        <RightDrawerMessage />
        <SnackBar />
      </React.Fragment>
    );
  }
}

DesignerPage.propTypes = {
  contentType: PropTypes.oneOf(['message', 'template']),
};

const mS = state => ({
  editorContent: state.messageReducer.messageContent.editorContent,
  isTemplate: state.messageReducer.isTemplate,
  messageType: state.messageReducer.messageContent.type,
  recipientType: state.messageReducer.recipientType,

  organizationPublicId: state.organizationReducer.organization.public_id,

  profilePublicId: state.userProfileReducer.public_id,
  profileName: state.userProfileReducer.display_name,
  profileEmail: state.userProfileReducer.display_email,
});

const mD = {
  applySelectedTemplate,
  getOrganizationFacebookLinking,
  getTemplateById,
  changeEditTemplateFlag,
  clearMessage,
  displayGallery,
  enableRightDrawer,
  formatMessageToDesigner,
  getMessage,
  initForwardedMessage,
  initDirectMessage,
  initResendUnopenedMessage,
  navigationControllerRedirect,
  notifyIfDeprecated,
  saveDraftMessage,
  setMessage,
  saveDraftMessageWithDebounce,
  selectTemplate,
  setEditorType,
  closeCustomModal,
  openCustomModal,
  updateCustomModal,
  updateEditorContent,
  updateIsTemplateFlag,
  releaseMessageLockedMember
};

export default withRouter(connect(mS, mD)(DesignerPage));
