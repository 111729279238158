import { ACTIONS } from '../Actions/statesDictionaryActions';

const initialState = {
  list: [],
  hash: {},
  isLoaded: false,
  isLoading: false,
};

const statesDictionaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_LIST:
      return {
        ...state,
        countryId: action.payload.countryId,
        list: action.payload.list,
        hash: {
          [action.payload.countryId]: action.payload.list,
        },
        isLoaded: true,
      };
    case ACTIONS.LOADING:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};

export default statesDictionaryReducer;
