import React, { Component } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ContentEditable from 'react-contenteditable';
import EvaTooltip from '../EvaTooltip';
import { switchStyles } from '../../Theme/switchStyles';
import TemplateMessagePreviewPropType from '../../PropTypes/TemplateMessagePreviewPropType';
import './index.sass';

const TabContainer = props => <Typography component="div">{props.children}</Typography>;

const styles = theme => ({
  colorBar: {},
  colorChecked: {},
  ...switchStyles,
  tabsIndicator: {
    background: 'linear-gradient(to right, #9966bf 0%, #6174c9 98%)',
  },
  tabsRoot: {
    textTransform: 'initial',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'GothamSSm-Book',
    color: '#a9a9a9',

    '&:focus': {
      color: '#9a66bf',
    },
  },
  pageContainer: {
    '&.page-container': {
      height: 'auto',
      '&>.content': {
        height: 'calc(100vh - 215px)',
        marginBottom: '25px',
      },
    },
  },
});

class TemplateEmailPreview extends Component {
  state = {
    value: 0,
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { value } = this.state;
    const { classes, content } = this.props;
    return (
      <div className="message-email-container">
        <div className="send-email-container">
          <div className="switch-container template-switch-container">
            <div className="responsive-preview-icons">
              <Tabs
                classes={{
                  root: classes.tabsRoot,
                  indicator: classes.tabsIndicator,
                }}
                value={value}
                onChange={this.handleChange}
              >
                <Tab
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                  }}
                  label=""
                  icon={
                    <EvaTooltip title="Desktop Preview">
                      <i className="desktop-icon" />
                    </EvaTooltip>
                  }
                />
                <Tab
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                  }}
                  label=""
                  icon={
                    <EvaTooltip title="Mobile Phone Preview">
                      <i className="mobile-icon" />
                    </EvaTooltip>
                  }
                />
              </Tabs>
            </div>
          </div>
        </div>
        <div className={`page-container designerContent ${classes.pageContainer}`}>
          {value === 0 && (
            <TabContainer>
              <ContentEditable
                className="content"
                id="scroll"
                tagName="p"
                html={content}
                disabled
              />
            </TabContainer>
          )}
          {value === 1 && (
            <TabContainer>
              <ContentEditable
                className="content content-mobile-preview"
                id="scroll"
                tagName="p"
                html={content} // innerHTML of the editable div
                disabled
              />
            </TabContainer>
          )}
        </div>
      </div>
    );
  }
}

TemplateEmailPreview.propTypes = TemplateMessagePreviewPropType;

export default withStyles(styles)(TemplateEmailPreview);
