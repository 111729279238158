export const ACTIONS = {
  TOGGLE: '[Groups Filters] Toggle',
  CHANGE: '[Groups Filters] Change filter',
  SORT_CHANGE: '[Groups Filters] Change sort',
  SEARCH_TERM_CHANGE: '[Groups Filters] Change search term',
  RESET: '[Groups Filters] Reset all',
};

export const toggleGroupsFilters = (value) => dispatch => {
  dispatch({
    type: ACTIONS.TOGGLE,
    payload: value,
  });
};

export const changeGroupsFilters = (value) => dispatch => {
  dispatch({
    type: ACTIONS.CHANGE,
    payload: value,
  });
  return Promise.resolve();
};

export const changeGroupsSort = (value) => dispatch => {
  dispatch({
    type: ACTIONS.SORT_CHANGE,
    payload: value,
  });
  return Promise.resolve();
};

export const changeGroupsSearchTerm = value => dispatch => {
  dispatch({
    type: ACTIONS.SEARCH_TERM_CHANGE,
    payload: value,
  });
  return Promise.resolve();
};

export const resetGroupsFilters = () => dispatch => {
  dispatch({
    type: ACTIONS.RESET,
  });
};

