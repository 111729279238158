import PropTypes from 'prop-types';
import React from 'react';

const MessageMyParishAppPreviewPropType = {
  allowed: PropTypes.bool.isRequired,
  enabled: PropTypes.bool.isRequired,
  toAllMembers: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  profilePic: PropTypes.string.isRequired,
  profileName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSendByChange: PropTypes.func.isRequired,
};

export default MessageMyParishAppPreviewPropType;
