import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import EvaButton from '../../EvaButton';
import NoMatchButton from '../NoMatchButton';
import { memberFoundStyles } from '../styles';
import { selectMember, update } from '../../../Actions/groupInviteMemberActions';
import MemberItem from '../../MemberItem';

class MemberFound extends React.Component {
  handleAddMembersClick = () => {
    const {
      stepValues: { members },
      nextStep,
    } = this.props;
    nextStep(members);
  };

  handleMemberCheckboxChange = publicId => {
    this.props.selectMember(publicId);
  };

  render() {
    const {
      stepValues: { members },
      classes,
      selectedMembers,
    } = this.props;
    const canAddMember = selectedMembers.length > 0;

    return (
      <div className="member-found">
        <div className={classes.matchFound}>
          <p className={classes.matchFoundText}>
            We found a match!
          </p>
        </div>
        {members.map(member => {
          const { publicId, firstName, lastName, profilePictureUrl } = member;
          return (
            <MemberItem
              isAvatarVisible
              key={publicId}
              id={publicId}
              firstName={firstName}
              lastName={lastName}
              pictureUrl={profilePictureUrl}
              viewMode="grid"
              isChecked={selectedMembers.includes(member.publicId)}
              onSelectMember={this.handleMemberCheckboxChange}
            />
          );
        })}
        <div className={classes.actions}>
          <EvaButton
            onClick={this.handleAddMembersClick}
            active={canAddMember}
          >
            <span
              className={`${classes.addMembers} ${canAddMember && 'active'}`}
            >
              Add
            </span>
          </EvaButton>
          <div className={classes.noMatch}>
            <NoMatchButton onClick={this.props.handleCancel}>
              No match, try again
            </NoMatchButton>
          </div>
        </div>
      </div>
    );
  }
}


MemberFound.propTypes = {
  stepValues: PropTypes.instanceOf(Object).isRequired,
  nextStep: PropTypes.func.isRequired,
};

const mapStateToProps = ({
                           groupInviteMemberReducer: { selectedMembers },
                         }) => ({
  selectedMembers,
});

const mapDispatchToProps = {
  selectMember,
  update,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withStyles(memberFoundStyles)(MemberFound),
);
