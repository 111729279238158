import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Scrollbars } from 'react-custom-scrollbars';

import TreeNavigatorPropTypes from '../../PropTypes/TreeNavigatorPropTypes';
import TreeNavigatorItem from './TreeNavigatorItem';

const TreeNavigator = props => {
  const { tree, selectedTreeItem, childrenTree, handleTreeItemClick, ...rest } = props;
  const treeToRender = tree && tree.map(item => (
    <TreeNavigatorItem
      key={item.label}
      selectedTreeItem={selectedTreeItem}
      treeItem={item}
      handleTreeItemClick={handleTreeItemClick || null}
    />
  ));

  return (
    <Grid {...rest}>
      {
        childrenTree ? treeToRender : (
          <Scrollbars
            className="nav-templates-list-scrollbar custom-scrollbar"
            autoHeight
            autoHide
            autoHeightMin={100}
            autoHeightMax={window.innerHeight - 260}
          >
            {treeToRender}
          </Scrollbars>
        )
      }
    </Grid>
  );
};

TreeNavigator.propTypes = TreeNavigatorPropTypes;

export default TreeNavigator;
