import React from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { withStyles } from '@material-ui/core/styles';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import EvaTooltip from '../EvaTooltip';
import './index.sass';
import { styles } from './styles';
import phoneIcon from '../../Theme/Icons/phone.svg';

/*  ======================================================================
  INPUT PHONE WITH CHECK MARK
 ====================================================================== */

const checkValidityOfPhone = value => {
  const phone = parsePhoneNumberFromString(value);
  return phone ? phone.isValid() : false;
};

/**
 * Component to input a phone number but it shows a check when the phone
 * number is considered correct.
 * @param {Object} param0
 */
const InputPhoneV4 = ({
  onInputPhoneChange,
  name,
  placeholder = '',
  value = '',
  classes,
  style,
  useV3 = false,
}) => (
  <div className={useV3 ? classes.rootV3 : classes.root} style={style}>
    <EvaTooltip title="SMS Number">
      <div className="icon">
        <img src={phoneIcon} alt="phone input" />
      </div>
    </EvaTooltip>
    <div className={`check ${useV3 ? 'input-v3' : 'input'}`}>
      <PhoneInput
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onInputPhoneChange}
        country="US"
        limitMaxLength
        autoComplete="off"
      />
      <div className={`valid-phone ${!checkValidityOfPhone(value) ? 'valid-phone-hidden' : ''}`} />
    </div>
  </div>
);

const InputPhoneCheck = withStyles(styles)(InputPhoneV4);

export default InputPhoneCheck;
