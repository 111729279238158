import { ACTIONS } from '../Actions/membersActions';
import arrayToHash from '../Helpers/arrayToHash';
// fromGroup = true (action from a group) and fromGroup = false (action from the compose button)
const initialState = {
  membersList: [],
  memberImportList: [],
  importProcessDetail: [],
  loading: false,
  loadingMore: false,
  membersListSelected: [],
  selectedMembersHash: {}, // Use hash as a faster solution.
  membersTab: 0,
  changeRenderView: 'buttonImport',
  customMessage: {
    show: false,
    message: '',
    title: '',
    type: '',
    buttonMessageAction: '',
  },
  paging: {
    currentPage: null,
    nextPage: null,
    previousPage: null,
    totalPages: null,
  },
  reqProps: {
    // isPotentialGroupAdmin: false,
    // isNotBelongToGroup: false,
    // org: '',
    // phone: '',
    term: '',
    // email: '',
    // groups: [],
  }
};

const updateMemberInList = (list, publicId, data) =>
  list.map(member => member.publicId === publicId ? ({ ...member, ...data }) : member)

const membersReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_SEARCH:
    case ACTIONS.SET_LOAD_MORE:
    case ACTIONS.SET_GROUP_ADMINS:
    case ACTIONS.SET:
      return {
        ...state,
        membersList: action.payload,
      };
    case ACTIONS.SET_IMPORT_MEMBERS:
      return {
        ...state,
        ...action.payload,
      };
    case ACTIONS.SET_REQ_PROPS:
      return {
        ...state,
        reqProps: action.payload,
      };

    case ACTIONS.SET_PAGING:
      return {
        ...state,
        paging: action.payload,
      };
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ACTIONS.SET_LOADING_MORE:
      return {
        ...state,
        loadingMore: action.payload,
      };
    case ACTIONS.SELECT_MEMBER: {
      const isMemberSelected = state.selectedMembersHash[action.payload];
      const updatedHash = { ...state.selectedMembersHash };
      if (isMemberSelected) {
        delete updatedHash[action.payload];
      } else {
        updatedHash[action.payload] = true;
      }
      return {
        ...state,
        selectedMembersHash: updatedHash,
      };
    }
    case ACTIONS.SELECT_ALL_CSV:
      return {
        ...state,
        selectedMembersHash: arrayToHash(state.memberImportList.map(m => m.line.toString())),
      };

    case ACTIONS.CLEAR_SELECTED_MEMBERS:
      return { ...state, selectedMembersHash: {} };
    case ACTIONS.UPDATE_MEMBERS_TAB:
      return { ...state, membersTab: action.payload };
    case ACTIONS.UPDATE_IMPORT_MESSAGE:
      return {
        ...state,
        customMessage: action.payload,
      };
    case ACTIONS.CHANGE_VIEW:
      return { ...state, changeRenderView: action.payload };
    case ACTIONS.CLEAR:
      return {
        ...state,
        membersList: [],
        membersListSelected: [],
        paging: initialState.paging,
      };

    case ACTIONS.UPDATE_SPECIFIC_MEMBER: {
      const { publicId, data } = action.payload;
      return {
        ...state,
        membersList: updateMemberInList(state.membersList, publicId, data),
        membersListSelected: updateMemberInList(state.membersListSelected, publicId, data),
      }
    }

    case ACTIONS.REMOVE_MEMBERS:
      return {
        ...state,
        membersList: state.membersList.filter(m => !action.payload.includes(m.publicId)),
      }

    case ACTIONS.REMOVE_ALL_MEMBERS:
      return { ...initialState }

    default:
      return state;
  }
};

export default membersReducer;
