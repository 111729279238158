import React from 'react';
import Proptypes from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import styles from './styles';

class ConfirmationMessageComponent extends React.Component {
  static propTypes = {
    cancelScheduledMessage: Proptypes.func.isRequired,
    handleCloseModal: Proptypes.func.isRequired,
    classes: Proptypes.instanceOf(Object),
    displayConfirm: Proptypes.bool.isRequired,
  }

  static defaultProps = {
    classes: {
      containerConfirmation: 'container-warning-delete',
    },
  };

  constructor(props) {
    super(props);
    this.state = { disabledButton: false };
  }

  /**
   * Disables the button to prevent the user from executing the
   * the action multiple times in a short period of time and
   * executes the cancelShceduledMessage functin.
   */
  handleCancelScheduledMessage = () => {
    this.setState({ disabledButton: true });
    this.props.cancelScheduledMessage();
  }

  render() {
    const { disabledButton } = this.state;
    const { handleCloseModal, classes, displayConfirm } = this.props;
    return (
      <DialogContent
        className={classes.containerConfirmation}
        style={{ display: !displayConfirm ? 'none' : '' }}
      >
        <div className="warning-message">
          <div className="single-line">
            ATTENTION: Cancelling this message will stop sending and change it to a draft.
            <strong> Are you sure you want to cancel this message?</strong>
          </div>
        </div>

        <button
          onClick={this.handleCancelScheduledMessage}
          disabled={disabledButton}
          className="button-warning-decision "
        >
          <span className="text-warning">
            Yes, cancel this message
          </span>
        </button>
        <button
          onClick={handleCloseModal}
          disabled={disabledButton}
          className="button-warning-decision no-margin-bottom "
        >
          <span className="text-warning">No, do not cancel this message</span>
        </button>
      </DialogContent>
    );
  }
}

const mapStateToProps = ({ messageReducer: { displayConfirm } }) => ({ displayConfirm });

export default connect(mapStateToProps)(withStyles(styles)(ConfirmationMessageComponent));
