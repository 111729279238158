import Messages from '../Api/Messages';
import snackBarStatus from './snackbarActions';

export const ACTIONS = {
  SET_MAIN_MESSAGE: '[Thread] Set main message',
  LOAD: '[Thread] Load',
  CLEAR: '[Thread] Clear',
  LOADING: '[Thread] Loading thread',
  LOADED: '[Thread] Loaded thread',
  SELECT: '[Thread] Set selected thread',
};

const setLoading = bool => dispatch => {
  dispatch({
    type: ACTIONS.LOADING,
    payload: bool,
  });
}

const setLoaded = bool => dispatch => {
  dispatch({
    type: ACTIONS.LOADED,
    payload: bool,
  });
}

export const getThread = (id, org = '', selectThreadId) => async dispatch => {
  try {
    setLoading(true)(dispatch);
    const response = await Messages.getThread(id, org);
    const messages = response.data.data;
    dispatch({
      type: ACTIONS.LOAD,
      payload: messages,
    });

    if (selectThreadId) {
      const threadToSelect = messages.find(m => m.publicId === selectThreadId);
      if (threadToSelect) {
        setSelectedThread(threadToSelect)(dispatch);
      }
    }

    setLoaded(true)(dispatch);

    setLoading(false)(dispatch);
  } catch (error) {
    snackBarStatus({
      payload: {
        title: 'Thread loading error',
        type: 'error',
        enable: true,
      },
    })(dispatch);
    setLoading(false)(dispatch);
    setLoaded(false)(dispatch);
    clearThread()(dispatch);
    console.error(error);
  }
};

export const setSelectedThread = thread => dispatch => {
  dispatch({ type: ACTIONS.SELECT, payload: thread });
};

export const setMainThreadMessage = message => dispatch => {
  dispatch({ type: ACTIONS.SET_MAIN_MESSAGE, payload: message });
};

export const clearThread = () => dispatch => {
  dispatch({ type: ACTIONS.CLEAR });
};
