import PropTypes from 'prop-types';

const MiddleMenuTabsPropType = {
  classes: PropTypes.instanceOf(Object).isRequired,
  element: PropTypes.node,
  changeTab: PropTypes.func.isRequired,
  tab: PropTypes.number.isRequired,
};

export default MiddleMenuTabsPropType;
