import { ACTIONS } from '../Actions/imageGalleryActions';

const initialState = {
  folder: null,
  galleryImages: null,
  isOpen: false,
  isLoading: false,
  inputField: '',
  selectedImage: [],
  selectedFolder: '',
  globalFolder: false,
  nextPage: null,
  previousPage: null,
  currentPage: 0,
  totalPages: 0,
};

const imageGalleryReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_GALLERY:
      return { ...state, folder: action.payload };
    case ACTIONS.GET_GALLERY_IMAGES:
      return { ...state, galleryImages: action.payload };
    case ACTIONS.ENABLE_GALLERY:
      return { ...state, isOpen: action.payload };
    case ACTIONS.HANDLE_PAGE:
      return { ...state, ...action.payload };
    case ACTIONS.CREATE_FOLDER:
      return { ...state, ...action.payload };
    case ACTIONS.SET_INPUT_FIELD:
      return { ...state, inputField: action.payload };
    case ACTIONS.SELECTED_IMAGE:
      return { ...state, selectedImage: action.payload };
    case ACTIONS.SELECTED_FOLDER:
      return { ...state, selectedFolder: action.payload };
    case ACTIONS.GLOBAL_FOLDER:
      return { ...state, globalFolder: action.payload };
    case ACTIONS.LOADING:
      return { ...state, isLoading: action.payload };
    case ACTIONS.CLEAR:
      return { ...state, ...initialState };
    default:
      return state;
  }
};

export default imageGalleryReducer;
