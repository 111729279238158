// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../Theme/Icons/designer-icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".subject-container{text-align:right;font-size:16px;margin-top:10px;color:#9a66bf;cursor:pointer;width:100%}.text-area-container{margin-bottom:150px}.form-create-new-message{padding:0 40px;overflow:hidden}.form-create-new-message .form__create{display:-ms-flexbox;display:flex;width:100%;font-family:\"GothamSSm-Medium\",Arial,sans-serif;font-size:20px}.form-create-new-message .form__create div{width:100%}.form-create-new-message .form__create div input{max-width:100%}.form-create-new-message .form__create .subject-container{text-align:right;font-size:16px;margin-top:10px;color:#9a66bf;cursor:pointer}.form-create-new-message .form__create .next-button{margin-top:10px;color:#9a66bf;text-align:right}.form-create-new-message .form__create .subject-container{outline:0}.designer-container-flexed{text-align:left;margin:20px 0px 20px 0px !important;cursor:pointer}.designer-container-flexed .designer-container-title{display:-ms-flexbox;display:flex;width:100%;-ms-flex-pack:end;justify-content:flex-end;text-decoration:none;color:#9a66bf;font-family:\"GothamSSm-Book\",Arial,sans-serif;margin-right:40px}.designer-container-flexed .designer-container-icon{margin-left:2%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:contain;width:24px;height:24px}#compose-container{margin-top:70px}#compose-container .form-validation-simple-compose{border-bottom:2px solid #EA9798;border-radius:6px}#compose-container .text-area-container{margin-bottom:0}#compose-container .text-area-container .composer{height:335px;margin-bottom:0}#compose-container .text-area-container .composer .editable{min-height:250px;padding-bottom:0}@media (max-width: 768px){.form-create-new-message{display:block !important}}\n", ""]);
// Exports
module.exports = exports;
