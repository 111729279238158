import Member from '../Api/Member';
import snackBarStatus from './snackbarActions';
import { ACTIONS as navigationActions } from './navigationControllerActions';
import { ACTIONS as groupsActions } from './groupsActions';
import { updateSpecificMember } from './membersActions';
import { camelCaseObjects } from '../Helpers/Utility';
import { updateToken } from './loginActions';

export const ACTIONS = {
  CLEAR: '[Member] Clear',
  CLEAR_SHOW_MEMBER: '[Member] Clear show member',
  CREATE: '[Member] Request',
  EDIT: '[Member] Edit',
  ERROR: '[Member] Error',
  GET_MEMBER_PROFILE: '[Member] Get member profile',
  GET_MEMBER_FULL_PROFILE: '[Member] Get member full profile',
  JOIN_GROUP: '[Member] Request join',
  STATUS_LOADING: '[Member] Status loading',
  SUCCESS: '[Member] Success',
  SUCCESS_CLEAR: '[Member] Success clear',
  UPDATE_MEMBER: '[Member] Update member',
  UPDATE_MEMBER_GROUPS: '[Member] Update member groups',
  UPDATE_MEMBER_PICTURE: '[Member] Update member picture',
  UPDATE_PREFERENCE: '[Member] Update preference',
  DISPLAY_CHANGE_PASSWORD: '[Member] Display change password',
  DISPLAY_EDIT_MEMBER_FORM: '[Member] Display edit member form',
  REVOKE_TEMPORARY_ADMIN_PRIVILEGES: '[Member] Revoke temporary admin privileges',
  MyGroupsList: '[Member] Sweep backend member',
  REMOVE_MEMBER_FROM_GROUP: '[Member] Remove member from group',
  RESTORE_SELECTED_MEMBER: '[Member] Restore selected member fields',
  UPDATE_EMAIL_BOUNCED_STATUS: '[Member] Update email bounced status'
};

export const updateEmailBouncedStatus = (memberId, isEmailBounced) => dispatch => {
  dispatch({ type: ACTIONS.UPDATE_EMAIL_BOUNCED_STATUS, payload: { isEmailBounced, bouncedMessage }});
};

export const restoreSelectedMember = (member) => dispatch => {
  dispatch({ type: ACTIONS.RESTORE_SELECTED_MEMBER, payload: member });
}

export const clearMember = () => dispatch => {
  dispatch({ type: ACTIONS.CLEAR });
};

export const clearShowMemberFromGroup = () => dispatch => {
  dispatch({ type: ACTIONS.CLEAR_SHOW_MEMBER });
};

export const clearSuccessMember = () => dispatch => {
  dispatch({ type: ACTIONS.SUCCESS_CLEAR });
};

export const createMember = ({
                               firstName,
                               lastName,
                               birthday,
                               phoneNumber,
                               email,
                               selectedGroup,
                               selectedSubGroup,
                             }) => async dispatch => {

  try {
    const response = await Member.createMember({
      firstName,
      lastName,
      birthday,
      phoneNumber,
      email,
      groupOrSubGroup,
    });
    if (response.status === 200) {
      dispatch({
        type: ACTIONS.CREATE,
        payload: { ...response.data, success: response.data.message },
      });
      dispatch({ type: ACTIONS.SUCCESS, success: response.data.message });
    }
    return response.data;
  } catch (error) {
    console.error(error);
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const editMember = ({
                             publicId,
                             firstName,
                             lastName,
                             birthday,
                             email,
                             groups,
                             phoneNumber,
                             postalCode,
                             cityText,
                             referenceAddress,
                             country,
                             countryPublicId,
                             statePublicId,
                           }) => async (dispatch, getState) => {
  try {
    const { userProfileReducer: { member: { public_id: currentMemberPublicId } } } = getState();
    const response = await Member.editMember({
      publicId,
      firstName,
      lastName,
      birthday,
      email,
      groups,
      phoneNumber,
      postalCode,
      cityText,
      referenceAddress,
      country,
      countryPublicId,
      statePublicId,
    });
    if (response.status === 200) {
      dispatch({
        type: ACTIONS.CREATE,
        payload: { ...response.data, success: response.data.message },
      });

      const member = camelCaseObjects(response.data.data.member);
      const { publicId, firstName, lastName, email, hasEmail, mainPhoneNumber, status } = member;

      if (publicId === currentMemberPublicId && response.data.data.token) {
        updateToken(response.data.data.token)(dispatch);
      }

      updateSpecificMember(publicId, {
        firstName,
        lastName,
        email,
        mainPhoneNumber,
        status,
        has_email: hasEmail
      })(dispatch);
    }
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const editProfileUser = profileData => async dispatch => {
  try {
    const response = await Member.editMember(profileData);

    if (response.status === 200) {
      // Update token (need if email was updated)
      updateToken(response.data.data.token)(dispatch);
      dispatch({
        type: ACTIONS.CREATE,
        payload: { ...response.data.member, success: response.data.message },
      });
    }
    return response.data;
  } catch (error) {
    console.error(error);
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const updateMember = values => dispatch => {
  dispatch({ type: ACTIONS.UPDATE_MEMBER, payload: values });
};

export const updateMemberPicture = profilePictureUrl => dispatch => {
  dispatch({ type: ACTIONS.UPDATE_MEMBER_PICTURE, payload: profilePictureUrl });
};

export const setMemberError = error => dispatch => {
  dispatch({ type: ACTIONS.ERROR, payload: error });
};

/* eslint-disable */
export const getMemberGroups = member => async (dispatch, getState) => {
  const { organizationReducer: { organization } } = getState();
  try {
    const response = await Member.getProfileInformation(member, organization.public_id);
    let groups = [];
    if (response.status === 200) {
      updateMemberGroups(response.data.data.groups)(dispatch);
    }
    return groups;
  } catch (error) {
    console.error(error);
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const getMemberFullInfo = publicId => async (dispatch, getState) => {
  const { organizationReducer: { organization } } = getState();
  setLoading(true)(dispatch);
  try {
    const response = await Member.getProfileInformation(publicId, organization.public_id);
    if (response.status === 200) {
      const data = { ...response.data.data };
      delete data.address; // TODO: convert to the same format

      const { city, address, countryPublicId, statePublicId, birthDate, ...others } = response.data.data;

      dispatch({
        type: ACTIONS.GET_MEMBER_FULL_PROFILE, payload: {
          ...others,
          cityText: city,
          birthday: birthDate,
          referenceAddress: address,
          countryPublicId,
          statePublicId,
        }
      });
    }
    setLoading(false)(dispatch);

    return response.data.data;
  } catch (error) {
    console.error(error);
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);

    setLoading(false)(dispatch);

    return error;
  }
};

/* eslint-enable */
export const getMemberProfile = () => async dispatch => {
  try {
    const response = await Member.getMember();
    let data = {};
    let parsedProfile = {}; // TODO: Remove this, manage the object structure from backend.
    if (response.status === 200) {
      data = {
        ...response.data.data,
      };
      parsedProfile = {
        ...camelCaseObjects(response.data.data),
      };

      const {
        referenceAddress, birthDate, cityText, country, email, firstName, lastName, phoneNumber, postalCode,
        profilePictureUrl, publicId, mainPhoneNumber, countryPublicId, statePublicId,
      } = parsedProfile;

      const selectedMember = {
        referenceAddress,
        birthDate,
        cityText,
        country,
        email,
        firstName,
        lastName,
        phoneNumber: phoneNumber || mainPhoneNumber,
        postalCode,
        profilePictureUrl,
        publicId,
        countryPublicId,
        statePublicId,
      }

      dispatch({ type: ACTIONS.GET_MEMBER_PROFILE, payload: selectedMember });
    } else if (response.status === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('sso_token');
      localStorage.removeItem('expiration');
      if (!/^\/login/.test(window.location.pathname)) {
        dispatch({ type: navigationActions.REDIRECT, payload: '/login' });
      }
    }
    return data;
  } catch (error) {
    console.error(error);
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const requestJoin = groupId => async dispatch => {
  try {
    const response = await Member.joinRequest(groupId);
    if (response.status === 200) {
      dispatch({ type: ACTIONS.JOIN_GROUP, payload: response.data });
    }
    return response.data;
  } catch (error) {
    console.error(error);
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const sendInvitation = (
  selectedMember,
  selectedGroup,
) => async dispatch => {
  try {
    const response = Member.sendInvitation(selectedMember, selectedGroup);
    if (response.status === 200) {
      // TODO: some refactors here
    }
    return response;
  } catch (error) {
    console.error(error);
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};
export const setLoading = value => dispatch => {
  dispatch({ type: ACTIONS.STATUS_LOADING, payload: value });
};

export const updateMemberGroups = list => dispatch => {
  dispatch({ type: ACTIONS.UPDATE_MEMBER_GROUPS, payload: list });
};

const updateNotificationPreferences = (
  notificationOpts,
  groupId,
  memberId,
  isOrgPreferences,
) => async dispatch => {
  try {
    const response = await Member.updateNotificationPreferences(notificationOpts, groupId, memberId);
    if (response.status === 200) {

      const payload = {
        groupId,
        options: notificationOpts,
      };

      dispatch({
        type: ACTIONS.UPDATE_PREFERENCE,
        payload,
      });

      dispatch({
        type: isOrgPreferences ? groupsActions.UPDATE_ORG_NOTIFICATION_PREFERENCES : groupsActions.UPDATE_NOTIFICATION_PREFERENCES,
        payload,
      });

      snackBarStatus({
        payload: {
          enable: true,
          title: 'Notification preference updated successfully.',
          type: 'success',
        },
      })(dispatch);

    }
    return response;
  } catch (error) {
    console.error(error);
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const updateGroupNotificationPreferences = (notificationOpts, groupId, memberId) =>
  updateNotificationPreferences(notificationOpts, groupId, memberId);

export const updateOrgNotificationPreferences = (notificationOpts, groupId, memberId) =>
  updateNotificationPreferences(notificationOpts, groupId, memberId, true);

export const removeMemberFromWideCommunication = ( groupId, member ) => async dispatch => {
  try {
    const response = await Member.removeMemberFromWideCommunication(groupId, member);
    if (response.status === 200) {
      const payload = {
        groupId,
        options: { isNotifyBySms: false, isNotifyByEmail: false },
      };

      dispatch({
        type: ACTIONS.UPDATE_PREFERENCE,
        payload,
      });

      dispatch({
        type: groupsActions.UPDATE_ORG_NOTIFICATION_PREFERENCES,
        payload,
      });
    }
    return response.data;
  } catch (error) {
    console.error(error);
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const updatePictureCompleteProfile = ({
                                               member,
                                               img,
                                             }) => async dispatch => {
  try {
    const response = await Member.updateProfileImage(
      '',
      member.member ? member.member.public_id : member.publicId,
      img,
    );
    if (response.status === 200) {
      const newPictureUrl = response.data.data.profile_picture_url ? response.data.data.profile_picture_url + `?t=${new Date().getTime()}` : null
      updateMemberPicture(newPictureUrl)(dispatch);
      updateSpecificMember(response.data.data.public_id, {
        profilePictureUrl: newPictureUrl,
      })(dispatch);
      // TODO: update group in groupsReducer.
    }
    return response;
  } catch (error) {
    console.error(error);
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const updateDisplayChangePassword = value => dispatch => {
  dispatch({ type: ACTIONS.DISPLAY_CHANGE_PASSWORD, payload: value });
};

export const displayEditMemberForm = show => ({
  type: ACTIONS.DISPLAY_EDIT_MEMBER_FORM,
  payload: show,
});

export const revokeTemporaryAdminPrivileges = (
  member,
  organization,
) => async dispatch => {
  try {
    const response = await Member.revokeTemporaryAdminPrivileges(
      member,
      organization,
    );
    if (response.status === 200) {
      dispatch({
        type: ACTIONS.REVOKE_TEMPORARY_ADMIN_PRIVILEGES,
        payload: response.data.data,
      });
    }
    return response;
  } catch (error) {
    console.error(error);
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const clearBounce = (memberPublicId) => async dispatch => {
  try {
    const response = await Member.clearBounce(memberPublicId);
    if (response.status === 200) {
      dispatch({
        type: ACTIONS.UPDATE_EMAIL_BOUNCED_STATUS,
        payload: { 
          isEmailBounced: false, 
          bouncedMessage: null 
        },
      });
    }

    return response;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);

    return error;
  }
};
