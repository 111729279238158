import React, { Component } from 'react';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import ClearIcon from '@material-ui/icons/Clear';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { enableRightDrawerGroupMessage } from '../../Actions/rightDrawerGroupMessagesActions';
import RightDrawerPropType from '../../PropTypes/RightDrawerPropType';
import { changeTab } from '../../Actions/middleMenuActions';
import { displayGallery } from '../../Actions/imageGalleryActions';
import SimpleMessage from '../SimpleMessage';
import MessagePreview from '../../Containers/MessagePreview';
import GenericMessage from '../GenericMessage';
import ActionUserSettingsButton from '../ActionUserSettingsButton';
import { clearMessage, statusWindowsMessage } from '../../Actions/messageActions';
import EvaTooltip from '../EvaTooltip';
import { MessageActionsButtons } from '../MessageActionsButtons';
import AppBarMobile from '../../Containers/AppBarMobile';
import './index.sass';

const drawerWidth = window.innerWidth > 768 ? 705 : window.innerWidth;

const empty = () => <div />;

const styles = theme => ({
  [theme.breakpoints.only('sm')]: {
    drawerPaper: {
      width: '100% !important',
      height: '100% !important',
    },
    drawerHeader: {
      marginBottom: 0,
      padding: '0px !important',
    },
    iconButtonContainer: {
      backgroundColor: '#f3f3f3',
      height: 66,
      borderRadius: 0,
      paddingLeft: '30px',
    },
  },
  [theme.breakpoints.only('xs')]: {
    drawerPaper: {
      width: '100% !important',
      height: '100% !important',
      overflowX: 'hidden',
    },
    drawerHeader: {
      marginBottom: 0,
      padding: '0px !important',
    },
    iconButtonContainer: {
      backgroundColor: '#f3f3f3',
      height: 52,
      borderRadius: 0,
    },
  },
  [theme.breakpoints.up('md')]: {
    drawerPaper: {
      marginRight: 0,
    },
  },
  drawerPaper: {
    position: 'absolute',
    width: drawerWidth,
    overflowX: 'hidden',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-left',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    minHeight: 52,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  'content-left': {
    marginLeft: -drawerWidth,
  },
  'content-right': {
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  'contentShift-left': {
    marginLeft: 0,
  },
  'contentShift-right': {
    marginRight: 0,
  },
});

class RightDrawerGroupMessages extends Component {
  state = {
    side: 'right',
  };

  handleDrawer = () => {
    const { isBackButton, backTitle } = this.props;
    let ShowElement;
    let SecondElement;
    let back;
    let buttonStatus = false;
    let enableComponent = true;
    if (isBackButton) {
      switch (backTitle) {
        case 'Message':
          ShowElement = SimpleMessage;
          SecondElement = ActionUserSettingsButton;
          buttonStatus = false;
          break;
        case 'Messages':
          ShowElement = empty;
          SecondElement = empty;
          buttonStatus = false;
          enableComponent = false;
          break;
        case 'Message Preview':
          ShowElement = MessagePreview;
          SecondElement = MessageActionsButtons;
          buttonStatus = true;
          back = 'Message';
          break;
        case 'Message Failure':
          ShowElement = SimpleMessage;
          SecondElement = MessageActionsButtons;
          buttonStatus = true;
          back = 'Message';
          break;
        case 'Groups':
          ShowElement = empty;
          SecondElement = empty;
          buttonStatus = false;
          enableComponent = false;
          break;
        default:
          break;
      }
      this.props.enableRightDrawerGroupMessage({
        payload: {
          enable: enableComponent,
          title: backTitle,
          element: <ShowElement />,
          secondTitle: <SecondElement />,
          isBackButton: buttonStatus,
          backTitle: back,
        },
      });
    } else {
      this.props.enableRightDrawerGroupMessage({
        payload: { enable: false, element: <div /> },
      });

      this.props.clearMessage();

      if (this.props.isGalleryOpen) {
        this.props.displayGallery(false);
      }

      if (window.innerWidth >= 1024) {
        document.querySelector('.right-drawer.drawer-message > .drawer-md-up').style.display =
          'none';
      }
    }
  };

  handleChangeAnchor = event => {
    this.setState({
      side: event.target.value,
    });
  };

  close = () => {
    this.props.statusWindowsMessage({ show: false });
  };

  render() {
    const {
      classes,
      element,
      status,
      title,
      secondTitle,
      isBackButton,
      windowParams,
      disableEnforceFocus,
    } = this.props;
    const { side } = this.state;

    return (
      <div className={`right-drawer drawer-message ${!status ? 'disable' : ''}`}>
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor={side}
            open={status}
            classes={{
              paper: classes.drawerPaper,
            }}
            disableEnforceFocus={disableEnforceFocus}
          >
            <AppBarMobile title={title} type="page" onBackClick={this.handleDrawer} />
            <div className="right-drawer-content drawer-content-message">{element}</div>
          </Drawer>
        </Hidden>
        <Hidden smDown>
          <div className="drawer-md-up">
            <Drawer
              anchor={side}
              open={status}
              classes={{
                paper: classes.drawerPaper,
              }}
              disableEnforceFocus={disableEnforceFocus}
            >
              <div className={classes.drawerHeader}>
                <Grid item xs={1}>
                  {isBackButton ? (
                    <EvaTooltip title="Back">
                      <IconButton onClick={this.handleDrawer}>
                        <KeyboardArrowLeft />
                      </IconButton>
                    </EvaTooltip>
                  ) : (
                    <EvaTooltip title="Close">
                      <IconButton onClick={this.handleDrawer}>
                        <ClearIcon />
                      </IconButton>
                    </EvaTooltip>
                  )}
                </Grid>
                <Grid item xs={9}>
                  <div className="drawer-title">{title}</div>
                </Grid>
                <Grid item xs={2}>
                  {secondTitle && <div className="drawer-message-second-title">{secondTitle}</div>}
                </Grid>
              </div>
              {windowParams.show && (
                <div className="message-container">
                  <GenericMessage
                    hideMessage={this.close}
                    type="message"
                    title={windowParams.titleMessage}
                    message={windowParams.message}
                  />
                </div>
              )}
              <div className="right-drawer-content">{element}</div>
            </Drawer>
          </div>
        </Hidden>
      </div>
    );
  }
}

const mS = state => ({
  status: state.rightDrawerGroupMessagesReducer.enable,
  element: state.rightDrawerGroupMessagesReducer.element,
  title: state.rightDrawerGroupMessagesReducer.title,
  secondTitle: state.rightDrawerGroupMessagesReducer.secondTitle,
  BackSecondTitleElement: state.rightDrawerGroupMessagesReducer.BackSecondTitleElement,
  BackElement: state.rightDrawerGroupMessagesReducer.BackElement,
  backTitle: state.rightDrawerGroupMessagesReducer.backTitle,
  isBackButton: state.rightDrawerGroupMessagesReducer.isBackButton,
  cleaner: state.rightDrawerGroupMessagesReducer.cleaner,
  disableEnforceFocus: state.rightDrawerGroupMessagesReducer.disableEnforceFocus,
  windowParams: state.messageReducer.windowParams,
  isGalleryOpen: state.imageGalleryReducer.isOpen,
});

const mD = {
  enableRightDrawerGroupMessage,
  changeTab,
  clearMessage,
  statusWindowsMessage,
  displayGallery,
};

RightDrawerGroupMessages.propTypes = RightDrawerPropType;

export default connect(
  mS,
  mD,
)(withStyles(styles, { withTheme: true })(RightDrawerGroupMessages));
