export const composerToDesign = html => {
  return {
    body: {
      values: { backgroundColor: "#ffffff" },
      rows: [
        {
          cells: [1],
          columns: [
            {
              contents: [{
                type: 'html',
                values: {
                  html,
                }
              }],
            }
          ]
        }
      ]
    }
  }
}
