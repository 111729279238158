import { EmailSimple, EmailSimpleSemiColon, ValidEmailPhone } from './email';
import PhoneSimple from './phone';
import { PasswordCheck, ValidatePassword } from './password';
import { validDate, getYears, getDate, getDayOfTheWeek, formatDate, addDays, getDateToday,
  getDateInTimezone, addTimezoneToDate } from './date';
import arrayToHash from './arrayToHash';
import getMemberName from './getMemberInfo';
import existObject, { hasKey, hasEmailOrPhoneKeys } from './ObjectUtilities';
import LocalStorageMock from './localStorage';
import transforToHtml, { isUrlImage } from './image';
import required from './validationForm';
import { isValuesFromArrayInArray } from './isValuesFromArrayInArray';
import { redirectToLogin } from './redirectToLogin';
import * as arrayUtils from './ArrayUtils';
import * as folders from './Folders';
import * as groups from './Groups';
import * as members from './Members';
import * as messages from './Messages';
import * as reduxFormValidators from './reduxFormValidators';
import * as utility from './Utility';
import * as selection from './selection';
import * as organizations from './organizations';

export default {
  EmailSimple,
  EmailSimpleSemiColon,
  hasKey,
  PhoneSimple,
  ValidEmailPhone,
  PasswordCheck,
  ValidatePassword,
  validDate,
  getYears,
  getDate,
  getDayOfTheWeek,
  LocalStorageMock,
  formatDate,
  addDays,
  existObject,
  transforToHtml,
  arrayToHash,
  getMemberName,
  isUrlImage,
  required,
  getDateToday,
  getUtcDate: getDateInTimezone,
  addTimezoneToDate,
  hasEmailOrPhoneKeys,
  isValuesFromArrayInArray,
  redirectToLogin,
  ...arrayUtils,
  ...folders,
  ...groups,
  ...members,
  ...messages,
  ...reduxFormValidators,
  ...utility,
  ...selection,
  ...organizations,
};
