import { ACTIONS } from '../Actions/messagesFiltersActions';
import { SORT_RECENT_MESSAGES } from '../Config/Constants';

const initialState = {
  isFiltersOpen: false,
  filters: {
    type: null,
    date: {
      from: null,
      to: null,
    },
  },
  sort: SORT_RECENT_MESSAGES[0].value,
  searchTerm: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.TOGGLE:
      return { ...state, isFiltersOpen: action.payload };

    case ACTIONS.CHANGE:
      return { ...state, filters: {
          ...state.filters,
          ...action.payload,
        } };

    case ACTIONS.SORT_CHANGE:
      return { ...state, sort: action.payload };

    case ACTIONS.SEARCH_TERM_CHANGE:
      return { ...state, searchTerm: action.payload };

    case ACTIONS.RESET:
      return { ...initialState };

    default:
      return state;
  }
};
