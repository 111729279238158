import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import EvaButton from '../../EvaButton';
import { InputIconGridless as InputStandard } from '../../InputIcon';
import snackBarStatus from '../../../Actions/snackbarActions';
import { InputPhoneCheck as InputPhone } from '../../InputPhone';
import { Datepicker } from '@mobiscroll/react';
import BirthdayPicker from '../../BirthdayPicker';
// import EvaTooltip from '../../EvaTooltip';

/* eslint-disable */
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/* eslint-enable */

// Custom Styles declaration
const styles = {
  root: {
    '#root &': {
      width: '100% !important',
      boxSizing: 'border-box',
      paddingLeft: '40px',
      paddingRight: '40px',
      '&::placeholder': {
        padding: '0 !important',
      },
    },
  },
  phoneInput: {
    '#root &': {
      paddingRight: '70px',
      '&::placeholder': {
        padding: '0px !important',
      },
    },
  },
  displace: {
    '& input.mbsc-comp': {
      '@media only screen and (min-width: 1024px)': {
        position: 'relative',
        left: '1em',
      },
    },
  },
  displaceActive: {
    '& input.mbsc-comp': {
      '@media only screen and (min-width: 1024px)': {
        position: 'relative',
        left: '1em',
      },
    },
  },
  fstRowSizse: {
    display: 'flex',
    alignItems: 'flex-end',
    height: '74px',
  },
  sndRowSize: {
    display: 'flex',
    alignItems: 'flex-end',
    height: '80px',
  },
  thrdRowSize: {
    display: 'flex',
    alignItems: 'flex-end',
    height: '56px',
  },
  fourthRowSize: {
    display: 'flex',
    alignItems: 'flex-end',
    height: '70px',
    '& > div': {
      alignItems: 'flex-end',
      width: '100%',
    },
    '& > div > input': {
      margin: '0 !important',
      borderRadius: '0px',
    },
  },
};

class RegisterMember extends Component {
  validateValues = (data) => {
    if (data.phone
      && typeof data.phone === 'string'
      && data.phone.length) {
      const parsedNumber = parsePhoneNumberFromString(data.phone);
      if (!parsedNumber || !parsedNumber.isValid()) {
        return 'Invalid phone number';
      }
    }

    if (this.props.isLiteMode && !data.email) {
      return 'An email address is required to add a new member.';
    }

    if (data.email && !emailRegex.test(data.email)) {
      return 'Invalid email';
    }
    return null;
  }

  addMember = () => {
    const { stepValues } = this.props;
    const error = this.validateValues(stepValues);

    if (error) {
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: error,
          type: 'error',
        },
      });
    } else {
      const {
        firstName,
        lastName,
        phone,
        email,
        birthday,
      } = stepValues;

      this.props.nextStep({ firstName, lastName, phone, email, birthday });
    }
  }

  render() {
    const {
      classes,
      stepValues: { firstName, phone, email, lastName, birthday },
      inputChange,
      handleBirthday,
    } = this.props;

    return (
      <Grid container justify="center" alignItems="center" id="register-member-container">
        <Grid item xs={12} className="underlined-border-bottom">
          <InputPhone
            inputClassName={classes.phoneInput}
            name="phone"
            placeholder="Phone number"
            value={phone}
            onInputPhoneChange={phoneNumber =>
              inputChange({ target: { value: phoneNumber, name: 'phone' } })
            }
            useV3
          />
        </Grid>
        <Grid item xs={12} className={classes.fstRowSizse}>
          <InputStandard
            className="take-all-available-space"
            name="email"
            placeholder="Email address"
            type="email"
            value={email}
            icon="email"
            color="transparent"
            onChange={inputChange}
            tooltipTitle="Email"
            useId="special"
            appendVoidContainer
          />
        </Grid>
        <Grid item xs={12} className={classes.sndRowSize}>
          <InputStandard
            className="take-all-available-space"
            name="firstName"
            placeholder="First name"
            type="text"
            value={firstName}
            icon="firstName"
            onChange={inputChange}
            color="transparent"
            tooltipTitle="First name"
            useId="special"
            appendVoidContainer
          />
        </Grid>
        <Grid item xs={12} className={classes.thrdRowSize}>
          <InputStandard
            className="take-all-available-space"
            name="lastName"
            placeholder="Last name"
            type="text"
            value={lastName}
            icon="lastName"
            onChange={inputChange}
            color="transparent"
            tooltipTitle=" "
            useDivContainer
            useId="special"
            appendVoidContainer
          />
        </Grid>
        <Grid item xs={12} className={classes.fourthRowSize}>
          <BirthdayPicker value={birthday} onChange={handleBirthday} />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center', marginTop: '40px' }}>
          <EvaButton onClick={this.addMember}>
            Add
          </EvaButton>
        </Grid>
      </Grid>
    );
  }
}

RegisterMember.propTypes = {
  stepValues: PropTypes.instanceOf(Object).isRequired,
  inputChange: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  isLiteMode: PropTypes.bool.isRequired,
};

const mapStateToProps = null;

const mapDispatchToProps = {
  snackBarStatus,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(RegisterMember));
