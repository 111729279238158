import React, { Component } from 'react';
import ContentEditable from 'react-contenteditable';
import { withStyles } from '@material-ui/core/styles';
import GenericMessage from '../GenericMessage';
import './index.sass';
import Helpers from '../../Helpers';
import { switchStyles } from '../../Theme/switchStyles';
import MessageSmsPreviewPropType from '../../PropTypes/MessageSmsPreviewPropType';
import MessagePreviewToolbar from '../MessagePreviewToolbar';
import { SMS_TEXT_LENGTH_LIMIT, SMS_TEXT_LENGTH_WITH_EMOJIS_LIMIT } from '../../Config/Constants';
import { SUPPORT_PHONE_NUMBER } from '../../Config/env';
import LettersCounter from '../LettersCounter';
import { getContentWithReplacedUrlsLength } from '../../Helpers/getContentWithReplacedUrlsLength';
import { emojisRegex } from '../../Helpers/regex';


const styles = theme => ({
  ...switchStyles,
});

class MessageSmsPreview extends Component {

  constructor(props) {
    super(props);
    this.state = {
      smsEditableContent: '',
      realContentLength: 0,
    };
  }

  disabledMessage = 'Disabling SMS may restrict some of your group members from receiving this message.';

  componentDidMount() {
    this.updateContent(this.props.content);
  }

  updateContent = content => {
    const { prefix } = this.props;
    const contentLengthWithShortLinks = getContentWithReplacedUrlsLength(content);
    const realContentLength = (prefix ? prefix.length : 0) + contentLengthWithShortLinks;
    this.setState({
      smsEditableContent: content,
      realContentLength,
    });
  }

  handleChange = e => {
    let value = e.target.value;
    this.updateContent(e.target.value);
    this.props.onChange(value)
  };

  renderLiteModeAlert = () => (
    <div className="form-message info sms-lite-mode">
      <p>
        Thank you for using Evangelus for all of your messaging needs.
        If you would like to enable the sending of text messages through Evangelus
        please contact support at <a className="support-phone-number" href={`tel:+${SUPPORT_PHONE_NUMBER}`}>{SUPPORT_PHONE_NUMBER}</a> to upgrade your account.
      </p>
    </div>
  )

  render = () => {
    const { smsEditableContent, realContentLength } = this.state;
    const { isLiteMode, prefix, enabled, isPreventChange, onSendByChange } = this.props;

    const isHasEmojis = emojisRegex.test(smsEditableContent);

    return (
      <div className="message-sms-preview-container">
        <div className="send-sms-container">
          <MessagePreviewToolbar
            text={`SMS sending ${enabled ? 'enabled' : 'disabled'}`}
            enabled={enabled}
            isPreventChange={isPreventChange}
            onChange={onSendByChange}
          />

          {isLiteMode && this.renderLiteModeAlert()}

          {!enabled && !isLiteMode && (
            <div className="message-container">
              <GenericMessage
                type="info"
                title="Warning"
                message={this.disabledMessage}
              />
            </div>
          )}

          {!isLiteMode && (
            <div className="preview-pad">
              <div className="info-message">
                <span className="sms-preview-prefix">{prefix}</span>
                <ContentEditable
                  tagName="pre"
                  className="message"
                  html={smsEditableContent} // innerHTML of the editable div
                  onChange={this.handleChange} // handle innerHTML change
                  onPaste={Helpers.handleOnlyTextPreviewPaste}
                />
              </div>
              <LettersCounter  
                limit={isHasEmojis ? SMS_TEXT_LENGTH_WITH_EMOJIS_LIMIT : SMS_TEXT_LENGTH_LIMIT}
                length={realContentLength} 
                tooltip="The character count includes required text such as your group name." 
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}

MessageSmsPreview.propTypes = MessageSmsPreviewPropType;

MessageSmsPreview.defaultProps = {
  isPreventChange: false,
};

export default withStyles(styles)(MessageSmsPreview);
