/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import {
  getAllFoldersList,
  selectFolder,
  getFolderById,
  clearTemplates,
  getFolderByIdPaged,
} from '../../Actions/templateActions';
import TemplateOptions from '../TemplateOptions';
import { TEMPLATE_PAGE_SIZE_GRID } from '../../Config/Constants';
import { Dropdown, Select } from '@mobiscroll/react';
import './index.sass';
import { mbscResponsiveOptions } from '../../Config/Constants/mobiscroll-props';

const styles = () => ({
  hidden: {
    display: 'none',
  },
  show: {
    visibility: 'visible',
    opacity: '1',
    transition: 'opacity 0.7s ease-in-out',
  },
  cssRoot: {
    color: 'blue',
    backgroundColor: 'white',
    border: '2px solid',
    borderColor: '#9a66bf',
    borderRadius: '20px',
  },
  dropdownContainer: {
    marginBottom: '12px',
    marginTop: '13px',
    position: 'relative',
  },
});

class TemplateSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      folderId: null,
      folderSelected: '',
      folderList: [],
      typeRender: 'grid',
    };
  }
  componentDidMount() {
    this.props.getAllFoldersList().then(res => {
      if (res.status === 200) {
        this.handleFolderInitial();
        this.handleSort();
      }
    });
  }

  componentWillUnmount() {
    this.props.clearTemplates();
  }

  handleFolderChange = ({ value }) => {
    this.setState({ value })
    if (value) {
      this.setState({ folderSelected: value });
      this.props
        .getFolderByIdPaged(value, undefined, undefined, 1, TEMPLATE_PAGE_SIZE_GRID)
        .then(() => {
          this.props.selectFolder(value);
        })
    }
  };
  changeTypeRender = type => {
    this.setState({
      typeRender: type,
    });
  };
  handleSort = () => {
    const { templateAllList } = this.props;
    const myTemplates = 'My Templates';
    const feature = 'Featured';
    const favorite = 'Favorites';
    const share = 'Shared with me';
    const lista = [];
    templateAllList.forEach(templateCurrent => {
      const mytemplate = myTemplates === templateCurrent.name;
      const isParent = !!templateCurrent.parentFolder;
      if (mytemplate) {
        lista.push({ name: templateCurrent.name, publicId: templateCurrent.publicId });
      } else if (isParent && templateCurrent.parentFolder.name === myTemplates) {
        lista.push({ name: templateCurrent.name, publicId: templateCurrent.publicId });
      }
    });
    templateAllList.forEach(templateCurrent => {
      const featured = feature === templateCurrent.name;
      const isParent = !!templateCurrent.parentFolder;
      if (featured) {
        lista.push({ name: templateCurrent.name, publicId: templateCurrent.publicId });
      } else if (isParent && templateCurrent.parentFolder.name === feature) {
        lista.push({ name: templateCurrent.name, publicId: templateCurrent.publicId });
      }
    });
    templateAllList.forEach(templateCurrent => {
      const favorites = favorite === templateCurrent.name;
      const isParent = !!templateCurrent.parentFolder;
      if (favorites) {
        lista.push({ name: templateCurrent.name, publicId: templateCurrent.publicId });
      } else if (isParent && templateCurrent.parentFolder.name === favorite) {
        lista.push({ name: templateCurrent.name, publicId: templateCurrent.publicId });
      }
    });
    templateAllList.forEach(templateCurrent => {
      const Shared = share === templateCurrent.name;
      const isParent = !!templateCurrent.parentFolder;
      if (Shared) {
        lista.push({ name: templateCurrent.name, publicId: templateCurrent.publicId });
      } else if (isParent && templateCurrent.parentFolder.name === share) {
        lista.push({ name: templateCurrent.name, publicId: templateCurrent.publicId });
      }
    });
    this.setState({ folderList: lista });
  };
  handleFolderInitial = async () => {
    const { templateAllList } = this.props;
    const folderSelect = 'Featured';
    let templateExisted = false;
    templateAllList.forEach(templateCurrent => {
      const templateExist = folderSelect === templateCurrent.name;
      if (templateExist) {
        templateExisted = true;
        this.props
          .getFolderByIdPaged(
            templateCurrent.publicId,
            undefined,
            undefined,
            1,
            TEMPLATE_PAGE_SIZE_GRID,
          )
          .then(() => {
            this.props.selectFolder(templateCurrent.publicId);
            this.setState({ folderSelected: templateCurrent.publicId });
          })
      }
    });
    // if (!templateExisted) {
    //   this.props.updateCustomModal({ isLoader: false });
    // }
  };

  render() {
    const { templateFolderSelected, classes, onTemplatePick, templatesLoading } = this.props;
    const { folderId, folderList, folderSelected } = this.state;
    const data = folderList.map(folder => ({value: folder.publicId, text: folder.name}));
    return (
      <section className="template-select">
        <Grid container id="template-select-container">
          <Grid item xs={12} className="template-list-input">
            <div id="dropdown-mobis" className={classes.dropdownContainer}>
              <Select
                data={data}
                value={folderSelected}
                onChange={this.handleFolderChange}
                name="folder"
                responsive={mbscResponsiveOptions}
                placeholder="Select folder"
                buttons={['cancel']}
              />
            </div>
          </Grid>
          {templateFolderSelected ? (
            <TemplateOptions
              folderId={folderId}
              isTemplatesLoading={templatesLoading}
              onTemplatePick={onTemplatePick}
              changeTypeRender={this.changeTypeRender}
              typeRender={this.state.typeRender}
            />
          ) : (
            ''
          )}
        </Grid>
      </section>
    );
  }
}

const mS = state => ({
  templateFolderSelected: state.templateReducer.templateFolderSelected,
  templateAllList: state.templateReducer.templateAllList,
  templatesLoading: state.templateReducer.templatesLoading,
  folderId: state.templateReducer.folder,
});

const mD = {
  getAllFoldersList,
  getFolderById,
  selectFolder,
  clearTemplates,
  getFolderByIdPaged,
};
export default withRouter(
  connect(
    mS,
    mD,
  )(withStyles(styles)(TemplateSelect)),
);
