import React from 'react';
import Proptypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from '../../Components/Button';
import Confirm from './ConfirmComponent';
import casesObj from '../../Translations/en/Unsubscribe';
import { unsubscribeUser, cleanUnsusbcribeProcess } from '../../Actions/userActions';
import { statusLoading } from '../../Actions/loadingActions';
import snackBarStatus from '../../Actions/snackbarActions';
import defaultOrg from '../../Theme/Icons/org-icon.svg';
import LoadingScreen from '../../Components/LoadingScreen';
import Loading from '../../Components/Loading';

function doNothing() {}

class UnsubscribeComponent extends React.Component {
  static propTypes = {
    match: Proptypes.instanceOf(Object).isRequired,
    // eslint-disable-next-line
    cases: Proptypes.instanceOf(Object),
  };

  static defaultProps = {
    cases: casesObj,
  };

  state = {
    afterConfirm: false,
  };

  /*
    LIFECYCLE METHODS ===================================================
  */

  componentDidMount() {
    const {
      match: {
        params: { groupId, memberId, token },
      },
      history,
      unsubscribeProcess,
    } = this.props;
    if (!unsubscribeProcess) {
      this.props
        .unsubscribeUser(groupId, memberId, token)
        .then(() => {
          this.setState({
            afterConfirm: true,
          });
        })
        .catch(err => {
          history.push('/not-subscribed');
          // eslint-disable-next-line no-console
          console.error(err);
        });
    }
  }

  componentDidUpdate() {
    const { unsubscribeProcess, history } = this.props;
    if (!unsubscribeProcess.case) {
      history.push('/not-subscribed');
    }
  }

  componentWillUnmount() {
    if (this.props.cleanUnsusbcribeProcess) this.props.cleanUnsusbcribeProcess();
  }

  /*
   COMPONENT METHODS ===================================================
  */

  render() {
    if (!this.props.unsubscribeProcess) {
      return <LoadingScreen />;
    }
    const {
      cases,
      unsubscribeProcess: {
        case: caseVal,
        information: {
          organization: { main_picture_url: mainPictureUrl, name: orgName },
          name,
        },
      },
    } = this.props;

    return (
      <Scrollbars autoHeight autoHide autoHeightMin={0} autoHeightMax="100vh">
        {this.state.afterConfirm && (
          <Confirm
            focusText={cases[caseVal].afterConfirmText}
            auxText={cases[caseVal].finalNotice}
            group={{ name, organization: orgName }}
            groupOrgPicture={mainPictureUrl || defaultOrg}
            buttonsFragment={
              <Link style={{ textDecoration: 'none' }} to="/login">
                <Button
                  title="Evangelus account login"
                  onClick={doNothing}
                  className="evangelus-account-login"
                />
              </Link>
            }
            afterConfirm
          />
        )}
        <Loading />
      </Scrollbars>
    );
  }
}

const mapStateToProps = ({ userProfileReducer: { unsubscribeProcess } }) => ({
  unsubscribeProcess,
});

const mapDispatcActions = {
  unsubscribeUser,
  cleanUnsusbribeProcess: cleanUnsusbcribeProcess,
  snackBarStatus,
  statusLoading,
};

export default connect(
  mapStateToProps,
  mapDispatcActions,
)(UnsubscribeComponent);
