import axios, { CancelToken } from 'axios';
import { loadProgressBar } from 'axios-progress-bar';
import URL from './env';

const AXIOS = axios.create({
  baseURL: URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 100000,
});
loadProgressBar(undefined, AXIOS);
export default AXIOS;
export { CancelToken };
