import React, { Component } from 'react';
import MyGroupItem from '../MyGroupItem';
import PropTypes from 'prop-types';
import './index.sass';


class MyGroupsList extends Component {
  state = {
    displayChangePassword: true,
  };

  handleRemoveFromOrgClick = (organizationId) => this.handleModalCall(organizationId);

  handleModalCall = (organizationId) => {
    this.setState({ isOpen: false });
    this.props.onConfirmationModalCall(organizationId);
  };

  render() {
    const { groups, onSelectTypeNotification, onRemoveFromGroupClick, userId, onOpenDialog, } = this.props;

    return <section className="my-groups-list">
      <div className="section-title">My Groups</div>
      <div className="list-wrapper">
        {
          groups.map(myGroup => (
            <div key={myGroup.all_member_group_id} className="my-org-group">
              <div className="organization-item-wrapper">
                <MyGroupItem
                  isOrg
                  allMembersGroupId={myGroup.all_member_group_id}
                  publicId={myGroup.organization.public_id}
                  key={myGroup.organization.public_id}
                  name={myGroup.organization.name}
                  isNotifyOnlyByEmail={myGroup.organization.lite}
                  isAdmin={myGroup.organization_member_role === 'admin'}
                  isNotifyByEmail={myGroup.all_member_group_notification_preference.isNotifyByEmail}
                  isNotifyBySms={myGroup.all_member_group_notification_preference.isNotifyBySms}
                  onChannelChange={(opts) => onSelectTypeNotification(myGroup, opts, true)}
                  onFromOrgRemoveClick={this.handleRemoveFromOrgClick}
                  onFromGroupRemoveClick={onRemoveFromGroupClick}
                  userId={userId}
                  onOpenDialog={onOpenDialog}
                />
              </div>

              <section className="my-groups">
                {myGroup.groups.map(
                  group =>
                    !group.group.organization_general_group && (
                      <MyGroupItem
                        publicId={group.group.public_id}
                        key={group.ref_public_id}
                        name={group.group.name}
                        description={group.group.description ? group.group.description : 'No description'}
                        isAdmin={group.isAdmin}
                        isPending={group.group_member_status === 'pending'}
                        isNotifyOnlyByEmail={myGroup.organization.lite}
                        isNotifyByEmail={group.group_notification_preference.isNotifyByEmail}
                        isNotifyBySms={group.group_notification_preference.isNotifyBySms}
                        onFromGroupRemoveClick={onRemoveFromGroupClick}
                        onChannelChange={(opts) => onSelectTypeNotification(group, opts)}
                      />
                    ),
                )}
              </section>
            </div>
          ))
        }
      </div>
    </section>
  }
}

MyGroupsList.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.object).isRequired, // TODO: provide group object
  onConfirmationModalCall: PropTypes.func.isRequired,
  onRemoveFromGroupClick: PropTypes.func.isRequired,
  onSelectTypeNotification: PropTypes.func.isRequired,
};

export default MyGroupsList;
