export const DEFAULT_RSS_OPTIONS = 10;
export const RSS_OPTIONS_LIMIT = 25;
export const PRIVACY_OPTIONS = {
  open: 'open',
  closed: 'closed',
  hidden: 'hidden',
};

const { open, closed, hidden } = PRIVACY_OPTIONS;
export const PRIVACY_OPTIONS_HIERARCHY = {
  defaultOptions: [open, closed, hidden],
  [open]: [open, closed, hidden],
  [closed]: [closed, hidden],
  [hidden]: [hidden],
};
export const PRIVACY_OPTIONS_LABELS = [
  {
    value: open,
    label: 'Open',
  },
  {
    value: closed,
    label: 'Closed',
  },
  {
    value: hidden,
    label: 'Hidden',
  },
];
