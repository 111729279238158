import AXIOS from '../Config/AxiosConfig';
import headers from './Helper';

const Profile = {
  getUserAndGroupInformation(groupId, token) {
    return AXIOS.get(
      `/public/eva/complete-invitation/group/${groupId}/invitation/${token}`,
      { groupId, token },
      { headers: headers() },
    );
  },
  getCountries() {
    return AXIOS.get('/public/countries', { headers: headers() });
  },
  getStates(publicId) {
    return AXIOS.get(`public/states/${publicId}`, { headers: headers() });
  },
  createUserProfile(data) {
    return AXIOS.post(
      '/public/eva/sign-up', data,
      { headers: headers() },
    );
  },
  updateUserProfile(groupId, token, data) {
    return AXIOS.put(
      `/public/eva/complete-invitation/group/${groupId}/invitation/${token}`, data,
      { headers: headers() },
    );
  },
};

export default Profile;
