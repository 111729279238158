import React from 'react';
import download from 'downloadjs';
import './index.sass';

const AttachmentsPreview = ({ files }) => {
  const handleDownload = (path) => download(path);

  return (
    <div className="message-attachments-preview">
      {files.map(f => (
        <div className="attachment-item">
          <button className="icon-button download" onClick={() => handleDownload(f.path)} />
          <div className="attachment-file-name" onClick={() => handleDownload(f.path)}>{f.name}</div>
        </div>
      ))}
    </div>
  );
};

export default AttachmentsPreview;
