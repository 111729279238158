import { ACTIONS } from '../Actions/loginActions';

const initialState = {
  username: '',
  password: '',
  error: '',
  token: null,
  message: '',
  expiration: '',
  roles: [],
  isTokenCheckedOnInit: false,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE:
      return { ...state, ...action.payload };

    case ACTIONS.UPDATE_TOKEN:
      localStorage.setItem('token', action.payload);
      return { ...state, token: action.payload };


    case ACTIONS.CLEAR:
      return { ...state, ...initialState };

    case ACTIONS.LOG_IN: {
      const { expiration, token } = action.payload.data;
      localStorage.setItem('token', token);
      localStorage.setItem('expiration', expiration);
      return { ...state, ...action.payload.data };
    }

    case ACTIONS.SSO_LOG_IN: {
      const { token, expiration, roles } = action.payload.data;
      localStorage.setItem('token', token);
      localStorage.setItem('expiration', expiration);
      localStorage.setItem('roles', JSON.stringify(roles));
      localStorage.setItem('sso_token', action.payload.token);
      return { ...state, ...action.payload.data };
    }

    case ACTIONS.LOG_OUT:
      localStorage.removeItem('token');
      localStorage.removeItem('expiration');
      localStorage.removeItem('roles');
      localStorage.removeItem('sso_token');
      return { state: initialState };

    case ACTIONS.ERROR:
      return { ...state, ...action.payload };

    case ACTIONS.INIT_TOKEN_CHECK:
      return { ...state, isTokenCheckedOnInit: true };

    default:
      return state;
  }
};

export default loginReducer;
