import React, { Component } from 'react';
import { debounce } from '../../Helpers/debounce';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
// import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import ButtonStandard from '../Button';
import Input from '../Input';
import CreateFormPropTypes from '../../PropTypes/CreateFormPropType';
import GenericMessage from '../GenericMessage';
import {
  clearFormGroup,
  clearGroups,
  clearValidateKeyword,
  createGroupAsync,
  existsUrlShortener,
  initSelectedGroup,
  searchGroups,
  setKeywordError,
  setValidateKeyword,
  updateGroupsReducer,
  updateSelectedGroup,
} from '../../Actions/groupsActions';

import { setEnforceFocus } from '../../Actions/rightDrawerMessageActions';
import { enableRightDrawer } from '../../Actions/rightDrawerActions';
import { changeTab } from '../../Actions/middleMenuActions';
import snackBarStatus from '../../Actions/snackbarActions';
import ErrorMessage from '../ErrorMessage';
import './index.sass';
// waiting for active state icons
import { statusLoading } from '../../Actions/loadingActions';
import SuccessIcon from '../../Theme/Icons/check-green.svg';
import ErrorIcon from '../../Theme/Icons/error.svg';
import { SHORTENER_URL } from '../../Config/env';
import { PRIVACY_OPTIONS_HIERARCHY, PRIVACY_OPTIONS_LABELS, ROLE_EVA_GROUP_ADMIN } from '../../Config/Constants';
import LoaderOverlay from '../LoaderOverlay';
import { switchStyles } from '../../Theme/switchStyles';
import { Dropdown, Select } from '@mobiscroll/react';
import { mbscResponsiveOptions } from '../../Config/Constants/mobiscroll-props';

const styles = theme => ({
  colorBar: {},
  colorChecked: {},
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  ...switchStyles,
  customSelect: {
    borderBottom: ' 2px solid #e4e4e4',
  },
  iconPlace: {
    right: '74%',
    top: '50%',
    transform: 'translateY(-50%)',
    '@media only screen and (max-width: 1023px) and (min-width: 600px)': {
      right: '80% !important',
    },
    '@media only screen and (max-width: 414px) and (min-width: 375px)': {
      right: '70%',
    },
    '@media only screen and (max-width: 374px)': {
      right: '65%',
    },
  },
  inputWidthLimit: {
    '& input.mbsc-control': {
      '@media only screen and (min-width: 1024px)': {
        width: '94%',
      },
    },
  },
  '@media (max-width: 414px) and (min-width: 320px)': {
    createGroupButton: {
      marginBottom: '140px',
    },
  },
});

const inputFields = {
  name: { stateName: 'nameValidation', message: 'A name is required.' },
  shortName: {
    stateName: 'shortNameValidation',
    message: 'A short name is required.',
  },
  description: {
    stateName: 'descriptionValidation',
    message: 'The Description must contain at least 3 letters',
  },
  privacyLevel: {
    stateName: 'privacyLevelValidation',
    message: 'Group type must be selected.',
  },
  keyword: { stateName: 'keywordValidation' },
  parentGroup: { stateName: 'parentGroupValidation' },
};

class CreateForm extends Component {
  state = {
    iconDropdown: '',
    changePagetoPostFlag: false,
    count: 0,
    focus: '',
    showWarningKeyword: true,
    validationsForm: {
      shortNameValidation: false,
      senderNameValidation: false,
      descriptionValidation: false,
      keywordValidation: false,
      nameValidation: false,
      privacyLevelValidation: false,
      parentGroupValidation: false,
    },
  };

  componentDidMount() {
    window.interval = false;
    window.theWindow = false;
    this.props.initSelectedGroup();
  }

  getCustomUrl = () => {
    const { customUrl } = this.state;
    const { organization, keyword } = this.props;
    if (organization && organization.keyword && keyword && keyword.length >= 3) {
      return `${SHORTENER_URL}${organization.keyword}-${keyword}`;
    }
    return customUrl;
  };

  validateKeyword = keyword => {
    // A-Z, a-z, 0-9 and hypens (-)
    // max length 10 min length 3
    const validPattern = /^([a-z]|[0-9]|-){3,10}$/gi;
    const invalidHyphenPattern = /-{2,}/g;
    const invalidHyphenEndingPattern = /.*-$/g;
    const returnValue = { pass: false, reason: '' };
    if (keyword.length > 0 && keyword.length < 3) {
      returnValue.reason = 'Group keyword must be at least 3 characters long.';
    } else if (keyword.length > 10) {
      returnValue.reason = 'Group keyword must not be more than 10 characters long.';
    } else if (!validPattern.test(keyword)) {
      returnValue.reason = 'Organization keyword can only use numbers, letters and hypens';
    } else if (invalidHyphenPattern.test(keyword)) {
      returnValue.reason = 'Organization keyword must not have two or more hyphens together';
    } else if (invalidHyphenEndingPattern.test(keyword)) {
      returnValue.reason = 'Organization keyword must not end with a hyphen';
    } else {
      returnValue.pass = true;
      returnValue.reason = 'All checks passed';
    }
    return returnValue;
  };

  getCheckIcon = () => (
    <button className="form_warning-close" type="button" aria-label="Check">
      <img src={SuccessIcon} alt="SuccessIcon"/>
    </button>
  );

  getErrorIcon = () => {
    return (
      <button className="form_warning-close" type="button" aria-label="Close">
        <img src={ErrorIcon} alt="ErrorIcon"/>
      </button>
    );
  };

  handleChangeKeyword = async event => {
    const {
      organization: { keyword },
    } = this.props;
    const { validationsForm } = this.state;
    validationsForm.keywordValidation = false;
    const keywordGroup = event.target.value;
    const validationKeywordResponse = this.validateKeyword(keywordGroup);
    this.props.clearValidateKeyword();
    this.props.setKeywordError(true);
    this.handleChange(event);

    if (keywordGroup.length > 0) {
      const customUrl = `${keyword}-${keywordGroup}`;
      if (!validationKeywordResponse.pass) {
        this.props.snackBarStatus({
          payload: {
            title: validationKeywordResponse.reason,
            type: 'warning',
            enable: true,
          },
        });
        validationsForm.keywordValidation = true;
      } else {
        await this.verifyKeywordAvailability(customUrl);
      }
      this.setState({ validationsForm });
    } else {
      this.props.snackBarStatus({
        payload: {
          title: '',
          type: 'warning',
          enable: false,
        },
      });
      this.setState({ validationsForm });
    }
  };

  verifyKeywordAvailability = debounce(customUrl => {
    this.props.existsUrlShortener(customUrl).then(res => {
      let type = res.availability ? (type = 'success') : 'warning';
      const msg = res.availability ? 'Keyword is available' : res.message;
      this.props.snackBarStatus({
        payload: {
          title: msg,
          type,
          enable: true,
        },
      });

      this.setState({
        customUrl,
      });
    });
  }, 1000);

  removeDuplicate = array => {
    const uniqueArray = array.filter(
      (elem, index, self) => index === self.findIndex(group => group.public_id === elem.public_id),
    );
    return uniqueArray;
  };

  getThirdLevel = name => {
    const { groups } = this.props;
    const parent = groups.find(group => group.name === name);
    if (parent) {
      return parent.group_parent.name;
    }
    return '';
  };

  isParent = group => {
    const { selectedGroup } = this.props;

    return (
      group.group_parent &&
      group.group_parent.name &&
      group.group_parent.name === selectedGroup.name
    )
  };

  getParentGroupData = () => {
    const { groups } = this.props;
    const filteredGroups = this.removeDuplicate(groups);
    let parentOptions = filteredGroups.filter(
      group => !group.organization_general_group, // can't pick the All Members
    );
    parentOptions = parentOptions.map(group => ({
      text:
        group.group_parent && group.group_parent.name
          ? `${group.group_parent.name} / ${group.name}`
          : group.name,
      value: group.public_id,
      disabled: (group.group_parent && this.getThirdLevel(group.group_parent.name)) || this.isParent(group),
    }));

    return parentOptions;
  };

  getValidationButton = () => {
    const { validateKeyword, selectedGroup: { keyword }, keywordError } = this.props;
    if (
      (keyword && keyword.length > 0 && keyword.length < 3) ||
      (keyword && keyword.length > 10) ||
      !keywordError
    ) {
      return this.getErrorIcon();
    }
    if (keyword === '') {
      return validateKeyword;
    } else if (validateKeyword) {
      return this.getCheckIcon();
    }
  };

  handleChange = e => {
    const { validationsForm } = this.state;
    const { name } = e.target;
    let { value } = e.target;

    if (inputFields[name] && validationsForm[inputFields[name].stateName] && value !== '') {
      validationsForm[inputFields[name].stateName] = false;
      this.setState(validationsForm);
    }

    if (e.target.name === 'keyword') {
      value = e.target.value.toLowerCase();
    }
    this.props.updateSelectedGroup({ [e.target.name]: value });
  };

  handleDropdown = ({ value }) => {
    this.setState({ iconDropdown: value });
    this.handleChange({
      target: {
        name: 'privacy_level',
        value,
      },
    });
  };

  // handleChangeReplies = (event, type) => {
  //   /* eslint-disable */
  //   let forward_reply_sms_number;
  //   let forward_reply_email;
  //   if (type) {
  //     if (type === 'sms') {
  //       forward_reply_sms_number = event.target.checked;
  //       this.props.updateSelectedGroup({ forward_reply_sms_number: forward_reply_sms_number });
  //     } else {
  //       forward_reply_email = event.target.checked;
  //       this.props.updateSelectedGroup({ forward_reply_email: forward_reply_email });
  //     }
  //   }
  //   /* eslint-enable */
  // };

  getAllowedPrivacyOptions = parentGroup => {
    const parentGroupData = this.props.groups.find(group => group.public_id === parentGroup);
    return parentGroupData
      ? PRIVACY_OPTIONS_HIERARCHY[parentGroupData.privacy_level]
      : PRIVACY_OPTIONS_HIERARCHY.defaultOptions;
  };

  parseToValue = groups =>
    groups.map(group => ({
      value: group.public_id,
      label: group.name,
    }));

  parseValidationMessage = () => {
    const { role } = this.props;
    const { validationsForm } = this.state;
    const { selectedGroup: { name, shortName, description, privacy_level, keyword, parentGroup } } = this.props;
    const validateKeyword = this.validateKeyword(keyword);
    const messages = [];

    if (name === '') {
      messages.push([inputFields.name.message]);
      validationsForm[inputFields.name.stateName] = true;
    }

    if (shortName === '') {
      messages.push([inputFields.shortName.message]);
      validationsForm[inputFields.shortName.stateName] = true;
    }

    if (description === '' || description.length < 3) {
      messages.push([inputFields.description.message]);
      validationsForm[inputFields.description.stateName] = true;
    }

    if (privacy_level === '') {
      messages.push([inputFields.privacyLevel.message]);
      validationsForm[inputFields.privacyLevel.stateName] = true;
    }

    if (role === ROLE_EVA_GROUP_ADMIN && !parentGroup) {
      messages.push('You must specify a Parent Group');
      validationsForm[inputFields.parentGroup.stateName] = true;
    }

    if (keyword !== '' && !validateKeyword.pass) {
      messages.push(validateKeyword.reason);
      validationsForm[inputFields.keyword.stateName] = true;
    }

    this.setState(validationsForm);
    return messages;
  };

  submit = e => {
    e.preventDefault();
    const { selectedGroup, selectedGroup: { keyword } } = this.props;
    const validateKeyword = this.validateKeyword(keyword);

    const validationMessages = this.parseValidationMessage();
    if (validationMessages.length > 0) {
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: validationMessages,
          type: 'warning',
        },
      });
    } else if (keyword !== '' && !validateKeyword.pass) {
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: validateKeyword.reason,
          type: 'warning',
        },
      });
    } else {
      this.props.createGroupAsync(selectedGroup).then(response => {
        if (response) {
          this.resetForm();
          this.props.statusLoading(false);
        }
      });
    }
    /* eslint-enable */
  };

  resetForm = () => {
    this.props.enableRightDrawer({ payload: { enable: false } });
    this.props.initSelectedGroup();

    this.props.clearGroups();
    this.setState({
      hiddenPlaceholderParent: '',
    });
    this.refreshGroupsList();
  };

  refreshGroupsList = () => {
    const {
      sort,
      pagination: { currentPage },
      filters: { privacyLevel, activity, shareOptions, groupType },
      searchTerm,
    } = this.props;

    this.props.searchGroups({
      sort,
      searchTerm,
      privacyLevel,
      activity,
      shareOptions,
      groupType,
      page: currentPage
    });
  }

  close = () => {
    this.setState({
      showWarningKeyword: false,
    });
  };

  render() {

    if (!this.props.selectedGroup) {
      return '';
    }

    const {
      iconDropdown,
      focus,
      showWarningKeyword,
      validationsForm,
    } = this.state;

    const {
      classes,
      keywordStatusRequest,
      error,
      selectedGroup: {
        name,
        shortName,
        senderName,
        description,
        keyword,
        privacy_level,
        parentGroup,
      },
    } = this.props;

    const isGroupAdmin = this.props.role === ROLE_EVA_GROUP_ADMIN;
    const allowedOptions = this.getAllowedPrivacyOptions(parentGroup);

    const keywordUrl = this.getCustomUrl();

    return (
      <Grid
        container
        spacing={0}
        className="form-create-group"
        id="create-group"
      >
        <Grid item xs={12} className="form__create">
          <form noValidate autoComplete="off">
            {error ? (
              <ErrorMessage error={error} hideMessage={this.props.clearGroups}/>
            ) : null}

            <Input
              type="text"
              name="name"
              value={name}
              placeholder="Name"
              className={validationsForm.nameValidation && 'validation-form'}
              maxLength={50}
              onChange={this.handleChange}
            />
            <Input
              type="text"
              name="shortName"
              placeholder="Short Name"
              value={shortName}
              onChange={this.handleChange}
              className={validationsForm.shortNameValidation && 'validation-form'}
              maxLength={16}
            />
            <Input
              type="text"
              name="description"
              placeholder="Description"
              value={description}
              maxLength={70}
              onChange={this.handleChange}
              className={validationsForm.descriptionValidation && 'validation-form'}
            />
            <Input
              type="text"
              name="senderName"
              placeholder="Sender Name"
              value={senderName}
              onChange={this.handleChange}
              className={validationsForm.senderNameValidation && 'validation-form'}
              maxLength={16}
            />
            <div
              className={`dropdown-mobis dropdown-group-type ${iconDropdown !== '' &&
              'dropdown-highlight'} ${
                classes.inputWidthLimit
              } ${validationsForm.privacyLevelValidation && 'validation-form-textarea-mobiscroll'}`}
            >
              <Select
                data={PRIVACY_OPTIONS_LABELS
                  .filter(option => allowedOptions.includes(option.value)).map(
                    ({ label, value }) => ({ text: label, value }),
                  )}
                value={privacy_level}
                onChange={this.handleDropdown}
                name="privacyPolicy"
                responsive={mbscResponsiveOptions}
                placeholder="Group type"
                buttons={[]}
              />

              <i className={`${iconDropdown}-icon icon-base ${classes.iconPlace}`}/>
            </div>
            {!isGroupAdmin && (
              <div className="keyword-container ">
                <div className="input-container">
                  <Input
                    name="keyword"
                    title="keyword"
                    type="text"
                    placeholder="Keyword"
                    value={keyword}
                    focused={focus}
                    onChange={this.handleChangeKeyword}
                    className={`border-keyword form-input ${validationsForm.keywordValidation &&
                    'validation-form'}`}
                    color="#FAFAFA"
                  />
                </div>
                <div className="icon-container">{this.getValidationButton()}</div>
              </div>
            )}
            {keywordStatusRequest && (
              <div
                style={{
                  paddingTop: '15px',
                  paddingBottom: '15px',
                  textAlign: 'center',
                }}
              >
                <LoaderOverlay/>
              </div>
            )}
            {keywordUrl && showWarningKeyword && (
              <Grid xs={12} className="message-keyword-container">
                <GenericMessage
                  hideMessage={this.close}
                  type="info message-keyword"
                  title="Warning:"
                  message="Changing the Keyword will also change the URL for this group. The previous URL will be invalid and can not be used to join the group."
                />
              </Grid>
            )}

            {/*<div className="options-title">Receive Notification of Replies</div>*/}
            {/*<div className="share-options row">*/}
            {/*  <div className="column left">Notification via SMS</div>*/}
            {/*  <div className="column right">*/}
            {/*    <Switch*/}
            {/*      onChange={event => this.handleChangeReplies(event, 'sms')}*/}
            {/*      classes={{*/}
            {/*        switchBase: classes.switchBase,*/}
            {/*        colorSecondary: classes.switchColorSecondary,*/}
            {/*        track: classes.track,*/}
            {/*        thumb: classes.thumb,*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className="share-options row">*/}
            {/*  <div className="column left">Notification via Email</div>*/}
            {/*  <div className="column right">*/}
            {/*    <Switch*/}
            {/*      onChange={event => this.handleChangeReplies(event, 'email')}*/}
            {/*      classes={{*/}
            {/*        switchBase: classes.switchBase,*/}
            {/*        colorSecondary: classes.switchColorSecondary,*/}
            {/*        track: classes.track,*/}
            {/*        thumb: classes.thumb,*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}
            <ButtonStandard
              className={classes.createGroupButton}
              title="Create Group"
              onClick={this.submit}
            />
          </form>
        </Grid>
      </Grid>
    );
  }
}

CreateForm.propTypes = CreateFormPropTypes;


const mS = state => ({
  organization: state.organizationReducer.organization,

  validateKeyword: state.groupsReducer.validateKeyword,
  keywordError: state.groupsReducer.keywordError,
  keywordStatusRequest: state.groupsReducer.keywordStatusRequest,
  validationKeywordResponse: state.groupsReducer.validationKeywordResponse,
  error: state.groupsReducer.error,
  groups: state.groupsReducer.groups,
  groupSettings: state.groupsReducer.groupSettings,
  forward_reply_email: state.groupsReducer.forward_reply_email,
  forward_reply_sms_number: state.groupsReducer.forward_reply_sms_number,
  selectedGroup: state.groupsReducer.selectedGroup,
  pagination: state.groupsReducer.pagination,

  searchTerm: state.groupsFiltersReducer.searchTerm,
  filters: state.groupsFiltersReducer.filters,
  sort: state.groupsFiltersReducer.sort,

  role: state.userProfileReducer.main_role,
});

const mD = {
  updateGroupsReducer,
  enableRightDrawer,
  createGroupAsync,
  clearGroups,
  clearFormGroup,
  changeTab,
  updateSelectedGroup,
  initSelectedGroup,
  snackBarStatus,
  statusLoading,
  clearValidateKeyword,
  setValidateKeyword,
  setKeywordError,
  existsUrlShortener,
  setEnforceFocus,
  searchGroups,
};
export default withStyles(styles)(
  connect(
    mS,
    mD,
  )(CreateForm),
);
