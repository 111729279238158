import React, { Component } from 'react';
import queryString from 'query-string';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';

import TwitterCallbackPropType from '../../PropTypes/TwitterCallbackPropType';
import {
  saveTwitterAuthorizationToken,
  setTwitterAuthorizationProgressStatus,
} from '../../Actions/socialShareActions';

class TwitterCallback extends Component {
  UNSAFE_componentWillMount() {
    const params = queryString.parse(window.location.search);
    let prom = false;

    if (params.denied) {
      // it was rejected permissions
      prom = this.props.setTwitterAuthorizationProgressStatus({ oauth_token: params.denied }, 'reject-twitter-authorization');
    } else if (params.oauth_token && params.oauth_verifier) {
      prom = this.props.saveTwitterAuthorizationToken(params);
    } else {
      // the windows was closed
      prom = this.props.setTwitterAuthorizationProgressStatus(params, 'close-window');
    }

    prom.then(() => {
      window.close();
    });
  }
  render = () => <Grid item xs={12} />
}

TwitterCallback.propTypes = TwitterCallbackPropType;
const mS = state => ({
  socialShare: state.socialShareReducer,
});

const mD = {
  saveTwitterAuthorizationToken,
  setTwitterAuthorizationProgressStatus,
};

export default connect(mS, mD)(TwitterCallback);
