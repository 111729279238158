import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SimpleMessage from '../../Components/SimpleMessage';
import GalleryOptions from '../GalleryOptions';
import ConfirmSendMessage from '../../Components/ConfirmSendMessage';
import TemplateSelect from '../../Components/TemplateSelect';
import PropTypes from 'prop-types';
import {
  addImageInComposer,
  applySelectedTemplate,
  clearMessage,
  CONSTANTS,
  resetScheduledStatus,
  sendDirectMessage,
  sendDraftTest,
  sendMessage,
  updateMessageSettings,
  validateMessage,
  initDraftMessage,
  releaseMessageLockedMember,
} from '../../Actions/messageActions';

import { updateComposerState } from '../../Actions/composeActions';

import {
  closeCustomModalFromHandler,
  setCustomModalBackHandler,
  setCustomModalCloseHandler,
  updateCustomModalSize,
  updateCustomModalTitle
} from '../../Actions/customModalActions';
import MessagePreview from '../MessagePreview';
import ScheduleMessage from '../../Components/ScheduleMessage';
import TimeZonePicker from '../../Components/TimeZonePicker';
import { MESSAGE_COMPOSER_TYPE, MESSAGE_DESIGNER_TYPE } from '../../Config/Constants';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MessageSentNotification from '../../Components/MessageSentNotification';
import { navigationControllerRedirect } from '../../Actions/navigationControllerActions';
import { camelCaseObjects } from '../../Helpers/Utility';
import EvaButton from '../../Components/EvaButton';

import './index.sass';

export const MESSAGE_MODAL_MANAGER_VIEWS = {
  EDITOR: 0,
  CONFIRM: 1,
  GALLERY: 2,
  PREVIEW: 3,
  SENT: 4,
  SCHEDULE: 5,
  TEMPLATES: 6,
  TIMEZONE: 7,
  LOCKED_BY_MEMBER: 8,
}

class MessageModalManager extends Component {
  state = {
    isMessageSentSuccessful: false,
    isConfirmationDialogOpen: false,
  };

  componentDidMount() {
    const { view } = this.props;
    this.props.updateComposerState({ simple: true });
    // this.props.getOrganizationFacebookLinking(); // TODO: move to Routes.
    this.setState({ isCloseWithConfirm: view === MESSAGE_MODAL_MANAGER_VIEWS.PREVIEW });

    this.goTo(view);

    // Set close with confirmation modal for a Preview step.
    this.props.setCustomModalCloseHandler(this.handleClose);
  }

  componentWillUnmount() {
    const { location: { pathname } } = this.props;
    if (!pathname.includes('/messages/new') && pathname.includes('/messages/')) {
      this.props.updateComposerState({ selectedMessage: true });
    }
  }

  shouldComponentUpdate = (nextProps, nextState) =>
    (this.state.view !== nextState.view) ||
    (this.state.isConfirmationDialogOpen !== nextState.isConfirmationDialogOpen) ||
    (this.props.messageTimeZone !== nextProps.messageTimeZone) ||
    (this.props.isScheduled !== nextProps.isScheduled) ||
    (this.props.isSuccess !== nextProps.isSuccess) ||
    (this.props.isMessageSending !== nextProps.isMessageSending);

  goTo = view => this.setState({ view, isCloseWithConfirm: view === MESSAGE_MODAL_MANAGER_VIEWS.PREVIEW });


  handleAfterMessageSend = () => {
    const { location: { pathname } } = this.props;
    if (pathname.includes('/messages/new') || pathname.includes('/messages/edit/')) {
      this.props.navigationControllerRedirect('/messages');
    }
  }

  handleMessageSend = () => {
    let { recipientGroups, recipientType } = this.props;

    const isValid = this.props.validateMessage();

    if (!isValid) return;

    if (isValid) {
      const sendActionPromise =
        recipientType === CONSTANTS.RECIPIENT_TYPE_MEMBER ? this.props.sendDirectMessage(recipientGroups[0]) :
          this.props.sendMessage();

      sendActionPromise
        .then(response => {
          this.setState({ isMessageSentSuccessful: response.status === 200 });
          this.goTo(MESSAGE_MODAL_MANAGER_VIEWS.SENT);
          this.handleAfterMessageSend();
        })
        .catch(() => {
          this.goTo(MESSAGE_MODAL_MANAGER_VIEWS.SENT);
          this.handleAfterMessageSend();
        });
    }
  }

  handleTemplatePick = () => {
    const { messageContentType, messagePublicId, template: { publicId, messageContent: { type: tplContentType } } } = this.props;
    if (messageContentType === MESSAGE_COMPOSER_TYPE && tplContentType === MESSAGE_DESIGNER_TYPE) {
      const messageBaseUrl = messagePublicId ? `/messages/edit/${messagePublicId}` : `/messages/new`;
      this.props.navigationControllerRedirect(messageBaseUrl + `?fromTpl=${publicId}`);
    } else {
      this.props.applySelectedTemplate();
      this.goTo(MESSAGE_MODAL_MANAGER_VIEWS.EDITOR);
    }
  }

  handleDraftTestSend = () => {
    const isValid = this.props.validateMessage();
    if (isValid) {
      this.props.sendDraftTest();
    }
  }

  getViewContent = view => {
    const { isMessageSending, isResendUnopened, isScheduledMessage, isTemplate, messageTimeZone, orgTimeZone, lockedByMember } = this.props;
    const { isMessageSentSuccessful } = this.state;
    let content;
    let title = null;
    let backHandler = null;
    let size = 'default';

    switch (view) {
      // Composer
      case MESSAGE_MODAL_MANAGER_VIEWS.EDITOR:
        content = (
          <SimpleMessage
            onNextButtonClick={this.goTo.bind(this, MESSAGE_MODAL_MANAGER_VIEWS.PREVIEW)}
            onGalleryOpen={this.goTo.bind(this, MESSAGE_MODAL_MANAGER_VIEWS.GALLERY)}
            onTemplatesOpen={this.goTo.bind(this, MESSAGE_MODAL_MANAGER_VIEWS.TEMPLATES)}
          />
        );
        title = isTemplate ? 'Edit template' :
          (isResendUnopened ? 'Resend Message' : 'New Message');
        break;

      // Message Preview
      case MESSAGE_MODAL_MANAGER_VIEWS.PREVIEW:
        content = (
          <MessagePreview
            showButtons
            onSend={this.goTo.bind(this, MESSAGE_MODAL_MANAGER_VIEWS.CONFIRM)}
            onDraftTestSend={this.handleDraftTestSend}
          />
        );
        title = 'Message Preview';
        break

      // Image picker
      // TODO: fix other galleries handlers
      case MESSAGE_MODAL_MANAGER_VIEWS.GALLERY:
        content = (
          <GalleryOptions
            onImagePick={src => {
              this.props.addImageInComposer(src);
              this.goTo(MESSAGE_MODAL_MANAGER_VIEWS.EDITOR);
            }}
          />
        );
        title = 'Insert Image';
        backHandler = this.goTo.bind(this, MESSAGE_MODAL_MANAGER_VIEWS.EDITOR);
        break;

      // Templates picker
      case MESSAGE_MODAL_MANAGER_VIEWS.TEMPLATES:
        content = <TemplateSelect onTemplatePick={this.handleTemplatePick}/>;
        title = 'Select Template';
        backHandler = this.goTo.bind(this, MESSAGE_MODAL_MANAGER_VIEWS.EDITOR);
        break;

      // Schedule message
      case MESSAGE_MODAL_MANAGER_VIEWS.SCHEDULE:
        content = (
          <ScheduleMessage
            isLoading={isMessageSending}
            messageTimeZone={messageTimeZone}
            orgTimeZone={orgTimeZone}
            onChange={this.props.updateMessageSettings}
            onSendMessage={this.handleMessageSend}
            onTimezonePick={this.goTo.bind(this, MESSAGE_MODAL_MANAGER_VIEWS.TIMEZONE)}
          />
        );
        title = 'Schedule Message';
        backHandler = this.goTo.bind(this, MESSAGE_MODAL_MANAGER_VIEWS.CONFIRM);
        size = 'small';
        break;

      // Confirm and send
      case MESSAGE_MODAL_MANAGER_VIEWS.CONFIRM:
        content = (
          <ConfirmSendMessage
            onScheduleMessage={this.goTo.bind(this, MESSAGE_MODAL_MANAGER_VIEWS.SCHEDULE)}
            onSendMessage={this.handleMessageSend}
            onDraftTestSend={this.handleDraftTestSend}
          />
        );
        title = 'Confirm and Send';
        backHandler = this.goTo.bind(this, MESSAGE_MODAL_MANAGER_VIEWS.PREVIEW);
        size = 'small';

        this.props.resetScheduledStatus();
        break;

      // TimeZone picker
      case MESSAGE_MODAL_MANAGER_VIEWS.TIMEZONE:
        content = (
          <TimeZonePicker
            selected={messageTimeZone === 'rm' ? orgTimeZone : messageTimeZone}
            onTimeZoneSave={settings => {
              this.props.updateMessageSettings(settings);
              this.goTo(MESSAGE_MODAL_MANAGER_VIEWS.SCHEDULE);
            }}
          />
        );
        title = 'Select Timezone';
        backHandler = this.goTo.bind(this, MESSAGE_MODAL_MANAGER_VIEWS.SCHEDULE);
        size = 'small';
        break;

      // Sent message notification
      case MESSAGE_MODAL_MANAGER_VIEWS.SENT:
        content = (
          <MessageSentNotification
            isScheduled={isScheduledMessage}
            isSuccess={isMessageSentSuccessful}
            onNewMessageClick={() => {
              this.props.clearMessage();
              this.goTo(MESSAGE_MODAL_MANAGER_VIEWS.EDITOR);
            }}
          />
        );
        size = 'small';
        break;

        case MESSAGE_MODAL_MANAGER_VIEWS.LOCKED_BY_MEMBER:
          content = (
            <div className="locked-message">
              <p>{`${lockedByMember.first_name} ${lockedByMember.last_name} `} is editing this message.</p>
              <div className="button-actions">
                <EvaButton onClick={this.props.closeCustomModalFromHandler}>OK</EvaButton>
              </div>
            </div>
          );
          title = 'This message is locked';
          break;
    }

    this.props.updateCustomModalTitle(title);
    this.props.updateCustomModalSize(size);
    this.props.setCustomModalBackHandler(backHandler);

    return content;
  }

  handleClose = () => {
    const { messageContentType } = this.props;
    const { isCloseWithConfirm } = this.state;

    if (isCloseWithConfirm) {
      this.setState({ isConfirmationDialogOpen: true });
      return;
    }

    if (messageContentType === MESSAGE_COMPOSER_TYPE) {
      this.props.clearMessage();
    }
    this.props.closeCustomModalFromHandler();
    this.props.releaseMessageLockedMember();
  };

  handleModalCancelOption = () => {
    this.setState({ isConfirmationDialogOpen: false });
  };

  handleCloseModalAndReleaseLock = () => {
    this.props.closeCustomModalFromHandler();
    this.props.releaseMessageLockedMember();
  }

  renderDialog = () => (
    // TODO: unify
    <div>
      <Dialog
        open={this.state.isConfirmationDialogOpen}
        onClose={this.handleClose}
        className="return-to-edit"
        style={{
          backgroundColor: 'transparent',
          borderRadius: '15px',
          zIndex: '1300000',
        }}
      >
        <DialogContent className="return-to-edit-modal-message">
          <div className="attention-Settings">
            ATTENTION: If you return, the edits made on the channels will be discarded. Would you
            like to proceed?
          </div>
          <div className="modal-actions">
            <button onClick={this.handleCloseModalAndReleaseLock} className="button">
              Yes
            </button>
            <button onClick={this.handleModalCancelOption} className="button">
              No
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );

  render = () => (
    <React.Fragment>
      <section id="message-modal-manager">{this.getViewContent(this.state.view)}</section>
      {this.renderDialog()}
    </React.Fragment>
  );
}

MessageModalManager.propTypes = {
  view: PropTypes.oneOf(Object.values(MESSAGE_MODAL_MANAGER_VIEWS)).isRequired,
  onSend: PropTypes.func,
};

MessageModalManager.defaultProps = {
  onSend: () => {
  },
}

const mS = state => ({
  isMessageSending: state.messageReducer.isMessageSending,
  isResendUnopened: state.messageReducer.isResendUnopened,
  isTemplate: state.messageReducer.isTemplate,
  isScheduledMessage: state.messageReducer.messageSettings.isScheduledMessage,
  messageContentType: state.messageReducer.messageContent.type,
  messagePublicId: state.messageReducer.publicId,
  messageTimeZone: state.messageReducer.messageSettings.timeZone,
  recipientGroups: state.messageReducer.recipientGroups,
  recipientType: state.messageReducer.recipientType,
  template: state.templateReducer.template,
  orgTimeZone: state.organizationReducer.organization.time_zone,
  lockedByMember: state.messageReducer.lockedByMember,
  selectedMessage: state.messagesReducer.selectedMessage,
});

const mD = {
  addImageInComposer,
  applySelectedTemplate,
  clearMessage,
  closeCustomModalFromHandler,
  navigationControllerRedirect,
  resetScheduledStatus,
  sendMessage,
  sendDirectMessage,
  sendDraftTest,
  setCustomModalBackHandler,
  setCustomModalCloseHandler,
  validateMessage,
  updateComposerState,
  updateCustomModalSize,
  updateCustomModalTitle,
  updateMessageSettings,
  initDraftMessage,
  releaseMessageLockedMember,
};

// TODO: remove withRouter (?)
export default withRouter(connect(mS, mD)(MessageModalManager));
