import { ACTIONS } from '../Actions/completeProfileActions';

const initialState = {
  firstName: '',
  lastName: '',
  birthDate: '',
  phoneNumber: '',
  email: '',
  error: '',
  groupId: '',
  token: '',
  group: {},
  code: '',
  street: '',
  city: '',
  province: '',
  country: [],
  roles: [],
  countryPublicId: '',
  statePublicId: '',
  postalCode: '',
  loading: false,
  contactPreference: 'email',
};

const completeProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    case ACTIONS.UPDATE_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case ACTIONS.GET:
      return {
        ...state,
        firstName: action.payload.member.first_name || '',
        lastName: action.payload.member.last_name || '',
        birthDate: action.payload.member.birth_date,
        phoneNumber: action.payload.member.main_phone
          ? action.payload.member.main_phone.phone_number
          : '',
        email: action.payload.member.user.email,
        member: action.payload.member,
        group: action.payload.group,
        groupId: action.payload.group.public_id,
        street: action.payload.member.reference_address,
        city: action.payload.member.city_text,
        countryPublicId: action.payload.member.countryPublicId,
        statePublicId: action.payload.member.statePublicId,
        // province: action.payload.member.state_text, // TODO: remove
        // stateIsoCode: action.payload.member.state_iso_code, // TODO: remove
        postalCode: action.payload.member.postal_code,
        profilePictureUrl: action.payload.member.profile_picture_url,
        error: action.payload.error,
        token: action.payload.token,
        code: action.payload.code,
        isLiteMode: action.payload.group.organization.lite,
      };
    case ACTIONS.LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default completeProfileReducer;
