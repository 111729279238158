import PropTypes from 'prop-types';

const TopBarPropTypes = {
  cancel: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  edit: PropTypes.bool,
  group: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCancelClick: PropTypes.func,
  onEditClick: PropTypes.func,
  subject: PropTypes.string.isRequired,
};

export default TopBarPropTypes;
