import { ACTIONS } from '../Actions/membersFiltersActions';

const initialState = {
  isFiltersOpen: false,
  filters: {
    status: null,
  },
  searchTerm: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SEARCH_TERM_CHANGE:
      return { ...state, searchTerm: action.payload };

    case ACTIONS.TOGGLE:
      return { ...state, isFiltersOpen: action.payload };

    case ACTIONS.CHANGE:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        }
      };

    case ACTIONS.RESET:
      return { ...initialState };

    default:
      return state;
  }
};
