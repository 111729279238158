import AXIOS from '../Config/AxiosConfig';
import headers from './Helper';

const Location = {
  getCountries() {
    return AXIOS.get('/public/countries', { headers: headers() });
  },
  getStates(publicId) {
    return AXIOS.get(`public/states/${publicId}`, { headers: headers() });
  },
};

export default Location;
