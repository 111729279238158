import AXIOS from '../Config/AxiosConfig';
import headers from './Helper';

const URL_BASE = 'v2/eva';

const Files = {
  upload(file, options) {
    const data = new window.FormData();
    data.append('file', file);

    return AXIOS.post(`${URL_BASE}/upload-file`, data, {
      headers: headers(),
      ...options,
    });
  },

  remove(id, options) {
    return AXIOS.delete(`${URL_BASE}/remove-file/${id}`, {
      headers: headers(),
      ...options,
    });
  },

  download(id) {
    return AXIOS.get(`${URL_BASE}/download-file/${id}`, {
      headers: headers(),
      ...options,
    });
  },
};

export default Files;
