// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../Theme/Icons/remove-icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".form-message{margin:0 32px 35px;padding:25px 50px 25px 50px;border-radius:10px;position:relative}.form-message .form-text{font-family:\"GothamSSm-Book\",Arial,sans-serif;color:#d4edda;font-size:16px;font-weight:normal;font-style:normal;font-stretch:normal;line-height:normal;letter-spacing:normal;margin:0}.form-message .form-close{background:none;border:none;position:absolute;top:26px;right:10px;cursor:pointer;font-size:13px;font-weight:bolder}.form-message .form-close:focus{outline:0}.success{background-color:#efe3f7}.success .form-text{color:#9a66bf}.success .form-close{color:#9a66bf}.warning{background-color:#efe3f7}.warning .form-text{color:#9a66bf}.warning .form-close{color:#9a66bf}.danger{background-color:#f1cfcf}.danger .form-text{color:#ea5858}.danger .form-text span{color:#ea5858}.danger .form-close{color:#a94442;margin-top:3px}.danger .form-close .close-icon{background-color:#ea5858;mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;-webkit-mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;padding:0px 7px}.info{background-color:#efe3f7}.info .form-text{color:#9a66bf}.info .form-text span{color:#9a66bf;font-weight:bold}.info .form-close{color:#9a66bf;margin-top:3px}.info .form-close .close-icon{background-color:#9a66bf;mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;-webkit-mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;padding:0px 7px;height:15px;display:block}\n", ""]);
// Exports
module.exports = exports;
