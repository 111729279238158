import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import AvatarEditor from 'react-avatar-editor';
import Slider from '@material-ui/core/Slider';
import EditMember from '../EditMember';
import { enableRightDrawer } from '../../Actions/rightDrawerShortActions';
import EditPicturePropType from '../../PropTypes/EditPicturePropType';
import {
  updatePictureCompleteProfile,
  updateMember,
  setMemberError,
} from '../../Actions/memberActions';
import { getUserProfile } from '../../Actions/userActions';
import { getOrgAsync } from '../../Actions/groupsActions';
import { statusLoading } from '../../Actions/loadingActions';
import './index.sass';
import { forceReload } from '../../Actions/AvatarActions';

class EditPicture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      disable: false,
      value: 50,
    };
  }

  onDrop = file => {
    this.setState({ file: file[0], disable: true });
  };

  setEditorRef = editor => {
    this.editor = editor;
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };
  clearErrorMessage = () => {
    this.props.setMemberError({ payload: { error: '' } });
  };
  cancel = () => {
    this.setState({ file: null, disable: false });
    if (this.props.changeDisplay) {
      this.props.changeDisplay({ displayPicture: false });
    }
    if (!window.location.pathname === '/invited-complete-profile') {
      this.props.enableRightDrawer({
        payload: {
          enable: true,
          element: <EditMember/>,
          title: '',
        },
      });
    }
  };

  submit = () => {
    const { selectedGroup, selectedMember, member, organization, afterUpdate } = this.props;
    const orgId = organization && organization.public_id;
    let payload = {};
    if (this.props.changeDisplay) {
      payload = { member };
    } else {
      payload = { member: selectedMember };
    }
    if (this.editor) {
      const canvas = this.editor.getImageScaledToCanvas();
      this.props.statusLoading(true);
      this.props
        .updatePictureCompleteProfile({
          ...payload,
          img: canvas.toDataURL('image/png'),
        })
        .then(async response => {
          if (response) {
            if (typeof afterUpdate === 'function') {
              afterUpdate();
            }
            if (!window.location.pathname === '/invited-complete-profile') {
              this.props.enableRightDrawer({ payload: { enable: false } });
            }
            if (this.props.changeDisplay) {
              this.props.changeDisplay({ displayPicture: false });
              await this.props.getUserProfile(orgId);
            }
            // this.props.forceReload(true);
            this.props.statusLoading(false);
          }
        })
        .then(() => {
          if (!window.location.pathname === '/groups') {
            this.props.getUserProfile(orgId);
            this.props.statusLoading(false);
          }
        })
        .catch(err => {
          this.props.setMemberError(err.message);
          this.props.statusLoading(false);
        });
    }
  };

  render() {
    const { file, disable, value } = this.state;
    return (
      <div className="edit-picture">
        <div className="crop-zone">
          <Dropzone
            className="crop-zone"
            accept="image/jpeg, image/png"
            onDrop={this.onDrop}
            disabled={disable}s
          >
            {!file && (
              <div className="drop-info-container">
                <div className="drop-info">
                  <span>Drop image file here or click to upload.</span>
                </div>

                <div className="crop-image">
                  <div className="upload-label">
                    <label
                      disabled="disabled"
                      className="upload-fake-button"
                      htmlFor="input-picture"
                    >
                      Upload an image
                    </label>
                    <input
                      disabled="disabled"
                      id="input-picture"
                      className="input-button"
                      type="file"
                    />
                  </div>
                </div>
              </div>
            )}

            {file && (
              <React.Fragment>
                <AvatarEditor
                  image={file}
                  width={300}
                  ref={this.setEditorRef}
                  scale={(value * 2) / 100}
                  height={300}
                  borderRadius={350}
                />
                <Slider value={value} onChange={this.handleChange}/>
              </React.Fragment>
            )}
          </Dropzone>
        </div>
        {file && (
          <div className="cancel-container">
            <button className="button-filled" onClick={this.submit}>
              Crop and accept
            </button>
          </div>
        )}
        <div className="cancel-container">
          <button onClick={this.cancel} className="cancel-button">
            Cancel
          </button>
        </div>
      </div>
    );
  }
}

EditPicture.propTypes = EditPicturePropType;

const mS = ({
              memberReducer: { error, selectedMember },
              groupsReducer: {
                selectedGroup,
                globalGroups: { myGroups },
              },
              organizationReducer: { organization },
              rightDrawerShortReducer: { afterUpdate },
            }) => ({ error, selectedMember, selectedGroup, myGroups, organization, afterUpdate });

const mD = {
  updatePictureCompleteProfile,
  updateMember,
  setMemberError,
  enableRightDrawer,
  getOrgAsync,
  getUserProfile,
  statusLoading,
  forceReload,
};

export default connect(
  mS,
  mD,
)(EditPicture);
