import React, { Component } from 'react';
import Switch from '@material-ui/core/Switch';
import { switchStyles } from '../../Theme/switchStyles';
import { withStyles } from '@material-ui/core/styles';
import MessageImagePicker from '../MessageImagePicker';
import './index.sass';
import MessagePreviewToolbarPropType from '../../PropTypes/MessagePreviewToolbarPropType';

const styles = theme => ({
  ...switchStyles,
});


class MessagePreviewToolbar extends Component {
  componentDidMount() {
  }

  handleChange(value) {

  }

  render() {
    const { classes, text, enabled, previewImages, selectedPreviewImage, isPreventChange, children, imagePicker,
      onChange, onImageSelect } = this.props;
    return (
      <div className="message-preview-toolbar">
        <div className="switch-wrapper">
          <Switch
            onChange={() => onChange(!enabled)}
            classes={{
              switchBase: classes.switchBase,
              colorSecondary: classes.switchColorSecondary,
              track: classes.track,
              thumb: classes.thumb,
            }}
            checked={enabled}
            disabled={isPreventChange}
          />
          <div className="switch-label">{text}</div>
        </div>
        <div className="side-bar">
          {children && (
            <div className="children-content">
              {children}
            </div>
          )}
          {imagePicker && (
            <MessageImagePicker
              selectedPreviewImage={selectedPreviewImage}
              previewImages={previewImages}
              onSelect={onImageSelect}
            />
          )}
        </div>
      </div>
    );
  }
}
MessagePreviewToolbar.propTypes = MessagePreviewToolbarPropType;

MessagePreviewToolbar.defaultProps = {
  previewImages: [],
  imagePicker: false,
  isPreventChange: false,
  selectedPreviewImage: null,
  onImageSelect: () => {},
  children: null,
};


export default withStyles(styles)(MessagePreviewToolbar);
