import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../Avatar';
import './index.sass';
import Checkbox from '@material-ui/core/Checkbox';

const FacebookPagesList = ({ pages, editMode, disabled, selectedPublicIds, onChange }) => (
  <ul className={`facebook-pages-list ${editMode ? 'edit-mode' : ''}`}>
    {pages.map(page => (
      <li>
        <div className="page-item-wrapper">
          <div className="fb-username">
            <Avatar
              borderless
              preventForceReload
              className="fb-page-picture"
              image={page.pagePicture}
            />
            {page.username}
          </div>
          <div className="fb-page">Posting on <strong>{page.pageName}</strong></div>
        </div>

        <Checkbox
          className="page-check"
          checked={selectedPublicIds.includes(page.publicId)}
          value="checked"
          color="default"
          disabled={disabled}
          onChange={() => onChange(page.publicId)}
        />

      </li>
    ))}
  </ul>
);

FacebookPagesList.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.object).isRequired,
  editMore: PropTypes.bool,
  selectedPublicIds: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

FacebookPagesList.defaultProps = {
  interactive: false,
  selectedPublicIds: [],
  disabled: false,
  onChange: () => {
  },
};

export default FacebookPagesList;
