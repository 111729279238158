import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import TextareaAutosize from 'react-textarea-autosize';
import moment from 'moment';
import closeIcon from '../../Theme/Icons/quick-action.svg';
import favoriteIcon from '../../Theme/Icons/favorite.svg';
import commentIcon from '../../Theme/Icons/chat_bubble_twitter.svg';
import emailIcon from '../../Theme/Icons/email_twitter.svg';
import retweetIcon from '../../Theme/Icons/repeat.svg';
import arrowIcon from '../../Theme/Icons/arrow_down.svg';
import Helpers from '../../Helpers';
import MessageTwitterFbPreviewPropType from '../../PropTypes/MessageTwitterFbPreviewPropType';
import MessagePreviewToolbar from '../MessagePreviewToolbar';
import './index.sass';
import PropTypes from 'prop-types';

class MessageTwitterPreview extends Component {
  state = {
    html: '',
    image: '',
  };

  componentDidMount() {
    this.setState({ html: this.props.content, image: this.props.image });
  }

  render() {
    const { allowed, image, enabled, profile, previewImages, selectedPreviewImage, onChange, onSendByChange, onPreviewChange } = this.props;
    return (
      <div className="send-twitter-container">
        {allowed && profile ? (
          <div>
            <MessagePreviewToolbar
              imagePicker
              text="Send to Twitter"
              enabled={enabled}
              previewImages={previewImages}
              selectedPreviewImage={selectedPreviewImage}
              onImageSelect={onPreviewChange}
              onChange={onSendByChange}
            />
            <div className="message-container">
              <div className="twitter-content" id="scroll">
                <div className={`twitter-message ${enabled ? 'show' : 'blur'}`}>
                  <Grid item xs={1} className="member-message-twitter">
                    <img
                      src={profile.picture}
                      alt={profile.name}
                    />
                  </Grid>
                  <div className="twitter-body">
                    <div className="twitter-header">
                      <p className="twitter-name">{profile.name}</p>
                      <div className="twitter-lower-header">
                        <p className="twitter-handle">@{profile.screenName}</p>
                        <p className="twitter-date"> · {moment().format('MMM. D')} </p>
                      </div>
                      <img src={arrowIcon} className="twitter-arrow" alt="arrow_down"/>
                    </div>
                    <TextareaAutosize
                      className="twitter-body-text"
                      disabled={!enabled}
                      defaultValue={this.state.html}
                      onChange={(e) => onChange({text: e.target.value})}
                      onPaste={Helpers.handleOnlyTextPreviewPaste}
                    />
                    {image && image !== '' && (
                      <div className="twitter-images">
                        <img
                          src={image}
                          alt="gallery"
                          className="gallery-image"
                        />
                        {enabled && (
                          <Tooltip title="Remove image" placement="right">
                            <button
                              className="twitter-remove"
                              onClick={() => onChange({ image: '' })}
                              onKeyPress={() => {
                              }}
                            >
                              <img src={closeIcon} alt="twitter-remove"/>
                            </button>
                          </Tooltip>
                        )}
                      </div>
                    )}
                    <div className="twitter-buttons">
                      <img src={commentIcon} alt="comment"/>
                      <img src={retweetIcon} alt="retweet"/>
                      <img src={favoriteIcon} alt="favorite"/>
                      <img src={emailIcon} alt="email "/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="not-available">
            <p>
              Posting to X (formerly Twitter) is not currently enabled for this group(s).
              <br/>
              Please check the group(s) settings. Be sure to save this message as a draft to resume
              sending it at a later time.
            </p>
          </div>
        )}
      </div>
    );
  }
}

MessageTwitterPreview.defaultProps = {
  image: null,
};

MessageTwitterPreview.propTypes = MessageTwitterFbPreviewPropType;

export default MessageTwitterPreview;
