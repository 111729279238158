import React, { Component } from 'react';
import ImageGalleryListPropType from '../../PropTypes/imageGalleryListProptype';
import './index.sass';
import EvaTooltip from '../EvaTooltip';

class ImageGalleryList extends Component {
  renderImage = key => {
    const { images, handleCheck } = this.props;
    const image = images[key];
    const newsource = image.source.replace(/ /g, '%20');

    return (
      <button
        key={key}
        className={`item-button ${image.check ? 'active' : ''}`}
        onKeyPress={() => handleCheck(image.publicId, key, newsource)}
        onClick={() => handleCheck(image.publicId, key, newsource)}
      >
        <EvaTooltip title={image.name}>
          <div
            className="image-prev"
            style={{ backgroundImage: `url(${newsource})` }}
            alt={image.name}
          />
        </EvaTooltip>
        <div className={`check-container`} role="button" tabIndex="0" />
      </button>
    );
  };

  render() {
    const { images } = this.props;
    return (
      <div className="image-gallery-list-container" id="scroll">
        {Object.keys(images).map(this.renderImage)}
      </ div>
    );
  }
}

ImageGalleryList.propTypes = ImageGalleryListPropType;

export default ImageGalleryList;
