import { ACTIONS } from '../Actions/navigationControllerActions';

const initialState = {
  redirect: false,
  goToURL: '',
};

const navigationControllerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.REDIRECT:
      return { ...state, redirect: true, goToURL: action.payload };

    case ACTIONS.STOP:
      return { ...state, redirect: action.payload.flag, goToURL: action.payload.url };

    default:
      return state;
  }
};

export default navigationControllerReducer;
