const getOrgFromList = (arr) => {
  let selectedOrg = null;
  if (localStorage.selectedOrgId) {
    selectedOrg = arr.find(org => org.organization.id === Number(localStorage.selectedOrgId));
  }
  return selectedOrg ? selectedOrg : arr[0];
};

export const getFirstAvailableOrg = orgs => {
  let selectedOrg = null;

  const activeOrgs = [];
  const suspendedOrgs = [];
  const inactiveOrgs = [];

  orgs.forEach(r => {
    switch (r.organization.status) {
      case 'active':
        activeOrgs.push(r);
        break;
      case 'inactive':
        inactiveOrgs.push(r);
        break;
      case 'suspended':
        suspendedOrgs.push(r);
        break;
    }
  });
  // const isNoOrgs = (activeOrgs.length && suspendedOrgs.length + inactiveOrgs.length) === 0;
  // const isOnlyInactiveOrgs = !isNoOrgs && (activeOrgs.length && suspendedOrgs.length === 0);
  const allowedAccessOrgs = activeOrgs.concat(suspendedOrgs);
  const selectedOrgIdFromLs = parseInt(localStorage.getItem('selectedOrgId'), 10);
  const previouslySelectedOrg = allowedAccessOrgs.find(o => o.organization.id === selectedOrgIdFromLs);
  if (previouslySelectedOrg) {
    selectedOrg = previouslySelectedOrg;
  } else if (activeOrgs.length > 0) {
    selectedOrg = getOrgFromList(activeOrgs);
  } else if (suspendedOrgs.length > 0) {
    selectedOrg = getOrgFromList(suspendedOrgs);
  }
  return selectedOrg ? selectedOrg.organization : null;
};
