/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';
import CreateStore from './Store/Store';
import Routes from './Config/Routes';
import registerServiceWorker from './registerServiceWorker';
import { createGenerateClassName, StylesProvider } from '@material-ui/core/styles';
import './index.css';

const store = CreateStore();
const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'diocesan-',
});

// Enable GA for prod only
if (process.env.APP_CONFIG === 'prod') {
  ReactGA.initialize('UA-168426677-1');
  ReactGA.pageview(window.location.pathname + window.location.search);
}

ReactDOM.render(
  <StylesProvider generateClassName={generateClassName}>
    <Provider store={store}>
      <Routes />
    </Provider>
  </StylesProvider>,
  document.getElementById('root'),
);
registerServiceWorker();
