import posed from 'react-pose';

const AnimatedImg = posed.img({
  visible: {
    scale: 1,
    transition: {
      type: 'spring',
      stiffness: 200,
      damping: 10,
      duration: 50,
    },
  },
  hidden: {
    scale: 0,
  },
});

export default AnimatedImg;
