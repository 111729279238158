import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Checkbox from '@material-ui/core/Checkbox';
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment';
// Components
import MemberFound from '../../Components/AddMembersTabs/InviteNewSteps/MemberFound';
import RegisterMember from '../../Components/AddMembersTabs/InviteNewSteps/RegisterMember';
import SearchMember from '../../Components/AddMembersTabs/InviteNewSteps/SearchMember';
import EvaButton from '../../Components/EvaButton';
import TabContainer from './TabContainer';
import AddedMembers from '../../Components/AddMembersTabs/AddedMembers';
import CsvImport from '../../Components/CsvImport';
import MemberAdd from '../../Components/MemberAdd';
import MemberList from '../../Components/MemberList';
// Action Creators
import {
  clear,
  create,
  removeInviteDestinationGroup,
  searchMemberAction,
  submitMembersActions,
  update,
  updateInviteDestinationGroups,
} from '../../Actions/groupInviteMemberActions';
import snackBarStatus from '../../Actions/snackbarActions';
import { updateAutocompleteMenu, updateRecipientGroups, } from '../../Actions/messageActions';
import { getGroupsDictionary } from '../../Actions/groupsDictionaryActions';
import {
  changeRenderView,
  clearSelectedMembers,
  getGroupMembers,
  updateImportMessage,
} from '../../Actions/membersActions';
import { changeTab } from '../../Actions/middleMenuActions';
import { getGroupsActivity, selectGroup } from '../../Actions/groupsActions';
import CustomMessage from '../../Components/CustomMessage';
import Helpers from '../../Helpers';
import { statusLoading } from '../../Actions/loadingActions';
import './index.sass';
import GroupsPicker from '../../Components/GroupsPicker';
import PermissionsConfirmation from '../../Components/PermissionsConfirmation';

const styles = () => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#FAFAFA',
    width: '100%',
    boder: 'none',
    padding: 'unset',
    marginTop: '30px',
  },
  wrapper: {},
  tabsIndicator: {
    background: '#9a66bf',
    height: 2,
  },
  customTabsIndicator: {
    background: '#9a66bf',
    height: 2,
  },
  twoTabs: {
    maxWidth: 'none',
    width: '50%',
  },
  tabRoot: {
    fontFamily: 'GothamSSm-Bold',
    fontSize: 16,
    textTransform: 'initial',
  },
  tabsRoot: {
    textTransform: 'initial',
    minWidth: 142,
    backgroundColor: '#FAFAFA',
    fontFamily: 'GothamSSm-Book',
    color: '#a9a9a9',
    '&:focus': {
      color: '#9a66bf',
    },
  },
  tabSelected: { color: '#9a66bf' },
  title: {
    fontFamily: 'GothamSSm-Bold',
    color: '#4a4a4a',
    fontSize: '20px',
  },
});

// TODO: refactoring
const stepComponents = [SearchMember, RegisterMember, MemberFound];

const SEARCH_MEMBER_STEP = 0;
const REGISTER_MEMBER_STEP = 1;
const MEMBER_FOUND_STEP = 2;

class GroupInviteMembers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
    };
    this.addMemberWrapperRef = React.createRef();
  }

  UNSAFE_componentWillMount() {
    this.props.getGroupsDictionary();
    const { inviteDestinationGroups } = this.props;
    if (inviteDestinationGroups.length > 0) {
      this.props.updateImportMessage({
        show: false,
        type: 'info',
        message: '',
      });
    }
  }

  componentWillUnmount() {
    this.props.clearSelectedMembers();
    this.props.clear();
  }

  addMemberWrapperRef = null;

  nextStep = (...params) => {
    switch (this.props.currentStep) {
      case SEARCH_MEMBER_STEP:
        this.searchMemberHandler(...params);
        break;

      case REGISTER_MEMBER_STEP:
        this.registerMemberHandler(...params);
        break;

      case MEMBER_FOUND_STEP:
        this.memberFoundHandler(...params);
        break;
      default:
    }
  };

  searchMemberHandler = (phone, email) => {
    const { members } = this.props;

    if ((email && Helpers.EmailSimple(email)) || phone) {
      const multipleEmail = members.find(member => member.email === email);
      if (email !== '' && multipleEmail !== undefined && 'email' in multipleEmail) {
        this.props.snackBarStatus({
          payload: {
            enable: true,
            title: 'The email is already added, please try another',
            type: 'error',
          },
        });
      } else {
        this.props.searchMemberAction({ phone, email });
      }
    } else if (!email && !phone) {
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: 'Phone or Email Required',
          type: 'error',
        },
      });
    }
  };

  registerMemberHandler = memberInfo => {
    const { members } = this.props;
    if (
      memberInfo &&
      Object.values(memberInfo).some(key => {
        if (key === 'email') {
          return memberInfo[key] !== '';
        } else if (key === 'phone') {
          return memberInfo[key] !== '' && memberInfo[key] > 7;
        }
        return true;
      })
    ) {
      this.props.update({
        members: [...members, memberInfo],
        currentStep: SEARCH_MEMBER_STEP,
        steps: [{ phone: '', email: '' }, {}, {}],
      });
    } else {
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: 'Invalid fields',
          type: 'error',
        },
      });
    }
  };

  memberFoundHandler = membersFound => {
    const { selectedMembers, members } = this.props;
    this.props.update({
      members: [
        ...members,
        ...membersFound.filter(member => selectedMembers.includes(member.publicId)),
      ],
      currentStep: SEARCH_MEMBER_STEP,
      steps: [{ phone: '', email: '' }, {}, {}],
    });
  };

  handleTabChange = (_, value) => {
    this.setState({ tabValue: value });
    this.props.clearSelectedMembers();
  };

  handleInputChange(index, { target }) {
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    const updatedStep = this.props.steps;
    updatedStep[index] = { ...updatedStep[index], [name]: value };
    this.props.update({ steps: updatedStep });
    this.forceUpdate();
  }

  handleBirthday = date => {
    const { steps } = this.props;
    const birthday = moment(date.valueText).format('YYYY-MM-DD');
    steps[REGISTER_MEMBER_STEP].birthday = birthday;
    this.props.update({ steps });
    this.forceUpdate();
  };

  handleRemoveCandidate = candidateIndex => {
    const { members } = this.props;
    const candidateList = members
      .slice(0, candidateIndex)
      .concat(members.slice(candidateIndex + 1));
    this.props.update({ members: candidateList });
  };

  // This method using for MemberFound component:
  // to return user back to the SEARCH step if selecting was cancelled
  handleCancel = () => {
    this.props.update({
      currentStep: 0,
      steps: [{ phone: '', email: '' }, {}, {}],
    });
  };

  /* eslint-disable */
  submitCandidates = (membersFound = undefined) => {
    const {
      submitting,
      members: membersQueue,
      permissionToAdd: permissionToAddNormal,
      selectedGroup,
      organizationPublicId,
      role,
      matchPermissionToAdd,
      afterMembersAction,
      inviteDestinationGroups,
    } = this.props;
    const useMatchFound = Boolean(matchPermissionToAdd && membersFound);
    // Permission to add should be granted for members
    const permissionToAdd = useMatchFound || permissionToAddNormal;
    // Members found have priority over the members to be queued.
    const members = membersFound.length > 0 ? membersFound : membersQueue;
    if (!submitting && permissionToAdd) {
      this.props.statusLoading(true);
      this.props.submitMembersActions(inviteDestinationGroups, members)
        .then(res => {
          console.log('res 2 ', res);
          if (res.data.status === 200) {
            this.props.selectGroup(selectedGroup);
            this.props.getGroupMembers(selectedGroup.public_id, '', false, 1);
            this.props.changeTab(<MemberList />, 1);
            this.props.getGroupsActivity(organizationPublicId, role);
          }
        })
        .finally(() => this.props.statusLoading(false));
    }
    this.props.update({
      members: [],
      currentStep: SEARCH_MEMBER_STEP,
      steps: [{ phone: '', email: '' }, {}, {}],
    });
    if (typeof afterMembersAction === 'function') {
      afterMembersAction();
    }
  };

  close = () => {
    this.props.updateImportMessage({
      show: false,
      message: '',
    });
  };

  handleChangeIndex = tabValue => {
    this.setState({ tabValue });
  };

  renderInviteNewTab = () => {
    const { currentStep, members, steps, permissionToAdd, isLiteMode } = this.props;
    const CurrentComponent = stepComponents[currentStep];
    return (
      <TabContainer dir="x" key="addMember">
        {steps && (
          <CurrentComponent
            nextStep={this.nextStep}
            isLiteMode={isLiteMode}
            stepValues={steps[currentStep]}
            inputChange={this.handleInputChange.bind(this, currentStep)}
            handleBirthday={this.handleBirthday}
            handleCancel={this.handleCancel}
            returnToFirstStep={this.handleInputChange.bind(this, currentStep)}
          />
        )}
        {this.state.tabValue === SEARCH_MEMBER_STEP &&
          currentStep !== MEMBER_FOUND_STEP &&
          members.length > 0 && (
            <React.Fragment>
              <AddedMembers members={members} removeCandidate={this.handleRemoveCandidate} />
              <PermissionsConfirmation isChecked={permissionToAdd}
                                       onChange={bool => this.props.update({ permissionToAdd: bool })} />

              <div style={{ textAlign: 'center' }}>
                <EvaButton onClick={this.submitCandidates} active={permissionToAdd}>
                  <span
                    style={{
                      color: permissionToAdd ? '#9a66bf' : '#a9a9a9',
                    }}
                  >
                    Add members
                  </span>
                </EvaButton>
              </div>
            </React.Fragment>
          )}
      </TabContainer>
    );
  };

  render() {
    const { classes, customMessage, inviteDestinationGroups, groupsDictionaryList } = this.props;
    const WindowHeight = `calc(100vh - 270px)`;
    const inviteNewTab = this.renderInviteNewTab();
    const browseExistingTab = (
      <TabContainer dir="x-reverse" key="browseExisting">
        <MemberAdd />
      </TabContainer>
    );
    const csvImportTab = (
      <TabContainer dir="x-reverse" key="csvImport">
        <CsvImport wrapperRef={this.addMemberWrapperRef} />
      </TabContainer>
    );

    const isAllMemberGroupSelected = inviteDestinationGroups
      .some(id => groupsDictionaryList.find(g => g.publicId === id && g.organizationGeneralGroup));

    return (
      <Scrollbars autoHeight autoHide autoHeightMin={WindowHeight} autoHeightMax={WindowHeight}>
        <section className="group-invite-members" ref={this.addMemberWrapperRef}>
          {customMessage.show && (
            <div className="members-custom-message">
              <CustomMessage
                hideMessage={this.close}
                type={customMessage.type}
                title={customMessage.title}
                message={customMessage.message}
              />
            </div>
          )}
          <GroupsPicker
            dictionary={groupsDictionaryList}
            values={inviteDestinationGroups}
            onUpdate={this.props.updateInviteDestinationGroups}
          />
          <div className={classes.root}>
            <Tabs
              id="tabs-invite-member"
              value={this.state.tabValue}
              onChange={this.handleTabChange}
              classes={{
                root: classes.tabsRoot,
                indicator:
                  this.state.tabValue !== 2 ? classes.tabsIndicator : classes.customTabsIndicator,
              }}
            >
              <Tab
                classes={{
                  wrapper: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
                className={isAllMemberGroupSelected ? classes.twoTabs : ''}
                label="Invite new"
              />
              {!isAllMemberGroupSelected && (
                <Tab
                  classes={{
                    wrapper: classes.tabRoot,
                    selected: classes.tabSelected,
                  }}
                  label="Browse existing"
                />
              )}
              <Tab
                classes={{
                  wrapper: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
                className={`import-tab ${isAllMemberGroupSelected ? classes.twoTabs : ''}`}
                label="Import"
              />
            </Tabs>

            <SwipeableViews
              axis="x"
              index={this.state.tabValue}
              onChangeIndex={this.handleChangeIndex}
              style={{ marginTop: '36px' }}
            >
              {!isAllMemberGroupSelected
                ? [inviteNewTab, browseExistingTab, csvImportTab]
                : [inviteNewTab, csvImportTab]}
            </SwipeableViews>
          </div>
        </section>
      </Scrollbars>
    );
    /* eslint-enable */
  }
}

const mS = state => ({
  selectedGroup: state.groupsReducer.selectedGroup,

  organizationPublicId: state.organizationReducer.organization.public_id,
  isLiteMode: state.organizationReducer.organization.lite,

  groupsDictionaryList: state.groupsDictionaryReducer.list,

  customMessage: state.membersReducer.customMessage,

  selectedGroups: state.groupInviteMemberReducer.groups,
  matchPermissionToAdd: state.groupInviteMemberReducer.matchPermissionToAdd,
  permissionToAdd: state.groupInviteMemberReducer.permissionToAdd,
  currentStep: state.groupInviteMemberReducer.currentStep,
  steps: state.groupInviteMemberReducer.steps,
  members: state.groupInviteMemberReducer.members,
  selectedMembers: state.groupInviteMemberReducer.selectedMembers,
  inviteDestinationGroups: state.groupInviteMemberReducer.groups,

  role: state.userProfileReducer.main_role,
});

const mD = {
  create,
  clear,
  removeInviteDestinationGroup,
  searchMemberAction,
  snackBarStatus,
  submitMembersActions,
  update,
  updateInviteDestinationGroups,
  updateAutocompleteMenu,
  updateRecipientGroups,
  changeTab,
  updateImportMessage,
  changeRenderView,
  selectGroup,
  getGroupsActivity,
  statusLoading,
  clearSelectedMembers,
  getGroupsDictionary,
  getGroupMembers,
};

export default withStyles(styles)(
  connect(
    mS,
    mD,
  )(GroupInviteMembers),
);
