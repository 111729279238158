import ButtonMessageSave from '../ButtonMessageSave';
import ButtonMessageSend from '../ButtonMessageSend';
import React from 'react';
import PropTypes from 'prop-types';
import './index.sass';

export const MessageActionsButtons = ({ isDraftSaveAllowed, onDraftSave, onDraftTestSend, onSend, onTemplateSave }) => (
  <div className="message-actions-buttons">
    <ButtonMessageSave
      isDraftSaveAllowed={isDraftSaveAllowed}
      onDraftSave={onDraftSave}
      onTemplateSave={onTemplateSave}
    />
    <ButtonMessageSend
      onSend={onSend}
      onDraftTestSend={onDraftTestSend}
    />
  </div>
);

MessageActionsButtons.propTypes = {
  onDraftSave: PropTypes.func.isRequired,
  onTemplateSave: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  onDraftTestSend: PropTypes.func.isRequired,
  isDraftSaveAllowed: PropTypes.bool.isRequired,
};
