import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { navigationControllerRedirect } from './../../Actions/navigationControllerActions';
import { redirectToLogin } from '../../Helpers/redirectToLogin';
import { getRootRouteByRole } from '../../Helpers/getRootRouteByRole';
import { isValuesFromArrayInArray } from '../../Helpers/isValuesFromArrayInArray';
import LoadingScreen from '../LoadingScreen';

class GuardedRoute extends Component {

  constructor(props) {
    super(props);
    this.state = { isDone: false }
  }

  componentDidMount() {
    const { resolver, token, roles, authRequired, permittedForRoles } = this.props;
    const isAllowedRole = permittedForRoles ? isValuesFromArrayInArray(permittedForRoles, roles) : true;

    // Not authenticated if auth required
    if (authRequired && !token) {
      redirectToLogin(this.props.location.pathname);
      return;
    }

    // Not allowed for current role
    if (!isAllowedRole) {
      global.window.location = getRootRouteByRole(roles);
      return;
    }

    // Allowed
    if (resolver) {
      resolver().then(() =>
        this.setState({isDone: true})
      );
    } else {
      this.setState({isDone: true});
    }
  }

  render() {
    const { component: Component, resolver, token, roles, authRequired, permittedForRoles, ...rest } = this.props;
    return this.state.isDone ? <Route component={Component} {...rest} /> : <LoadingScreen loadingText="Loading..." />;
  }

}

const mS = state => ({
  token: state.loginReducer.token,
  roles: state.loginReducer.roles,
});

const mD = { navigationControllerRedirect };

export default connect(
  mS,
  mD,
)(GuardedRoute);
