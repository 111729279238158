import React, { PureComponent } from 'react';
// Material UI Components
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
// Components
import EvaTooltip from '../EvaTooltip';
import SearchOrganization from './SearchOrganization';
import GenericMessage from '../GenericMessage';
// Action Creators
import { changeFormValue } from '../../Actions/templateActions';
// PropTypes
import TemplateSettingsFormPropType from '../../PropTypes/TemplateSettingsFormPropType';
// Styles
import colors from '../../Theme/Colors';
import './index.sass';
import LoaderOverlay from '../LoaderOverlay';
import { switchStyles } from '../../Theme/switchStyles';
import SharesList from '../SharesList';
import { mbscResponsiveOptions } from '../../Config/Constants/mobiscroll-props';
import { Select } from '@mobiscroll/react';

const styles = theme => ({
  colorBar: {},
  charExceeded: {
    color: '#ea5858 !important',
  },
  customDropdown: {
    '&>.custom-select-container>.right-icon': {
      position: 'relative',
      '&>.icon-dropdown': {
        borderColor: `${colors.grey} transparent transparent transparent`,
      },
    },
  },
  iconStyle: {
    position: 'absolute',
    top: 13,
    right: 0,
    display: 'inline-block',
    width: 24,
    height: 24,
  },
  colorChecked: {},
  ...switchStyles,
});

const renderInput = ({ input, type, placeholder, icon, focused,
                       meta: { touched, error, warning } }) => {
  const iconClass = icon ? `input-eva-${icon}-icon` : '';
  return (
    <div>
      <input
        required
        minLength="2"
        type={type}
        autoComplete="off"
        placeholder={placeholder}
        className={`${iconClass} ${focused} input-eva-border-bottom input-eva`}
        {...input}
      />
      <div className="error-message">
        {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
      </div>
    </div>
  );
};

const renderSelect = ({
  input,
  label,
  list,
  handleFeaturedSubFolder,
}) => {
  /* eslint-disable */
  const subFoldersList =
    list && list instanceof Array
      ? list.map(subFolder => {
          if (subFolder.isSystemFolder && subFolder.global) {
            // hide 'Featured' option as 'No sub-folder selected' option
            return { text: 'No sub-folder selected', value: subFolder.publicId };
          }
          return { text: subFolder.name, value: subFolder.publicId };
        })
      : [];
  /* eslint-enable */
  return (
    <Select
      data={subFoldersList}
      value={input.value}
      onChange={handleFeaturedSubFolder}
      responsive={mbscResponsiveOptions}
      placeholder={label}
      filterPlaceholderText="Search"
      buttons={[]}
    />
  );
};

const renderGroupDropdown = ({
  label,
  handleChange,
  group,
  selectedGroup,
}) => {
  const groupOptions = group && group instanceof Array ? group : [];
  return (
    <React.Fragment>
      <div id="dropdown-mobis" className="dropdown-mobis dropdown-confirm">
        <Select
          data={groupOptions.map(g => ({ value: g.public_id, text: g.name }))}
          value={selectedGroup}
          name="selected_groups"
          onChange={handleChange}
          responsive={mbscResponsiveOptions}
          filter
          placeholder={label}
          filterPlaceholderText="Search"
          buttons={['cancel']}
        />
      </div>
    </React.Fragment>
  );
};

const renderSwitch = ({ input, classes, featured }) => (
  <Switch
    className="feature-switch"
    {...input}
    classes={{
      switchBase: classes.switchBase,
      colorSecondary: classes.switchColorSecondary,
      track: classes.track,
      thumb: classes.thumb,
    }}
    checked={featured}
    value={(featured).toString()}
  />
);

const renderTextArea = ({ input, placeholder, classTextArea, charLim = 140, classes }) => (
  <React.Fragment>
    <textarea className={classTextArea} placeholder={placeholder} {...input} />
    <span
      className={`character-left ${charLim - input.value.length < 0 ? classes.charExceeded : ''}`}
    >
      {`${charLim - input.value.length} characters left`}
    </span>
  </React.Fragment>
);

const renderButton = ({ input, title, subtitle, buttonClass, onClick }) => (
  <div className={`button-eva-container${buttonClass ? ` ${buttonClass}` : ''}`}>
    <Button className="button-eva" type="submit" onClick={onClick} {...input}>
      {title}
      {subtitle ? <span className="button-subtitle">&nbsp;{subtitle}</span> : null}
    </Button>
  </div>
);

class TemplateSettingsForm extends PureComponent {

  handleSave = (e, isNavigateToView) => {
    e.preventDefault();
    this.props.onSubmit(isNavigateToView);
  }

  render = () => {
    const {
      classes,
      errors,
      success,
      templateFolder,
      handleFolderChange,
      handleTitleChange,
      handleChangeError,
      foldersList,
      handleFeaturedSubFolder,
      subFoldersList,
      templateSubFolder,
      checkCharacters,
      isSuperAdmin,
      handleShareTemplate,
      onRemoveShare,
      featured,
      handleFeaturedSwitch,
      handleChange,
      groups,
      loadingGroups,
      addShareEnabled,
      shares,
      selectedOrganization,
      selectedGroup,
      handleChangeOrg,
      shareAllOrg,
    } = this.props;
    const folderBelongsToUser = foldersList.some(folder => folder.publicId === templateFolder);
    const showFolderField = !templateFolder || folderBelongsToUser;

    const required = (value) => (value ? undefined : 'Field is required');
    const minLength = (min) => (value) =>
      (value && value.length < min ? `Must be ${min} characters or more` : undefined);
    const maxLength = (max) => (value) =>
      (value && value.length > max ? `Must be ${max} characters or less` : undefined);

    return (
      <form>
        <Grid container spacing={0} id="save-template">
          {errors && (
            <Grid item xs={12}>
              <GenericMessage
                type="danger"
                title="Error"
                message="Missing field values."
                hideMessage={() => handleChangeError(true)}
              />
            </Grid>
          )}
          {success && (
            <Grid item xs={12}>
              <GenericMessage
                type="success"
                title="Success"
                message="Template saved."
                hideMessage={() => handleChangeError(false)}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Field
              name="title"
              component={renderInput}
              type="text"
              placeholder="Template name *"
              validate={[required, minLength(2), maxLength(50)]}
              onChange={handleTitleChange}
            />
          </Grid>
          {
            showFolderField &&
            <Grid item xs={12} className="input-select-template folder">
              <Select
                data={foldersList.map(({ name, publicId}) => ({ text: name, value: publicId }))}
                value={templateFolder}
                onChange={handleFolderChange}
                name="templateFolder"
                responsive={mbscResponsiveOptions}
                placeholder="Select a folder"
                buttons={['cancel']}
              />
            </Grid>
          }
          {isSuperAdmin ? (
            <React.Fragment>
              <Grid className="make-feature" item xs={12}>
                <Grid container className="feature-container">
                  <Grid className="title-switch" item xs={5}>
                    <span>Make Featured</span>
                  </Grid>
                  <Grid className="switch-grid" item xs={7}>
                    <Field
                      name="featuredSwitch"
                      component={renderSwitch}
                      classes={classes}
                      featured={!!featured}
                      onChange={handleFeaturedSwitch}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {featured && (
                <Grid
                  item
                  xs={12}
                  className={`input-select-template right-select sub-folder-select ${classes.customDropdown}`}
                >
                  <Field
                    name="templateSubFolder"
                    component={renderSelect}
                    propsName="selectSubFolder"
                    label="Select a sub-folder"
                    value={templateSubFolder}
                    list={subFoldersList}
                    handleFeaturedSubFolder={handleFeaturedSubFolder}
                  />
                </Grid>
              )}
            </React.Fragment>
          ) : null}
          <Grid item xs={12} className="options-title">
            Share template
          </Grid>
          {!shareAllOrg && (
            <React.Fragment>
              <Grid item xs={12} className="input-select-template share-select">
                <Field
                  name="selected_organizations"
                  component={SearchOrganization}
                  propsName="selectOrg"
                  propsId="select-org"
                  label="Select an Org"
                  onChange={handleChangeOrg}
                  selectedOrganization={selectedOrganization}
                />
              </Grid>

              <Grid item xs={12} className="input-select-template right-select sub-folder-select">
                {loadingGroups && <LoaderOverlay className="select-template-overlay" />}
                {groups.length > 0 && (
                  <Field
                    name="selected_groups"
                    component={renderGroupDropdown}
                    className="select"
                    label="Select a Group"
                    selectedGroup={selectedGroup}
                    handleChange={handleChange}
                    group={groups}
                  />
                )}
              </Grid>

              {addShareEnabled && (
                <Grid item xs={12} className="invite-member">
                  <EvaTooltip title="Add a Group">
                    <IconButton className="button-eva-invite-icon" onClick={handleShareTemplate} />
                  </EvaTooltip>
                </Grid>
              )}
            </React.Fragment>
          )}
          <Grid item xs={12}>
            <SharesList items={shares} onRemove={onRemoveShare} />
          </Grid>
          <Grid item xs={12} className="options-title comments">
            Comments
          </Grid>
          <Grid item xs={12}>
            <Field
              name="comments"
              component={renderTextArea}
              placeholder="Information about this template"
              classTextArea="comments-area"
              onChange={event => checkCharacters(event)}
              parse={elem => elem && elem.slice(0, 140)}
              classes={classes}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="SaveButton"
              component={renderButton}
              title="Save"
              onClick={e => this.handleSave(e, false)}
            />
          </Grid>
          <Grid item xs={12} className="save-and-view-action">
            <Field
              name="SaveAndViewButton"
              component={renderButton}
              title="Save and View Templates"
              input={{ value: 'SaveAndViewTemplate' }}
              onClick={e => this.handleSave(e, true)}
            />
          </Grid>
        </Grid>
      </form>
    );
  };
}

TemplateSettingsForm.propTypes = TemplateSettingsFormPropType;

const mS = () => ({});

const mD = {
  changeFormValue,
};

export default withStyles(styles)(
  connect(
    mS,
    mD,
  )(TemplateSettingsForm),
);
