import Axios from 'axios';
import AXIOS, { CancelToken } from '../Config/AxiosConfig';
import headers from './Helper';
import { REQUEST_CANCELLED_MESSAGE } from '../Config/Constants';

const v2baseURL = '/v2/eva';
const baseURL1 = '/eva/group';
const baseURL2 = '/admin-panel/group';

const Members = {
  cancelGetMembersToken: null,

  // General method for getting a members.
  getMembers(data, page, limit = 500) {
    if (this.cancelGetMembersToken) {
      this.cancelGetMembersToken.cancel(REQUEST_CANCELLED_MESSAGE)
    }
    this.cancelGetMembersToken = CancelToken.source()

    let queryParams = '';
    if (page) {
      queryParams = `?limit=${limit}&page=${page}`
    }
    const promiseVal = AXIOS.post(`${v2baseURL}/member/search${queryParams}`, data,
      {
        headers: headers(),
        cancelToken: this.cancelGetMembersToken.token
      },
    );
    promiseVal.catch(err => {
      if (Axios.isCancel(err)) {
        return false;
      }
      throw err;
    });
    return promiseVal;
  },

  getGroupsMembers(data, page) {
    return this.getMembers({
      ...data,
    }, page);
  },

  addMembers(data, groupId) {
    return AXIOS.post(
      `${baseURL1}/${groupId}/member`,
      { ...data },
      { headers: headers() },
    );
  },
  updateGroupRoleMembers(data, groupId) {
    return AXIOS.put(
      `${baseURL2}/${groupId}/assign-role-admin`,
      { ...data },
      { headers: headers() },
    );
  },
  removeRoleGroupAdmin(data, groupId) {
    return AXIOS.put(
      `${baseURL2}/${groupId}/remove-role-admin`,
      { ...data },
      { headers: headers() },
    );
  },
  removeMembers(groupId, members) {
    const data = {
      members,
    };
    return AXIOS.put(
      `/eva/groups/${groupId}/members`,
      { ...data },
      { headers: headers() },
    );
  },
  addCaminoMembersToAll(orgId, members) {
    const data = {
      members,
    };
    return AXIOS.post(
      `/eva/organization/${orgId}/add-to-general-group`,
      { ...data },
      { headers: headers() },
    );
  },
  removeAllMembers(groupId) {
    return AXIOS.delete(
      `/eva/groups/${groupId}/remove-all-members`,
      { headers: headers() },
    );
  },
  updateMembersCamino(groupId) {
    return AXIOS.post(
      `/eva/group/${groupId}/update-camino-members`,
      {},
      { headers: headers() },
    );
  },

  importFile(file) {
    const data = new window.FormData();
    data.append('file', file);
    return AXIOS.post('/admin-panel/csv-file/upload', data, {
      headers: headers(),
    });
  },
  importMembers(processId, groups, decision, selectedMembers) {
    const groupsToSend = groups.map(element => ({ public_id: element.publicId || element }));
    return AXIOS.put(
      `/admin-panel/csv-file/${processId}/${decision}`,
      { groups: groupsToSend, lines: selectedMembers },
      { headers: headers() },
    );
  },
};

export default Members;
