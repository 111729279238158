import React from 'react';
import PropTypes from 'prop-types';
import LoaderOverlay from '../LoaderOverlay';

const AdminEvaSwitchButton = props => (
  !props.isLoading ? (
    <button className="change-superadmin" onClick={props.handleAction}>
      <img src={props.icon} alt="switch"/>
    </button>
  ) : <LoaderOverlay/>
);

AdminEvaSwitchButton.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  handleAction: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
};

export default AdminEvaSwitchButton;
