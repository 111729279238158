const style = () => ({
  inputStyle: {
    '&:before': {
      borderBottom: '2px solid #d9d9d9',
    },
    '&:after': {
      borderBottom: '3px solid #d9d9d9',
    },
    '&:hover:not(.MuiInput-disabled):not(.MuiInput-focused):not(.MuiInput-focused):before': {
      borderBottom: '2px solid #d9d9d9',
    },
  },
});

export default style;
