/** Global messages (EN)  */
export default {
  greetings: {
    welcomeTo: 'Hi {{username}}, Welcome!',
  },
  header: {
    tempAdminBanner: {
      desktop: {
        text: 'You are currently a Temporary Admin of {{organizationName}}. Click {{link}} to return to the Super Admin portal.',
      },
      mobile: {
        text: 'Temporarily you are Admin of {{organizationName}}. Return {{link}}.',
      },
    },
  },
};
