import React, { Component } from 'react';
import { connect } from 'react-redux';
import { enableRightDrawerMessage } from '../../Actions/rightDrawerMessageActions';
import { navigationControllerRedirect } from '../../Actions/navigationControllerActions';
import { openCustomModal } from '../../Actions/customModalActions';
import { clearCompose } from '../../Actions/composeActions';
import { clearMessage } from '../../Actions/messageActions';
import './index.sass';
import ButtonDrop from '../ButtonDrop';
import GroupInviteMembers from '../../Containers/GroupInviteMembers';
import MessageModalManager, { MESSAGE_MODAL_MANAGER_VIEWS } from '../../Containers/MessageModalManager';

class ButtonCompose extends Component {
  displayNewMessage = () => {
    this.props.clearCompose();
    this.props.clearMessage();
    this.props.openCustomModal({ content: <MessageModalManager view={MESSAGE_MODAL_MANAGER_VIEWS.EDITOR} /> });
  };

  displayDesigner = () => {
    this.props.clearMessage();
    const url = '/messages/new';
    this.props.navigationControllerRedirect(url);
  };

  displayAddMembers = event => {
    event.preventDefault();
    this.props.openCustomModal({
      content: <GroupInviteMembers />,
      title: 'Invite Members',
    });
  };

  render() {
    return (
      <ButtonDrop
        buttonStyle="separated"
        className="button-compose"
        title="Compose"
        onClick={this.displayNewMessage}
      >
        <ul>
          <li
            onKeyPress={this.handleOnKeyPress}
            role="menuitem"
            tabIndex="0"
            onClick={this.displayDesigner}
          >
            <i className="designer-icon"/>
            <span>Designer</span>
          </li>
          <li
            onKeyPress={this.handleOnKeyPress}
            role="menuitem"
            tabIndex="0"
            onClick={this.displayAddMembers}
          >
            <i className="add-member"/>
            <span>Add members</span>
          </li>
          <li className="item-ref">
            <a
              href="https://www.diocesan.com/quicksupport"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="help"/>
              <span>Help</span>
            </a>
          </li>
        </ul>
      </ButtonDrop>
    );
  }
}

const mD = {
  enableRightDrawerMessage,
  navigationControllerRedirect,
  openCustomModal,
  clearCompose,
  clearMessage,
};

export default connect(null, mD)(ButtonCompose);
