import React from 'react';
import 'react-phone-number-input/style.css';
import { withStyles } from '@material-ui/core/styles';
import PhoneInput from 'react-phone-number-input';
import EvaTooltip from '../EvaTooltip';
import './index.sass';
import phoneIcon from '../../Theme/Icons/phone.svg';
import { styles } from './styles';

/**
 * A simpler component without gird and without all the phantom classes haunting
 * that component. (V3 is a special parameter that removes the border bottom line from the input)
 * field. This component is also Redux form compatible.
 * @param {Object} params
 * @param {string} params.placeholder Placeholder when field is empty
 * @param {Object} param.classes MUI injected classes
 * @param {Object} param.style Object with css attributes to be applied as an inline style in the
 * containing box.
 * @param {Object} param.input Redux-Form input object (See Documentation).
 * @param {Object} param.meta Redux-Form meta object (See Documentation)
 * @param {Boolean} param.useV3 Special class to be used.
 */
const InputPhoneV2 = ({
  placeholder = '',
  classes,
  style,
  useV3 = false,
  input,
  meta: { touched, submitFailed, valid },
}) => {
  let errorClass = '';
  if (touched || submitFailed) {
    if (valid) errorClass = '';
    else errorClass = 'has-errors';
  }
  return (
    <div className={`${useV3 ? classes.rootV3 : classes.root} ${errorClass}`} style={style}>
      <EvaTooltip title="SMS Number">
        <div className="icon">
          <img src={phoneIcon} alt="phone input" />
        </div>
      </EvaTooltip>
      <div className={useV3 ? 'input-v3' : 'input'}>
        <PhoneInput
          {...input}
          placeholder={placeholder}
          country="US"
          limitMaxLength
          autoComplete="off"
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(InputPhoneV2);
