import React from 'react';
import MainLayoutPropType from '../../PropTypes/MainLayoutPropType';
import CustomModal from '../../Components/CustomModal';

const MainLayout = ({ children }) => (
  <div className="main-layout-eva">
    {children}
    <CustomModal/>
  </div>
);

MainLayout.propTypes = MainLayoutPropType;

export default MainLayout;
