import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ChannelPicker from '../ChannelPicker';

const getTypeFromFlags = (isNotifyByEmail, isNotifyBySms) => {
  if (isNotifyByEmail && isNotifyBySms) return 'all';

  if (isNotifyByEmail) return 'email';

  if (isNotifyBySms) return 'sms';

  return null;
}

const NotificationsChannelPicker = ({
  className = '', isEditMode, isNotifyOnlyByEmail, isNotifyByEmail, isNotifyBySms, onChange,
}) => {
  const [channel, setChannel] = useState(getTypeFromFlags(isNotifyByEmail, isNotifyBySms));

  useEffect(() => {
    let changes = { isNotifyBySms: false, isNotifyByEmail: false };

    if (channel === 'all') {
      changes.isNotifyByEmail = true;
      changes.isNotifyBySms = true;
    }

    if (channel === 'email') {
      changes.isNotifyByEmail = true;
    }

    if (channel === 'sms') {
      changes.isNotifyBySms = true;
    }

    if (changes.isNotifyBySms !== !!isNotifyBySms || changes.isNotifyByEmail !== !!isNotifyByEmail) {
      onChange(changes);
    }

  }, [channel])

  return (
    <ChannelPicker
      className={className}
      isPreviewMode={!isEditMode}
      isSmsDisabled={isNotifyOnlyByEmail}
      value={channel}
      onChange={setChannel}
    />
  )
}

NotificationsChannelPicker.propTypes = {
  className: PropTypes.string,
  isEditMode: PropTypes.bool,
  isNotifyOnlyByEmail: PropTypes.bool,
  isNotifyByEmail: PropTypes.bool,
  isNotifyBySms: PropTypes.bool,
  onChange: PropTypes.func,
};

NotificationsChannelPicker.defaultProps = {
  isEditMode: false,
  isNotifyOnlyByEmail: false,
  onChange: () => {
  },
}

export default NotificationsChannelPicker;
