import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import API_URL, { SHORTENER_URL } from '../../Config/env';
import { DEFAULT_RSS_OPTIONS, PRIVACY_OPTIONS, ROLE_EVA_GROUP_ADMIN, RSS_OPTIONS_LIMIT } from '../../Config/Constants';
import snackBarStatus from '../../Actions/snackbarActions';
import Helpers from '../../Helpers';
import getMemberInfo from '../../Helpers/getMemberInfo';
import {
  clearValidateKeyword,
  existsUrlShortener,
  getGroupsAsync,
  getOrgAsync,
  removeGroup,
  saveSelectedGroup,
  searchGroups,
  sendWelcomeMessageByEmail,
  sendWelcomeMessageByText,
  setKeywordError,
  toggleGroupActivation,
  updateGroupFromList,
  updateMemberPreference,
  updateMiddleMenu,
  updateSelectedGroup,
  updateSelectedGroupFacebookPages,
} from '../../Actions/groupsActions';
import {
  getTwitterAccessToken,
  getTwitterAccountInfo,
  removeTwitterAccount,
  setTwitterAuthorizationProgressStatus,
} from '../../Actions/socialShareActions';
import {
  getOrgAdmins,
  getOrganizationFacebookLinking,
  removeOrgFbConnection,
  submitOrgFbToken,
  updateOrgAdminReplyNotification
} from '../../Actions/organizationActions';
import GroupAdminsList from '../GroupAdminsList';
import {
  getGroupMembers,
  removeAllMembers,
  removeRoleGroupAdmin,
  updateMembersCamino
} from '../../Actions/membersActions';
import { debounce } from '../../Helpers/debounce';
import Input from '../Input';
import { switchStyles } from '../../Theme/switchStyles';
import download from 'downloadjs';
import Groups from '../../Api/Groups';
import Switch from '@material-ui/core/Switch';
import FacebookPagesList from '../FacebookPagesList';
import ButtonSimple from '../ButtonSimple';
import LoaderOverlay from '../LoaderOverlay';
import TwitterIntegration from '../TwitterIntegration';
import LettersCounter from '../LettersCounter';
import PropTypes from 'prop-types';
import { setEnforceFocus } from '../../Actions/rightDrawerMessageActions';
import MyParishAppLogin from '../MyParishAppLogin';
import { enableModal } from '../../Actions/modalActions';
import GenericMessage from '../GenericMessage';
import SuccessIcon from '../../Theme/Icons/check-green.svg';
import ErrorIcon from '../../Theme/Icons/error.svg';
import { Select } from '@mobiscroll/react';
import { mbscResponsiveOptions } from '../../Config/Constants/mobiscroll-props';
import './index.sass';
import ChannelPicker from '../ChannelPicker';

const styles = theme => ({
  ...switchStyles,
});

const inputFields = {
  name: { stateName: 'nameValidation', message: 'Group Name is Required' },
  short_name: {
    stateName: 'shortNameValidation',
    message: 'The Short Name is Required',
  },
  sender_name: {
    stateName: 'senderNameValidation',
    message: 'The Sender Name is Required',
  },
  keyword: { stateName: 'keywordValidation' },
  description: {
    stateName: 'descriptionValidation',
    message: 'The Description must contain at least 3 letters',
  },
  custom_welcome_message: {
    stateName: 'customWelcomeValidation',
    message: 'The custom welcome message does not allow html tags',
  },
};

const REQUEST_DEBOUNCE_TIME = 2000;

class SettingsGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRssVisible: false,
      rssSelectOptionsValues: Helpers.genArrayOfLength(RSS_OPTIONS_LIMIT),
      showWarningKeyword: true,
      validationsForm: {
        shortNameValidation: false,
        senderNameValidation: false,
        descriptionValidation: false,
        customWelcomeValidation: false,
        keywordValidation: false,
        nameValidation: false,
        keywordExistValidation: false,
      },
      isRemoveConfirmationOpen: false,
      typeOfDeleting: null,
    };

    this.twitterSignInInterval = null;
    this.twitterIntervalCounter = 0;
    this.saveWithDebounce = debounce(this.updateGroup, REQUEST_DEBOUNCE_TIME);
  }

  componentDidMount = () => {
    window.theWindow = false;

    this.props.getOrganizationFacebookLinking();

    // Fetch admins
    this.props.getGroupMembers(this.props.selectedGroup.public_id, '', true);

    // Fetch org admins
    this.props.getOrgAdmins(this.props.selectedGroup.public_id);

    this.checkTwitterAccountState();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.selectedGroup.public_id !== this.props.selectedGroup.public_id) {
      this.props.getGroupMembers(this.props.selectedGroup.public_id, '', true);
      this.props.getOrgAdmins(this.props.selectedGroup.public_id);
    }
  }

  checkTwitterAccountState = async () => {
    const { selectedGroup } = this.props;
    try {
      const profile = await this.props.getTwitterAccountInfo(selectedGroup.public_id);
      this.props.updateSelectedGroup({
        twitter_integrations: profile ? [profile] : []
      });
    } catch (e) {
      this.props.updateSelectedGroup({
        twitter_integrations: [],
        post_twitter: false,
      });
    }
  };

  isInvalidWelcomeMessage = string => !!string ? /<[a-z/][\s\S]*>/i.test(string) : false;

  updateGroup = () => {
    this.props
      .saveSelectedGroup()
      .then(res => {
        if (res.status === 200) {
          this.refresh();
        }
      });
  };

  eraseTwitterInterval = () => {
    clearInterval(this.twitterSignInInterval);
    this.twitterSignInInterval = null;
    this.twitterIntervalCounter = 0;
  }

  twitterSignIn = () => {
    const { selectedGroup, isLoadingGroupSettings } = this.props;

    if (!selectedGroup.isEnabled || isLoadingGroupSettings) return;

    this.props.getTwitterAccessToken(selectedGroup.public_id).then(token => {
      window.theWindow = window.open(
        `https://api.twitter.com/oauth/authorize?oauth_token=${token.oauth_token}&force_login=true`,
        'Twitter Authorization',
        'width=554,height=283',
        false,
      );

      if (this.twitterSignInInterval) {
        this.eraseTwitterInterval();
      }

      this.twitterSignInInterval = setInterval(() => {
        if (window.theWindow.closed) {
          this.eraseTwitterInterval();
          this.checkTwitterAccountState();
        } else {
          this.twitterIntervalCounter++;

          if (this.twitterIntervalCounter > 30) {
            this.eraseTwitterInterval();

            this.props.setTwitterAuthorizationProgressStatus(token, 'timeout');
            this.props.updateSelectedGroup({
              post_twitter: false,
              twitter_integrations: [],
            });

            window.theWindow.close();
          }
        }
      }, 1000);

    });
  };

  refresh = () => this.props.getGroupsAsync();

  validateKeyword = keyword => {
    const { validationsForm } = this.state;
    // A-Z, a-z, 0-9 and hypens (-)
    // max length 10 min length 3
    const validPattern = /^([a-z]|[0-9]|-){3,10}$/gi;
    const invalidHyphenPattern = /-{2,}/g;
    const invalidHyphenEndingPattern = /.*-$/g;
    const returnValue = { pass: false, reason: '' };
    if (keyword.length < 3) {
      returnValue.reason = 'Group keyword must be at least 3 characters long.';
    } else if (keyword.length > 10) {
      returnValue.reason = 'Group keyword must not be more than 10 characters long.';
    } else if (!validPattern.test(keyword)) {
      returnValue.reason = 'Organization keyword can only use numbers, letters and hypens';
    } else if (invalidHyphenPattern.test(keyword)) {
      returnValue.reason = 'Organization keyword must not have two or more hyphens together';
    } else if (invalidHyphenEndingPattern.test(keyword)) {
      returnValue.reason = 'Organization keyword must not end with a hyphen';
    } else {
      returnValue.pass = true;
      returnValue.reason = 'All checks passed';
    }

    if (!returnValue.pass) validationsForm[inputFields.keyword.stateName] = true;
    else validationsForm[inputFields.keyword.stateName] = false;

    this.setState({
      validationsForm,
    });

    return returnValue;
  };

  getValidationButton = () => {
    const { validateKeyword, selectedGroup, keywordError } = this.props;
    const { keyword } = selectedGroup;

    if ((keyword && keyword.length > 0 && keyword.length < 3) || !keywordError) {
      return (
        <button className="form_warning-close-v2" type="button" aria-label="Close">
          <img src={ErrorIcon} alt="ErrorIcon" />
        </button>
      );
    }

    if (keyword === '') {
      return validateKeyword;
    } else if (validateKeyword) {
      return (
        <button className="form_warning-close-v2" type="button" aria-label="Check">
          <img src={SuccessIcon} alt="SuccessIcon" />
        </button>
      );
    }
  };

  handleRssToggle = () => this.setState({ isRssVisible: !this.state.isRssVisible });

  handleCopyToClipboard = (elName, message) => {
    const el = document.getElementsByName(elName)[0];
    Helpers.copyToClipboard(el);
    el.select();
    this.props.snackBarStatus({
      payload: {
        title: message,
        type: 'success',
        enable: true,
      },
    });
  }

  handleCopyKeywordUrl = keywordUrl =>
    this.handleCopyToClipboard('customUrl', `Copy the text : ${keywordUrl}`);

  handleCopyKeywordUrlPreview = keywordUrl =>
    this.handleCopyToClipboard('customUrlPreview', `Copy the text : ${keywordUrl}`);

  handleCopyHtmlSnippet = () =>
    this.handleCopyToClipboard('snippet', `HTML snippet code was copied`);

  handleCopyRssUrl = () =>
    this.handleCopyToClipboard('rss', `RSS feed URL copied to clipboard!`);

  handleToggle = fieldName => {
    const { selectedGroup, isLoadingGroupSettings } = this.props;

    if (!selectedGroup.isEnabled || isLoadingGroupSettings) return;

    switch (fieldName) {
      case 'post_facebook':
        this.props.updateSelectedGroup({ post_facebook: !this.props.selectedGroup.post_facebook });
        break;
      case 'post_twitter':
        const updProps = {
          post_twitter: !selectedGroup.post_twitter,
          twitter_integrations: [],
        };

        this.props.updateSelectedGroup(updProps);

        if (updProps.post_twitter) {
          this.twitterSignIn();
        } else {
          this.props.removeTwitterAccount(selectedGroup.public_id);
        }
        break;
      default:
        this.handleChange(fieldName, !selectedGroup[fieldName]);
    }
  };

  handleAdminRemove = (publicId) => {
    const { selectedGroup, membersList } = this.props;
    this.props.removeRoleGroupAdmin(selectedGroup.public_id, publicId, membersList);
  };

  facebookLogin = async () => {
    FB.login(
      async response => {
        if (!['not_authorized', 'unknown'].includes(response.status)) {
          this.props.submitOrgFbToken(response.authResponse.accessToken);
        }
      },

      { scope: 'public_profile,pages_show_list,pages_manage_posts', auth_type: 'reauthenticate' },
    );
  };

  validateAndSave = () => {
    const validationErrors = [];
    const {
      selectedGroup,
      selectedGroup: { name, short_name, sender_name, description, keyword }
    } = this.props;

    const newValidationsForm = {
      ...this.state.validationsForm,
      nameValidation: false,
      shortNameValidation: false,
      descriptionValidation: false,
      customWelcomeValidation: false,
      keywordValidation: false,
    };

    const isAllMembersGroup = selectedGroup.organization_general_group;
    const validateKeyword = keyword && this.validateKeyword(keyword);

    if (!name) {
      validationErrors.push([inputFields.name.message]);
      newValidationsForm.nameValidation = true;
    }

    if (!isAllMembersGroup && !short_name) {
      validationErrors.push([inputFields.short_name.message]);
      newValidationsForm.shortNameValidation = true;
    }

    if (!isAllMembersGroup && !sender_name) {
      validationErrors.push([inputFields.sender_name.message]);
      newValidationsForm.senderNameValidation = true;
    }

    if (!description || description.length < 3) {
      validationErrors.push([inputFields.description.message]);
      newValidationsForm.descriptionValidation = true;
    }

    if (!isAllMembersGroup && keyword && !validateKeyword.pass) {
      validationErrors.push(validateKeyword.reason);
      newValidationsForm.keywordValidation = true;
    }

    if (this.isInvalidWelcomeMessage(selectedGroup.custom_welcome_message)) {
      validationErrors.push([inputFields.custom_welcome_message.message]);
      newValidationsForm.customWelcomeValidation = true;
    }

    this.setState({ validationsForm: newValidationsForm });

    if (validationErrors && validationErrors.length > 0) {
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: validationErrors,
          type: 'formError',
        },
      });
    } else {
      // updateGroupFromList({
      //   public_id: selectedGroup.public_id,
      //   name, short_name, description, keyword,
      // });

      if (Object.values(this.state.validationsForm).every(i => i === false)) {
        this.saveWithDebounce();
      }
    }
  };

  verifyKeyword = debounce((groupKeyword, orgKeyword) => {
    const localValidation = this.validateKeyword(groupKeyword);

    if (!localValidation.pass) {

      this.props.snackBarStatus({
        payload: {
          title: localValidation.reason,
          type: 'formError',
          enable: true,
        },
      });

    } else {
      const fullKeyword = `${orgKeyword}-${groupKeyword}`;
      this.setState({ customUrl: `${SHORTENER_URL}${fullKeyword}` });

      this.props.existsUrlShortener(`${fullKeyword}`).then(res => {
        let type = res.availability ? 'success' : 'formError';

        const newValidationsForm = {
          ...this.state.validationsForm,
        };

        if (type === 'success') {
          newValidationsForm.keywordExistValidation = false;
        } else {
          newValidationsForm.keywordExistValidation = true;
        }

        this.setState({
          validationsForm: newValidationsForm,
        });

        this.validateAndSave();

        this.props.snackBarStatus({
          payload: {
            title: res.message,
            type,
            enable: true,
          },
        });
      });
    }
  }, REQUEST_DEBOUNCE_TIME);

  // TODO: avoid of composedKeyword
  getCustomUrl = () => {
    const { customUrl } = this.state;
    const { selectedGroup } = this.props;
    if (customUrl) {
      return customUrl;
    } else if (selectedGroup.composedKeyword) {
      return `${SHORTENER_URL}${selectedGroup.composedKeyword}`;
    }
    return '';
  };

  handleChangeKeyword = event => {
    const {
      organization: { keyword: orgKeyword },
    } = this.props;
    const groupKeyword = event.target.value;

    const newValidationsForm = {
      ...this.state.validationsForm,
      keywordValidation: false,
    }

    if (groupKeyword.length < 3) {
      newValidationsForm.keywordValidation = true;
    } else if (groupKeyword.length > 10) {
      this.props.snackBarStatus({
        payload: {
          title: 'Group keyword must not be more than 10 characters long.',
          type: 'formError',
          enable: true,
        },
      });

      this.setState({ validationsForm: newValidationsForm });
      return false;
    }

    const value = event.target.value.toLowerCase();
    this.props.updateSelectedGroup({
      ...this.props.selectedGroup,
      keyword: value,
    });

    this.verifyKeyword(groupKeyword, orgKeyword);
    this.props.clearValidateKeyword();
    this.props.setKeywordError(true);

  };

  handleToggleFacebookLinking = () => {
    const { selectedGroup: { isEnabled }, isFbLinked, isLoadingGroupSettings, removeOrgFbConnection } = this.props;

    if (!isEnabled || isLoadingGroupSettings) return;

    if (isFbLinked) {
      removeOrgFbConnection();
      // logout
    } else {
      this.facebookLogin();
    }
  };

  renderSwitchRow = (label, name, isChecked, handler, disabled = false, icon) => (
    <div className="switcher-wrapper">
      <label>{label}</label>
      <div>
        {icon && <i className={`${icon} ${isChecked ? 'checked' : ''}`} />}
        <Switch
          disabled={disabled}
          onChange={(e) => handler ? handler(name) : null}
          checked={isChecked}
          classes={{
            root: this.props.classes.root,
            switchBase: this.props.classes.switchBase,
            colorSecondary: this.props.classes.switchColorSecondary,
            track: this.props.classes.track,
            thumb: this.props.classes.thumb,
          }}
        />
      </div>
    </div>
  )

  getPrivacyList = () => {
    const { selectedGroup } = this.props;
    const isParentHidden = selectedGroup.group_parent && selectedGroup.group_parent.privacy_level === PRIVACY_OPTIONS.hidden;
    const isParentClosed = selectedGroup.group_parent && selectedGroup.group_parent.privacy_level === PRIVACY_OPTIONS.closed;

    const list = [{ text: 'Hidden', value: PRIVACY_OPTIONS.hidden }];

    if (!isParentHidden) {
      list.unshift({ text: 'Closed', value: PRIVACY_OPTIONS.closed });

      if (!isParentClosed) {
        list.unshift({ text: 'Open', value: PRIVACY_OPTIONS.open });
      }
    }

    return list;
  }

  handleInputChange = (event, name) => this.handleChange(name, event.target.value);

  handleChange = (name, value) => {
    if (!this.props.selectedGroup.isEnabled || this.props.isLoadingGroupSettings) return;

    this.props.updateSelectedGroup({
      [name]: value,
    }, true).then(this.validateAndSave);
  }

  downloadExportedMembersFile = () => {
    const { name, public_id } = this.props.selectedGroup;
    Groups.getExportedMembersFile(public_id)
      .then(a => download(new Blob([a.data]), `members - ${name}.csv`, 'text/csv'));
  };

  downloadUnsubscribeMembersFile = () => {
    const { name, public_id } = this.props.selectedGroup;
    Groups.getUnsubscribeMembersFile(public_id)
      .then(a => download(new Blob([a.data]), `unsubscribe list - ${name}.csv`, 'text/csv'));
  };

  handleUpdateMembersCamino = () => {
    const { public_id } = this.props.selectedGroup;
    this.props.updateMembersCamino(public_id)
  };

  sendWelcomeMessageByEmail = () => {
    const { public_id } = this.props.selectedGroup;
    this.props.sendWelcomeMessageByEmail(public_id)
  };

  sendWelcomeMessageByText = () => {
    const { public_id } = this.props.selectedGroup;
    this.props.sendWelcomeMessageByText(public_id)
  };

  handleFbShareChange = publicId => {
    const { selectedGroup, isLoadingGroupSettings } = this.props;

    if (!selectedGroup.isEnabled || isLoadingGroupSettings) return;

    let pages = selectedGroup.facebook_pages.map(p => p.publicId);
    if (pages.includes(publicId)) {
      pages = pages.filter(id => id !== publicId);
    } else {
      pages.push(publicId);
    }

    this.props.updateSelectedGroupFacebookPages(pages);

    this.handleChange('facebook_pages', pages.map(id => ({ publicId: id }))); //  TODO: use plain array instead)
  }

  handleParentGroupChange = ({ value }) => {
    const { groupsDictionaryList, isLoadingGroupSettings, selectedGroup } = this.props;

    if (!selectedGroup.isEnabled || isLoadingGroupSettings) return;

    const parentFromDictionary = groupsDictionaryList.find(
      group => group.publicId === value,
    );

    let parentGroup = null;

    if (parentFromDictionary) {
      parentGroup = {
        picture_url: parentFromDictionary.group.picture_url,
        default_time_zone: parentFromDictionary.group.default_time_zone,
        privacy_level: parentFromDictionary.group.privacy_level,
        isEnabled: parentFromDictionary.group.isEnabled,
        name: parentFromDictionary.group.name,
        description: parentFromDictionary.group.description,
        short_name: parentFromDictionary.group.short_name,
        public_id: parentFromDictionary.group.public_id,
        status: parentFromDictionary.group.status,
      }
    }

    this.handleChange('group_parent', parentGroup);
  }

  handleMyParishAppPostToggle = () => {
    const { selectedGroup } = this.props;
    if (!selectedGroup.send_to_my_parish_app) {
      const afterSubmit = result => {
        if (result) {
          this.props.updateSelectedGroup({ send_to_my_parish_app: true })
            .then(this.updateGroup);
        }
      };

      const elem = <MyParishAppLogin callbackAfterSubmit={afterSubmit} />;
      this.props.enableModal({
        payload: { status: true, element: elem },
      });
    } else {
      this.props.updateSelectedGroup({ send_to_my_parish_app: !selectedGroup.send_to_my_parish_app })
        .then(this.updateGroup);
    }
  }

  handleGroupActivation = () => {
    this.props.toggleGroupActivation()
      .then(() => {
        const {
          sort,
          pagination: { currentPage },
          filters: { privacyLevel, activity, shareOptions },
          searchTerm
        } = this.props;

        this.props.searchGroups({
          sort,
          searchTerm,
          privacyLevel,
          activity,
          shareOptions,
          page: currentPage
        });
      });
  };

  handleMemberPreferenceChange = (v) => {
    this.props.updateMemberPreference(v);
  }

  getParentGroupsList = () => {
    const { groupsDictionaryList, selectedGroup } = this.props;
    let parentOptions = groupsDictionaryList.filter(
      group => group.publicId !== selectedGroup.public_id && !group.organizationGeneralGroup, // can't pick itself // nor the All Members
    );

    parentOptions = parentOptions.map(group => ({
      text:
        group.groupParent && group.groupParent.name
          ? `${group.groupParent.name} / ${group.name}`
          : group.name,
      value: group.publicId,
      disabled: group.groupParent &&
        (group.groupParent.hasParent || this.props.selectedGroup.public_id === group.groupParent.publicId),
    }));
    parentOptions = [{ text: 'No Parent Group', value: '', disabled: false }, ...parentOptions];

    return parentOptions;
  };

  openParentSelect = () => {
    this.props.setEnforceFocus(false);
  };

  closeParentSelect = () => {
    this.props.setEnforceFocus(true);
  };

  closeKeywordWarning = () => {
    this.setState({
      showWarningKeyword: false,
    });
  };

  handleOpenDialog = (type) => this.setState({ isRemoveConfirmationOpen: true, typeOfDeleting: type })

  handleCloseDialog = () =>
    this.setState({ isRemoveConfirmationOpen: false, typeOfDeleting: null })

  handleRemoveAllMembersFromGroup = () => {
    this.props.removeAllMembers(this.props.selectedGroup.public_id)
    this.handleCloseDialog()
  }

  handleRemoveGroup = () => {
    this.props.removeGroup(this.props.selectedGroup.public_id)
    this.handleCloseDialog()
  }

  renderDialog = () => (
    // TODO: unify
    <div>
      <Dialog
        open={this.state.isRemoveConfirmationOpen}
        onClose={this.handleCloseDialog}
        className="return-to-edit"
        style={{
          backgroundColor: 'transparent',
          borderRadius: '15px',
          zIndex: '1300000',
        }}
      >
        <DialogContent className="return-to-edit-modal-message">
          <div className="attention-Settings">
            {this.state.typeOfDeleting === 'members'
              ? ('Warning: This will remove all members from this group. Continue with deletion? Yes No')
              : (`This will delete ${this.props.selectedGroup.name} and all it’s members.  Continue with deletion?`)}
          </div>
          <div className="modal-actions">
            <button
              onClick={this.state.typeOfDeleting === 'members' ? this.handleRemoveAllMembersFromGroup : this.handleRemoveGroup}
              className="button">
              Yes
            </button>
            <button onClick={this.handleCloseDialog} className="button">
              No
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );

  render() {
    const {
      classes,
      organization,
      selectedGroup,
      membersList,
      adminsNotificationList,
      adminListLoading,
      isFbLinked,
      isFbExpired,
      orgFbPages,
      isFbLoading,
      keywordStatusRequest,
      role,
      userPublicId,
      organization: {
        keyword: orgKeyword,
        link_to_my_parish_app: linkToMyParishApp,
        mpa_organization_id: mpaOrganizationId,
      },
      isLoadingGroupSettings,
    } = this.props;

    const {
      validationsForm,
      isRssVisible,
      rssSelectOptionsValues,
      showWarningKeyword
    } = this.state;

    if (!selectedGroup.public_id) {
      return <LoaderOverlay />
    }

    const keywordUrl = this.getCustomUrl();

    const customUrl =
      organization && organization.keyword
        ? `${SHORTENER_URL}${organization.keyword}`
        : 'Custom URL has not been set';

    const isAllMembersGroup = selectedGroup.organization_general_group;

    const groupFbPagesIds = selectedGroup.facebook_pages.map(obj => obj.publicId);

    const twitterIntegration = selectedGroup.post_twitter && selectedGroup.twitter_integrations.length > 0 ?
      selectedGroup.twitter_integrations[0] : null;

    const snippetHtml = `<iframe src="${API_URL}/public/registration-form/${selectedGroup.public_id}" width="400px" height="600px"></iframe>`;

    const isMpaLinked = linkToMyParishApp && mpaOrganizationId;
    const shouldDisplayKeywordField = !isAllMembersGroup && selectedGroup.privacy_level !== PRIVACY_OPTIONS.hidden;

    return (
      <section id="settings-page">
        <h3 className="section-title">Settings</h3>

        <section className="sect-common-fields">
          <Input
            className={validationsForm.nameValidation ? 'error' : ''}
            name="name"
            title="name"
            type="text"
            placeholder="Group Name"
            value={selectedGroup.name}
            onChange={this.handleInputChange}
            color="#FAFAFA"
            maxLength={50}
          />

          {!isAllMembersGroup && (
            <Input
              className="form-input group-short-name-input"
              name="short_name"
              title="shortName"
              type="text"
              placeholder="Short Name"
              value={selectedGroup.short_name}
              onChange={this.handleInputChange}
              color="#FAFAFA"
            />
          )}

          <Input
            className={validationsForm.descriptionValidation ? 'error' : ''}
            name="description"
            title="description"
            type="text"
            placeholder="Description"
            value={selectedGroup.description}
            onChange={this.handleInputChange}
            color="#FAFAFA"
          />

          <section className="sect-sender-name">
            <h4 className="section-title">Sender Name</h4>
            <Input
              className={validationsForm.descriptionValidation ? 'error' : ''}
              name="sender_name"
              title="senderName"
              type="text"
              placeholder="Sender Name"
              value={selectedGroup.sender_name}
              onChange={this.handleInputChange}
              color="#FAFAFA"
              disable={isAllMembersGroup}
            />
          </section>

          {!isAllMembersGroup && (
            <div id="dropdown-privacy" className="dropdown-mobis">
              <Select
                data={this.getPrivacyList()}
                value={selectedGroup.privacy_level}
                onChange={e => this.handleChange('privacy_level', e.value)}
                name="customTimeSelect"
                responsive={mbscResponsiveOptions}
                placeholder="Group privacy level"
                buttons={[]}
              />
            </div>
          )}

          {shouldDisplayKeywordField && (
            <div className="keyword-wrapper">
              <Grid
                className={`${
                  validationsForm.keywordValidation || validationsForm.keywordExistValidation ? 'error' : ''} input-keyword`}>
                <div className="placeholder-organization-keyword">{orgKeyword}-</div>
                <div>
                  <Input
                    name="keyword"
                    title="keyword"
                    type="text"
                    placeholder="Keyword"
                    value={selectedGroup.keyword || ''}
                    onChange={this.handleChangeKeyword}
                    className={`border-keyword-v2 form-input margin-organization`}
                    color="#FAFAFA"
                    maxLength={10}
                  />
                </div>
                {this.getValidationButton()}
              </Grid>
              {keywordStatusRequest && (
                <div
                  style={{
                    paddingTop: '15px',
                    paddingBottom: '15px',
                    textAlign: 'center',
                  }}
                >
                  <LoaderOverlay />
                </div>
              )}
            </div>
          )}

          {shouldDisplayKeywordField && (
            <React.Fragment>
              {keywordUrl && showWarningKeyword && (
                <div>
                  <GenericMessage
                    hideMessage={this.closeKeywordWarning}
                    type="info message-keyword"
                    title="Warning:"
                    message="Changing the Keyword will also change the URL for this group. The previous URL will be invalid and can not be used to join the group."
                  />
                </div>
              )}
            </React.Fragment>
          )}
        </section>

        <section className="sect-registration">
          <header>
            <h4 className="section-title">Online registration</h4>
          </header>

          <div>
            <input
              type="text"
              name="snippet"
              value={snippetHtml}
              className="simple-input"
              readOnly
            />
            <div className="input-actions">
              <button className="link-like-button" onClick={this.handleCopyHtmlSnippet}>Copy html</button>
            </div>
          </div>

          {!isAllMembersGroup ? (
            !keywordStatusRequest && keywordUrl && (
              <div className="keyword-input-wrapper">
                <Input
                  readOnly={true}
                  name="customUrl"
                  title="customUrl"
                  type="text"
                  placeholder="customUrl"
                  className="input-url"
                  value={keywordUrl}
                />
                <button
                  className="link-like-button"
                  onClick={() => this.handleCopyKeywordUrl(keywordUrl)}
                >
                  Copy URL
                </button>
              </div>
            )
          ) : (
            <div className="keyword-input-wrapper">
              <Input
                readOnly={true}
                name="customUrlPreview"
                title="customUrlPreview"
                type="text"
                placeholder="customUrlPreview"
                className="input-url"
                value={customUrl}
              />

              {organization && organization.keyword && (
                <button
                  className="link-like-button"
                  onClick={() => this.handleCopyKeywordUrlPreview(customUrl)}
                >
                  Copy URL
                </button>
              )}
            </div>
          )}
        </section>

        {selectedGroup.type === 'camino' && (<>
          <section className="sect-update-members-camino">
            <div className="fields-wrapper">
              <label>Sync Group Members with Camino</label>
              <button className="icon-button update-camino" onClick={this.handleUpdateMembersCamino} />
            </div>
          </section>

          <section className="sect-welcome-email">
            <div className="fields-wrapper">
              <label>Send Welcome Email to New Members</label>
              <button className="icon-button new-message" onClick={this.sendWelcomeMessageByEmail} />
            </div>
          </section>

          <section className="sect-welcome-text">
            <div className="fields-wrapper">
              <label>Send Welcome Text to New Members</label>
              <button className="icon-button welcome-text" onClick={this.sendWelcomeMessageByText} />
            </div>
          </section>
        </>)}

        {!isAllMembersGroup && (
          <section className="sect-parent-group">
            <h4 className="section-title">Parent Group</h4>
            <Select
              filter
              filterPlaceholderText="Search"
              placeholder="No Parent Group"
              iconLeftSrc="none"
              iconRight={<i className={`icon-dropdown ${classes.mobiscrollSelectIconDropdown}`} />}
              name="group_parent"
              setText="Select"
              dropdown={false}
              responsive={mbscResponsiveOptions}
              value={selectedGroup.group_parent ? selectedGroup.group_parent.public_id : null}
              data={this.getParentGroupsList()}
              onChange={this.handleParentGroupChange}
              buttons={[]}
            />
          </section>
        )}

        <GroupAdminsList
          list={membersList}
          loading={adminListLoading}
          showAddButton={!isAllMembersGroup}
          showRemoveButton={!isAllMembersGroup}
          isGroupAdmin={role === ROLE_EVA_GROUP_ADMIN}
          userPublicId={userPublicId}
          onAdd={!isAllMembersGroup ? this.props.handleAdminAdd : null}
          onRemove={!isAllMembersGroup ? this.handleAdminRemove : null}
        />

        <section className="sect-share-options">
          <header>
            <h4 className="section-title">Share Options</h4>
            <div className="section-subheader">
              Link your social media and web accounts to post messages through them using Evangelus.
            </div>
          </header>

          <div className="switch-list-container">


            {isAllMembersGroup && (
              <React.Fragment>

                {this.renderSwitchRow(
                  'Link to myParish app profile', 'mpa_linked',
                  isMpaLinked, null, true, 'icon-mpa'
                )}

                <section className="fb-link">
                  <div className="switcher-with-notification">

                    {this.renderSwitchRow(
                      'Link to Facebook Profile', 'fb-link',
                      isFbLinked, this.handleToggleFacebookLinking, isFbLoading, 'icon-fb'
                    )}

                    {isFbExpired && (
                      <div className="info notification-expired">
                        <div className="form-text">
                          <strong> WARNING:</strong> Organization link to Facebook is <span>expired</span>.
                          <br />
                          Sharing to Facebook is disabled. You have to update link first.
                        </div>

                        <ButtonSimple mini text="Update" onClick={this.facebookLogin} />
                      </div>
                    )}
                  </div>

                  <FacebookPagesList pages={orgFbPages} />

                  {isFbLoading && <LoaderOverlay />}

                </section>
              </React.Fragment>
            )}

            {!isAllMembersGroup && (
              <React.Fragment>
                {this.renderSwitchRow(
                  'Post to myParish app',
                  'send_to_my_parish_app',
                  selectedGroup.send_to_my_parish_app,
                  this.handleMyParishAppPostToggle, !isMpaLinked,
                  'icon-mpa'
                )}

                {!organization.link_to_my_parish_app && (
                  <div className="settings-tip">
                    This option has not been activated for this organization.{' '}
                    <b>Please contact your administrator.</b>
                  </div>
                )}
              </React.Fragment>
            )}


            <section className="fb-post">
              {this.renderSwitchRow(
                'Post to Facebook', 'post_facebook', selectedGroup.post_facebook,
                this.handleToggle, !isFbLinked || (isFbExpired && !selectedGroup.post_facebook), 'icon-fb'
              )}

              {!isFbLinked && (
                <div className="info notification-expired">
                  <div className="form-text">
                    <strong> WARNING:</strong> You need to link Facebook Profile with organization first.
                  </div>
                </div>
              )}

              {isFbExpired && (
                <div className="info notification-expired">
                  <div className="form-text">
                    <strong> WARNING:</strong> Organization link to Facebook is <span>expired</span>.
                  </div>
                </div>
              )}

              {!isFbExpired && selectedGroup.post_facebook && (
                <FacebookPagesList
                  editMode
                  disabled={!selectedGroup.post_facebook}
                  pages={orgFbPages}
                  selectedPublicIds={groupFbPagesIds}
                  onChange={this.handleFbShareChange}
                />
              )}
            </section>

            <section className="x-post">
              {this.renderSwitchRow('Post to X', 'post_twitter',
                selectedGroup.post_twitter, this.handleToggle, false, 'icon-x')}

              {twitterIntegration &&
                <TwitterIntegration name={twitterIntegration.name} picture={twitterIntegration.picture} />}
            </section>

            {this.renderSwitchRow('Post on website', 'post_website',
              selectedGroup.post_website, this.handleToggle, false, 'icon-web')}
            {selectedGroup.post_website &&
              selectedGroup && (
                <React.Fragment>
                  <div className="web-extended">
                    <div className="web-inner-wrapper">
                      <div className="dropdown-mobis">
                        <Select
                          data={rssSelectOptionsValues.map(item => ({ text: item, value: item }))}
                          value={selectedGroup.number_of_messages_displayed || DEFAULT_RSS_OPTIONS}
                          onChange={e => this.handleChange('number_of_messages_displayed', e.value)}
                          name="page_to_post"
                          responsive={mbscResponsiveOptions}
                          placeholder="# messages displayed"
                          buttons={[]}
                        />
                      </div>

                      <div className="rss">
                        <button className="link-like-button" onClick={this.handleRssToggle}>View RSS feed URL</button>
                        <button className="link-like-button" onClick={this.handleCopyRssUrl}>Copy URL</button>
                      </div>
                    </div>

                    <input
                      type="text"
                      name="rss"
                      value={`${API_URL}/public/eva/group/${selectedGroup.public_id}/messages/rss`}
                      className={`simple-input ${!isRssVisible ? 'hidden' : ''}`}
                    />

                  </div>
                </React.Fragment>
              )}
          </div>
        </section>

        <section className="sect-notifications">
          <header>
            <h4 className="section-title">Receive Notification of Replies</h4>
          </header>

          <div className="switch-list-container">
            {this.renderSwitchRow(
              'Notification via SMS', 'forward_reply_sms_number',
              selectedGroup.forward_reply_sms_number, this.handleToggle
            )}

            {this.renderSwitchRow(
              'Notification via Email', 'forward_reply_email',
              selectedGroup.forward_reply_email, this.handleToggle
            )}
          </div>
        </section>

        <section className="sect-notifications">
          <header>
            <h4 className="section-title">Receive Notification of Replies (Admins)</h4>
          </header>

          {adminsNotificationList.length === 0 ? (
            <div className="no-admin-message">No Group Admin</div>
          ) : (
            <div className="switch-list-container">
              {adminsNotificationList.map(admin => {
                const { lastName, firstName, email, mainPhoneNumber, phoneNumber } = admin
                return (
                  <div className="switcher-wrapper">
                    <label>{getMemberInfo({
                      lastName,
                      firstName,
                      email,
                      mainPhoneNumber,
                      phoneNumber
                    }).displayedFullName}</label>
                    <div>
                      <Switch
                        disabled={false}
                        onChange={() =>
                          this.props.updateOrgAdminReplyNotification(
                            selectedGroup.public_id, admin.publicId, admin.isReplyNotification
                          )
                        }
                        checked={admin.isReplyNotification}
                        classes={{
                          root: this.props.classes.root,
                          switchBase: this.props.classes.switchBase,
                          colorSecondary: this.props.classes.switchColorSecondary,
                          track: this.props.classes.track,
                          thumb: this.props.classes.thumb,
                        }}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          )}
        </section>

        <section className="sect-welcome-message">
          <h4 className="section-title">Custom Email Welcome Message</h4>
          <textarea
            rows="8"
            name="custom_welcome_message"
            placeholder="Group welcome message"
            onChange={(e) => this.handleInputChange(e, 'custom_welcome_message')}
            value={selectedGroup.custom_welcome_message}
            maxLength="140" />
          <LettersCounter
            length={selectedGroup.custom_welcome_message ? selectedGroup.custom_welcome_message.length : 0}
            limit={140} />
        </section>

        {(selectedGroup.bulletin_send && isAllMembersGroup) && (
          <section className="sect-bulletin-message">
            <h4 className="section-title">Custom Bulletin Email Message</h4>
            <textarea
              rows="8"
              name="bulletin_message"
              placeholder="Bulletin email message"
              onChange={(e) => this.handleInputChange(e, 'bulletin_message')}
              value={selectedGroup.bulletin_message}
              maxLength="140" />
            <LettersCounter length={selectedGroup.bulletin_message ? selectedGroup.bulletin_message.length : 0}
                            limit={140} />
          </section>
        )}

        {selectedGroup.isMemberPreferenceAvailable && (
          <section className="sect-preference">
            <h4 className="section-title">Member Preference</h4>
            <div className="fields-wrapper">
              <label>Select Channel</label>
              <ChannelPicker
                value={selectedGroup.memberPreference}
                onChange={this.handleMemberPreferenceChange}
              />
            </div>
          </section>
        )}

        <section className="sect-export">
          <h4 className="section-title">Data Export</h4>
          <div className="fields-wrapper">
            <label>Download Member List</label>
            <button onClick={this.downloadExportedMembersFile} />
          </div>
        </section>

        <section className="sect-unsubscribe">
          <div className="fields-wrapper">
            <label>Download Unsubscribe List</label>
            <button onClick={this.downloadUnsubscribeMembersFile} />
          </div>
        </section>


        {!isAllMembersGroup && (
          <section className="sect-remove">
            <div className="fields-wrapper">
              <label>Delete All Members</label>
              <button disabled={!(selectedGroup.members_quantity > 0)}
                      onClick={() => this.handleOpenDialog('members')} />
            </div>
          </section>
        )}

        {!isAllMembersGroup && (
          <section className="sect-group-activation">
            <h4 className="section-title">Group Activation</h4>

            <div className="switch-list-container">
              {this.renderSwitchRow(
                'Disable/Enable Group', 'activation',
                selectedGroup.isEnabled, this.handleGroupActivation
              )}
            </div>
          </section>
        )}

        {(!isAllMembersGroup && selectedGroup.sub_groups_quantity === 0) && (
          <section className="sect-remove">
            <div className="fields-wrapper">
              <label>Delete Group</label>
              <button onClick={() => this.handleOpenDialog('group')} />
            </div>
          </section>
        )}

        {!selectedGroup.isEnabled && !isLoadingGroupSettings && <div className="lock-overlay" />}

        {isLoadingGroupSettings && <LoaderOverlay className="settings-update-loader-overlay" />}
        {this.renderDialog()}
      </section>
    );
  }
}

SettingsGroup.propTypes = {
  handleAdminAdd: PropTypes.func
};

SettingsGroup.defaultProps = {
  handleAdminAdd: () => {
  }
}

const mS = state => ({
  selectedGroup: state.groupsReducer.selectedGroup,
  keywordStatusRequest: state.groupsReducer.keywordStatusRequest,
  keywordError: state.groupsReducer.keywordError,
  validateKeyword: state.groupsReducer.validateKeyword,
  isLoadingGroupSettings: state.groupsReducer.isLoadingGroupSettings,
  pagination: state.groupsReducer.pagination,

  organization: state.organizationReducer.organization,
  isFbLinked: state.organizationReducer.isFbLinked,
  isFbExpired: state.organizationReducer.isFbExpired,
  isFbLoading: state.organizationReducer.isFbLoading,
  orgFbPages: state.organizationReducer.fbPages,

  adminListLoading: state.membersReducer.loading,
  membersList: state.membersReducer.membersList,
  adminsNotificationList: state.organizationReducer.adminsNotificationList,

  groupsDictionaryList: state.groupsDictionaryReducer.list,

  searchTerm: state.groupsFiltersReducer.searchTerm,
  filters: state.groupsFiltersReducer.filters,
  sort: state.groupsFiltersReducer.sort,

  role: state.userProfileReducer.main_role,
  userPublicId: state.userProfileReducer.public_id,
});

const mD = {
  snackBarStatus,
  getGroupsAsync,
  getOrgAsync,
  saveSelectedGroup,
  updateSelectedGroup,
  getTwitterAccessToken,
  setTwitterAuthorizationProgressStatus,
  getTwitterAccountInfo,
  removeTwitterAccount,
  updateMiddleMenu,
  getGroupMembers,
  submitOrgFbToken,
  removeOrgFbConnection,
  updateGroupFromList,
  updateSelectedGroupFacebookPages,
  getOrganizationFacebookLinking,
  removeRoleGroupAdmin,
  setEnforceFocus,
  enableModal,
  setKeywordError,
  existsUrlShortener,
  clearValidateKeyword,
  toggleGroupActivation,
  updateMemberPreference,
  searchGroups,
  removeGroup,
  removeAllMembers,
  updateMembersCamino,
  getOrgAdmins,
  updateOrgAdminReplyNotification,
  sendWelcomeMessageByEmail,
  sendWelcomeMessageByText,
};

export default connect(
  mS,
  mD,
)(withStyles(styles)(SettingsGroup));
