import moment from 'moment';

export const validDate = date => moment(date).isValid();

export const getDateToday = () => moment().format('YYYY-MM-DD');

export const getYears = date => moment().diff(date, 'years');

export const getDate = date => moment(date).fromNow();

export const getDayOfTheWeek = date => moment(date).format('dddd');

export const formatDate = date => moment(date).format('MMMM Do YYYY');

export const addDays = (date, days) => moment(date, 'DD-MM-YYYY').add('days', days);

// Get date/time in specific timezone. Using for displaying date/time in organization timezone
export const getDateInTimezone = (dateTime, timezone, format) =>
  moment.tz(dateTime, timezone).format(format);

// Get date/time with ignored time zone offset.
export const addTimezoneToDate = (dateTime, timezone) => moment.tz(dateTime, timezone).format();
