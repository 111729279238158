const ACTIONS = {
  FORCE_RELOAD: 'avatar/force_reload',
};

/**
 * Tells the avatar to refresh the url image of every
 * instance.
 * @param {boolean} status
 */
export function forceReload(status) {
  // eslint-disable-next-line
  return function(dispatch) {
    let retVal = '';
    if (status) {
      const u32 = new Uint32Array(1);
      // eslint-disable-next-line
      retVal = crypto.getRandomValues(u32);
    }
    return dispatch({
      type: ACTIONS.FORCE_RELOAD,
      payload: `${retVal}`,
    });
  };
}

export default ACTIONS;
