import defaultMessages from './Default';
import login from './Login';
import user from './User';
import uploadGallery from './UploadGallery';
import message from './Message';

const messages = {
  defaultMessages,
  login,
  uploadGallery,
  user,
  message,
};

/**
 * Spanish Messages
 */
export default messages;
