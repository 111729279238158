// import moment from 'moment';
import momentTimezone from 'moment-timezone';
import {
  APP_MESSAGE_TYPES,
  SCHEDULED_MSG_API_DATE_FORMAT,
  SCHEDULED_MSG_DATE_FORMAT,
} from '../Config/Constants';

export const isOrgAdmin = role => role === 'admin';

export const isGroupAdmin = (groupsRoles, groups) => {
  if (!Array.isArray(groupsRoles) || !Array.isArray(groups)) {
    return false;
  }

  const isGroupInArray = groupRole => (
    groups.some(group => group.group && group.group.publicId === groupRole.groupId)
  );

  return groupsRoles.some(isGroupInArray);
};

export const isMessageFromAdmin = message => {
  if (
    typeof message !== 'object' ||
    typeof message.memberRoles !== 'object'
  ) {
    return false;
  }

  const {
    memberRoles: { organizationRole, groupsRoles },
    _source: { groups, type },
  } = message;
  const messageIsFromAdmin = isOrgAdmin(organizationRole) || isGroupAdmin(groupsRoles, groups);

  return messageIsFromAdmin && APP_MESSAGE_TYPES.includes(type);
};

export const getGroupsNames = (groups, separator = ' - ') => {
  if (!Array.isArray(groups)) {
    return '';
  }

  return groups.map(group => group.group && group.group.name).join(separator);
};

export const handleOnlyTextPreviewPaste = e => {
  // Stop data actually being pasted
  e.stopPropagation();
  e.preventDefault();

  // Get pasted data via clipboard API
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedData = clipboardData.getData('Text');
  // eslint-disable-next-line no-console
  document.execCommand('insertText', false, pastedData);
};
