import React from 'react';
import 'react-phone-number-input/style.css';
import { withStyles } from '@material-ui/core/styles';
import PhoneInput from 'react-phone-number-input';
import EvaTooltip from '../EvaTooltip';
import './index.sass';
import phoneIcon from '../../Theme/Icons/phone.svg';
import { styles } from './styles';

/**
 * A simpler component without gird and without all the phantom classes haunting
 * that component. (V3 is a special parameter that removes the border bottom line from the input)
 * field.
 * @param {Object} param0
 */
const InputPhoneV2 = ({
  onInputPhoneChange,
  name,
  placeholder = '',
  value = '',
  classes,
  style,
  useV3 = false,
}) => (
  <div className={useV3 ? classes.rootV3 : classes.root} style={style}>
    <EvaTooltip title="SMS Number">
      <div className="icon">
        <img src={phoneIcon} alt="phone input" />
      </div>
    </EvaTooltip>
    <div className={useV3 ? 'input-v3' : 'input'}>
      <PhoneInput
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onInputPhoneChange}
        country="US"
        limitMaxLength
        autoComplete="off"
      />
    </div>
  </div>
);

export default withStyles(styles)(InputPhoneV2);
