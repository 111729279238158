// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../Theme/Icons/iconadd.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".my-available-groups-list .organization-item-wrapper:not(:last-child){margin-bottom:20px}.my-available-groups-list .org-name,.my-available-groups-list .group-name,.my-available-groups-list .group-description{white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.my-available-groups-list .org-name{margin-bottom:15px;padding-bottom:10px;border-bottom:1px solid #e3e3e3;font-family:\"GothamSSm-Medium\",Arial,sans-serif;font-size:16px;color:#a9a9a9}.my-available-groups-list .available-group-item{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-align:center;align-items:center;margin-bottom:20px;font-family:\"GothamSSm-Book\",Arial,sans-serif}.my-available-groups-list .available-group-item .group-inner-wrapper{margin-right:20px;width:calc(100% - 40px - 20px)}.my-available-groups-list .available-group-item button{width:40px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");padding:40px 30px 0 10px;background-repeat:no-repeat;background-position:right;background-size:24px;background-color:Transparent;border:none;cursor:pointer;overflow:hidden;outline:none;margin:0}.my-available-groups-list .group-name{font-size:16px;color:#4a4a4a}.my-available-groups-list .group-description{font-size:14px;color:#a9a9a9}@media only screen and (min-width: 960px){.my-available-groups-list .groups-list-wrapper{display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-ms-flex-pack:justify;justify-content:space-between}.my-available-groups-list .groups-list-wrapper .available-group-item{width:calc(50% - 20px);-ms-flex:0 0 auto;flex:0 0 auto}}\n", ""]);
// Exports
module.exports = exports;
