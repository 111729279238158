import Avatar from '../Avatar';
import React from 'react';
import './index.sass';
import PropTypes from 'prop-types';

export const MembersPreviews = ({ members, keyPrefix = '', limit = 5, direction = 'row' }) => (
  <div className={`members-previews direction-${direction}`}>
    {members.slice(0, limit - 1).map((member, index) => (
      <Avatar
        key={`img-${keyPrefix}-${member.publicId}-${index}`}
        image={member.profilePictureUrl}
      />
    ))}
  </div>
);

MembersPreviews.propTypes = {
  members: PropTypes.arrayOf(PropTypes.object).isRequired,
  limit: PropTypes.number,
  keyPrefix: PropTypes.string,
  direction: PropTypes.oneOf(['row', 'column']).isRequired
};
