import Colors from '../../Theme/Colors';

export default {
  root: {
    backgroundColor: Colors.amethyst,
    minHeight: '38px',
    maxHeight: '50px',
    padding: '0 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progress: {
    marginLeft: '20px',
    verticalAlign: 'middle',
  },
  wrapper: {
    fontFamily: 'GothamSSm-Medium',
    fontSize: '13px',
    color: '#fff',
    textAlign: 'center',
    lineHeight: '1.8',
  },
  bold: {
    fontFamily: 'GothamSSm-Bold',
  },
  anchor: {
    color: '#fff',
    padding: '8px 0px',
  },
};
