/* eslint-disable */
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import ContentEditable from 'react-contenteditable';
import Avatar from '../Avatar';
import speechBubble from '../../Theme/Icons/speech-bubble.svg';
import Color from '../../Theme/Colors';
import { switchStyles } from '../../Theme/switchStyles';
import TemplateMessagePreviewPropType from '../../PropTypes/TemplateMessagePreviewPropType';
import './index.sass';

const styles = theme => ({
  colorBar: {},
  colorChecked: {},
  ...switchStyles,
  tabsIndicator: {
    background: 'linear-gradient(to right, #9966bf 0%, #6174c9 98%)',
  },
  tabsRoot: {
    textTransform: 'initial',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'GothamSSm-Book',
    color: Color.grey,

    '&:focus': {
      color: Color.purple,
    },
  },
});

class TemplateMyParishAppPreview extends Component {
  state = {
    enabled: true,
    myParishAppIntegration: null,
  };

  render() {
    const { content, profilePicture, profileName } = this.props;
    return (
      <div className="send-my-parish-app-container">
        <div className="switch-container">
          {/* <div className="text-container">Send to myParish App</div> */}
        </div>
        <div className="message-container">
          <div className="my-parish-app-content" id="scroll">
            <div className="my-parish-app-message designerContent show">
              <div className="my-parish-app-header">
                <Grid item xs={1} className="member-message-my-parish-app">
                  <Avatar
                    borderless
                    className="my-parish-app-member-picture"
                    image={profilePicture}
                  />
                </Grid>
                <div className="my-parish-app-header-text">
                  <p className="my-parish-app-name">{profileName}</p>
                  <div className="my-parish-app-lower-header">
                    <p className="my-parish-app-comments-count">
                      Now | 0 Comments{' '}
                    </p>
                  </div>
                </div>
                <div className="my-parish-app-speech-bubble">
                  <img src={speechBubble} alt="speech-bubble" />
                  <span>Comment</span>
                </div>
              </div>
              <div className="my-parish-app-title">
                {/* {this.props.msgTitle} */}
              </div>
              <ContentEditable
                className="my-parish-app-body-text"
                id="scroll"
                tagName="p"
                html={content}
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
TemplateMyParishAppPreview.propTypes = TemplateMessagePreviewPropType;

export default TemplateMyParishAppPreview;
