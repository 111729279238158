const Color = {
  placeholder: '#b3b3b3',
  evaSoft: '#efe3f7',
  amethyst: '#9a66bf',
  purple: '#9a66bf',
  grey: '#a9a9a9',
  greyCheck: '#e3e3e3',
  evaPrimaryWhite: '#d9d9d9',
  evaGrey: '#4a4a4a',
  bgSidebarHover: '#d8d8d8',
  whiteTwo: '#f3f3f3',
  lineRange: '#9b9b9b',
  grayBorder: '#cfcfcf',
  resultTotal: '#757575',
  lightGrey: '#f3f3f3',
  whiteOpacity: 'rgba(255, 255, 255, 0.5)',
};

export default Color;
