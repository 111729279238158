import React from 'react';
import EvaAnimation from '../EvaAnimation';
import { LoaderAnimation } from '../../Animations';
import PropTypes from 'prop-types';
import './index.sass';

const LoaderOverlay = (props) => (
  <div className={`loader-overlay ${props.className} ${props.fullScreen ? 'full-screen' : ''}`}>
    <EvaAnimation loop animation={LoaderAnimation} />
  </div>
);

LoaderOverlay.propTypes = {
  className: PropTypes.string,
  fullScreen: PropTypes.bool,
};

LoaderOverlay.defaultProps = {
  className: '',
  fullScreen: false,
};

export default LoaderOverlay;
