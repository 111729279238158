import React from 'react';
import { Link } from 'react-router-dom';
import ActionUserSettingsButtonPropType from '../../PropTypes/ActionUserSettingsButtonPropType';
import './index.sass';

const ActionUserSettingsButton = () => (
  <Link to="designer" className="user-setting-button">
    Designer
  </Link>
);

ActionUserSettingsButton.propTypes = ActionUserSettingsButtonPropType;

export default ActionUserSettingsButton;
