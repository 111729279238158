import MyParishApp from '../Api/MyParishApp';

export const ACTIONS = {
  MPA_GET_LOGIN: 'groups/get_login',
};

export const MPALogin = (group, email, password) => async dispatch => {
  try {
    const response = await MyParishApp.MPALogin(group, email, password);
    let groupData = [];
    if (response.status === 200) {
      groupData = response.data;
      const mpaToken = groupData.data;
      dispatch({ type: ACTIONS.MPA_GET_LOGIN, payload: mpaToken });
    } else {
      dispatch({ type: ACTIONS.MPA_GET_LOGIN, payload: null });
    }
    return groupData;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error.message);
    return error;
  }
};

export const MPALoginCheck = group => async () => {
  try {
    const response = await MyParishApp.MPALoginCheck(group);
    let checkResponse = [];
    if (response.status === 200) {
      checkResponse = response;
    }
    return checkResponse;
  } catch (error) {
    return error;
  }
};
