import React, { Component } from 'react';
import { Portal } from 'react-portal';
import ButtonStandard from '../Button';
import ModalConfirm from '../ModalConfirm';
import MemberList from '../MemberList';
import MembersPicker from '../MembersPicker';
import {
  changeRenderView as changeView,
  importMembers,
  selectAllCsvMembers,
  selectMember,
  clearSelectedMembers,
  updateImportMessage,
} from '../../Actions/membersActions';
import { connect } from 'react-redux';
import { changeTab } from '../../Actions/middleMenuActions';
import snackBarStatus from '../../Actions/snackbarActions';
import {  debounce } from '../../Helpers/debounce';
import { MEMBERS_COUNT_FOR_DEBOUNCE } from '../../Config/Constants';
import { membersFilterFunction } from '../../Helpers/membersFilterFunction';
import organizations from '../../Api/Organizations';


/* eslint-disable */

class MembersImportList extends Component {
  constructor(props) {
    super(props);

    const { disableWelcomeEmail, disableWelcomeText, lite } = props.organization;

    this.state = {
      isModalOpen: false,
      filterString: '',
    };

    this.callSearchWithDebounce = debounce(this.handleSearchInputChange, 200);
    this.isNoWarningModal = disableWelcomeEmail && (disableWelcomeText || lite)
  }

  UNSAFE_componentWillMount() {
    const { selectAllCsvMembers } = this.props;
    selectAllCsvMembers();
  }

  componentWillUnmount() {
    this.props.clearSelectedMembers();
  }

  cancelImport = () => {
    const { changeView, updateImportMessage, wrapperRef, } = this.props;
    changeView('buttonImport');
    updateImportMessage({
      show: false,
      message: '',
    });
    if (wrapperRef && wrapperRef.current) {
      wrapperRef.current.scrollIntoView();
    }
  };

  handleSearchInputChange = value => {
    this.setState({ filterString: value.toLowerCase() });
  };

  handleConfirm = async () => {
    const { importMembers, importProcessDetail, selectedMembersHash, groups } = this.props;
    const response = await importMembers(
      importProcessDetail.public_id,
      groups,
      'accept',
      Object.keys(selectedMembersHash).map(lineStr => Number(lineStr)),
    );

    if (response.status === 200) {
      this.props.updateImportMessage({
        show: true,
        message: `
        <p style={{ marginBottom: '0.7rem' }}>
          ${response.data.message}
        </p>
        <p style={{ lineHeight: '1.3' }}>
          Please continue using Evangelus and the import will process in the background until it is finished.
        </p>`,
        type: 'warning',
      });
      this.setState({ isModalOpen: false });
      this.props.changeView('buttonImport');
      this.props.changeTab(<MemberList/>, 1);
    }
  };

  handleClose = () => {
    this.setState({ isModalOpen: false });
  };

  handleImportMembers = () => {
    const { groups, selectedMembersHash, organization: { disableWelcomeEmail, disableWelcomeText, lite } } = this.props;

    if (!Object.keys(selectedMembersHash).length) {
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: 'You must select at least one member to import',
          type: 'formError',
        },
      });
    } else if (!groups.length) {
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: 'You must select at least one group for import',
          type: 'formError',
        },
      });
    } else {
      this.props.snackBarStatus({
        payload: {
          enable: false,
          title: '',
        },
      });

      if (this.isNoWarningModal) {
        this.handleConfirm();
      } else {
        this.setState({ isModalOpen: true });
      }
    }
  };

  render() {
    const {
      loading, loadingMore, memberImportList, selectedMembersHash,
      selectMember, selectAllCsvMembers, clearSelectedMembers
    } = this.props;
    const { filterString } = this.state;

    const members = memberImportList
      .map(item => ({
        ...item._source,
        publicId: item.line.toString(),
      }));
    const filteredMembers =
      (filterString ?
          members
            .filter(item => membersFilterFunction(item, filterString)) : members
      )

    const { isModalOpen } = this.state;
    const isDebounced = memberImportList.length > MEMBERS_COUNT_FOR_DEBOUNCE;

    return (
      <div id="csv-import-container">
        <MembersPicker
          members={filteredMembers}
          selectedMembersHash={selectedMembersHash}
          isAllSelectButtons={true}
          isLoading={loading}
          isLoadingMore={loadingMore}
          onSelectMember={selectMember}
          onSearchInputChange={isDebounced ?
            this.callSearchWithDebounce : this.handleSearchInputChange}
          onSelectAllMembers={selectAllCsvMembers}
          onUnselectAllMembers={clearSelectedMembers}
        />

        <div className="add-member-action">
          <ButtonStandard
            title="Add Members"
            onClick={this.handleImportMembers}
          />
          <button
            className="cancel-import"
            onClick={this.cancelImport}
          >
            Cancel import
          </button>
        </div>
        <Portal node={document && document.getElementById('modal-confirm')}>
          <ModalConfirm
            handleClose={this.handleClose}
            statusDialog={isModalOpen}
            handleConfirm={this.handleConfirm}
            message="<strong>IMPORT WARNING:</strong> Members will receive a welcome message via email or text immediately. Continue with import?"
          />
        </Portal>
      </div>
    );
  }
}

/* eslint-enable */
// export default MembersImportList;

const mS = state => ({
  organization: state.organizationReducer.organization,
  selectedMembersHash: state.membersReducer.selectedMembersHash,
  memberImportList: state.membersReducer.memberImportList,
  importProcessDetail: state.membersReducer.importProcessDetail,
  loading: state.membersReducer.loading,
  loadingMore: state.membersReducer.loadingMore,
  groups: state.groupInviteMemberReducer.groups,
});

const mD = {
  importMembers,
  selectMember,
  selectAllCsvMembers,
  clearSelectedMembers,
  changeTab,
  changeView,
  updateImportMessage,
  snackBarStatus,
};

export default connect(
  mS,
  mD,
)(MembersImportList);
