import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Img from './AnimatedImg';
import Text from './AnimatedText';
import { styles } from './styles';
import Paragraph from './AnimatedParagraph';
import memberImg from '../../Theme/Icons/eva-avatar.svg';
import NotificationsPropType from '../../PropTypes/NotificationsPropType';

const toSingleLine = (str, ...values) => {
  let singleLineStr = str;

  if (str instanceof Array) {
    // concatenate template
    singleLineStr = values.reduce((acum, currentStr, i) => acum + str[i] + currentStr, '');
    singleLineStr += str[values.length];
  }
  // remove carriage returns and new lines
  singleLineStr = singleLineStr.replace(/(?:\r\n|\n|\r)/gm, '');
  // remove identations and extra spaces
  singleLineStr = singleLineStr.replace(/(\s{2,}|\t)/g, ' ');
  // and trim the result
  return singleLineStr.trim();
};

class Notifications extends Component {
  constructor(props) {
    super(props);

    const texts = props.texts.map((text, i) => ({
      id: i,
      // trim content to make it single-line
      content: toSingleLine(text),
      pose: 'hidden',
      textLength: text.length * 11, // a value around the actual width
    }));

    this.state = {
      avatarPose: 'hidden',
      textBubblesMaxWidth: 0,
      texts,
    };
    this.textBubbleContainer = React.createRef();
  }

  componentDidMount() {
    const delay = this.props.delay ? this.props.delay : 0;
    const textBubblesMaxWidth = this.textBubbleContainer.current.clientWidth - 38;
    setTimeout(() => {
      this.setState({
        avatarPose: 'visible',
        textBubblesMaxWidth,
      });
      this.nextAnimationStep(0);
    }, delay);
  }

  nextAnimationStep = id => {
    // try to animate current bubble
    const isNextStep = this.startNextTextAnimationStep(id);

    if (!isNextStep) {
      // if not possible, animation is over
      // next bubble animation will start from this.trackParagraphWidth()
      if (this.props.onAnimationComplete) {
        this.props.onAnimationComplete();
      }
    }
  };

  startNextTextAnimationStep = id => {
    // returned value
    let isNextStep = false;

    if (id >= this.state.texts) {
      return isNextStep;
    }

    const texts = this.state.texts.map(text => {
      if (id === text.id) {
        if (text.pose === 'hidden') {
          isNextStep = true;
          return { ...text, pose: 'appearing' };
        }
        if (text.pose === 'appearing') {
          isNextStep = true;
          return { ...text, pose: 'visible' };
        }
        if (text.pose === 'visible') {
          isNextStep = true;
          return { ...text, pose: 'fullHeight' };
        }
        if (text.pose === 'fullHeight' && id < this.state.texts.length - 1) {
          // when next animation step
          // is called and previous text bubbles have already being animated
          // but not the last one
          isNextStep = true;
        }
      }
      return text;
    });

    this.setState({ texts });
    return isNextStep;
  };

  trackParagraphWidth = (width, textId) => {
    if (this.state.texts[textId + 1]) {
      if (this.state.texts[textId + 1].pose === 'hidden' && Number.parseInt(width, 10) >= 60) {
        // above 60px
        this.nextAnimationStep(textId + 1);
      }
    }
  };

  render() {
    const { classes } = this.props;
    const { texts, avatarPose, textBubblesMaxWidth } = this.state;
    return (
      <div className={`notifications-eva-root ${classes.root}`}>
        <div className={`notifications-eva-avatar ${classes.avatar}`}>
          <Img src={memberImg} alt="member" pose={avatarPose} />
        </div>

        <div
          className={`notifications-eva-messages ${classes.messages}`}
          ref={this.textBubbleContainer}
        >
          {texts.map(text => (
            <div key={text.id} className="notifications-eva-text">
              <Paragraph
                pose={text.pose}
                textLength={text.textLength}
                maxWidth={textBubblesMaxWidth}
                onPoseComplete={() => this.nextAnimationStep(text.id)}
                onValueChange={{
                  width: width => this.trackParagraphWidth(width, text.id),
                }}
              >
                <Text>{text.content}</Text>
              </Paragraph>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

Notifications.propTypes = NotificationsPropType;

export default withStyles(styles)(Notifications);
