import ImageGallery from '../Api/ImageGallery';
import snackBarStatus from './snackbarActions';

export const ACTIONS = {
  CREATE_FOLDER: '[Image Gallery] Create folder',
  ENABLE_GALLERY: '[Image Gallery] Enable gallery',
  GET_GALLERY: '[Image Gallery] Get eva folder and images',
  GET_GALLERY_IMAGES: '[Image Gallery] Get eva images',
  HANDLE_PAGE: '[Image Gallery] pages_index',
  UPLOAD_PICTURE_URL: '[Image Gallery] Upload picture url',
  SET_INPUT_FIELD: '[Image Gallery] Set input_field',
  SELECTED_IMAGE: '[Image Gallery] Selected image',
  SELECTED_FOLDER: '[Image Gallery] Selected folder',
  CLEAR: '[Image Gallery] Clear',
  GLOBAL_FOLDER: '[Image Gallery] Global folder',
  LOADING: '[Image Gallery] Loading status',
};

export const getFoldersContent = () => async (dispatch, getState) => {
  const { organizationReducer: { organization: { public_id } } } = getState();
  try {
    updateLoadingStatus(true)(dispatch);

    const response = await ImageGallery.getFoldersContent(public_id);
    const {
      data: { data },
      status,
    } = response;
    if (status === 200) {
      dispatch({ type: ACTIONS.GET_GALLERY, payload: data });
    }
    updateLoadingStatus(false)(dispatch);
    return data;
  } catch (error) {
    updateLoadingStatus(false)(dispatch);

    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const getFolderImage = (publicId, page, sort, term, recentImage) => async dispatch => {
  try {
    updateLoadingStatus(true)(dispatch);

    const {
      data: { data },
      status,
    } = await ImageGallery.getFolderImage(publicId, page, sort, term, recentImage);
    let getFolderImageResponse = [];
    if (status === 200) {
      getFolderImageResponse = status;
      const imageGalleryImages = data.data;
      const handlePage = {
        nextPage: data.nextPage,
        previousPage: data.previousPage,
        currentPage: data.currentPage,
        totalPages: data.totalPages,
      };
      dispatch({
        type: ACTIONS.GET_GALLERY_IMAGES,
        payload: imageGalleryImages,
      });
      dispatch({
        type: ACTIONS.HANDLE_PAGE,
        payload: handlePage,
      });
    }
    updateLoadingStatus(false)(dispatch);
    return getFolderImageResponse;
  } catch (error) {
    updateLoadingStatus(false)(dispatch);

    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const displayGallery = show => dispatch => {
  dispatch({ type: ACTIONS.ENABLE_GALLERY, payload: show });
};

export const uploadPicture = data => async dispatch => {
  try {
    updateLoadingStatus(true)(dispatch);

    const response = await ImageGallery.uploadPicture(data);
    let uploadResponse = [];
    if (response.status === 200) {
      uploadResponse = response;
      dispatch({
        type: ACTIONS.UPLOAD_PICTURE_URL,
        payload: response.data.data,
      });
      snackBarStatus({
        payload: {
          title: 'Picture uploaded successfully',
          enable: true,
          type: 'success',
        },
      })(dispatch);

      updateLoadingStatus(false)(dispatch);
    }
    return uploadResponse;
  } catch (error) {
    updateLoadingStatus(false)(dispatch);

    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const createFolder = (folderName, selectedFolder) => async dispatch => {
  try {
    updateLoadingStatus(true)(dispatch);

    const { data: { data }, status } = await ImageGallery.createFolder(folderName, selectedFolder);
    if (status === 200) {
      const createFolderResponse = data;
      dispatch({
        type: ACTIONS.CREATE_FOLDER,
        payload: createFolderResponse,
      });
      snackBarStatus({
        payload: {
          title: 'Folder created successfully',
          enable: true,
          type: 'success',
        },
      })(dispatch);

      updateLoadingStatus(false)(dispatch);
    }
    return status;
  } catch (error) {
    updateLoadingStatus(false)(dispatch);

    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const setInputField = inputField => dispatch => {
  dispatch({ type: ACTIONS.SET_INPUT_FIELD, payload: inputField });
};

export const updateSelectedImage = img => ({ type: ACTIONS.SELECTED_IMAGE, payload: img });

export const updateGallery = images => ({ type: ACTIONS.GET_GALLERY_IMAGES, payload: images });

export const removeImage = images => async dispatch => {
  try {
    updateLoadingStatus(true)(dispatch);
    const response = await ImageGallery.removeImage(images);
    updateLoadingStatus(false)(dispatch);
    return response;
  } catch (error) {

    updateLoadingStatus(false)(dispatch);
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const moveImagesToFolder = (currentFolder, images, targetFolder) => async dispatch => {
  try {
    updateLoadingStatus(true)(dispatch);
    const response = await ImageGallery.moveImagesToFolder(currentFolder, images, targetFolder);
    updateLoadingStatus(false)(dispatch);
    return response;
  } catch (error) {
    updateLoadingStatus(false)(dispatch);
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const updateSelectedFolder = (folder, global) => dispatch => {
  dispatch({ type: ACTIONS.SELECTED_FOLDER, payload: folder });
  dispatch({ type: ACTIONS.GLOBAL_FOLDER, payload: global });
};

export const clearGallery = () => ({ type: ACTIONS.CLEAR });

const updateLoadingStatus = bool => dispatch =>
  dispatch({ type: ACTIONS.LOADING, payload: bool });
