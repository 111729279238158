import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import NoMatchButtonPropTypes from '../../PropTypes/AddMembersTab/NoMatchButtonPropTypes';
import { noMatchButtonStyles } from './styles';

const NoMatchButton = ({ classes, ...props }) => (
  <button className={classes.root} {...props} />
);

NoMatchButton.propTypes = NoMatchButtonPropTypes;

export default withStyles(noMatchButtonStyles)(NoMatchButton);
