import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { joinOrganizationGroup } from '../../Actions/loginActions';
import { navigationControllerRedirect } from '../../Actions/navigationControllerActions';
import { getRootRouteByRole } from '../../Helpers/getRootRouteByRole';

class InvitationSubmit extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { type, publicId } = this.props.match.params;
    const { roles } = this.props;
    if (!roles) return;

    const rootUrl = getRootRouteByRole(roles);

    if (type && publicId) {
      this.props.joinOrganizationGroup(type, publicId)
        .then(() => {
          this.props.navigationControllerRedirect(rootUrl);
        });
    } else {
      this.props.navigationControllerRedirect(rootUrl);
    }
  }

  render() {
    return null;
  }
}

const mS = state => ({
  organizations: state.organizationReducer.organizations,
  roles: state.userProfileReducer.member.roles,
});

const mD = {
  joinOrganizationGroup,
  navigationControllerRedirect,
};

export default withRouter(connect(
  mS,
  mD,
)(InvitationSubmit));
