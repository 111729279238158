import React from 'react';
import Avatar from '../Avatar';
import './index.sass';

const TwitterIntegration = ({name, picture}) => (
  <div className="twitter-integration">
    <Avatar
      borderless
      preventForceReload
      className="tw-picture"
      image={picture}
    />
    {name}
  </div>
);

export default TwitterIntegration;
