import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import './index.sass';
import EvaTooltip from '../EvaTooltip';
import styles from './InputIconGridlessStyles';

/**
 * Gridless Implementation of icon input
 * @param {Object} param0
 */
const InputStandard = ({
  className,
  name,
  type,
  placeholder,
  value,
  onChange,
  icon,
  // focused,
  maxLength,
  onBlur,
  disable,
  // color,
  tooltipTitle,
  classes,
}) => {
  const iconClass = icon ? `input-eva-${icon}-icon` : 'iconless';
  /* eslint-disable */
  const tooltipedElement = tooltipTitle ? (
    <EvaTooltip title={tooltipTitle}>
      <div className={classes.iconContainer}>
        <i className={iconClass} />
      </div>
    </EvaTooltip>
  ) : (
    <div className={classes.iconContainer}>
      <i className={iconClass} />
    </div>
  );
  /* eslint-enable */
  return (
    <div className={`input-box ${className} ${classes.root}`}>
      {tooltipedElement}
      <input
        name={name}
        type={type}
        placeholder={placeholder}
        value={value || ''}
        onChange={event => onChange(event, name)}
        onBlur={onBlur}
        maxLength={maxLength}
        disabled={disable ? 'disabled' : ''}
        autoComplete="off"
      />
      <div className={classes.voidInputBox} />
    </div>
  );
};

export default withStyles(styles)(InputStandard);
