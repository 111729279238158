import PropTypes from 'prop-types';
import React from 'react';
import './index.sass';
import EvaTooltip from '../EvaTooltip';

const DisplayModeSwitcher = ({ type, onChange }) => (
  <div className="display-mode-switcher">
    <EvaTooltip title="List View">
      <button
        className={`mode-list ${type === 'list' ? 'active' : ''}`}
        onClick={() => onChange('list')}
      />
    </EvaTooltip>
    <EvaTooltip title="Grid View">
      <button
        className={`mode-grid ${type === 'grid' ? 'active' : ''}`}
        onClick={() => onChange('grid')}
      />
    </EvaTooltip>
  </div>
);

DisplayModeSwitcher.propTypes = {
  type: PropTypes.oneOf(['list', 'grid']).isRequired,
  onChange: PropTypes.func.isRequired,
};

DisplayModeSwitcher.defaultProps = {};

export default DisplayModeSwitcher;
