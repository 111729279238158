import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Helpers from '../../Helpers';
import ButtonDrop from '../ButtonDrop';
import './index.sass';

const TemplatePreviewButtons = props => {
  const {
    onEditTemplate,
    onUseTemplate,
    onSettingsTemplate,
    template,
    folder,
    userMainRole,
  } = props;
  const { parentFolder } = folder;
  const editableFolders = Helpers.getEditableFolderSettingsByRole(userMainRole);
  const folderName = folder.label ? folder.label : folder.name;
  const showSettings = editableFolders.includes(folderName)
    || editableFolders.includes(parentFolder);

  return (
    <div className="template-preview-buttons">
      {showSettings && (
        <ButtonDrop className="" title="Settings" buttonStyle="stroke">
          <ul>
            <li
              onKeyPress={onSettingsTemplate}
              role="menuitem"
              tabIndex="0"
              onClick={onSettingsTemplate}
            >
              Settings
            </li>
            <li
              onKeyPress={onEditTemplate}
              role="menuitem"
              tabIndex="0"
              onClick={onEditTemplate}
            >
              Edit
            </li>
          </ul>
        </ButtonDrop>
      )}
      <button className="use-tpl-button" onClick={event => onUseTemplate(event, template)}>
        <span style={{ fontSize: '14px' }}> USE TEMPLATE </span>{' '}
      </button>
    </div>
  );
};

TemplatePreviewButtons.propTypes = {
  onEditTemplate: PropTypes.func.isRequired,
  onUseTemplate: PropTypes.func.isRequired,
  onSettingsTemplate: PropTypes.func.isRequired,
  template: PropTypes.instanceOf(Object).isRequired,
  folder: PropTypes.instanceOf(Object).isRequired,
  userMainRole: PropTypes.string.isRequired, // TODO: use pre-defined values instead.
};

export default TemplatePreviewButtons;
