import PropTypes from 'prop-types';
import React from 'react';
import { MESSAGE_COMPOSER_TYPE, MESSAGE_DESIGNER_TYPE } from '../Config/Constants';

const TwitterPostviewPropType = {
  enabled: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired,
  profilePic: PropTypes.string.isRequired,
  twitterProfilePic: PropTypes.string.isRequired,
  profileName: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  type: PropTypes.oneOf([MESSAGE_COMPOSER_TYPE, MESSAGE_DESIGNER_TYPE]).isRequired,
};

export default TwitterPostviewPropType;
