import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import './index.sass';
import EvaTooltip from '../EvaTooltip';
import styles from './InputIconGridlessStyles';

/**
 * Gridless Implementation of icon input and compatible with redux form.
 * @param {Object}  params
 * @param {string}  param.className Custom class name to apply to the component
 * @param {string}  params.type Type of input
 * @param {string}  params.icon URL to the SVG (ideally) of the icon to display
 * @param {string}  params.input Redux-Form input object
 * @param {string}  params.meta  Redux-Form meta object for validations and state.
 * @param {number}  params.maxLength Maximum length of input
 * @param {boolean} params.disable Used if the field should editable or not.
 * @param {string}  params.tooltipTitle Used in for tooltip that would show over the icon (if any)
 * @param {Object}  params.classes MUI Injected classes.
 */
const InputStandard = ({
  className,
  type,
  placeholder,
  icon,
  input,
  meta: { touched, submitFailed, valid },
  // focused,
  maxLength,
  disable,
  // color,
  tooltipTitle,
  classes,
}) => {
  const iconClass = icon ? `input-eva-${icon}-icon` : 'iconless';
  /* eslint-disable */
  const tooltipedElement = tooltipTitle ? (
    <EvaTooltip title={tooltipTitle}>
      <div className={classes.iconContainer}>
        <i className={iconClass} />
      </div>
    </EvaTooltip>
  ) : (
    <div className={classes.iconContainer}>
      <i className={iconClass} />
    </div>
  );
  /* eslint-enable */
  let errorClass = '';
  if (touched || submitFailed) {
    if (valid) errorClass = '';
    else errorClass = 'has-errors';
  }
  return (
    <div className={`input-box ${className} ${classes.root} ${errorClass}`}>
      {tooltipedElement}
      <input
        {...input}
        type={type}
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={disable ? 'disabled' : ''}
        autoComplete="off"
      />
      <div className={classes.voidInputBox} />
    </div>
  );
};

export default withStyles(styles)(InputStandard);
