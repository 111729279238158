import { ACTIONS } from '../Actions/userActions';

const initialState = {
  username: '',
  email: '',
  public_id: '',
  display_name: '',
  new_message_replies: false,
  group_activity_status: false,
  language_preference: {},
  is_verified_email: true,
  is_incomplete: true,
  selectedOrganization: {},
  memberPublicId: '',
  member: {},
  isAdmin: false,
  isSuperAdmin: false,
  unsubscribeProcess: null,
};

const userProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_USER_PROFILE:
      return { ...action.payload };
    case ACTIONS.SET_IS_ADMIN:
      return {
        ...state,
        isAdmin: action.payload,
      };
    case ACTIONS.SET_IS_SUPER_ADMIN:
      return {
        ...state,
        isSuperAdmin: action.payload,
      };
    case ACTIONS.GET_MEMBER_PROFILE:
      return {
        ...state,
        ...action.payload,
      };
    case ACTIONS.UPDATE_SUGGESTIONS_SETTINGS:
      return {
        ...state,
        ...action.payload,
      };
    case ACTIONS.SELECT_ORG:
      return { ...state, selectedOrganization: { ...action.payload } };
    case ACTIONS.GET_UNSUBSCRIBE_CASE: {
      return {
        ...state,
        unsubscribeProcess: action.payload,
      };
    }
    default:
      return state;
  }
};

export default userProfileReducer;
