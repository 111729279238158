import React, { Component } from 'react';
import { connect } from 'react-redux';
import { enableRightDrawer } from '../../Actions/rightDrawerActions';
import { clearGroups } from '../../Actions/groupsActions';
import { changeTab } from '../../Actions/middleMenuActions';
import CreateGroupPropType from '../../PropTypes/CreateGroupPropType';
import CreateForm from '../../Components/CreateForm';
import EvaTooltip from '../../Components/EvaTooltip';

class CreateGroups extends Component {
  handleClick = e => {
    e.preventDefault();
    this.props.changeTab('', 0);
    this.props.clearGroups();
    this.props.enableRightDrawer({
      payload: {
        enable: true,
        element: <CreateForm />,
        title: 'Create Group',
        secondTitle: '',
        cleaner: this.props.clearGroups,
      },
    });
  };

  render = () => (
    <EvaTooltip title="New Group">
      <button onClick={this.handleClick} className="icon-button group-create" />
    </EvaTooltip>
  )
}

CreateGroups.propTypes = CreateGroupPropType;
const mD = {
  enableRightDrawer,
  clearGroups,
  changeTab,
};

export default connect(null, mD)(CreateGroups);
