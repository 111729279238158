import React from 'react';
import ButtonDrop from '../ButtonDrop';

const ButtonMessageSend = ({ onDraftTestSend, onSend }) => (
  <ButtonDrop buttonStyle="fill" listAlign="right" className="button-send" title="Send" onClick={onSend}>
    <ul>
      <li
        onKeyPress={onSend}
        role="menuitem"
        tabIndex="0"
        onClick={onSend}
      >
        Confirm and send
      </li>
      <li
        onKeyPress={onDraftTestSend}
        role="menuitem"
        tabIndex="0"
        onClick={onDraftTestSend}
      >
        Send test
      </li>
    </ul>
  </ButtonDrop>
)

export default ButtonMessageSend;
