import colors from '../../Theme/Colors';

const styles = {
  container: {
    height: '100vh',
  },
  contentContainer: {
    position: 'relative',
    display: 'flex',
    height: '80%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bubbles: {
    width: '100%',
    height: 'calc(50vh - 164px)',
    position: 'absolute',
    bottom: '0',
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'url("https://s3.amazonaws.com/diocesan-eva/shorterner/sky3x.png")',
    backgroundSize: 'cover',
  },
  content: {
    width: '257px',
    textAlign: 'center',
    '&>img': {
      width: '104px',
    },
    '&>.oops': {
      fontSize: '60px',
      fontFamily: 'GothamSSm-Bold',
      fontWeight: 'lighter',
      color: colors.evaGrey,
      margin: '0',
      marginTop: '30px',
    },
    '&>.message': {
      fontFamily: 'GothamSSm-Medium',
      fontSize: '16px',
      color: colors.resultTotal,
      margin: '0',
      marginTop: '20px',
    },
    '&>button': {
      fontFamily: 'GothamSSm-Bold',
      height: '40px',
      borderRadius: '100px',
      border: `solid 2px ${colors.amethyst}`,
      background: 'white',
      color: colors.amethyst,
      fontSize: '16px',
      display: 'inline-block',
      width: '100%',
      marginTop: '30px',
      '&:hover': {
        background: '#dddddd',
      },
    },
  },
  '@media (min-width: 768px)': {
    bubbles: {
      height: 'calc(50vh - 105px)',
    },
    content: {
      width: '508px',
      '&>img': {
        width: '120px',
      },
      '&>.oops': {
        fontSize: '80px',
        marginTop: '15px',
        fontWeight: 'lighter',
        color: colors.evaGrey,
      },
      '&>.message': {
        fontSize: '20px',
        marginTop: '10px',
        '&>br': {
          display: 'none',
        },
      },
      '&>button': {
        marginTop: '40px',
        maxWidth: '250px',
      },
    },
  },
};

export { styles };
