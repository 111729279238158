import PropTypes from 'prop-types';

const ButtonPropType = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  mini: PropTypes.bool,
  colored: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default ButtonPropType;
