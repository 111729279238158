import PropTypes from 'prop-types';

const SimpleModalPropType = {
  title: PropTypes.string.isRequired,
  content: PropTypes.array.isRequired, // react nodes
  open: PropTypes.bool,
  close: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default SimpleModalPropType;
