export const ACTIONS = {
  ENABLE: 'rightDrawerManageTemplate/enable',
  SET_ENFORCE_FOCUS: 'rightDrawerManageTemplate/set_enforce_focus',
};

export const enableRightDrawer = value => dispatch => {
  dispatch({ type: ACTIONS.ENABLE, ...value });
  const rightDrawer = document.querySelector(
    '.right-drawer.drawer-manage-template .drawer-md-up',
  );
  if (rightDrawer && window.innerWidth >= 1024) {
    rightDrawer.style.display = 'block';
  }
};

export const setEnforceFocus = enforceFocus => ({
  type: ACTIONS.SET_ENFORCE_FOCUS,
  payload: { enforceFocus },
});
