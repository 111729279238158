const cases = {
  1: {
    question: 'Are you sure you want to unsubscribe from email communications?',
    // eslint-disable-next-line
    warningText:
      'By unsubscribing you will be removed as admin from this organization and any groups in it, and you will no longer receive any communications from this organization.',
    afterConfirmText: 'Unsubscribe successful',
    finalNotice: 'You will no longer receive communications from this organization.',
    yesButtonMessage: 'Yes, I want to unsubscribe from this organization',
    noButtonMessage: 'No, I want to remain in this organization',
  },
  2: {
    question: 'Are you sure you want to unsubscribe from email communications?',
    // eslint-disable-next-line
    warningText:
      'By unsubscribing you will be removed as group admin from this group and you will no longer receive any communications from this group.',
    afterConfirmText: 'Unsubscribe successful',
    finalNotice: 'You will no longer receive communications from this group.',
    yesButtonMessage: 'Yes, I want to unsubscribe from this group',
    noButtonMessage: 'No, I want to remain in this group',
  },
  3: {
    question: 'Are you sure you want to unsubscribe from email communications?',
    // eslint-disable-next-line
    warningText:
      'By unsubscribing you will be removed from this organization and administration of any of its groups, and you will no longer receive any communications from this organization.',
    afterConfirmText: 'Unsubscribe successful',
    finalNotice: 'You will no longer receive communications from this organization.',
    yesButtonMessage: 'Yes, I want to unsubscribe from this organization',
    noButtonMessage: 'No, I want to remain in this organization',
  },
  4: {
    question: 'Are you sure you want to unsubscribe from email communications?',
    // eslint-disable-next-line
    warningText:
      'By unsubscribing you will be removed from this organization and all of its groups, and you will no longer receive any communications from this organization.',
    afterConfirmText: 'Unsubscribe successful',
    finalNotice: 'You will no longer receive communications from this organization.',
    yesButtonMessage: 'Yes, I want to unsubscribe from this organization',
    noButtonMessage: 'No, please continue sending me communications',
  },
  5: {
    question: 'Are you sure you want to unsubscribe from email communications?',
    // eslint-disable-next-line
    warningText:
      'By unsubscribing you will be removed from this group and will no longer receive any communications from this group.',
    afterConfirmText: 'Unsubscribe successful',
    finalNotice: 'You will no longer receive communications from this group.',
    yesButtonMessage: 'Yes, I want to unsubscribe from this group',
    noButtonMessage: 'No, please continue sending me communications',
  },
};

export default cases;
