import { ACTIONS } from '../Actions/localeActions';
import { defaultLanguageCode, translatedMessages } from '../Translations/intl/messages';

const browserLanguage = window.navigator.language.split(/[-_]/)[0];

const initialState = {
  locale: translatedMessages[browserLanguage] ? browserLanguage : defaultLanguageCode,
};

const localeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.CHANGE_LANGUAGE:
      return { ...state, locale: action.payload };

    default:
      return state;
  }
};

export default localeReducer;
