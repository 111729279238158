import AbstractAdapter from '../AbstractAdapter';
import TreeNavigationAdapter from './TreeNavigationAdapter';
import TreeItem from '../../Components/TreeNavigator/TreeItem';

export default class TreeItemAdapter extends AbstractAdapter {
  adapt(selected): TreeItem {
    const treeItem = new TreeItem();
    const newSubFolders = [];

    if (this.object) {
      treeItem.publicId = this.object.publicId;
      treeItem.isSystemFolder = this.object.isSystemFolder;
      treeItem.label = this.object.name;
      treeItem.comments = this.object.comments;
      treeItem.parentFolder = this.object.parentFolder
        ? this.object.parentFolder
        : '';
      treeItem.publicIdParentFolder = this.object.publicIdParentFolder
        ? this.object.publicIdParentFolder
        : '';
      treeItem.createdAt = this.object.createdAt;
      if (selected && selected === this.object.publicId) {
        treeItem.isSelected = true;
      }

      if (this.object.subFolders && this.object.subFolders.length > 0) {
        this.object.subFolders.forEach(subFolder => {
          const folder = subFolder;
          folder.parentFolder = this.object.name;
          folder.publicIdParentFolder = this.object.publicId;
          newSubFolders.push(folder);
        });
      }

      if (/^Featured$/gi.test(this.object.name)) {
        treeItem.iconDefault = 'folder-star-icon';
        treeItem.iconActive = 'folder-star-icon active';
        treeItem.iconHover = 'folder-star-icon active';
      } else if (/^favorites$/gi.test(this.object.name)) {
        treeItem.iconDefault = 'folder-heart-icon';
        treeItem.iconActive = 'folder-heart-icon active';
        treeItem.iconHover = 'folder-heart-icon active';
      } else if (/^shared with me$/gi.test(this.object.name)) {
        treeItem.iconDefault = 'folder-share-icon';
        treeItem.iconActive = 'folder-share-icon active';
        treeItem.iconHover = 'folder-share-icon';
      } else {
        treeItem.iconDefault = 'folder-icon';
        treeItem.iconActive = 'folder-icon active';
      }

      treeItem.childrens = new TreeNavigationAdapter(newSubFolders).adapt(
        selected,
      );

      return treeItem;
    }

    return null;
  }
}
