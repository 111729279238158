// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../Theme/Icons/close.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "#page-designer .designer-header{padding:0 40px;display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;background-color:white;border-bottom:2px solid #d9d9d9;-webkit-box-shadow:0 0 10px 0 rgba(0,0,0,0.1);box-shadow:0 0 10px 0 rgba(0,0,0,0.1)}#page-designer .designer-header .left-header-wrapper,#page-designer .designer-header .right-header-wrapper{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}#page-designer .designer-header .right-header-wrapper button{margin-left:20px}#page-designer .designer-header .btn-designer-close{margin-left:-20px;width:60px;height:60px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center}#page-designer .designer-header .title{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;font-family:\"GothamSSm-Book\",Arial,sans-serif;font-size:20px;color:#4a4a4a}#page-designer .settings-buttons{text-align:center;background:red}#page-designer .settings-buttons button{min-width:250px}.unlayer-wrapper{margin:0 auto;max-width:1200px;min-width:1024px}.unlayer-wrapper>div{min-width:1024px;min-height:calc(100vh - 62px) !important}\n", ""]);
// Exports
module.exports = exports;
