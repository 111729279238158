import AXIOS from '../Config/AxiosConfig';
import headers from '../Api/Helper';
import FileSaver from 'file-saver';

export const downloadFile = (url, fileName) => {
  console.log(headers())
  AXIOS.get(url, { responseType: 'blob', headers: headers() }).then((response) => {
    FileSaver.saveAs(response.data, fileName);
  }).catch((response) => {
    console.error('Could not download a file.', response);
  });
};

