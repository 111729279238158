import React, { Component } from 'react';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import ClearIcon from '@material-ui/icons/Clear';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { KeyboardArrowLeft } from '@material-ui/icons';
import { connect } from 'react-redux';
import { getDayOfTheWeek } from '../../Helpers/date';
// Components
import EvaTooltip from '../EvaTooltip';
import CreateForm from '../../Components/CreateForm';
// Action Creators
import { enableRightDrawer } from '../../Actions/rightDrawerActions';
import RightDrawerPropType from '../../PropTypes/RightDrawerPropType';
import { updateGroupsReducer } from '../../Actions/groupsActions';
import { changeTab } from '../../Actions/middleMenuActions';
import './index.sass';
import drawerStyles from './styles';

const styles = theme => drawerStyles(theme);

class RightDrawer extends Component {
  state = {
    side: 'right',
  };

  handleDrawerClose = () => {
    const { cleaner } = this.props;
    this.props.updateGroupsReducer({
      payload: { type: 'subGroup', element: <CreateForm /> },
    });
    if (cleaner) {
      this.props.cleaner();
    }
    this.props.enableRightDrawer({
      payload: { enable: false, element: <div />, menuOptions: null },
    });
    if (window.innerWidth >= 1024) {
      document.querySelector('.right-drawer.drawer-standard > .drawer-md-up').style.display =
        'none';
    }
  };

  handleChangeAnchor = event => this.setState({ side: event.target.value });

  render() {
    const {
      classes: {
        paper,
        tooltip,
        drawerHeader,
        tooltipPlacementLeft,
        tooltipPlacementRight,
        tooltipPlacementTop,
        tooltipPlacementBottom,
        titleBox,
      },
      element,
      status,
      title,
      subtitle,
      secondTitle,
      menuOptions,
      isBack,
      subtitleDate,
      disableEnforceFocus,
    } = this.props;

    const { side } = this.state;

    return (
      <div className={`right-drawer drawer-standard ${!status ? 'disable' : ''}`}>
        <Hidden smUp>
          <Drawer
            anchor={side}
            open={status}
            classes={{
              paper,
            }}
            disableEnforceFocus={disableEnforceFocus}
          >
            <div className={drawerHeader}>
              <Tooltip
                title="Super Admin"
                classes={{
                  tooltip,
                  tooltipPlacementLeft,
                  tooltipPlacementRight,
                  tooltipPlacementTop,
                  tooltipPlacementBottom,
                }}
              >
                <IconButton onClick={this.handleDrawerClose}>
                  <ClearIcon />
                </IconButton>
              </Tooltip>

              <div className="drawer-title">{title}</div>
            </div>
            <div className="right-drawer-content">{element}</div>
          </Drawer>
        </Hidden>
        <Hidden xsDown>
          <div className="drawer-md-up">
            <Drawer
              anchor={side}
              open={status}
              classes={{
                paper,
              }}
              disableEnforceFocus={disableEnforceFocus}
            >
              <div className="drawer-wrapper">
                <div className={drawerHeader}>
                  <div className={titleBox}>
                    {!isBack && (
                      <EvaTooltip title="Close">
                        <IconButton onClick={this.handleDrawerClose}>
                          <ClearIcon />
                        </IconButton>
                      </EvaTooltip>
                    )}
                    {isBack && (
                      <EvaTooltip title="Back">
                        <IconButton onClick={this.handleDrawerClose}>
                          <KeyboardArrowLeft />
                        </IconButton>
                      </EvaTooltip>
                    )}
                    {menuOptions ? (
                        <div>
                          <div className="drawer-title-small" title={title}>
                            {title}
                          </div>
                          <div className="drawer-subtitle-small">
                            {subtitle} | {getDayOfTheWeek(subtitleDate)}
                          </div>
                        </div>
                      ) :
                      (
                        <div className="drawer-title">{title}</div>
                      )}
                  </div>
                  {secondTitle && <div className="drawer-second-title">{secondTitle}</div>}
                  {menuOptions && <div className="drawer-menu-options">{menuOptions}</div>}
                </div>
                <div className="right-drawer-content">{element}</div>
              </div>
            </Drawer>
          </div>
        </Hidden>
      </div>
    );
  }
}

const mS = ({
  rightDrawerReducer: {
    enable,
    element,
    isBack,
    title,
    secondTitle,
    cleaner,
    menuOptions,
    subtitle,
    subtitleDate,
    disableEnforceFocus,
  },
}) => ({
  status: enable,
  element,
  isBack,
  title,
  secondTitle,
  cleaner,
  menuOptions,
  subtitle,
  subtitleDate,
  disableEnforceFocus,
});

const mD = {
  enableRightDrawer,
  updateGroupsReducer,
  changeTab,
};

RightDrawer.propTypes = RightDrawerPropType;

export default connect(
  mS,
  mD,
)(withStyles(styles, { withTheme: true })(RightDrawer));
