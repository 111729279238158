import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import './index.sass';

const PermissionsConfirmation = ({ isChecked, onChange }) => (
  <div className="permissions-confirmation">
    <Checkbox
      className="confirmation-checkbox"
      checked={isChecked}
      onChange={(e) => onChange(e.target.checked)}
      value="checked"
      color="default"
    />
    <label>I have permission from these individuals to send them communications.</label>
  </div>
);


export default PermissionsConfirmation;
