import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

const ModalConfirm = ({
  handleClose,
  statusDialog,
  handleConfirm,
  message,
}) => (
  <Dialog
    open={statusDialog}
    onClose={handleClose}
    className="return-to-edit"
    style={{
      backgroundColor: 'transparent',
      borderRadius: '10px',
      zIndex: 1500,
    }}
  >
    <DialogContent className="return-to-edit-modal-message">
      <div className="attention-Settings" dangerouslySetInnerHTML={{ __html: message }} />
      <div className="modal-actions">
        <button onClick={handleConfirm} className="button">
          Yes
        </button>
        <button onClick={handleClose} className="button">
          No
        </button>
      </div>
    </DialogContent>
  </Dialog>
);

ModalConfirm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  statusDialog: PropTypes.bool.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};

export default ModalConfirm;
