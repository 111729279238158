import React, { memo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

/**
 *
 * @param {Object}    props
 * @param {string}    props.warningText Text to be displayed after the question
 * @param {string}    props.question Text to be displayed as question
 * @param {Object}    props.group Group information
 * @param {Function}  props.cancelFunction Action to execute if the user doesn't want
 * to proceed
 * @param {Function}  props.confirmFunction Action to execute if the user proceeds
 * @param {Object}    props.classes Styles to apply to the HTML.
 * @param {Component} props.buttonsFragment Buttons actions.
 */
function ConfirmComponent({
  auxText,
  focusText,
  group: { name, organization },
  groupOrgPicture,
  classes: { confirmComponent },
  buttonsFragment,
  afterConfirm,
}) {
  return (
    <div className={confirmComponent}>
      <div className="group-org-header">
        <img src={groupOrgPicture} className="image-group-org" alt="Group or org" />
        <h2 className="org-name">{organization}</h2>
        <h4 className="group-name">{name}</h4>
      </div>
      <div className="confirm-box">
        <div className={`question ${afterConfirm ? 'reduced-height' : ''}`}>
          <h1>{focusText}</h1>
        </div>
        <div className={`warning-text ${afterConfirm ? 'reduced-height' : ''}`}>
          <h3>{auxText}</h3>
        </div>
        <div className="buttons-box">{buttonsFragment}</div>
      </div>
    </div>
  );
}

const StyledComponent = withStyles(styles)(memo(ConfirmComponent));

export default StyledComponent;
