import PropTypes from 'prop-types';
import React from 'react';
import { MESSAGE_COMPOSER_TYPE, MESSAGE_DESIGNER_TYPE } from '../Config/Constants';

const WebPostviewPropType = {
  content: PropTypes.string.isRequired,
  type: PropTypes.oneOf([MESSAGE_COMPOSER_TYPE, MESSAGE_DESIGNER_TYPE]).isRequired,
};

export default WebPostviewPropType;
