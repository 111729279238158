import React from 'react';
import SuccessIcon from '../../Theme/Icons/success.png';
import SuccessMessagePropType from '../../PropTypes/SuccessPropType';
import './index.sass';

const SuccessMessage = props => (
  <div className="form__success-message">
    <p className="form__success-text">{props.success}</p>
    <button
      onClick={() => {
        props.hideMessage();
      }}
      className="form_success-close"
      type="button"
      aria-label="Close"
    >
      <img src={SuccessIcon} alt="SuccessIcon" />
    </button>
  </div>
);

SuccessMessage.propTypes = SuccessMessagePropType;

export default SuccessMessage;
