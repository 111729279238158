import PropTypes from 'prop-types';

const FolderSettingsPropType = {
  enableRightDrawerTemplates: PropTypes.func.isRequired,
  getFoldersList: PropTypes.func.isRequired,
  createFolder: PropTypes.func.isRequired,
  updateFolder: PropTypes.func.isRequired,
  setEnforceFocus: PropTypes.func.isRequired,
  clearOrganizationsSuggestions: PropTypes.func.isRequired,
  allGroupsGet: PropTypes.func.isRequired,
  mainUserRole: PropTypes.string.isRequired,
  viewType: PropTypes.string.isRequired,
};

export default FolderSettingsPropType;
