import { ACTIONS } from '../Actions/organizationActions';

const initialState = {
  alreadyExistAdmin: false,
  adminsNotificationList: [],
  fbPages: [],
  group: {},
  groups: [],
  isFbExpired: false,
  isFbLinked: false,
  isFbLoading: false,
  loadingOrganizationsList: false,
  organization: {},
  organizations: [],
  organizationsListLoaded: false,
  organizationSuggestionValue: '',
  organizationSuggestionValueId: '',
  organizationsSuggestions: [],
  searched: [],
  searchedOrg: [],
  temporaryAdmin: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.LOAD:
      return { ...state, organization: { ...action.payload } };
    case ACTIONS.LOADING_GET_ALL:
      return { ...state, loadingOrganizationsList: true, organizationsListLoaded: false };
    case ACTIONS.LOAD_ALL:
      return {
        ...state,
        organizations: [...action.payload],
        loadingOrganizationsList: false,
        organizationsListLoaded: true,
      };
    case ACTIONS.LOAD_ORGANIZATION_GROUP:
      return { ...state, group: { ...action.payload } };

    case ACTIONS.LOAD_ORGANIZATION_MEMBER:
      return {
        ...state,
        temporaryAdmin: action.payload.temporary_admin || false,
        alreadyExistAdmin: action.payload.already_exist_admin || false,
      };
    case ACTIONS.LOAD_ORGANIZATION_GROUPS:
      return { ...state, groups: action.payload };
    case ACTIONS.SELECT:
      // TODO: refactor to more elegant way.
      const temporaryAdmin = state.organizations.find(obj => obj.organization.public_id === action.payload.public_id).temporary_admin;
      return {
        ...state,
        organization: { ...action.payload },
        temporaryAdmin,
      };
    case ACTIONS.LOAD_ORGANIZATION_LOOKUP:
      return { ...state, organizationsSuggestions: [...action.payload] };
    case ACTIONS.CLEAR_ORGANIZATION_SUGGESTIONS:
      return {
        ...state,
        organizationsSuggestions: initialState.organizationsSuggestions,
      };
    case ACTIONS.SET_ORGANIZATION_SUGGESTIONS: {
      const { suggestions } = action.payload;
      return {
        ...state,
        organizationsSuggestions: suggestions,
      };
    }
    case ACTIONS.SET_ORG_ADMINS:
      return {
        ...state,
        adminsNotificationList: action.payload,
      };
    case ACTIONS.UPDATE_ORG_ADMIN_REPLY_NOTIFICATION:
      return {
        ...state,
        adminsNotificationList: state.adminsNotificationList.map(
          m => m.publicId === action.payload ? {...m, isReplyNotification: !m.isReplyNotification} : {...m
        }),
      }
    case ACTIONS.SEARCH_ORGANIZATION_UPDATE_VALUE:
      return {
        ...state,
        organizationSuggestionValue: action.payload,
      };
    case ACTIONS.SEARCH_ORGANIZATION_UPDATE_VALUE_ID:
      return {
        ...state,
        organizationSuggestionValueId: action.payload,
      };
    case ACTIONS.ORG_FB_UPDATE:
      const { isFbExpired, isFbLinked, fbPages } = action.payload;
      return {
        ...state,
        isFbExpired,
        isFbLinked,
        fbPages,
      };

    case ACTIONS.LOADING_FB_CONNECTION:
      return {
        ...state,
        isFbLoading: action.payload,
      }

    default:
      return state;
  }
};
