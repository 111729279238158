import React from 'react';
import { Link } from 'react-router-dom';
import './index.sass';

const ForgotPasswordMessage = () => (
  <div className="container-center forgot-password-message">
    <div className="login__title">
      <p className="logo-evangelus">EVANGELUS</p>
    </div>
    <h1 className="title-forgot-password">Forgot Password</h1>
    <p className="content-forgot-password">
      Follow the instructions sent to your email or phone to reset your
      password.
    </p>
    <div className="links">
      <span className="links__psw">
        <Link to="/request-password">Request it again?</Link>
      </span>
    </div>
  </div>
);
export default ForgotPasswordMessage;
