import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Scrollbars } from 'react-custom-scrollbars';
import TemplatePageSidePanel from './TemplatePageSidePanel';
import TreeNavigatorActions from './TreeNavigatorActions';
import TreeNavigator from '../TreeNavigator';
import TemplateList from '../TemplateList';
import CustomRighHeader from '../CustomRightHeader';
import FolderSettings from '../../Containers/FolderSettings';
import TreeNavigationAdapter from '../../Adapters/TreeNavigation/TreeNavigationAdapter';
import { enableRightDrawer } from '../../Actions/rightDrawerActions';
import { enableRightDrawerMessage } from '../../Actions/rightDrawerMessageActions';
import { enableRightDrawerTemplates } from '../../Actions/rightDrawerTemplatesActions';
import {
  getFoldersList,
  getFolderById,
  getFolderByIdPaged,
  updateFolderList,
  setTree,
  selectFolder,
  saveFeaturedData,
  showButtonAnimation,
  changeEditTemplateFlag,
  updateFolderName,
} from '../../Actions/templateActions';
import { changeTab, updateIsTemplateFlag } from '../../Actions/messageActions';
import { getPendingReplies } from '../../Actions/messagesActions';
import { statusLoading } from '../../Actions/loadingActions';
import ChangeMainTabs from '../../Actions/mainTabsActions';
import { getNotificationsMessages } from '../../Actions/notificationsActions';
import TemplatePropType from '../../PropTypes/TemplatePropType';
import { TEMPLATE_PAGE_SIZE_GRID } from '../../Config/Constants';
import './styles.sass';
import { getFlatFolders } from '../../Helpers/getFlatFolders';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

class TemplatesPage extends Component {
  state = {
    selectedTreeItem: null,
    folderList: [],
    width: window.innerWidth,
  };

  componentDidMount = () => {
    this.props.getPendingReplies();

    this.mountComponent();
    localStorage.removeItem('statusDesignerRedirect');
    this.props.ChangeMainTabs({ payload: { value: 2 } });
  };

  // TODO: unify
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.organizationId && !nextProps.isLoaded && !nextProps.isLoading) {
      this.props.getNotificationsMessages();
    }
  }

  componentDidUpdate = prevProps => {
    if (prevProps.match.params.publicId !== this.props.match.params.publicId) {
      this.getReorderFolder();
    }
  };
  componentWillUnmount() {
    this.getReorderFolder();
  }
  getReorderFolder = deleteFolder => {
    const { folders } = this.props;
    const { selectedTreeItem } = this.state;
    const reorderFolder = [];
    const folderOrder = [
      'My Templates',
      'Featured',
      'Favorites',
      'Shared with me',
    ];
    const isFromNotificationId = this.props.location.state ?
      this.props.location.state.folder : null;

    if (folders) {
      folderOrder.forEach(folder => {
        folders.forEach((template, index) => {
          if (template.name === folder) {
            reorderFolder.push(template);
            folders.splice(index, 1);
          }
        });
      });
    }

    if (folders && folders.length > 0) {
      folders.forEach(template => {
        reorderFolder.push(template);
      });
    }

    let itemSelected;
    const flattenTree = getFlatFolders(reorderFolder);
    if (!selectedTreeItem) {
      itemSelected = flattenTree.find(item => {
        if (isFromNotificationId) {
          return item.publicId === isFromNotificationId;
        } else {
          return item.name === 'Featured';
        }
      });

      // If shared folder not found
      // if (!itemSelected) {
      //   itemSelected = flattenTree.find(i => i.name === 'Shared with me');
      // }
    } else {
      itemSelected = selectedTreeItem;
    }
    this.props.updateFolderList(reorderFolder);

    if (!deleteFolder) {
      this.selectItem(itemSelected);
    }

    this.setState({
      folderList: reorderFolder,
    });
  };

  selectItem = (treeItem, deleteFolder) => {
    const {
      profile_picture_url: profilePicture,
    } = this.props.userProfile;

    if (treeItem) {
      /* eslint-disable */
      treeItem.isSelected = true;
      /* eslint-enable */
      this.setState({ selectedTreeItem: treeItem });
      if (!deleteFolder) {
        this.props.getFolderByIdPaged(
          treeItem.publicId,
          undefined,
          undefined,
          1,
          TEMPLATE_PAGE_SIZE_GRID,
        );
      }
      this.buildTree(treeItem);
      if (this.state.width < 1000) {
        this.props.enableRightDrawerMessage({
          payload: {
            enable: true,
            element: (
              <TemplateList
                folderData={treeItem}
                getReorderFolder={this.getReorderFolder}
                folderList={this.state.folderList}
                handleTreeItemClick={this.handleTreeItemClick}
                mountComponent={this.mountComponent}
              />
            ),
            title: 'Templates',
            secondTitle: (
              <CustomRighHeader memberImage={profilePicture || undefined} />
            ),
          },
        });
      }
    }
  };

  mountComponent = deleteFolder => {
    this.props.getFoldersList().then(() => {
      this.getReorderFolder(deleteFolder);

      this.buildTree();
      if (
        this.props.tree &&
        this.props.tree.length > 0
      ) {
        let selected = this.props.tree.filter(
          item => item.publicId === this.props.saveAndViewFolder,
        );

        if (selected && selected.length > 0) {
          selected = selected.pop();
          this.selectItem(selected, deleteFolder);
        }
      }
    });
  };

  buildTree = (treeItem = null) => {
    const { selectedTreeItem, folderList } = this.state;
    let publicId = '';
    /* eslint-disable */
    if (treeItem) {
      publicId = treeItem.publicId;
    } else if (selectedTreeItem) {
      publicId = selectedTreeItem.publicId;
    }
    /* eslint-enable */
    const tree = new TreeNavigationAdapter(folderList).adapt(publicId);
    this.props.setTree(tree);
    tree.forEach(folder => {
      if (folder.label === 'Featured') this.props.saveFeaturedData(folder);
    });
  };

  handleTreeItemClick = treeItem => {
    const { profile_picture_url: profilePicture } = this.props.userProfile;
    const { templateFolderUpdated } = this.props;

    if (
      templateFolderUpdated &&
      treeItem.publicId !== templateFolderUpdated.publicId
    ) {
      this.props.updateFolderName();
    }
    this.props.selectFolder(treeItem);
    this.props.showButtonAnimation(false);
    this.setState({ selectedTreeItem: treeItem }, () => {
      this.buildTree();
    });
    this.props.statusLoading(true);
    this.props
      .getFolderByIdPaged(
        treeItem.publicId || treeItem.value,
        undefined,
        undefined,
        1,
        TEMPLATE_PAGE_SIZE_GRID,
        this.props.templateFolderSelected,
        treeItem,
      )
      .then(() => {
        this.props.statusLoading(false);
      });
    if (this.state.width < 1000) {
      this.props.enableRightDrawerMessage({
        payload: {
          enable: true,
          element: (
            <TemplateList
              folderData={treeItem}
              getReorderFolder={this.getReorderFolder}
              mountComponent={this.mountComponent}
              folderList={this.state.folderList}
              handleTreeItemClick={this.handleTreeItemClick}
            />
          ),
          title: 'Templates',
          secondTitle: (
            <CustomRighHeader memberImage={profilePicture || undefined} />
          ),
        },
      });
    }
  };

  handleActionTriggered = action => {
    const WindowHeight = window.innerHeight - 64;

    switch (action) {
      case 'new-folder':
        this.props.enableRightDrawerTemplates({
          payload: {
            enable: true,
            element: (
              <Scrollbars autoHeight autoHide autoHeightMax={WindowHeight}>
                <FolderSettings
                  getReorderFolder={this.getReorderFolder}
                  mountComponent={this.mountComponent}
                />
              </Scrollbars>
            ),
            title: 'New folder',
            secondTitle: '',
          },
        });
        break;
      case 'settings':
        this.props.history.push('/templates/new');
        break;
      default:
    }
  };

  handleNewTemplateClick = () => {
    this.props.changeEditTemplateFlag(false);
    this.props.updateIsTemplateFlag(true);
  }

  isDesktop = () => window.innerWidth > 999;

  render() {
    const { selectedTreeItem, folderList } = this.state;
    return (
      <Grid
        container
        alignItems="center"
        className="global-templates-container"
      >
        <Grid
          className="templates-container"
          container
          item
          xs={false}
          sm={12}
          md={12}
          lg={12}
        >
          <TemplatePageSidePanel
            item
            xs={12}
            sm={12}
            md={5}
            lg={5}
            style={{ height: window.innerHeight + 100 }}
            className="panel-left"
            header={[
              <h2 key="title"> Templates </h2>,
              <div key="buttons" className="button-align">
                <TreeNavigatorActions
                  key="TreeNavigatorActions"
                  className="tree-navigator-actions"
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  onActionTriggered={this.handleActionTriggered}
                  onNewTemplateClick={this.handleNewTemplateClick}
                />{' '}
              </div>,
            ]}
          >
            <TreeNavigator
              className="tree-navigator-panel main"
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              tree={this.props.tree}
              handleTreeItemClick={this.handleTreeItemClick}
              selectedTreeItem={selectedTreeItem}
            />
          </TemplatePageSidePanel>
          <TemplatePageSidePanel
            item
            xs={12}
            sm={12}
            md={7}
            lg={7}
            className="panel-right"
          >
            {' '}
            {selectedTreeItem && this.isDesktop() && (
              <TemplateList
                folderData={selectedTreeItem}
                getReorderFolder={this.getReorderFolder}
                mountComponent={this.mountComponent}
                folderList={folderList}
                handleTreeItemClick={this.handleTreeItemClick}
              />
            )}{' '}
          </TemplatePageSidePanel>{' '}
        </Grid>{' '}
      </Grid>
    );
  }
}

const mS = state => ({
  element: state.middleMenuReducer.element,
  tab: state.middleMenuReducer.tab,
  organizations: state.groupsReducer.organizations,
  selectedGroup: state.groupsReducer.selectedGroup,
  subGroups: state.groupsReducer.subGroups,
  folders: state.templateReducer.folders,
  saveAndViewFolder: state.templateReducer.saveAndViewFolder,
  tree: state.templateReducer.tree,
  featuredFolder: state.templateReducer.featuredFolder,
  organizationId: state.organizationReducer.organization.public_id,
  userProfile: state.userProfileReducer.member,
  templateFolderUpdated: state.templateReducer.templateFolderUpdated,
  templateFolderSelected: state.templateReducer.templateFolderSelected,
  isLoaded: state.notificationsReducer.isLoaded,
  isLoading: state.notificationsReducer.isLoading,
});

const mD = {
  enableRightDrawer,
  enableRightDrawerMessage,
  enableRightDrawerTemplates,
  getFoldersList,
  getFolderById,
  getFolderByIdPaged,
  updateFolderList,
  changeTab,
  setTree,
  selectFolder,
  ChangeMainTabs,
  saveFeaturedData,
  showButtonAnimation,
  changeEditTemplateFlag,
  getPendingReplies,
  statusLoading,
  updateFolderName,
  updateIsTemplateFlag,
  getNotificationsMessages,
};

TemplatesPage.propTypes = TemplatePropType;

export default withRouter(withStyles(styles)(connect(mS, mD)(TemplatesPage)));
