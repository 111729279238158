import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import SearchInput from '../SearchInput';
import EvaTooltip from '../EvaTooltip';
import PropTypes from 'prop-types';
import './index.sass';
import { SORT } from '../../Config/Constants';
import ButtonSelect from '../ButtonSelect';
import { Select } from '@mobiscroll/react';
import { mbscResponsiveOptions } from '../../Config/Constants/mobiscroll-props';

class GallerySort extends Component {
  formatFolderOptions = folders => {
    const { isAdmin } = this.props;
    const folderOptions = [];
    folders.forEach(folder => {
      if (isAdmin || folder.name === 'My Images' || !folder.global) {
        folderOptions.push({ text: folder.name, value: folder.publicId });
      }
    });
    return folderOptions;
  };

  render() {
    const {
      onMessageRemove,
      selectedImage,
      folder,
      handleFolder,
      moveSelectedFolder,
      isMoveFolder,
      changeMoveFolder,
      handleMove,
      handleSearch,
      isGlobalFolder,
      isAdmin,
      onSort,
      searchTerm,
    } = this.props;

    const isEdition = isAdmin || isGlobalFolder;

    const folderOptions = Array.isArray(folder) ? this.formatFolderOptions(folder) : [];
    return (
      <div id="gallery-sort-container">
        <div>
          <div className="search-container">
            <div className="search-input">
              <SearchInput value={searchTerm} onChange={handleSearch}/>
            </div>
          </div>
          <div className="gallery-toolbar">
            <div className="left-side">
              <ButtonSelect className="button-sort" values={SORT} defaultValue={null} onChange={onSort} />
            </div>
            <div className={`right-side ${selectedImage.length === 0 || !isEdition ? 'hidden' : ''}`}>
              <React.Fragment>
                <EvaTooltip title="Move to Folder">
                  <button className={`icon-button folder ${isMoveFolder ? 'active' : ''}`} onClick={changeMoveFolder}/>
                </EvaTooltip>
                <EvaTooltip title="Delete">
                  <button className="icon-button remove-button" onClick={() => onMessageRemove(true)}/>
                </EvaTooltip>
              </React.Fragment>
            </div>
          </div>

          {isMoveFolder && (
            <Grid container spacing={0} className="move-folder-container">
              <Grid item xs={10} className="move-folder-list">
                <Select
                  data={folderOptions}
                  value={moveSelectedFolder}
                  onChange={handleFolder}
                  responsive={mbscResponsiveOptions}
                  placeholder="Select folder"
                  filterPlaceholderText="Search"
                  buttons={['cancel']}
                />
              </Grid>
              <Grid item xs={2} className="move-title" onClick={() => handleMove()}>
                Move
              </Grid>
            </Grid>
          )}

        </div>
      </div>
    );
  }
}

GallerySort.propTypes = {
  searchTerm: PropTypes.string,
  onSort: PropTypes.func.isRequired,
  onMessageRemove: PropTypes.func.isRequired,
};

GallerySort.defaultProp = {
  searchTerm: '',
}

export default GallerySort;
