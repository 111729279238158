import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import searchIcon from '../../Theme/Icons/search.png';
import {
  clearOrganizationsSuggestions,
  getOrganizationsLookup,
  setOrganizationsSuggestions,
} from '../../Actions/organizationActions';
import { ROLE_ADMIN, ROLE_EVA_GROUP_ADMIN, ROLE_ORGANIZATION_ADMIN, ROLE_SUPER_ADMIN } from '../../Config/Constants';
import { ALL_ORGS_IDENTIFIER } from '../../Containers/FolderSettings/constants';
import { mbscResponsiveOptions } from '../../Config/Constants/mobiscroll-props';
import { Select } from '@mobiscroll/react';

class SearchOrganization extends PureComponent {
  componentDidMount() {
    this.props.clearOrganizationsSuggestions();
    // search all visible orgs, without appending the AllOrg value

    // if the user is a ROLE_GROUP_ADMIN use the currently selected org
    const { roles, currentOrg } = this.props;
    if (roles.includes(ROLE_EVA_GROUP_ADMIN)
      && !roles.includes(ROLE_ADMIN)
      && !roles.includes(ROLE_ORGANIZATION_ADMIN)
      && !roles.includes(ROLE_SUPER_ADMIN)) {
      if (currentOrg && currentOrg.name && currentOrg.public_id) {
        this.props.setOrganizationsSuggestions([
          { name: currentOrg.name, publicId: currentOrg.public_id },
        ]);
      }
    } else { // if not a ROLE_GROUP_ADMIN then search the orgs
      this.props.getOrganizationsLookup();
    }
  }

  handleSuggestionSelect = ({ value }) => {
    const { organizationsSuggestions } = this.props;

    // here this find() seems to be unneeded, but I prefer to leave it to validate that
    // there is an org with that publicId to prevent erros like when Enter is hit
    const selectedOrg = value === ALL_ORGS_IDENTIFIER ?
      {
        publicId: null,
        name: 'All Organizations',
      } : organizationsSuggestions.find(suggestion => suggestion.publicId === value);

    if (selectedOrg) {
      this.props.onChange(value, ALL_ORGS_IDENTIFIER);
    }
  };

  render() {
    const {
      value,
      organizationsSuggestions,
    } = this.props;

    const orgSuggestions = [{
      value: ALL_ORGS_IDENTIFIER,
      text: 'All Organizations',
    }].concat(
      organizationsSuggestions
        .map(suggestion => ({
            value: suggestion.publicId,
            text: suggestion.name,
          }),
        ),
    );

    return (
      <Select
        data={orgSuggestions}
        value={value}
        onChange={this.handleSuggestionSelect}
        responsive={mbscResponsiveOptions}
        filter
        placeholder="Select an Org"
        filterPlaceholderText="Search"
        buttons={['cancel']}
      />
    );
  }
}

SearchOrganization.propTypes = {
  value: PropTypes.string.isRequired,
  organizationsSuggestions: PropTypes.arrayOf(PropTypes.object).isRequired,
  getOrganizationsLookup: PropTypes.func.isRequired,
  clearOrganizationsSuggestions: PropTypes.func.isRequired,
  setOrganizationsSuggestions: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  currentOrg: PropTypes.shape({
    name: PropTypes.string,
    public_id: PropTypes.string,
  }).isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = state => ({
  organizationsSuggestions: state.organizationReducer.organizationsSuggestions,
  currentOrg: state.organizationReducer.organization,
  roles: state.loginReducer.roles,
});

const mapDispatchToProps = {
  getOrganizationsLookup,
  clearOrganizationsSuggestions,
  setOrganizationsSuggestions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchOrganization);
