import React, { Component } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import LinesEllipsis from 'react-lines-ellipsis';
import EvaTooltip from '../EvaTooltip';
import { MSG_STATUSES } from '../../Config/Constants';
import { getDateInTimezone } from '../../Helpers/date';
import { emailRegex } from '../../Helpers/regex';
import { MembersPreviews } from '../MembersPreviews';
import PropTypes from 'prop-types';
import './index.sass';

class CardMessage extends Component {
  renderStatusIcon() {
    switch (this.props.status) {
      case MSG_STATUSES.DRAFT:
        return (
          <EvaTooltip title="Draft">
            <i className="draft-icon" />
          </EvaTooltip>
        );

      case MSG_STATUSES.SCHEDULED:
        return (
          <EvaTooltip title="Scheduled">
            <i className="calendar-time-icon" />
          </EvaTooltip>
        );

      default:
        return this.props.message
          ? getDateInTimezone(moment(this.props.message.sentAt), this.props.organizationTimezone, 'MM/DD/YY')
          : this.props.date;
    }
  }

  /* eslint-disable */
  render() {
    const { members, isActive, content, publicId, sendByTitle, subject, isUnread, onSelect, hasAttachments } = this.props;

    return (
      <section className={`card-message ${isUnread ? 'unread' : ''}${isActive ? ' active' : ''}`}>

        <div className="custom-checkbox">
          <input type="checkbox" id={publicId} onChange={() => this.props.onCheck(publicId)}/>
          <label htmlFor={publicId}/>
        </div>

        <div
          className="card-inner-wrapper"
          role="button"
          onClick={() => onSelect(publicId)}
          onKeyPress={() => onSelect(publicId)}
        >
          <header>
            <div className="card-name">{sendByTitle}</div>
            <div className="card-additional-info">{this.renderStatusIcon()}</div>
          </header>
          <div className="card-content-wrapper">
            <div className="card-meta">
              {members.length > 0 && <MembersPreviews keyPrefix={publicId} members={members} limit={2} direction="column" />}
              {hasAttachments && (
                <div className="card-attachments">
                  <EvaTooltip title="Message has an attachment">
                    <span className="icon-mark paperclip" />
                  </EvaTooltip>
                </div>
              )}
            </div>

            <article>
              <h3 className="card-content-title">{subject}</h3>
              {content && (
                <div className="card-content-text">
                  <LinesEllipsis
                    text={content.replace(emailRegex, '')}
                    style={{ whiteSpace: 'pre-wrap' }}
                    maxLine="2"
                    ellipsis="..."
                    basedOn="letters"
                  />
                </div>
              )}
            </article>
          </div>

        </div>
      </section>
    )
  }
}

CardMessage.propTypes = {
  publicId: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  isUnread: PropTypes.bool,
  sendByTitle: PropTypes.string.isRequired,
  onCheck: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

CardMessage.defaultProps = {
  isActive: false,
  isUnread: false,
};

export default withRouter(CardMessage);
