import PropTypes from 'prop-types';
import React from 'react';

const FacebookPostviewPropType = {
  enabled: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired,
  profilePic: PropTypes.string.isRequired,
  profileName: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default FacebookPostviewPropType;
