// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../Theme/Icons/remove-icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".message-statistics{position:relative;margin-top:10px;margin-bottom:35px;text-align:center;background-color:#f3f3f3;font-family:\"GothamSSm-Book\",Arial,sans-serif;font-size:16px;line-height:normal;padding-top:20px;padding-bottom:20px}@media only screen and (min-width: 1024px){.message-statistics{padding-top:40px;padding-bottom:40px}}.message-statistics .stats-box{margin:0 0 20px}.message-statistics .stats-box img{margin:0 auto 5px}.message-statistics .stats-box figcaption{color:#9a66bf}.message-statistics .categories{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between}.message-statistics .categories article{-ms-flex:0 0 30%;flex:0 0 30%}.message-statistics .title{min-height:40px;color:#4a4a4a}.message-statistics .value{font-family:\"GothamSSm-Medium\",Arial,sans-serif;font-size:24px;color:#9a66bf;margin-bottom:3px}.message-statistics .details{margin-bottom:6px;color:#a9a9a9}.message-statistics .download-link{font-size:12px;color:#9a66bf !important}.message-statistics .download-link:disabled{opacity:0.5;cursor:default}.message-statistics .btn-close{position:absolute;right:0;top:0;width:40px;height:40px;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;background-color:#a9a9a9;mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center center;-webkit-mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center center}.message-statistics .btn-close:hover{background-color:#9a66bf}\n", ""]);
// Exports
module.exports = exports;
