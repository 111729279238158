import colors from '../../Theme/Colors';

export const styles = {
  root: {
    padding: '20px 0',
    position: 'relative',
  },
  avatar: {
    zIndex: '50',
    position: 'relative',
  },
  messages: {
    position: 'relative',
    zIndex: '0',
    marginTop: '-25px',
    marginLeft: '28px',
    '&>.notifications-eva-text': {
      marginTop: '10px',
    },
    '&>.notifications-eva-text:first-child': {
      marginTop: '0',
    },
    '&>.notifications-eva-text>div': {
      display: 'inline-block',
    },
    '& p': {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: colors.greyCheck,
      fontFamily: 'GothamSSm-Book',
      fontSize: '16px',
      color: '#000000',
      padding: '10px 15px',
      borderRadius: '20px',
      margin: '0',
      maxWidth: 'max-content',
      lineHeight: '20px',
      overflow: 'hidden',
    },
    '& p>span': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      display: 'inline-block',
    },
  },
};

