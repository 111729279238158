import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { change, formValueSelector, reduxForm } from 'redux-form';
import { Portal } from 'react-portal';
import TemplateSettingsForm from '../../Components/TemplateSettingsForm';
import { enableRightDrawer } from '../../Actions/rightDrawerManageTemplateActions';
import { enableRightDrawer as enableRightDrawerClassic } from '../../Actions/rightDrawerActions';
import { allGroupsGet, getAllGroups } from '../../Actions/groupsActions';
import { statusLoading } from '../../Actions/loadingActions';
import {
  changeFormValue,
  getFolderById,
  getFoldersList,
  saveTemplate,
  setSaveAndViewFolder,
  updateShareAllGroup,
  updateShareAllOrg,
  updateShareWithAllGroup,
  updateTemplate,
} from '../../Actions/templateActions';
import {
  getOrganization,
  getOrganizationGroup,
  searchOrganizationUpdateValue,
} from '../../Actions/organizationActions';
import { clearMessage } from '../../Actions/messageActions';
import { clearCompose } from '../../Actions/composeActions';
import { closeCustomModal } from '../../Actions/customModalActions';
import ModalConfirm from '../../Components/ModalConfirm';
import TemplateSettingsPropType from '../../PropTypes/TemplateSettingsPropType';
import snackBarStatus from '../../Actions/snackbarActions';
import { ALL_GROUPS_IDENTIFIER, NO_GROUPS_IDENTIFIER } from '../../Config/Constants';

class TemplateSettings extends Component {
  constructor(props) {
    super(props);
    props.searchOrganizationUpdateValue('');
    let admin = false;
    if (props.roles.includes('ROLE_SUPER_ADMIN')) {
      admin = true;
    }
    this.state = {
      renderComponent: true,
      foldersList: [],
      subFoldersFeatured: [],
      maxCharacters: 140,
      isSuperAdmin: admin,
      statusModal: false,
      settings: {},
      allOrg: false,
      shares: [],
      groups: [],
      loadingGroups: false,
      addShareEnabled: false,
      submitTemplate: true, // TODO: temporary changed
    };

    this.checkCharacters = this.checkCharacters.bind(this);
  }

  componentDidMount() {
    const {
      subject,
      selectedTemplate,
      folder,
      featured,
      featuredSubFolder,
      shareOrg,
      shareGroup,
      comments,
      sharedWith,
      groups,
      organizations,
    } = this.props;

    const tplTitle = subject || selectedTemplate.title; // TODO: find more optimal way to pass correct title

    this.props.changeFormValue(change, 'TemplateSettingsForm', 'title', tplTitle);

    this.props.getFoldersList().then(folderList => {
      const myTemplatesFolders = folderList.filter(
        folderElement => folderElement.name === 'My Templates',
      );
      const favoriteFolders = folderList.filter(
        folderElement => folderElement.name === 'Favorites',
      );

      const selectableList = [];

      if (myTemplatesFolders.length) {
        const selectableTemplates = [myTemplatesFolders[0]];
        if (
          Array.isArray(myTemplatesFolders[0].subFolders) &&
          myTemplatesFolders[0].subFolders.length
        ) {
          selectableTemplates.push(...myTemplatesFolders[0].subFolders);
        }
        selectableList.push(...selectableTemplates);
      }

      if (favoriteFolders.length) {
        const selectableFolders = [favoriteFolders[0]];
        if (Array.isArray(favoriteFolders[0].subFolders) && favoriteFolders[0].subFolders.length) {
          selectableFolders.push(...favoriteFolders[0].subFolders);
        }
        selectableList.push(...selectableFolders);
      }

      const featuredFolder = folderList.find(
        folderElement => folderElement.name === 'Featured' && folderElement.isSystemFolder,
      );

      const featuredFolderId =
        featuredFolder && featuredFolder.publicId ? featuredFolder.publicId : null;

      const subFoldersFeatured =
        featuredFolder && featuredFolder.subFolders
          ? [featuredFolder, ...featuredFolder.subFolders]
          : [];

      let shares = [];

      if (sharedWith && sharedWith.length > 0) {
        /* eslint-disable */
        shares = sharedWith.map(share => {
          const dataObj = !!share.shareToGroup ? share.shareToGroup : share.shareToOrg;
          const { name, publicId, organization } = dataObj;
          let description = '';
          if (organization) {
            const org = organizations.find(o => o.organization && o.organization.public_id === organization.publicId);
            description = `${org ? org.organization.name : ''} ${organization.city ? `- ${organization.city}` : ''}`;
          }

          return {
            title: name,
            description,
            publicId,
            group: !!share.shareToGroup,
          }
        });
        /* eslint-enable */
      }
      this.setState(
        {
          foldersList: selectableList,
          subFoldersFeatured,
          shares,
        },
        () => {
          const initData = {
            title: tplTitle || null,
            templateFolder: folder || null,
            featuredSwitch: featured || false,
            templateSubFolder: featuredSubFolder || featuredFolderId,
            selected_organizations: shareOrg || '',
            selected_groups: shareGroup || '',
            comments: comments || null,
          };
          this.props.initialize(initData);
        },
      );
    });
  }

  // TODO: remove?
  changeTemplateSubmit = (bool) => this.setState({ submitTemplate: bool });

  // eslint-disable-next-line
  handleSubmit = (isNavigateToView) => {
    const { comments, templateFolder, title, featuredSwitch, templateSubFolder } = this.props.templateSettings.values;

    const {
      selectedTemplate,
      noModal,
      shareAllOrg,
      shareWithAllGroups,
      editTemplateFlag,
      submitTemplate,
      history,
      files,
    } = this.props;

    // eslint-disable-next-line
    const { shares, allOrg } = this.state;
    let sharesArray = [];
    let sharesArrayGroup = [];
    if (allOrg === true) {
      sharesArray = [];
      sharesArrayGroup = [];
    } else {
      /* eslint-disable */
      Object.keys(shares).forEach(key =>
        shares[key].title === 'All Groups'
          ? sharesArrayGroup.push({ org: shares[key].publicId })
          : sharesArray.push(
          shares[key].group
            ? { shareToGroup: shares[key].publicId }
            : { shareToOrg: shares[key].publicId },
          ),
      );
      /* eslint-enable */
    }

    const settings = {
      title,
      folder: templateFolder,
      featured: featuredSwitch,
      comments,
      featuredFolder: templateSubFolder,
      sharedWith: sharesArray,
      shareAllGrp: sharesArrayGroup,
      shareAllOrg,
      shareWithAllGroups,
      files,
    };

    if (selectedTemplate.publicId) {
      // For template edit mode.
      settings.publicId = selectedTemplate.publicId;
    }

    if (!noModal && Object.keys(selectedTemplate).length > 0) {
      this.setState({ statusModal: true, settings });

      if (!editTemplateFlag) {
        delete settings.publicId;
        if (!submitTemplate) {
          // this.changeTemplateSubmit(true);
          this.saveOrUpdateTemplate(settings);
        }
      }
    } else if (!submitTemplate) {
      // this.changeTemplateSubmit(true);
      this.saveOrUpdateTemplate(settings);
    }

    localStorage.setItem('statusDesignerRedirect', true);
    this.props.setSaveAndViewFolder(templateFolder);
    if (isNavigateToView) {
      history.push('/templates');
      this.clearCompose();
    }
    this.props.onSubmit();
  };
  clearCompose = () => {
    this.props.clearMessage();
    this.props.closeCustomModal();
  };
  drawerAction = () => {
    this.props.statusLoading(false);
    const { afterUpdate } = this.props;
    if (typeof afterUpdate === 'function') {
      afterUpdate();
    }
    // this.changeTemplateSubmit(false);
    this.props.enableRightDrawer({
      payload: { enable: false, element: <div/> },
    });
    this.props.enableRightDrawerClassic({
      payload: { enable: false, element: <div/> },
    });
    this.props.snackBarStatus({
      payload: {
        enable: true,
        title: 'Template updated successfully.',
        type: 'success',
      },
    });
  };

  saveOrUpdateTemplate = settings => {
    this.props.statusLoading(true);
    const catchStatement = err => {
      this.props.statusLoading(false);
      this.changeTemplateSubmit(false);
      if (err instanceof Error) {
        // eslint-disable-next-line no-console
        console.error(err);
        return;
      }
      const defMessage = 'Template Settings could not be saved. ';
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: err.message || defMessage,
          type: 'error',
        },
      });
    };

    const action = settings.publicId ? this.props.updateTemplate : this.props.saveTemplate;

    action(settings).then(response => {
      if (response.code === 200) {
        this.drawerAction();
      } else {
        this.props.statusLoading(false);
      }
    })
      .catch(catchStatement);

    // set shareAllOrg to its initial value
    this.props.updateShareAllOrg(false);
  };

  handleFolderChange = ({ value }) =>
    this.props.changeFormValue(change, 'TemplateSettingsForm', 'templateFolder', value);

  // redux form takes the field data and updates de reducer by itself
  // this are just side effects
  handleChangeOrg = publicId => {
    // reset group data
    this.props.changeFormValue(change, 'TemplateSettingsForm', 'selected_groups', '');
    this.setState({ groups: [], loadingGroups: true, addShareEnabled: false });

    if (publicId !== null) {
      this.loadGroups(publicId);
    } else {
      this.loadWithAllGroup();
    }
  };

  // here this function needed
  // because the first parameter isn't the new value
  handleChange = ({ value }) => {
    if (value) {
      this.setState({ group: value, addShareEnabled: true });
      this.props.changeFormValue(change, 'TemplateSettingsForm', 'selected_groups', value);
    }
  };

  handleFeaturedSwitch = (e, checked) => {
    this.props.changeFormValue(change, 'TemplateSettingsForm', 'featuredSwitch', checked);
  };

  handleTitleChange = (e) => {
    this.props.changeFormValue(change, 'TemplateSettingsForm', 'title', e.target.value);
  };

  handleFeaturedSubFolder = ({ value }) =>
    this.props.changeFormValue(change, 'TemplateSettingsForm', 'templateSubFolder', value);

  loadWithAllGroup = () => {
    this.props.allGroupsGet().then(groups => {
      const shortGroupList = [];
      Object.keys(groups).forEach(key =>
        shortGroupList.push({ public_id: groups[key], name: groups[key] }),
      );
      if (this.props.organization === null) {
        this.setState({ groups: shortGroupList, loadingGroups: false });
      }
    });
  };

  loadGroups = organizationPublicId => {
    this.props.getAllGroups(organizationPublicId).then(groups => {
      let shortGroupList = [];
      // add 'No Groups' option
      shortGroupList.push({
        public_id: NO_GROUPS_IDENTIFIER,
        name: NO_GROUPS_IDENTIFIER,
      });
      // add 'All Group' option
      shortGroupList.push({
        public_id: ALL_GROUPS_IDENTIFIER,
        name: ALL_GROUPS_IDENTIFIER,
      });

      shortGroupList = shortGroupList.concat(groups);

      this.setState({ groups: shortGroupList, loadingGroups: false });
    });
  };

  saveTemplate = settings =>
    new Promise(() => {
      this.props.publicId ? this.props.updateTemplate(settings) : this.props.saveTemplate(settings)
    });

  handleChangeError = error => {
    if (error) this.setState({ errors: false });
    else this.setState({ success: false });
  };

  handleCharactersCount = event => {
    if (event) {
      return this.props.comments ? this.state.maxCharacters - event.target.value.length : 140;
    }
    return this.props.comments ? this.state.maxCharacters - this.props.comments.length : 140;
  };

  checkCharacters = e => {
    if (this.handleCharactersCount(e) < 0) {
      if (e.target.value.length >= this.props.comments.length) {
        e.preventDefault();
      }
    }
  };

  handleClose = () => {
    const { settings } = this.state;
    this.saveOrUpdateTemplate(settings);
    this.setState({ statusModal: false });
  };

  handleConfirm = () => {
    const { title } = this.props;
    const { settings } = this.state;
    settings.title = title;
    this.saveOrUpdateTemplate(settings);
    this.setState({ statusModal: false });
  };

  clearShareOption = () => {
    this.props.changeFormValue(this.props.change, 'TemplateSettingsForm', 'selected_groups', '');
    this.props.changeFormValue(
      this.props.change,
      'TemplateSettingsForm',
      'selected_organizations',
      '',
    );
    this.setState({ groups: [], addShareEnabled: false });
  };

  handleShareTemplate = async () => {
    const { group, organization } = this.props;
    const { shares, groups } = this.state;
    if (group) {
      const groupExist = shares.filter(share => share.publicId === group);
      if (group === 'All Groups' && organization === null) {
        this.setState({
          shares: [
            ...shares,
            {
              title: 'All Organizations',
              description: 'All Groups',
              publicId: organization,
              group: false,
            },
          ],
        });
        this.props.updateShareAllOrg(true);
        this.props.updateShareWithAllGroup(true);
        this.setState({ allOrg: true });
        this.clearShareOption();
      } else if (group === 'All Groups') {
        // eslint-disable-next-line
        const groupInfo = groups.find(({ public_id }) => public_id === group);
        this.setState({
          shares: [
            ...shares,
            {
              title: 'All Groups',
              description: `${groupInfo?.org?.name} ${
                groupInfo?.org?.organizationAddresses[0]?.cityText
                  ? `- ${groupInfo?.org?.organizationAddresses[0]?.cityText}`
                  : ''
              }`,
              publicId: organization,
              group: true,
            },
          ],
        });
        // this.props.updateShareAllOrg(true);
        this.clearShareOption();
      } else if (group === 'No Groups') {
        this.setState({
          shares: [
            ...shares,
            {
              title: 'All Organizations',
              description: 'No Groups',
              publicId: organization,
              group: false,
            },
          ],
        });
        this.props.updateShareWithAllGroup(false);
        this.props.updateShareAllOrg(true);
        this.setState({ allOrg: true });
        this.clearShareOption();
      } else if (groupExist.length === 0) {
        // eslint-disable-next-line
        const groupInfo = groups.find(({ public_id }) => public_id === group);
        this.setState({
          shares: [
            ...shares,
            {
              title: groupInfo.name,
              description: groupInfo.org ? `${groupInfo.org.name} - ${groupInfo.org.organization_addresses[0].city_text}` : '',
              publicId: groupInfo.public_id,
              group: true,
            },
          ],
        });
        this.clearShareOption();
      }
      this.props.searchOrganizationUpdateValue('');
    } else if (organization) {
      const orgExist = shares.filter(share => share.publicId === organization);
      if (orgExist.length === 0) {
        this.props.getOrganization(organization).then(response => {
          this.setState({
            shares: [
              ...shares,
              {
                title: response.name,
                description: `${response.description} - ${response.organization.state_text}`,
                publicId: response.public_id,
                group: false,
              },
            ],
          });
          this.clearShareOption();
        });
      }
      this.props.searchOrganizationUpdateValue('');
    }
  };

  handleShareRemove = shareId => {
    const { shares } = this.state;
    this.setState({
      shares: shares.filter(share => share.publicId !== shareId),
    });
    if (shareId === null) {
      this.props.updateShareAllOrg(false);
    }
  };

  render() {
    const {
      featuredSwitch,
      organizations,
      shareAllOrg,
      templateFolder,
      templateSubFolder,
      editTemplateFlag,
    } = this.props;
    const {
      statusModal,
      redirect,
      groups,
      shares,
      group,
      loadingGroups,
      addShareEnabled,
    } = this.state;

    return redirect ? (
      <Redirect to="/templates"/>
    ) : (
      <React.Fragment>
        {this.state.renderComponent && (

          <TemplateSettingsForm
            errors={this.state.errors}
            success={this.state.success}
            templateFolder={templateFolder}
            handleFolderChange={this.handleFolderChange}
            handleChange={this.handleChange}
            handleChangeError={this.handleChangeError}
            onSubmit={this.handleSubmit}
            foldersList={this.state.foldersList}
            handleTitleChange={this.handleTitleChange}
            handleFeaturedSubFolder={this.handleFeaturedSubFolder}
            templateSubFolder={templateSubFolder}
            subFoldersList={this.state.subFoldersFeatured}
            handleCharactersCount={this.handleCharactersCount}
            checkCharacters={this.checkCharacters}
            isSuperAdmin={this.state.isSuperAdmin}
            featured={featuredSwitch}
            handleFeaturedSwitch={this.handleFeaturedSwitch}
            shares={shares}
            onRemoveShare={this.handleShareRemove}
            selectedOrganization={this.props.organization}
            selectedGroup={this.props.group}
            groups={groups}
            loadingGroups={loadingGroups}
            addShareEnabled={addShareEnabled}
            organizations={organizations}
            handleShareTemplate={this.handleShareTemplate}
            organizationSuggestionValue={this.props.organizationSuggestionValue}
            handleChangeOrg={this.handleChangeOrg}
            change={change}
            group={group}
            shareAllOrg={shareAllOrg}
          />
        )}
        {editTemplateFlag && (
          <Portal node={document && document.getElementById('modal-root')}>
            <ModalConfirm
              handleClose={this.handleClose}
              statusDialog={statusModal}
              handleConfirm={this.handleConfirm}
              message="Are you sure you want to replace the current template with the current version?"
            />
          </Portal>
        )}
      </React.Fragment>
    );
  }
}

const selector = formValueSelector('TemplateSettingsForm');

const mS = state => ({
  subject: state.messageReducer.subject,

  // comments: selector(state, 'comments'),
  organization: selector(state, 'selected_organizations'),
  group: selector(state, 'selected_groups'),
  templateFolder: selector(state, 'templateFolder'),
  featuredSwitch: selector(state, 'featuredSwitch'),
  templateSubFolder: selector(state, 'templateSubFolder'),
  templateSettings: state.form.TemplateSettingsForm,
  error: state.groupsReducer.error,
  roles: state.userProfileReducer.member.roles,
  selectedTemplate: state.templateReducer.template,
  saveAndViewFolder: state.templateReducer.saveAndViewFolder,
  groups: state.groupsReducer.groups,
  organizations: state.organizationReducer.organizations,
  organizationSuggestionValue: state.organizationReducer.organizationSuggestionValue,
  shareAllOrg: state.templateReducer.shareAllOrg,
  shareWithAllGroups: state.templateReducer.shareWithAllGroups,
  editTemplateFlag: state.templateReducer.editTemplateFlag,
  submitTemplate: state.templateReducer.submitTemplate,
});

const mD = {
  getFoldersList,
  change,
  saveTemplate,
  updateTemplate,
  enableRightDrawer,
  setSaveAndViewFolder,
  getFolderById,
  getAllGroups,
  getOrganizationGroup,
  getOrganization,
  searchOrganizationUpdateValue,
  updateShareAllOrg,
  updateShareAllGroup,
  updateShareWithAllGroup,
  changeFormValue,
  allGroupsGet,
  clearMessage,
  statusLoading,
  snackBarStatus,
  enableRightDrawerClassic,
  closeCustomModal,
  clearCompose,
};

TemplateSettings.propTypes = TemplateSettingsPropType;

TemplateSettings.defaultProps = {
  onSubmit: () => {
  },
  afterUpdate: () => {
  },
}

export default withRouter(
  reduxForm({
    form: 'TemplateSettingsForm',
    initialValues: {
      title: '',
      comments: null,
      selected_organizations: '',
      selected_groups: '',
      templateFolder: null,
      featuredSwitch: false,
      templateSubFolder: null,
    },
  })(
    connect(
      mS,
      mD,
    )(TemplateSettings),
  ),
);
