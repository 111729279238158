const styles = {
  confirmComponent: {
    width: '100%',
    height: '100%',
    '& div.group-org-header': {
      height: '227px',
      boxShadow: '0 4px 12px 0 rgba(0, 0, 0, 0.1)',
      backgroundColor: 'withe',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& img.image-group-org': {
        borderRadius: '100px',
        height: '70px',
        width: '70px',
      },
      '& h2.org-name': {
        marginTop: '10px',
        marginBottom: 0,
        width: '100%',
        height: '19px',
        fontFamily: 'GothamSsm-Medium',
        fontSize: '16px',
        fontWeight: 500,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#4a4a4a',
        textAlign: 'center',
      },
      '& h4.group-name': {
        marginTop: '10px',
        marginBottom: 0,
        width: '126px',
        height: '15px',
        fontFamily: 'GothamSsm-Book',
        fontSize: '12px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#4a4a4a',
        textAlign: 'center',
      },
    },
    '& div.confirm-box': {
      backgroundColor: 'transparent',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& div.question': {
        marginTop: '100px',
        height: '45px',
        width: '655px',
        '@media only screen and (max-width: 600px)': {
          width: '80%',
          height: '90px',
        },
        '&.reduced-height': {
          height: '24px',
          '@media only screen and (max-width: 600px)': {
            height: '24px',
          },
        },
        '& h1': {
          margin: 0,
          fontFamily: 'GothamSsm-Medium',
          fontSize: '20px',
          fontWeight: 500,
          fontStyle: 'normal',
          fontStretch: 'normal',
          lineHeight: 'normal',
          letterSpacing: 'normal',
          color: '#4a4a4a',
          textAlign: 'center',
        },
      },
      '& div.warning-text': {
        marginTop: '10px',
        marginBottom: '40px',
        maxHeight: '72px',
        width: '655px',
        '&.reduced-height': {
          height: '24px',
          '@media only screen and (max-width: 600px)': {
            height: '24px',
          },
        },
        '@media only screen and (max-width: 600px)': {
          width: '80%',
          height: '90px',
        },
        '& h3': {
          margin: '0px',
          fontFamily: 'GothamSsm-Medium',
          fontSize: '16px',
          fontWeight: 500,
          fontStyle: 'normal',
          fontStretch: 'normal',
          lineHeight: 1.5,
          letterSpacing: 'normal',
          color: '#a9a9a9',
          textAlign: 'center',
        },
      },
      '& div.buttons-box': {
        width: '465px',
        '@media only screen and (max-width: 600px)': {
          width: '90%',
        },
        '& .wide-button > button': {
          '& span': {
            textTransform: 'none',
          },
          marginBottom: '0px !important',
          height: '40px !important',
          '@media only screen and (max-width: 600px)': {
            height: '55px !important',
          },
          width: '100%',
          marginTop: '0px !important',
        },
        '& .evangelus-account-login > button': {
          marginBottom: '0px !important',
          height: '40px !important',
          width: '300px',
          marginTop: '0px !important',
        },
      },
    },
  },
  imageBox: {
    width: '100%',
    marginBottom: '60px',
    marginTop: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      width: 144,
      height: 86,
    },
  },
  textsBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  common: {
    textAlign: 'center',
    fontFamily: 'GothamSsm-Medium',
    fontSize: '16px',
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#a9a9a9',
    maxWidth: '650px',
    '&.not-subscribed': {
      marginTop: 0,
      marginBottom: 40,
    },
  },
};

export default styles;
