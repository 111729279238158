import { switchStyles } from '../../Theme/switchStyles';

const emailPreviewstyles = theme => ({
  tabsIndicator: {
    background: 'linear-gradient(to right, #9966bf 0%, #6174c9 98%)',
  },
  tabsRoot: {
    textTransform: 'initial',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'GothamSSm-Book',
    color: '#a9a9a9',

    '&:focus': {
      color: '#9a66bf',
    },
    headerEmail: {
      color: '#4a4a4a',
      backgroundColor: '#fff',
    },
  },
  tabRoot: {
    minWidth: 'auto',
  },
});

export default emailPreviewstyles;
