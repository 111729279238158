// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../Theme/Icons/eva.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".reply-wrapper .messages-threads{margin-bottom:10px}.reply-wrapper .thread-wrapper:not(:last-child){margin-bottom:10px}.reply-wrapper .thread-wrapper .message-thread-item{margin-bottom:10px}#message-selected{margin-bottom:50px}#message-selected .designerContent{overflow:hidden;background:transparent;cursor:initial;word-break:break-word}#message-selected .designerContent table.container{width:506px}#message-selected .designerContent center{min-width:unset}#message-selected .message-selected-header{margin-bottom:40px}#message-selected .composer .editable{min-height:200px}#message-view{margin-bottom:25px}#message-view .user-message>table{width:580px;-webkit-transform:scale(0.87);-ms-transform:scale(0.87);transform:scale(0.87);-webkit-transform-origin:0 0;-ms-transform-origin:0 0;transform-origin:0 0}.eva-custom-logo{background-color:#a9a9a9;background-image:-webkit-gradient(linear, left top, right top, from(#9a66bf), to(#6274c9));background-image:linear-gradient(to right, #9a66bf, #6274c9);-webkit-mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;margin-top:12px;right:112px;z-index:10;width:32px;height:15px;display:block;margin-right:20px}\n", ""]);
// Exports
module.exports = exports;
