import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { Field, reduxForm } from 'redux-form';
import CustomField from '../CustomField';
// import Helpers from '../../Helpers';
import ButtonStandard from '../Button';
import MyParishAppPropType from '../../PropTypes/MyParishAppLoginPropType';
import { enableModal } from '../../Actions/modalActions';
import { enableRightDrawer } from '../../Actions/rightDrawerActions';
import snackBarStatus from '../../Actions/snackbarActions';
import { MPALogin } from '../../Actions/myParishAppActions';
import {
  updateSelectedGroup,
  initSelectedGroup,
  clearGroups,
  getGroupsAsync,
  updateGroupsReducer,
  saveSelectedGroup,
  getOrgAsync,
} from '../../Actions/groupsActions';
import { changeTab } from '../../Actions/middleMenuActions';
import { updateIsMpaLogin } from '../../Actions/composeActions';
import { statusLoading } from '../../Actions/loadingActions';
import './index.sass';

class MyParishAppLogin extends Component {
  state = {
    isPreview: false,
    loginStatus: null, // null means we don't know yet.
    multiLoginMessage: false,
  };

  UNSAFE_componentWillMount() {
    const { isMPALoginCompose } = this.props;
    if (isMPALoginCompose) this.setState({ isPreview: true });
  }
  componentDidMount() {
    const promiseVal = this.submit();
    const { callbackAfterSubmit } = this.props;
    if (typeof callbackAfterSubmit === 'function') {
      promiseVal.then(callbackAfterSubmit);
    }
  }

  refresh = () => {
    this.props.getOrgAsync().then(response => {
      this.props.updateGroupsReducer({ payload: { organizations: response } });
      setTimeout(() => {
        this.props.getGroupsAsync();
      }, 500);
    });
  };

  submit = async () => {
    const {
      selectedGroup,
      isCreateGroup,
      group,
      isMPALoginCompose,
      Authgroup,
      groupsList,
    } = this.props;

    this.props.enableModal({ payload: { isLoader: true } });

    const publicId = isCreateGroup ? group.public_id : selectedGroup.public_id;
    if (groupsList && groupsList.length > 0) {
      const groupPromises = groupsList.map(groupItem => {
        if (groupItem.label !== 'All Members') {
          return this.props.MPALogin(groupItem.publicId);
        }
        return Promise.resolve();
      });
      try {
        await Promise.all(groupPromises);
        this.setState({ multiLoginMessage: true, loginStatus: true });
        return true;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Could not ligin with all ');
        this.setState({ loginStatus: false });
      }
      this.props.enableModal({ payload: { isLoader: false } });
      return false;
    }
    if (publicId) {
      const res = await this.props.MPALogin(publicId);
      if (res.code === 200) {
        this.props.updateIsMpaLogin(false);
        this.setState({ loginStatus: true });
        const payload = { send_to_my_parish_app: true };
        this.props.updateSelectedGroup(payload);
        this.props.statusLoading(true);
        if (isCreateGroup) {
          this.resetCreateFormGroup();
        } else {
          const response = await this.props.saveSelectedGroup();

          if (response.status === 200) {
            this.props.statusLoading(false);
          } else {
            this.props.statusLoading(false);
          }
        }
        this.props.enableModal({ payload: { isLoader: false } });
        return true;
      }
      this.props.enableModal({ payload: { isLoader: false } });
      this.setState({ loginStatus: false });
      const payload = { send_to_my_parish_app: false };
      this.props.updateSelectedGroup(payload);
      return false;
    } else if (isMPALoginCompose) {
      const res = await this.props.MPALogin(Authgroup.publicId);
      if (res.code === 200) {
        this.props.updateIsMpaLogin(false);
        const payload = { send_to_my_parish_app: true };
        this.props.updateSelectedGroup(payload);
        if (isCreateGroup) this.resetCreateFormGroup();
        return true;
      }
      this.setState({ loginStatus: false });
      this.props.enableModal({ payload: { isLoader: false } });
      const payload = { send_to_my_parish_app: false };
      this.props.updateSelectedGroup(payload);
      return false;
    }
    this.props.updateIsMpaLogin(false);
    this.props.enableModal({ payload: { isLoader: false } });
    this.setState({ loginStatus: false });
    const payload = { send_to_my_parish_app: false };
    this.props.updateSelectedGroup(payload);
    return false;
  };

  resetCreateFormGroup = () => {
    this.props.enableRightDrawer({ payload: { enable: false } });
    this.props.initSelectedGroup();
    this.props.clearGroups();
    this.props.getGroupsAsync();
  };

  handleCancel = () => {
    const { isCreateGroup } = this.props;
    this.setState({ loginStatus: false });
    this.props.enableModal({ payload: { status: false, element: <div /> } });
    if (isCreateGroup) this.resetCreateFormGroup();
  };

  handleSubmit = () => {
    const { invalid } = this.props;
    if (invalid) {
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: 'Required fields',
          type: 'error',
        },
      });
    }
  };

  renderInputField = (name, type, placeholder, requiredField = '') => (
    <Field
      name={name}
      type={type}
      component={CustomField}
      placeholder={placeholder}
      validate={requiredField}
    />
  );

  /* eslint-disable */
  render() {
    const { isPreview, loginStatus } = this.state;
    return (
      <Grid container spacing={0} justify="center" id="my-parish-app-login-container">
        <Grid className="title" item xs={10}>
          <i className="logo" />
        </Grid>
        <Grid className="description" item xs={10}>
          {isPreview ? (
            <React.Fragment>
              <p>You must login to myParish to post messages from</p>
              <p>Evangelus as Group discussions in myParish App.</p>
            </React.Fragment>
          ) : loginStatus === null ? (
            <React.Fragment>
              <p>Authenticating user with myParish App</p>
              <p>please wait a few seconds.</p>
            </React.Fragment>
          ) : loginStatus ? (
            <React.Fragment>
              <p>
                {this.state.multiLoginMessage ? 'All groups are ' : 'This group is'} now synced and
                ready for posting to myParish App.
              </p>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <p>You are not authorized to link myParish app.</p>
              <p>
                Please contact the Customer Care team at <br /> 1 (800) 994-9817).
              </p>
            </React.Fragment>
          )}
        </Grid>
        <Grid item xs={10}>
          <Grid item xs={12} className="cancel">
            <ButtonStandard
              title="Close"
              onClick={this.handleCancel}
              disabled={this.state.loginStatus === null}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
  /* eslint-enable */
}

MyParishAppLogin.propTypes = MyParishAppPropType;

const mD = {
  enableModal,
  MPALogin,
  snackBarStatus,
  updateSelectedGroup,
  initSelectedGroup,
  enableRightDrawer,
  clearGroups,
  getGroupsAsync,
  updateGroupsReducer,
  changeTab,
};

const mS = ({
  groupsReducer: { selectedGroup },
  organizationReducer: { organization },
  composeReducer: { isMPALoginCompose, Authgroup },
}) => ({
  selectedGroup,
  organization,
  isMPALoginCompose,
  Authgroup,
  updateIsMpaLogin,
  saveSelectedGroup,
  statusLoading,
  getOrgAsync,
});

const CustomMyParishAppLogin = reduxForm({ form: 'MPAForm' })(MyParishAppLogin);

export default connect(
  mS,
  mD,
)(CustomMyParishAppLogin);
