import { ACTIONS } from '../Actions/loaderActions';

const initialState = {
  isVisible: false,
};

const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.VISIBILITY:
      return { ...state, isVisible: action.payload };

    default:
      return state;
  }
};

export default loaderReducer;
