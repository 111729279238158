import React from 'react';
import MessageThreadItem from '../../Components/MessageThreadItem';
import MessageThreadReply from '../../Components/MessageThreadReply';
import MessageThreadPropType from '../../PropTypes/MessageThreadPropType';
import moment from 'moment';
import { getDateInTimezone } from '../../Helpers/date';
import getMemberInfo from '../../Helpers/getMemberInfo';
import './index.sass';

const MessageThread = ({ message, replyThread, isAdmin, isHideReplyPad,
                          organizationTimezone, threadReplyDate }) => {
  const { responseMessages, sentByMember, webContent } = message;
  const date = getDateInTimezone(moment(message.sentAt),
      organizationTimezone, 'MM/DD/YY h:mm A');
  return (
    <div className="thread-wrapper">
      <MessageThreadItem
        rtl={isAdmin}
        highlighted={isAdmin}
        messageHtml={webContent}
        userName={getMemberInfo(sentByMember).displayedFullName}
        userImage={sentByMember.profilePictureUrl}
        date={date}
      />

      {!isHideReplyPad &&
        <MessageThreadReply
          counter={responseMessages && responseMessages.length}
          userImage={sentByMember.profilePictureUrl}
          date={threadReplyDate}
          hideReplyButton={isAdmin}
          onReply={() => replyThread(message)}
        />
      }
    </div>
  );
};

MessageThread.propTypes = MessageThreadPropType;

export default MessageThread;
