import React from 'react';
import chartBar from '../../Theme/Icons/chart-bar.svg';
import PropTypes from 'prop-types';
import './index.sass';

export const MessageStatistics = props => {
  const {
    data: { clicks, clicksRate, opened, openedRate, recipientFailed, recipientSend },
    publicId, onStatisticsModalCall, onClose
  } = props;

  return (
    <section className="content-inner-wrapper message-statistics">
      <figure className="stats-box">
        <img src={chartBar} alt="chart"/>
        <figcaption>Statistics</figcaption>
      </figure>
      <div className="categories">

        <article>
          <div className="title">Recipients</div>
          <div className="value">{recipientSend}</div>
          <div className="details">{recipientFailed} Failed</div>
          <button
            className="download-link"
            disabled={recipientSend === 0}
            onClick={() => onStatisticsModalCall(publicId, 'all')}
          >
            Report
          </button>
        </article>

        <article>
          <div className="title">Clicks</div>
          <div className="value">{clicks}</div>
          <div className="details">{clicksRate}%</div>
          <button
            disabled={clicks === 0}
            className="download-link"
            onClick={() => onStatisticsModalCall(publicId, 'clicks')}
          >
            Report
          </button>
        </article>

        <article>
          <div className="title">Email Views</div>
          <div className="value">{opened}</div>
          <div className="details">{openedRate}%</div>
          <button
            className="download-link"
            disabled={opened === 0}
            onClick={() => onStatisticsModalCall(publicId, 'email-views')}
          >
            Report
          </button>
        </article>
      </div>

      <button
        type="button"
        aria-label="Close"
        onClick={onClose}
        className="tip-action tip-action-close btn-close"
      />
    </section>
  );
};

export default MessageStatistics;

MessageStatistics.propTypes = {
  publicId: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Object).isRequired, // TODO: provide a detailed type
  onClose: PropTypes.func.isRequired,
};
