/* eslint-disable class-methods-use-this */
export default class AbstractAdapter<T> {
  constructor(object) {
    this.object = object;
  }

  setObject(object) {
    this.object = object;
  }

  adapt(): T {
    return null;
  }
}
