import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MoveTemplatesPropTypes from '../../PropTypes/MoveTemplatesPropTypes';
import styles from './styles';
import { mbscResponsiveOptions } from '../../Config/Constants/mobiscroll-props';
import { Select } from '@mobiscroll/react';

const MoveTemplates = ({ classes, onClick, ...props }) => {
  const disabled = !props.value;

  return (
    <div className={classes.root}>
      <Select
        responsive={mbscResponsiveOptions}
        placeholder="Select destination folder"
        filterPlaceholderText="Search"
        name="destination_folder"
        buttons={['cancel']}
        {...props}
      />
      <button
        className={classes.button}
        disabled={disabled}
        onClick={onClick}
      >
        Move
      </button>
    </div>
  );
};

MoveTemplates.propTypes = MoveTemplatesPropTypes;

export default withStyles(styles)(MoveTemplates);
