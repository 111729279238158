import { ACTIONS } from '../Actions/responsiveActions';

const initialState = {
  windowHeight: window.innerHeight,
  windowWidth: window.innerWidth,
  viewType: '',
  orientation: '',
};

const responsiveReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_LAYOUT_RESPONSIVE_DATA: {
      const { layoutData } = action.payload;
      return {
        ...state,
        ...layoutData,
      };
    }
    default:
      return state;
  }
};

export default responsiveReducer;
