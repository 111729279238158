import PropTypes from 'prop-types';

const SideMenuPropType = {
  active: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  authToken: PropTypes.string.isRequired,
  env: PropTypes.string.isRequired,
  org: PropTypes.object.isRequired,
};

export default SideMenuPropType;
