import React from 'react';
import getMemberInfo from '../../Helpers/getMemberInfo';
import Helpers from '../../Helpers';
import PropTypes from 'prop-types';
import CardMessage from '../CardMessage';
import './index.sass';

// TODO: remove (?).
const removeDuplicates = arr => {
  const acc = [];
  return arr.filter(i => {
    const bool = !acc.includes(i.publicId);
    acc.push(i.publicId);
    return bool;
  });
};

export const MessagesList = ({ messages, timezone, selectedMessagePublicId, checkedMessages, onSelect, onCheck }) => (
  <section className="messages-list">
    {messages.map(message => {
      const { publicId, status, subject, textContent, isDirect, isUnread, toMember, member, threadMember, files } = message;
      const groupTitle = isDirect ? getMemberInfo(toMember).displayedFullName : message.groups.map(g => g.name).join(', ');
      const date = Helpers.getDate(message && message.lastMessage && message.lastMessage.updatedAt);

      let members = [];
      members = member ? members.concat(member) : members;
      members = threadMember ? members.concat(threadMember) : members;
      members = removeDuplicates(members).slice(0, 2);

      return (
        <CardMessage
          key={publicId}
          message={message}
          members={members}
          isActive={status !== 'draft' && selectedMessagePublicId === publicId}
          isChecked={checkedMessages.includes(publicId)}
          isUnread={isUnread}
          onSelect={onSelect}
          onCheck={onCheck}
          sendByTitle={groupTitle}
          organizationTimezone={timezone}
          date={date}
          subject={subject}
          content={textContent}
          publicId={publicId}
          status={status}
          hasAttachments={files?.length > 0}
        />
      );
    })}
  </section>
);

MessagesList.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
  timezone: PropTypes.string.isRequired,
  checkedMessages: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedMessagePublicId: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onCheck: PropTypes.func.isRequired,
}

MessagesList.defaultProps = {
  selectedMessagePublicId: null,
}


