import { ACTIONS, CONSTANTS } from '../Actions/messageActions';
import { MESSAGE_COMPOSER_TYPE, MESSAGE_DESIGNER_TYPE } from '../Config/Constants';

const initialState = {
  allowedChannels: {
    sms: true,
    email: true,
    mpa: false,
    facebook: false,
    twitter: false,
    web: false,
  },
  cancelScheduledMessageDialogStatus: false,
  contentImages: [],
  displayConfirm: true,
  draftMessageReferent: '',
  files: [],
  isAllMemberGroupSelected: false,
  isDraftAlreadySent: false, // Update this flag if editable message already sent
  isDraftSaving: false,
  isInsertTemplate: false,
  isMessageSending: false,
  isResendUnopened: false,
  isTemplate: false,
  isMessageLoading: false,
  menuIsOpen: false,

  messageContent: {
    mainPicture: null,
    type: MESSAGE_COMPOSER_TYPE,

    editorContent: {
      html: '',
      text: '',
      design: null,
      isEdited: false,
    },
    emailContent: {
      text: '',
      isEdited: false,
    },
    facebookContent: {
      image: null,
      isEdited: false,
      text: '',
    },
    mpaContent: {
      image: '',
      prefix: '',
      isEdited: false,
      text: '',
    },
    smsContent: {
      prefix: '',
      isEdited: false,
      text: '',
    },
    twitterContent: {
      image: null,
      isEdited: false,
      text: '',
    },
    webContent: {
      isEdited: false,
      text: '',
    },
  },
  messageSettings: {
    includeRecipientName: false,
    previewText: '',
    emailForward: '',
    isScheduledMessage: false,
    timeZone: '',
    deliveryTime: '',
    gmt: '',
  },
  publicId: null,
  recipientType: CONSTANTS.RECIPIENT_TYPE_GROUP,
  replyToMessage: null,
  subject: '',
  sender_name: '',
  recipientGroups: [],
  selectedMember: null,
  statistics: [],
  statusMessage: false,
  sendByEmail: true,
  sendBySms: true,
  sendByFacebook: false,
  sendByTwitter: false,
  sendByMpa: false,
  sendByWeb: false,
  statusDialog: false,
  subjectSetted: false,
  tabValue: '',
  textContent: '',
  timeZoneList: {},
  to: '',
  validationErrors: {
    recipient: false,
    subject: false,
    content: false,
    forwardEmail: false,
    channels: false,
  },
  urlFromBase: '',
  windowParams: {
    show: false,
    message: '',
    type: '',
    titleMessage: '',
  },
  lockedByMember: null,
};

const messageReducers = (state = initialState, action) => {
  switch (action.type) {

    case ACTIONS.SET_EDITOR_TYPE:
      return {
        ...state,
        messageContent: {
          ...state.messageContent,
          type: action.payload,
        }
      }

    case ACTIONS.SET_MESSAGE: {
      const { messageContent, ...rest } = action.payload
      return { ...state, ...initialState, ...rest, messageContent: { ...state.messageContent, ...messageContent } };
    }

    case ACTIONS.INIT_REPLY_MESSAGE: {
      const { messageContent, ...rest } = action.payload;
      return {
        ...initialState,
        ...rest,
        messageContent: {
          ...initialState.messageContent,
          ...messageContent,
        }
      };
    }

    case ACTIONS.UPDATE_IS_TEMPLATE:
      return { ...state, isTemplate: action.payload };

    case ACTIONS.SET_MESSAGE_PREVIEWS:
      return {
        ...state,
        messageContent: {
          ...state.messageContent,
          ...action.payload,
        },
        notGeneratePreview: false, // TODO: remove (?)
      };

    case ACTIONS.SET_REPLY_TO:
      return { ...state, replyToMessage: action.payload };

    case ACTIONS.UPDATE_MESSAGE_CONTENT:
      return { ...state, messageContent: { ...state.messageContent, ...action.payload } };

    case ACTIONS.GET_MESSAGE_STATISTICS:
      return { ...state, statistics: [ ...action.payload ] };

    case ACTIONS.UPDATE_MESSAGE_SETTINGS:
      return {
        ...state, messageSettings: {
          ...state.messageSettings,
          ...action.payload,
        }
      };

    case ACTIONS.RESET_SCHEDULED_STATUS: {
      const { deliveryTime, isScheduledMessage, timeZone } = initialState.messageSettings;
      return {
        ...state, messageSettings: {
          ...state.messageSettings,
          deliveryTime,
          isScheduledMessage,
          timeZone,
        }
      };
    }

    case ACTIONS.UPDATE_STATUS_MESSAGE:
      return { ...state, statusMessage: action.payload };

    case ACTIONS.STATUS_WINDOW_MESSAGE:
      return { ...state, windowParams: action.payload };

    case ACTIONS.CLEAR_VALUES_MESSAGE:
      return {
        ...state, ...initialState,
        messageSettings: { ...initialState.messageSettings },
        messageContent: { ...initialState.messageContent }
      };

    case ACTIONS.UPDATE_SEND_BY_SMS:
      return { ...state, sendBySms: action.payload };

    case ACTIONS.UPDATE_SEND_BY_EMAIL:
      return { ...state, sendByEmail: action.payload };

    case ACTIONS.UPDATE_SEND_BY_MPA:
      return { ...state, sendByMpa: action.payload };

    case ACTIONS.UPDATE_SEND_BY_TWITTER:
      return { ...state, sendByTwitter: action.payload };

    case ACTIONS.UPDATE_SEND_BY_FACEBOOK:
      return { ...state, sendByFacebook: action.payload };

    case ACTIONS.UPDATE_SEND_BY_WEB:
      return { ...state, sendByWeb: action.payload };

    case ACTIONS.UPDATE_SEND_BY:
      return {
        ...state,
        sendByMyEmail: action.payload.sendByMyEmail,
        sendByMpa: action.payload.sendByMpa,
        sendByTwitter: action.payload.sendByTwitter,
        sendByFacebook: action.payload.sendByFacebook,
        sendByWeb: action.payload.sendByWeb,
      };

    case ACTIONS.UPDATE_ALLOWED_CHANNELS:
      // Update flags if some was disallowed.
      const sendByObj = {};
      if (action.payload.hasOwnProperty('facebook')) {
        sendByObj.sendByFacebook = action.payload.facebook;
      }
      if (action.payload.hasOwnProperty('twitter')) {
        sendByObj.sendByTwitter = action.payload.twitter;
      }
      if (action.payload.hasOwnProperty('mpa')) {
        sendByObj.sendByMpa = action.payload.mpa;
      }
      if (action.payload.hasOwnProperty('web')) {
        sendByObj.sendByWeb = action.payload.web;
      }

      if (action.payload.hasOwnProperty('sms')) {
        sendByObj.sendBySms = action.payload.sms;
      }

      if (action.payload.hasOwnProperty('email')) {
        sendByObj.sendByEmail = action.payload.email;
      }

      return {
        ...state,
        ...sendByObj,
        allowedChannels: {
          ...state.allowedChannels,
          ...action.payload,
        }
      };


    case ACTIONS.UPDATE_EDITOR_CONTENT: {
      const { design, html, text } = action.payload;
      return {
        ...state,
        messageContent: {
          ...state.messageContent,
          editorContent: {
            ...state.messageContent.editorContent,
            design, html, text
          }
        }
      }
    }

    case ACTIONS.ERASE_EDITOR_CONTENT:
      return {
        ...state,
        messageContent: {
          ...initialState.messageContent
        }
      }

    case ACTIONS.UPDATE_SMS_CONTENT:
      return {
        ...state,
        messageContent: {
          ...state.messageContent,
          smsContent: {
            ...state.messageContent.smsContent,
            text: action.payload,
            isEdited: true,
          }
        }
      };

    case ACTIONS.UPDATE_MPA_CONTENT:
      return {
        ...state,
        messageContent: {
          ...state.messageContent,
          mpaContent: {
            ...state.messageContent.mpaContent,
            text: action.payload,
            isEdited: true,
          }
        }
      };

    case ACTIONS.UPDATE_TWITTER_CONTENT:
      return {
        ...state,
        messageContent: {
          ...state.messageContent,
          twitterContent: {
            ...state.messageContent.twitterContent,
            ...action.payload,
            isEdited: true
          }
        }
      };

    case ACTIONS.UPDATE_FACEBOOK_CONTENT:
      return {
        ...state,
        messageContent: {
          ...state.messageContent,
          facebookContent: {
            ...state.messageContent.facebookContent,
            ...action.payload,
            isEdited: true,
          }
        }
      };

    case ACTIONS.UPDATE_WEB_CONTENT:
      return {
        ...state,
        messageContent: {
          ...state.messageContent,
          webContent: {
            ...state.messageContent.webContent,
            text: action.payload,
            isEdited: true,
          },
        }
      };

    case ACTIONS.UPDATE_ALL_MEMBERS_SELECTED:
      return {
        ...state,
        isAllMemberGroupSelected: action.payload,
      }

    case ACTIONS.RECIPIENT_GROUPS_UPDATE:
      return {
        ...state,
        recipientGroups: action.payload,
        // recipientType: CONSTANTS.RECIPIENT_TYPE_GROUP,
      };


    case ACTIONS.SELECTED_MEMBER:
      return {
        ...state,
        selectedMember: action.payload,
        recipientType: action.payload ?
          CONSTANTS.RECIPIENT_TYPE_MEMBER : CONSTANTS.RECIPIENT_TYPE_GROUP,
      };

    case ACTIONS.UPDATE_SELECTED_MEMBER_PROPS:
      return {
        ...state,
        selectedMember: {
          ...state.selectedMember,
          ...action.payload,
        },
      };

    case ACTIONS.OPEN_MENU:
      return { ...state, menuIsOpen: action.payload };
    case ACTIONS.LOADING_MESSAGE:
      return { ...state, isMessageLoading: action.payload };
    case ACTIONS.LOADING_MESSAGE_SENDING:
      return { ...state, isMessageSending: action.payload };
    case ACTIONS.CHANGE_TAB:
      return { ...state, tabValue: action.payload };
    case ACTIONS.UPDATE_DIALOG:
      return { ...state, statusDialog: action.payload };
    case ACTIONS.SET_PUBLIC_ID:
      return { ...state, publicId: action.payload };
    case ACTIONS.HANDLE_DIALOG_STATUS: {
      return { ...state, cancelScheduledMessageDialogStatus: action.payload };
    }
    case ACTIONS.CHANGE_CONFIRM_DIALOG_STATUS: {
      return { ...state, displayConfirm: action.payload };
    }
    case ACTIONS.GET_URL_FROM_BASE: {
      return { ...state, urlFromBase: action.payload };
    }
    case ACTIONS.CROPPING_IMAGE: {
      return { ...state, croppingImages: action.payload };
    }

    case ACTIONS.SET_CONTENT_IMAGES: {
      return {
        ...state,
        contentImages: action.payload,
        messageContent: {
          ...state.messageContent,
          mainPicture: action.payload.length > 0 ? state.messageContent.mainPicture : null,
        }
      };
    }

    case ACTIONS.SELECT_PREVIEW_IMAGE: {
      return {
        ...state,
        messageContent: {
          ...state.messageContent,
          mainPicture: action.payload,
          facebookContent: {
            ...state.messageContent.facebookContent,
            image: state.messageContent.facebookContent.image ? action.payload : null
          },
          mpaContent: {
            ...state.messageContent.mpaContent,
            image: state.messageContent.mpaContent.image ? action.payload : null,
          },
          twitterContent: {
            ...state.messageContent.twitterContent,
            image: state.messageContent.twitterContent.image ? action.payload : null
          }
        }
      };
    }

    case ACTIONS.UPDATE_MESSAGE_SUBJECT: {
      return {
        ...state,
        subject: action.payload,
      }
    }

    case ACTIONS.UPDATE_MESSAGE_SENDER_NAME: {
      return {
        ...state,
        sender_name: action.payload,
      }
    }

    case ACTIONS.INIT_DRAFT_MESSAGE: {
      return { ...state, ...action.payload };
    }

    case ACTIONS.UPDATE_DRAFT_ALREADY_SENT: {
      return { ...state, isDraftAlreadySent: action.payload };
    }

    case ACTIONS.APPLY_TEMPLATE: {
      return { ...state, ...action.payload };
    }

    case ACTIONS.FORMAT_TO_DESIGNER:
      return {
        ...state,
        messageContent: {
          ...state.messageContent,
          type: MESSAGE_DESIGNER_TYPE,
          editorContent: {
            ...state.messageContent.editorContent,
            design: action.payload,
          }
        }
      }

    case ACTIONS.VALIDATE: {
      return { ...state, validationErrors: { ...initialState.validationErrors, ...action.payload } };
    }

    case ACTIONS.VALIDATION_RESET: {
      return { ...state, validationErrors: { ...initialState.validationErrors } };
    }

    case ACTIONS.SET_RESEND_UNOPENED:
      return {
        ...state,
        isResendUnopened: action.payload,
      };

    case ACTIONS.UPDATE_IS_DRAFT_SAVING: {
      return { ...state, isDraftSaving: action.payload }
    }

    case ACTIONS.UPDATE_RECIPIENT_TYPE: {
      return { ...state, recipientType: action.payload }
    }

    case ACTIONS.UPDATE_FILES: {
      return { ...state, files: action.payload }
    }

    case ACTIONS.SET_LOCKED_BY_MEMBER: 
      return { ...state, lockedByMember: action.payload };
    
    default:
      return state;
  }
};

export default messageReducers;
