import React from 'react';
import MUICheckbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import CheckboxPropTypes from '../../PropTypes/CheckboxPropTypes';
import Icon from './Icon';
import styles from './styles';
import check from '../../Theme/Icons/check.svg';
import inactiveCheckbox from '../../Theme/Icons/checkbox-inactive.svg';

const Checkbox = ({
  classes,
  className = '',
  filled = false,
  outlined = false,
  ...props
}) => {
  const filledClass = filled && 'filled';
  const outlinedClass = outlined && 'outlined';
  const finalClassName = `${className} ${filledClass} ${outlinedClass}`;
  const icon = (
    <Icon
      className={!filled ? classes.hiddenIcon : ''}
      src={inactiveCheckbox}
    />
  );

  return (
    <MUICheckbox
      disableRipple
      checkedIcon={<Icon src={check} />}
      classes={classes}
      className={finalClassName}
      icon={icon}
      {...props}
    />
  );
};

Checkbox.propTypes = CheckboxPropTypes;

export default withStyles(styles)(
  Checkbox,
);
