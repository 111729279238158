import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import {
  createFolder,
  getFolderImage,
  getFoldersContent,
  setInputField,
  updateSelectedFolder,
  updateSelectedImage,
  uploadPicture,
} from '../../Actions/imageGalleryActions';
import { MAX_IMAGE_FILE_SIZE } from '../../Config/Constants';
import Input from '../../Components/Input';
import snackBarStatus from '../../Actions/snackbarActions';
import UploadGalleryListPropType from '../../PropTypes/UploadGalleryListPropType';
import { updateCustomModal } from '../../Actions/customModalActions';
import translationService from '../../Translations/translation.service';
import styles from './styles';
import { Select } from '@mobiscroll/react';
import { mbscResponsiveOptions } from '../../Config/Constants/mobiscroll-props';
import './index.sass';

class UploadGallery extends Component {
  state = {
    name: '',
    image: null,
    folderName: 'My Images',
    displayCreateFolder: false,
    loadedImage: '',
    statusTitle: true,
    statusButton: true,
  };

  onChangeRenameImage = (e, extension) => {
    const newName = `${e.target.value}.${extension}`;
    this.setState({ name: newName });
  };

  handleStatusTitle = statusTitle => {
    this.setState({ statusTitle, statusButton: true });
  };

  handleStatusButton = statusButton => this.setState({ statusButton, statusTitle: true });

  handleChangeFolder = ({ value }) => {
    if (value !== 'newFolder') {
      this.props.getFolderImage(value, 1);
      this.props.updateSelectedFolder(value);
    } else {
      this.props.updateSelectedFolder('My Images');
    }
  };

  createFolder = () => {
    this.setState({ displayCreateFolder: !this.state.displayCreateFolder });
  };

  loadImageGallery = evt => {
    const input = evt.target;

    if (input && input.files && input.files.length) {
      const currentFile = input.files[0];

      // used to verify img dimensions
      if (currentFile.size > MAX_IMAGE_FILE_SIZE) {
        this.props.snackBarStatus({
          payload: {
            title: translationService('uploadGallery.imageFileTooBig'),
            enable: true,
            type: 'formError',
          },
        });
        return;
      }
      const img = document.createElement('img');
      img.src = URL.createObjectURL(currentFile);

      const reader = new FileReader();

      reader.readAsDataURL(currentFile);

      reader.onload = () => {
        this.setState({
          name: currentFile.name,
          image: reader.result,
          loadedImage: 'is-loaded',
          statusButton: false,
        });
      };

      img.onload = () => {
        if (img.naturalHeight <= 10000 && img.naturalWidth <= 10000) {
          reader.readAsDataURL(currentFile);
        } else {
          this.props.snackBarStatus({
            payload: {
              enable: true,
              title: translationService('uploadGallery.imageResolutionTooBig'),
              type: 'formError',
            },
          });
        }
      };
    }
  };

  uploadImage = () => {
    const { image, name, folderName, displayCreateFolder } = this.state;
    const { selectedFolder, selectedImage } = this.props;
    const folderValidation = displayCreateFolder ? 'newFolder' : selectedFolder;
    const data = {
      name,
      image,
      folderId: folderValidation,
    };

    if (folderName !== '' && displayCreateFolder) {
      this.props.createFolder(folderName, folderValidation).then(createFolderResponse => {
        if (createFolderResponse === 200) {
          this.props.getFoldersContent();
          this.setState({ displayCreateFolder: false });
        }
      });
    } else if (image) {
      this.props.uploadPicture(data).then(res => {
        if (res.status === 200) {
          this.props.getFolderImage(selectedFolder, 1);
          this.setState({
            name: '',
            loadedImage: '',
            image: null,
            statusTitle: true,
            statusButton: true,
          });
          selectedImage.push(res.data.data.public_id);
          this.props.updateSelectedImage(selectedImage);
          this.props.openGallery();
        }
      });
    }
  };

  triggerClick = input => {
    if (input) {
      input.click();
      this.props.setInputField(input);
    }
  };

  /* eslint-disable */
  handleFolderName = e => {
    e.preventDefault();
    this.setState({ folderName: e.target.value });
  };

  formatFolderOptions = folders => {
    const folderOptions = [];
    folders.forEach(folder => {
      const permissionBasicUpload = !folder.global;
      const permissionRootUpload = folder.global && this.props.isSuperAdmin;

      if (permissionBasicUpload || permissionRootUpload) {
        folderOptions.push({ text: folder.name, value: folder.publicId });
      }
    });
    return folderOptions;
  };

  render() {
    const { folder, selectedFolder, isSuperAdmin, classes } = this.props;
    let idFolderSelected = selectedFolder;
    const findSelectedFolder = folder.filter(fold => fold.publicId === idFolderSelected);
    if (findSelectedFolder && findSelectedFolder.length > 0 && findSelectedFolder[0].global) {
      if (!isSuperAdmin) {
        idFolderSelected = folder.find(fold => fold.name === 'My Images');
        if ('publicId' in idFolderSelected)
          this.props.updateSelectedFolder(idFolderSelected.publicId);
      }
    }
    const {
      name,
      folderName,
      displayCreateFolder,
      loadedImage,
      statusTitle,
      statusButton,
    } = this.state;
    const separator = '.';
    const splitName = name.split(separator);
    let nameExtensionless;
    let extension;
    if (splitName.length < 2) {
      nameExtensionless = name;
      extension = '';
    } else {
      nameExtensionless = splitName.slice(0, splitName.length - 1);
      extension = splitName[splitName.length - 1];
    }

    const folderOptions = Array.isArray(folder) ? this.formatFolderOptions(folder) : [];

    return (
      <div id="upload-image-container">
        <Grid container spacing={0} className="upload-naming">
          <Grid item xs={2} sm={1} className="icon-container">
            <i className={`mountain-icon ${loadedImage}`} />
          </Grid>
          <Grid item xs={8} className="name-image" id="name-image">
            <input
              type="text"
              id="rename_image"
              className={`input-image ${!statusTitle ? 'input-image-active' : ''}`}
              value={nameExtensionless}
              onChange={e => this.onChangeRenameImage(e, extension)}
              disabled={statusTitle}
            />
            {!statusTitle && <span>.{extension && extension}</span>}
          </Grid>
          {statusTitle ? (
            <Grid
              item
              xs={2}
              sm={3}
              className="title"
              onClick={() => this.handleStatusTitle(false)}
            >
              {name && 'Rename'}
            </Grid>
          ) : (
            <Grid
              item
              xs={2}
              sm={3}
              className="title save"
              onClick={() => this.handleStatusButton(false)}
            >
              {name && 'Save'}
            </Grid>
          )}
          <input
            style={{ display: 'none' }}
            type="file"
            id="load_image"
            accept="image/*"
            ref={this.triggerClick}
            onChange={this.loadImageGallery}
          />
        </Grid>
        {folder && (
          <Grid container id="image-gallery-container">
            <Grid item xs={11} className={classes.customSelect}>
              {!displayCreateFolder && (
                <Select
                  data={folderOptions}
                  value={idFolderSelected}
                  onChange={this.handleChangeFolder}
                  responsive={mbscResponsiveOptions}
                  placeholder="Select folder"
                  filterPlaceholderText="Search"
                  buttons={['cancel']}
                />
              )}
            </Grid>
            <Grid item xs={1} className="new-folder-container">
              <div className="new-folder-container" onClick={this.createFolder}>
                <i className={`${displayCreateFolder ? 'new-folder-active' : ''} new-folder`} />
              </div>
            </Grid>
            {displayCreateFolder && (
              <Grid item sm={11} md={10}>
                <Input
                  type="text"
                  name="newFolder"
                  value={folderName}
                  placeholder="New Folder"
                  onChange={this.handleFolderName}
                />
              </Grid>
            )}
          </Grid>
        )}
        <button
          type="button"
          className={`button-upload-gallery ${statusButton ? 'disable' : ''} `}
          onClick={this.uploadImage}
          disabled={statusButton}
        >
          Save
        </button>
      </div>
    );
  }
}
const mapStateToProps = ({
  imageGalleryReducer: { folder, inputField, selectedFolder, selectedImage },
  userProfileReducer: { isSuperAdmin },
}) => ({
  folder,
  inputField,
  selectedFolder,
  selectedImage,
  isSuperAdmin,
});

const mapDispatchToProps = {
  createFolder,
  getFolderImage,
  getFoldersContent,
  setInputField,
  snackBarStatus,
  uploadPicture,
  updateCustomModal,
  updateSelectedFolder,
  updateSelectedImage,
};

UploadGallery.propTypes = UploadGalleryListPropType;

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(UploadGallery),
);
