import { ACTIONS } from '../Actions/threadActions';

const initialState = {
  threadMessages: {},
  threadMainMessage: null,
  selectedThread: null,
  isLoading: false,
  isLoaded: false,
};

const threadReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.LOAD:
      return { ...state, threadMessages: action.payload };
    case ACTIONS.LOADING:
      return { ...state, isLoading: action.payload };
    case ACTIONS.LOADED:
      return { ...state, isLoaded: action.payload };
    case ACTIONS.SET_MAIN_MESSAGE:
      return { ...state, threadMainMessage: action.payload };
    case ACTIONS.SELECT:
      return { ...state, selectedThread: action.payload };
    case ACTIONS.CLEAR:
      return { ...state, threadMessages: initialState.threadMessages, selectedThread: initialState.selectedThread };

    default:
      return state;
  }
};

export default threadReducer;
