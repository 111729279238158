import Colors from '../../Theme/Colors';

export const selectStyles = {
  container: {
    flexGrow: 1,
  },
  icon: {
    width: '32px',
    '&>img': {
      height: 'auto',
      width: 12,
    },
  },
};

export default {
  root: {
    alignItems: 'center',
    display: 'flex',
    marginTop: 6,
  },
  button: {
    border: 'none',
    color: Colors.amethyst,
    fontFamily: 'GothamSSm-Book',
    fontSize: 16,
    margin: 0,
    marginLeft: 20,
    padding: 0,
    width: 46,
    '&:disabled': {
      color: Colors.grey,
      cursor: 'default',
    },
  },
};
