import React from 'react';
import SuccessIcon from '../../Theme/Icons/error.svg';
import WarningMessagePropType from '../../PropTypes/SuccessPropType';
import './index.sass';

const WarningMessage = props => (
  <div className="form__warning-message">
    <p className="form__warning-text">{props.warning}</p>
    <button
      style={props.displayClose ? {} : { display: 'none' }}
      onClick={() => {
        props.hideMessage();
      }}
      className="form_warning-close"
      type="button"
      aria-label="Close"
    >
      <img src={SuccessIcon} alt="SuccessIcon" />
    </button>
  </div>
);

WarningMessage.propTypes = WarningMessagePropType;

export default WarningMessage;
