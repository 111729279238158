import AXIOS from '../Config/AxiosConfig';
import headers from './Helper';

const baseURL = '/eva/twitter';

const SocialShare = {
  getTwitterAccessToken(groupPublicId) {
    let url = `${baseURL}/access-token`;

    if (groupPublicId) {
      url += `/${groupPublicId}`;
    }

    return AXIOS.get(url, { headers: headers() });
  },

  saveTwitterAuthorizationToken(params) {
    const { oauth_token, oauth_verifier } = params;
    return AXIOS.post(
      `/public/twitter/authorization-token/${params.oauth_token}`,
      {
        oauth_token,
        oauth_verifier,
      },
      { headers: headers() },
    );
  },

  setTwitterAuthorizationProgressStatus(params, status) {
    return AXIOS.put(
      `/public/twitter/authorization-token/${params.oauth_token}`,
      {
        status,
      },
      { headers: headers() },
    );
  },

  getTwitterAccountInfo(publicId) {
    return AXIOS.get(`${baseURL}/account-info/${publicId}`, {
      headers: headers(),
    });
  },

  removeTwitterAccount(groupPublicId) {
    return AXIOS.delete(`${baseURL}/account-info/${groupPublicId}`, { headers: headers() });
  },
};

export default SocialShare;
