import React from 'react';
import './index.sass';
import EvaTooltip from '../EvaTooltip';

const ThreadMainMessageButtons = ({
                              isStatistics, isResendUnopened, onResend, onForwardClick,
                              onHistoryClick, onTemplateClick, onStatisticsClick,
}) => (
  <div className="message-view-buttons">
    {isResendUnopened &&
    <EvaTooltip title="Resend unopened">
      <button className="button-resend" onClick={onResend}/>
    </EvaTooltip>
    }
    <EvaTooltip title="Forward">
      <button className="button-forward" onClick={onForwardClick}/>
    </EvaTooltip>
    <EvaTooltip title="View All Versions">
      <button className="button-history" onClick={onHistoryClick}/>
    </EvaTooltip>
    <EvaTooltip title="Save as Template">
      <button onClick={onTemplateClick} className="button-inactive"/>
    </EvaTooltip>
    <EvaTooltip title="Stats">
      <button
        className={`button-statistics ${isStatistics && 'active'}`}
        onClick={onStatisticsClick}
      />
    </EvaTooltip>
  </div>
);

export default ThreadMainMessageButtons;
