import queryString from 'query-string';
import AXIOS from '../Config/AxiosConfig';
import headers from './Helper';

const User = {
  getProfile(org = null) {
    const query = queryString.stringify(org && { org });
    return AXIOS.get(`/user-profile?${query}`, {
      headers: headers(),
    });
  },
  updateSuggestionsSettings({ new_message_replies, group_activity_status }) {
    const data = {
      new_message_replies,
      group_activity_status,
    };
    return AXIOS.put('/eva/user/suggestions/settings', data, {
      headers: headers(),
    });
  },

  deleteAccount() {
    const token = localStorage.getItem('sso_token');
    return AXIOS.post(
      '/user/delete-account',
      {
        token,
      },
      {
        headers: headers(),
      },
    );
  },

  /**
   * Requests the server to unsubscribe the user from the selected group.
   * @param {string} groupId
   * @param {string} memberId
   */
  unsubscribeUserFromGroup(groupId, memberId, token) {
    return AXIOS.post(`/unsubscribe/group/${groupId}/member/${memberId}/${token}`, {}, {
      headers: headers(),
    });
  },
};

export default User;
