const existObject = (object, key) => {
  if (typeof object === 'object' && object !== null && object !== undefined) {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      return true;
    }
  }
  return false;
};

export const isEmpty = obj => {
  const keys = obj ? Object.keys(obj) : null;
  if (keys && keys.length > 0) {
    return false;
  }
  return true;
};
/* eslint-disable */
export const hasKey = (object, keys) => {
  const [email, phone] = keys;
  for (const key in object) {
    console.log(key);
    console.log(object[key]);
    if (object.hasOwnProperty(email) || object.hasOwnProperty(phone)) {
      return object[key] !== '';
    }
  }
};

export const hasEmailOrPhoneKeys = (object, keys) => {
  const [hasEmail, hasPhone] = keys.map(elem => object.hasOwnProperty(elem));
  return hasEmail || hasPhone;
};

/* eslint-enable */
export default existObject;
