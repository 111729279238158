import React, { Component } from 'react';
import { connect } from 'react-redux';
import Switch from '@material-ui/core/Switch';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import ConfirmSendMessagePropType from '../../PropTypes/ConfirmSendMessagePropType';
import { enableRightDrawerMessage } from '../../Actions/rightDrawerMessageActions';
import {
  changeTab,
  CONSTANTS,
  resetMessageValidation,
  saveDraftMessageWithDebounce,
  statusWindowsMessage,
  updateFiles,
  updateMessageSettings,
} from '../../Actions/messageActions';
import { getNotificationPreferences, } from '../../Actions/groupsActions';
import snackBarStatus from '../../Actions/snackbarActions';
import UserSettingsMessage from '../UserSettingsMessage';
import { MPALogin } from '../../Actions/myParishAppActions';
import EvaTooltip from '../EvaTooltip';
import Input from '../Input';
import helpers from '../../Helpers';
import MessageHeadSettings from '../../Containers/MessageHeadSettings';
import MessageChannels from '../../Containers/MessageChannels';
import { switchStyles } from '../../Theme/switchStyles';
import ButtonSimple from '../ButtonSimple';
import GenericMessage from '../GenericMessage';
import { getRecipientGroupsFromDictionary } from '../../Helpers/getRecipientGroupsFromDictionary';
import './index.sass';
import LoaderOverlay from '../LoaderOverlay';
import FilesManager from '../FilesManager';

const styles = theme => ({
  ...switchStyles,
});

class ConfirmSendMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendButton: false,
      validEmail: false,
      statusEmailField: false,
    };
  }

  componentDidMount() {
    this.props.resetMessageValidation();

    const { recipientGroups } = this.props;

    if (this.props.sendByMpa) {
      this.checkMPALogin();
    }
    if (recipientGroups.length > 0) {
      this.props.getNotificationPreferences(recipientGroups);
    }
  }

  handleClose = () => this.setState({ selectOpenCustomTime: false });

  handleRecipient = e =>
    this.props.updateMessageSettings({ includeRecipientName: !this.props.messageSettings.includeRecipientName });

  handleMessageSettingsChange = e => {
    const updatedSettings = {};
    switch (e.target.name) {
      case 'emailForward':
        updatedSettings.emailForward = e.target.value;
        this.setState({ validEmail: helpers.EmailSimple(e.target.value) });
        if (e.target.value === '') {
          this.setState({ statusEmailField: false });
        } else {
          this.setState({ statusEmailField: true });
        }
        break;
      case 'previewText':
        updatedSettings.previewText = e.target.value;
        break;
    }
    this.props.updateMessageSettings(updatedSettings);
  };

  handleSave = () => {
    this.props.saveDraftMessageWithDebounce()

    this.props.statusWindowsMessage({
      show: false,
      message: 'Your draft has been save successfully',
      type: 'info',
      titleMessage: 'Success',
    });
  };

  handleRenderComponent = () => {
    this.props.enableRightDrawerMessage({
      payload: {
        enable: true,
        element: <UserSettingsMessage />,
        title: 'New Message',
        secondTitle: false,
      },
    });
  };

  checkMPALogin = () => {
    const { groupsDictionaryList, recipientGroups, sendByMpa } = this.props;
    if (sendByMpa) {
      const recipientGroupsFromDictionary = getRecipientGroupsFromDictionary(groupsDictionaryList, recipientGroups);
      const mpaGroups = recipientGroupsFromDictionary.filter(group => group.group.send_to_my_parish_app);
      if (mpaGroups.length > 0 && !mpaGroups) {
        const promises = mpaGroups.map(group => {
          if (group.name !== 'All Members') {
            return this.props.MPALogin(group.publicId);
          }
          return Promise.resolve();
        });
        const results = Promise.all(promises);
        results.catch(error => {
          this.props.snackBarStatus({
            payload: {
              title: 'User is not synced with some MPA groups.',
              enable: true,
              type: 'warning',
            },
          });
          console.error(error);
        });
      }
    }
  };

  renderScheduledMessage = messageSettings => () => {
    const { recipientGroups } = this.props;
    let groups = '';
    const counter = recipientGroups.length - 1;
    recipientGroups.forEach((element, index) => {
      groups += `${element.group.name}${counter === index ? '' : ' - '}`;
    });
    const time = moment(messageSettings.deliveryTime).format('MM/DD/YYYY - LT');
    return (
      <div className="scheduled-text">
        <div> {groups} </div>
        <div>{`${time} - ${messageSettings.gmt}`}</div>
      </div>
    );
  };

  render() {
    const { sendButton } = this.state;
    const {
      recipientType,
      classes,
      messageSettings: { previewText, emailForward, includeRecipientName },
      smsPreferences,
      emailPreferences,
      isMessageSending,
      validationErrors,
      onScheduleMessage,
      sendByEmail,
      sendBySms,
    } = this.props;

    let message;
    let showMessage;
    if (!sendByEmail && !sendBySms) {
      message = `Disabling Email and SMS will restrict ${emailPreferences +
      smsPreferences} group members from receiving this message.`;
      showMessage = true;
    } else if (!sendByEmail) {
      message = `Disabling Email will restrict ${emailPreferences} group members from receiving this message.`;
      showMessage = true;
    } else if (!sendBySms) {
      message = `Disabling SMS will restrict ${smsPreferences} group members from receiving this message.`;
      showMessage = true;
    }

    const isDirectMessage = recipientType === CONSTANTS.RECIPIENT_TYPE_MEMBER;

    const handleUpdateFiles = (files) => this.props.updateFiles(files);

    const handleFileUploadError = (err) =>
      this.props.snackBarStatus({
        payload: {
          title: err,
          enable: true,
          type: 'error',
        },
      });

    return (
      <section id="confirm-send-message">
        <MessageHeadSettings />
        <section className="side-to-side-wrapper send-to-section">
          <div>
            Send to:
          </div>

          <MessageChannels isInvalid={validationErrors.channels} />
        </section>

        <section className="input-eva">
          {showMessage && !isDirectMessage && (
            <div className="message-container">
              <GenericMessage
                type="info"
                title="Warning: "
                message={message}
              />
            </div>
          )}
        </section>

        <section className="optional-message-settings">
          <div className="title">Optional settings</div>
          <div className="side-to-side-wrapper add-to-wrapper">
            <div>Add recipient name in email "To:"</div>
            <div>
              <Switch
                onChange={this.handleRecipient}
                className="switchUp"
                classes={{
                  root: classes.root,
                  switchBase: classes.switchBase,
                  colorSecondary: classes.switchColorSecondary,
                  track: classes.track,
                  thumb: classes.thumb,
                }}
                checked={includeRecipientName}
              />
              <EvaTooltip
                title="Make the member's name visible along with the email address in the To: field on the message they receive.">
                <div className="help-icon" />
              </EvaTooltip>
            </div>
          </div>

          <div className="side-to-side-wrapper preview-text-wrapper">
            <div>Preview text for email:</div>
            <div>
              <Input
                className="preview-field"
                name="previewText"
                type="text"
                maxLength="150"
                value={previewText}
                onChange={this.handleMessageSettingsChange}
              />
              <EvaTooltip
                title="This text is usually visible with the message subject in the recipient's email inbox list of messages">
                <div className="help-icon" />
              </EvaTooltip>
            </div>
          </div>

          <div className="side-to-side-wrapper forward-replies-wrapper">
            <div>Forward replies to:</div>
            <Input
              className={`preview-field ${validationErrors.emailForward && 'validation-form'}`}
              name="emailForward"
              type="email"
              maxLength="150"
              value={emailForward}
              onChange={this.handleMessageSettingsChange}
            />
          </div>
        </section>

        <div className="attachments-wrapper">
          <FilesManager data={this.props.files} onChange={handleUpdateFiles} onError={handleFileUploadError} />
        </div>

        <div className="modal-actions">
          <ButtonSimple className="button-send" text="Send now" disabled={sendButton}
                        onClick={this.props.onSendMessage} />

          <br />

          {!isDirectMessage && (
            <ButtonSimple
              className="button-schedule"
              buttonStyle="transparent"
              text="Schedule message"
              onClick={onScheduleMessage}
            />
          )}

          <ButtonSimple
            className="button-send-test"
            buttonStyle="transparent"
            onClick={this.props.onDraftTestSend}
            text="Send test"
          />
        </div>

        {isMessageSending && <LoaderOverlay />}
      </section>
    );
  }
}

ConfirmSendMessage.propTypes = ConfirmSendMessagePropType;

const mS = state => ({
  subject: state.messageReducer.subject,
  sendByEmail: state.messageReducer.sendByEmail,
  sendByMpa: state.messageReducer.sendByMpa,
  sendBySms: state.messageReducer.sendBySms,

  files: state.messageReducer.files,
  isMessageSending: state.messageReducer.isMessageSending,
  recipientGroups: state.messageReducer.recipientGroups,
  recipientType: state.messageReducer.recipientType,
  messageSettings: state.messageReducer.messageSettings,
  validationErrors: state.messageReducer.validationErrors,

  selectedMessage: state.messagesReducer.selectedMessage,

  emailPreferences: state.groupsReducer.emailPreferences,
  selectedGroup: state.groupsReducer.selectedGroup,
  smsPreferences: state.groupsReducer.smsPreferences,

  groupsDictionaryList: state.groupsDictionaryReducer.list,

  isResendUnopened: state.messageReducer.isResendUnopened,
});

const mD = {
  changeTab,
  enableRightDrawerMessage,
  getNotificationPreferences,
  MPALogin,
  resetMessageValidation,
  saveDraftMessageWithDebounce,
  statusWindowsMessage,
  snackBarStatus,
  updateMessageSettings,
  updateFiles,
};

export default withRouter(withStyles(styles)(connect(mS, mD)(ConfirmSendMessage)));
