import React, { Component } from 'react';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { enableRightDrawerMessage } from '../../Actions/rightDrawerMessageActions';
import RightDrawerPropType from '../../PropTypes/RightDrawerPropType';
import { changeTab } from '../../Actions/middleMenuActions';
import { displayGallery } from '../../Actions/imageGalleryActions';
import SimpleMessage from '../SimpleMessage';
import MessagePreview from '../../Containers/MessagePreview';
import { getDate, getDayOfTheWeek } from '../../Helpers/date';
import ActionUserSettingsButton from '../ActionUserSettingsButton';
import { clearMessage, statusWindowsMessage } from '../../Actions/messageActions';
import { enableRightDrawer } from '../../Actions/rightDrawerManageTemplateActions';
// import './index.sass';
import EvaTooltip from '../EvaTooltip';
import styles from './styles';
import { MessageActionsButtons } from '../MessageActionsButtons';
import { clearSelectedTemplate } from '../../Actions/templateActions';

import './index.sass';

const empty = () => <div />;


class RightDrawerMessage extends Component {
  state = {
    side: 'right',
  };

  handleDrawer = () => {
    const { isBackButton, backTitle } = this.props;
    let ShowElement = null;
    let SecondElement;
    let back;
    let buttonStatus = false;
    let enableComponent = true;
    if (isBackButton) {
      switch (backTitle) {
        case 'Message':
          ShowElement = SimpleMessage;
          SecondElement = ActionUserSettingsButton;
          buttonStatus = false;
          break;
        case 'Messages':
          ShowElement = empty;
          SecondElement = empty;
          buttonStatus = false;
          enableComponent = false;
          break;
        case 'Message Preview':
          ShowElement = MessagePreview;
          SecondElement = MessageActionsButtons;
          buttonStatus = true;
          back = 'Message';
          break;
        case 'Message Failure':
          ShowElement = SimpleMessage;
          SecondElement = MessageActionsButtons;
          buttonStatus = true;
          back = 'Message';
          break;
        default:
          break;
      }
      this.props.enableRightDrawerMessage({
        payload: {
          enable: enableComponent,
          title: backTitle,
          element: <ShowElement />,
          secondTitle: <SecondElement />,
          isBackButton: buttonStatus,
          backTitle: back,
        },
      });
    } else {
      this.props.enableRightDrawerMessage({
        payload: { enable: false, element: <div /> },
      });

      this.props.clearMessage();

      if (this.props.isGalleryOpen) {
        this.props.displayGallery(false);
      }

      if (window.innerWidth >= 1024) {
        document.querySelector('.right-drawer.drawer-message > .drawer-md-up').style.display =
          'none';
      }
    }
  };


  handleDrawerClose = () => {
    this.props.clearSelectedTemplate();
    this.props.clearMessage();
    const { cleaner } = this.props;
    if (cleaner) {
      this.props.cleaner();
    }
    this.props.enableRightDrawer({
      payload: { enable: false, element: <div />, menuOptions: null },
    });
    if (window.innerWidth >= 1024) {
      document.querySelector('.right-drawer.drawer-manage-template .drawer-md-up').style.display =
        'none';
    }
  };

  handleChangeAnchor = event => {
    this.setState({
      side: event.target.value,
    });
  };

  close = () => {
    this.props.statusWindowsMessage({ show: false });
  };

  render() {
    const {
      classes,
      element,
      enable,
      title,
      secondTitle,
      isBackButton,
      disableEnforceFocus,
      menuOptions,
      subtitle,
      subtitleDate,
    } = this.props;
    const { side } = this.state;

    return (
      <div className={`right-drawer drawer-manage-template ${!enable ? 'disable' : ''}`}>
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor={side}
            open={enable}
            classes={{
              paper: classes.drawerPaper,
            }}
            disableEnforceFocus={disableEnforceFocus}
          >
            <div className="drawer-tpl-view">
              <div className={classes.drawerHeader}>
                <IconButton onClick={this.handleDrawerClose} className={`${classes.iconButtonContainer} abs stack-top`}>
                  <KeyboardArrowLeft />
                </IconButton>
                <div className="drawer-message-title abs full-width">Templates</div>
                {secondTitle && (
                  <div className="drawer-message-second-title design-title flexed-elements abs full-width transparent">{secondTitle}</div>
                )}
              </div>
              <div className={classes.tabletTemplateHeader}>
                <div className="title">
                  <div className={classes.drawerTitle} title={title}>
                    {title}
                  </div>
                  <div className="drawer-subtitle-small">
                    {subtitle} | {getDate(subtitleDate)}
                  </div>
                </div>
                {menuOptions && <div className="drawer-menu-options">{menuOptions}</div>}
              </div>

              <div className="right-drawer-content">{element}</div>
            </div>
          </Drawer>
        </Hidden>
        <Hidden smDown>
          <div className="drawer-md-up">
            <Drawer
              anchor={side}
              open={enable}
              classes={{
                paper: classes.drawerPaper,
              }}
              disableEnforceFocus={disableEnforceFocus}
            >
              <div className="drawer-tpl-view">
                <div className={classes.drawerHeader}>
                  <div className={classes.titleBox}>
                    {!isBackButton && (
                      <EvaTooltip title="Back">
                        <IconButton onClick={this.handleDrawerClose}>
                          <KeyboardArrowLeft />
                        </IconButton>
                      </EvaTooltip>
                    )}
                    {menuOptions ? (
                        <div style={{ maxWidth: '80%' }}>
                          <div className={classes.drawerTitle} title={title}>
                            {title}
                          </div>
                          <div className="drawer-subtitle-small">
                            {getDayOfTheWeek(subtitleDate)} {subtitle} | {getDate(subtitleDate)}
                          </div>
                        </div>
                      ) :
                      (
                        <div className="drawer-title">{title}</div>
                      )}
                  </div>
                  {menuOptions && <div className="drawer-menu-options">{menuOptions}</div>}
                </div>
                <div className="right-drawer-content">{element}</div>
              </div>
            </Drawer>
          </div>
        </Hidden>
      </div>
    );
  }
}

const mS = state => ({
  enable: state.rightDrawerManageTemplateReducer.enable,
  element: state.rightDrawerManageTemplateReducer.element,
  title: state.rightDrawerManageTemplateReducer.title,
  secondTitle: state.rightDrawerManageTemplateReducer.secondTitle,
  cleaner: state.rightDrawerManageTemplateReducer.cleaner,
  menuOptions: state.rightDrawerManageTemplateReducer.menuOptions,
  disableEnforceFocus: state.rightDrawerManageTemplateReducer.disableEnforceFocus,
  subtitle: state.rightDrawerManageTemplateReducer.subtitle,
  subtitleDate: state.rightDrawerManageTemplateReducer.subtitleDate,
});

const mD = {
  enableRightDrawerMessage,
  changeTab,
  clearMessage,
  statusWindowsMessage,
  displayGallery,
  enableRightDrawer,
  clearSelectedTemplate,
};

RightDrawerMessage.propTypes = RightDrawerPropType;

export default connect(
  mS,
  mD,
)(withStyles(styles, { withTheme: true })(RightDrawerMessage));
