const env = () => {
  const { API_URL, SHORTENER_URL, APP_CONFIG } = process.env;
  switch (APP_CONFIG) {
    case 'staging':
      return {
        API_URL: API_URL || 'https://api.diocesan-staging.com',
        SHORTENER_URL: SHORTENER_URL || 's.eva.us/',
        SSO_URL: 'https://sso.gateway.diocesan-staging.com',
        EVA_FRONT_URL: 'https://eva.diocesan-staging.com',
        ADMIN_FRONT_URL: 'https://admin.diocesan-staging.com',
        SHORTENER_URL_LENGTH: 25,
      };
    case 'prod':
      return {
        API_URL: API_URL || 'https://api.diocesan.com',
        SHORTENER_URL: SHORTENER_URL || 'eva.us/',
        SSO_URL: 'https://sso.gateway.diocesan.com',
        EVA_FRONT_URL: 'https://eva.diocesan.com',
        ADMIN_FRONT_URL: 'https://admin.diocesan.com',
        SHORTENER_URL_LENGTH: 23,
      };
    // Switch to development config by default
    default:
      return {
        API_URL: API_URL || 'https://api.diocesan-dev.com',
        SHORTENER_URL: SHORTENER_URL || 'd.eva.us/',
        SSO_URL: 'https://sso.gateway.diocesan-dev.com',
        EVA_FRONT_URL: 'https://eva.diocesan-dev.com',
        ADMIN_FRONT_URL: 'https://admin.diocesan-dev.com',
        SHORTENER_URL_LENGTH: 25,
      };
  }
};

export const FB_APP_ID = process.env.APP_CONFIG === 'prod' ? '284180182189877' : '1451526968993942';
export const FB_GRAPH_VERSION = 'v16.0';

export const { API_URL, SHORTENER_URL, SSO_URL, EVA_FRONT_URL, ADMIN_FRONT_URL, SHORTENER_URL_LENGTH } = env();

export const SSO_LOGIN_URL = `${SSO_URL}/proxy/secure`;
export const SSO_MENU_URL = `${API_URL}/public/sso/menu`;
export const DIOCESAN_EVA_URL = 'https://diocesan.com/dashboard/evangelus';

export const CHARACTERS_LIMIT_TWITTER_MESSAGE = 280;
export const CHARACTERS_LIMIT_FB_MESSAGE = 280;
export const CHARACTERS_LIMIT_MPA_MESSAGE = 3000;
export const CHARACTERS_LIMIT_SMS_MESSAGE = 160;

export const URL_PLACEHOLDER = '[message-link]';

export default API_URL;

export const SUPPORT_PHONE_NUMBER = '1 (800) 994-9817';
export const SUPPORT_EMAIL = 'evangelus@diocesan.com';
