import PropTypes from 'prop-types';

const CheckboxPropTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  filled: PropTypes.bool,
  outlined: PropTypes.bool,
};

export default CheckboxPropTypes;
