import React, { Component } from 'react';
import { connect } from 'react-redux';
import Input from '../../Components/InputIcon';
import ButtonStandard from '../../Components/Button';
import { updatePasswordUser, resetPasswordUser, clearPassword } from '../../Actions/passwordActions';
import snackBarStatus from '../../Actions/snackbarActions';
import Helpers from '../../Helpers';
import PropTypes from 'prop-types';
import './index.sass';

class PasswordChangePad extends Component {
  state = {
    uppercase: false,
    lowercase: false,
    length: false,
    specialCharacterOrNumber: false,
    focus: '',
    confirmPassword: false,
  };

  handleChangePass = (e, target) => {
    const { value } = e.target;
    const keys = Object.keys(this.state);
    const { first } = this.props;
    if (e.target.name !== 'currentPassword' && e.target.name !== 'confirmPassword') {
      keys.forEach(term => {
        const newState = {};
        newState[term] = Helpers.PasswordCheck(term, value);
        this.setState({ ...newState });
      });
    } else if (e.target.name === 'confirmPassword') {
      const newState = {};
      newState[target] = value === first;
      this.setState({ ...newState });
    }
    const payload = {};
    payload[target] = value;
    this.props.updatePasswordUser({ payload });
  };

  requestedParams = target => {
    if (this.state[target]) return 'active';
    return null;
  };

  cancelPasswordChange = e => {
    e.preventDefault();
    this.props.clearPassword();
    this.props.displayChangePasswordView(true);
  }

  submit = e => {
    e.preventDefault();
    const { currentPassword, first, second } = this.props;
    if (Helpers.ValidatePassword({ ...this.state, first, second, currentPassword })) {
      const payload = {
        current_password: currentPassword,
        plain_password: {
          first,
          second,
        },
      };
      this.props.resetPasswordUser(payload).then(backendResponse => {
        const { payload: response } = backendResponse;
        if (response.code === 200) {
          this.props.clearPassword();
          this.props.displayChangePasswordView(true);
        }
      });
    } else if (currentPassword === '') {
      this.props.snackBarStatus({
        payload: {
          title: 'Add current password',
          type: 'error',
          enable: true,
        },
      });
    } else if (first === '') {
      this.props.snackBarStatus({
        payload: {
          title: 'Add new password',
          type: 'error',
          enable: true,
        },
      });
    } else if (second === '') {
      this.props.snackBarStatus({
        payload: {
          title: 'Confirm password',
          type: 'error',
          enable: true,
        },
      });
    } else {
      this.props.updatePasswordUser({
        payload: {
          error: "Passwords don't have required characters or don't match",
        },
      });
      this.props.snackBarStatus({
        payload: {
          title: "Passwords don't have required characters or don't match",
          type: 'error',
          enable: true,
        },
      });
    }
  };

  handleFocus = () => {
    const focus = 'input-eva-focused';
    this.setState({ focus });
  };

  render() {
    const { focus } = this.state;
    return (
      <div className="password-change-pad">
        <div className="current-password">
          <Input
            type="password"
            title="current password"
            icon="current-password"
            placeholder="Current Password"
            name="currentPassword"
            useId="password-special"
            tooltipTitle="Current password"
            value={this.props.currentPassword}
            onChange={e => this.handleChangePass(e, 'currentPassword')}
          />
        </div>
        <div onFocus={() => this.handleFocus()}>
          <Input
            type="password"
            title="new password"
            icon="new-password"
            placeholder="New password"
            name="newPassword"
            tooltipTitle="New Password"
            focused={focus}
            value={this.props.first}
            useId="password-special"
            onChange={e => this.handleChangePass(e, 'newPassword')}
          />
          <Input
            type="password"
            title="confirm password"
            icon="confirm-password"
            placeholder="Confirm password"
            tooltipTitle="Confirm Password"
            focused={focus}
            useId="password-special"
            name="confirmPassword"
            value={this.props.second}
            onChange={e => this.handleChangePass(e, 'confirmPassword')}
          />
        </div>
        <div className="password-requires">
          <span className="title-medium">Your password requires at least:</span>
          <ul className="list-password-requires narrowed">
            <li className={this.requestedParams('lowercase')}>One lowercase letter</li>
            <li className={this.requestedParams('uppercase')}>One Uppercase letter</li>
            <li className={this.requestedParams('specialCharacterOrNumber')}>
              One number or special character
            </li>
            <li className={this.requestedParams('length')}>Minimum 8 character length</li>
            <li className={this.requestedParams('confirmPassword')}>Both fields match</li>
          </ul>
        </div>
        <div>
          <ButtonStandard title="Change" onClick={this.submit} />
          <br/>
          <ButtonStandard title="Cancel" onClick={this.cancelPasswordChange} />
        </div>
      </div>
    );
  }
}

PasswordChangePad.propTypes = {
  displayChangePasswordView: PropTypes.bool.isRequired,
};

const mS = state => ({
  currentPassword: state.passwordReducer.currentPassword,
  first: state.passwordReducer.newPassword,
  second: state.passwordReducer.confirmPassword,
});

const mD = {
  updatePasswordUser,
  resetPasswordUser,
  snackBarStatus,
  clearPassword,
};

export default connect(
  mS,
  mD,
)(PasswordChangePad);
