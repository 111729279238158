import PropTypes from 'prop-types';

const AvatarPropTypes = {
  classes: PropTypes.object.isRequired,
  image: PropTypes.string,
  className: PropTypes.string,
  defaultImg: PropTypes.string,
  borderless: PropTypes.bool,
  borderSize: PropTypes.string,
  bigAvatar: PropTypes.bool,
};

export default AvatarPropTypes;
