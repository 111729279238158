import React from 'react';
import './index.sass';
import { LoaderAnimation } from '../../Animations';
import EvaAnimation from '../EvaAnimation';
import PropTypes from 'prop-types';

const LoadingScreen = (props) => {
  const { loadingText } = props;
  return (
    <div className="container-center login">
      <div className="login__title">
        <p className="login__welcome">{loadingText}</p>
        <p className="logo-evangelus">EVANGELUS</p>
      </div>
      <EvaAnimation loop animation={LoaderAnimation}/>
    </div>
  );
};

LoadingScreen.propTypes = {
  loadingText: PropTypes.string,
};

LoadingScreen.defaultProps = {
  loadingText: 'Welcome to',
};

export default LoadingScreen;
