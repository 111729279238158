import { ACTIONS } from '../Actions/notificationsActions';

const initialState = {
  notifications: [],
  isRemoving: false,
  isLoading: false,
  isLoaded: false,
  isLastChunk: false,
  total: 0,
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.LOAD_NOTIFICATIONS: {
      const { isLastChunk, notifications, total } = action.payload;
      return {
        ...state,
        notifications,
        total,
        isLoaded: true,
        isLastChunk,
      };
    }

    case ACTIONS.REMOVE_NOTIFICATION:
    case ACTIONS.DISMISS_NOTIFICATION:
    case ACTIONS.ACCEPT_JOIN_REQUEST:
      return {
        ...state,
        notifications: state.notifications.filter(n => n.public_id !== action.payload),
        total: state.total > 0 ? state.total - 1 : 0,
      };

    case ACTIONS.LOADING_NOTIFICATIONS:
      return { ...state, isLoading: action.payload };

    case ACTIONS.REMOVING_NOTIFICATIONS:
      return { ...state, isRemoving: action.payload };

    case ACTIONS.CLEAR_NOTIFICATIONS:
      return {
        ...state,
        ...initialState,
      };

    default:
      return { ...state };
  }
};

export default notificationsReducer;

