import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import EvaTooltip from '../EvaTooltip';
import './index.sass';

const defaultAllowedValues = ['email', 'sms', 'all'];

const getTitleByType = (type) => {
  switch (type) {
    case 'email':
      return 'Email';
    case 'sms':
      return 'SMS';
    case 'all':
      return 'SMS and Email';
    default:
      return '';
  }
}
const ChannelPicker = ({
  className = '', isPreviewMode = false, isEmailDisabled = false, isSmsDisabled = false, value, onChange
}) => (
  <div className={classNames('channel-picker', className)}>
    {defaultAllowedValues
      .filter((type) => {
        switch (type) {
          case 'email':
            return !isEmailDisabled;
          case 'sms':
            return !isSmsDisabled;
          case 'all':
            return !isEmailDisabled && !isSmsDisabled;
          default:
            return false;
        }
      })
      .map((type) => (
        <EvaTooltip title={getTitleByType(type)} key={type}>
          <button
            disabled={isPreviewMode}
            className={classNames(`icon-button type-${type}`, { 'active': value === type })}
            onClick={() => onChange(type)}
          />
        </EvaTooltip>
      ))}
  </div>
);

export default ChannelPicker;

ChannelPicker.defaultProps = {
  value: null,
  isPreviewMode: false,
  isEmailDisabled: false,
  isSmsDisabled: false,
}

ChannelPicker.propTypes = {
  value: PropTypes.oneOf(defaultAllowedValues.concat(null)).isRequired,
  onChange: PropTypes.func.isRequired,
  isPreviewMode: PropTypes.bool,
  isEmailDisabled: PropTypes.bool,
  isSmsDisabled: PropTypes.bool,
};
