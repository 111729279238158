import React, { Component } from 'react';
import NimblePicker from 'emoji-mart/dist-es/components/picker/nimble-picker';
import data from 'emoji-mart/data/messenger.json';
import 'emoji-mart/css/emoji-mart.css';
import Radio from '@material-ui/core/Radio';
import Grid from '@material-ui/core/Grid';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ButtonStandard from '../Button';
import EvaTooltip from '../EvaTooltip';
import { bannedEmojisCodes } from '../../Config/banned-emojis';
import './index.sass';
import ComposerToolbarPropType from '../../PropTypes/ComposerToolbarPropType';
import {
  getParentLink,
  getSelectionParentElement,
  getSelectionRange,
  setSelectionRange
} from '../../Helpers/selection';
import ButtonSimple from '../ButtonSimple';
import LettersCounter from '../LettersCounter';
import { SMS_TEXT_LENGTH_LIMIT } from '../../Config/Constants';

class ComposerToolbar extends Component {
  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {
      linkControlType: 'link',
      linkControlHref: '',
      linkEditorVisible: false,
      emojiPickerVisible: false,
      selectionRange: null,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (this.state.linkEditorVisible) {
        this.toggleLinkEditor();
      }
      if (this.state.emojiPickerVisible) {
        this.toggleEmojiPicker();
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.isTextSelected && prevState.linkEditorVisible) {
      return {
        ...prevState,
        linkEditorVisible: false,
        linkControlHref: '',
        linkControlType: 'link',
      };
    }
    return prevState;
  }

  filterEmojis = emoji => !bannedEmojisCodes.includes(emoji.unified);

  getValidUrl = (url = '') => (url.indexOf('http') !== 0 ? `http://${url}` : url);

  handleRadio = (event, value) => {
    this.setState({ linkControlType: value });
  };

  handleNextButtonClick = () => {
    const { isLoading, onNextButtonClick } = this.props;
    if (isLoading) return;
    onNextButtonClick();
  };

  handleEmojiSelect = (e) => {
    this.props.onEmojiSelect(e.native);
    this.toggleEmojiPicker();
  };

  toggleLinkEditor = () => {
    this.setState({
      linkEditorVisible: !this.state.linkEditorVisible,
      emojiPickerVisible: false,
      linkControlHref: getParentLink(getSelectionParentElement()),
      linkControlType: 'link',
      selectionRange: getSelectionRange(),
    });
  };

  toggleEmojiPicker = () => {
    this.setState({
      emojiPickerVisible: !this.state.emojiPickerVisible,
      linkEditorVisible: false,
    });
  };

  submitLink = (e) => {
    e.preventDefault();
    let href = '';

    switch (this.state.linkControlType) {
      case 'email':
        href = `mailto:${this.state.linkControlHref}`;
        break;
      case 'link':
      default:
        href = this.getValidUrl(this.state.linkControlHref);
        break;
    }

    this.toggleLinkEditor();
    setSelectionRange(this.state.selectionRange);
    document.execCommand('createLink', false, href); // Send the command to the browser
  };

  renderLinkPopup = () => {
    const { linkControlType } = this.state;

    return (
      <div
        className={`toggled-visible-content popup-content popup-insert-link ${this.state.linkEditorVisible ? 'active' : ''}`}
      >
        <div className="link-text">{this.state.selectionRange ? this.state.selectionRange.toString() : ''}</div>
        <Grid container>
          <RadioGroup
            className="link-radio-group"
            value={this.state.linkControlType}
            onChange={this.handleRadio}
          >
            <FormControlLabel
              className="link-radio"
              value="link"
              control={<Radio/>}
              label="Link"
            />
            <FormControlLabel
              className="link-radio"
              value="email"
              control={<Radio/>}
              label="Email"
            />
          </RadioGroup>
        </Grid>
        <Grid container className="link-wrapper">
          <input
            className="link-field"
            type="text"
            placeholder={`${
              linkControlType === 'email' ? 'Email Address' : 'Web Address URL'
            }`}
            value={this.state.linkControlHref}
            name="value"
            onChange={e => this.setState({ linkControlHref: e.target.value })}
          />
          <Grid container className="link-actions">
            <ButtonStandard
              title="Insert"
              cmd="createLink"
              arg={this.state.email}
              name="hyperlink"
              className="link-action-insert"
              onClick={this.submitLink}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  render() {
    const {
      contentLength, onTemplateClick, onGalleryClick, isTextSelected, nextButtonText, counterTooltip, limit
    } = this.props;
    const isLoading = true;
    
    return (
      <div className="composer-toolbar" ref={this.setWrapperRef}>
        <LettersCounter  
          limit={limit}
          length={contentLength} 
          tooltip={counterTooltip}  
        />
        <div className="toolbar-panel">
          <div className="left-side">
            {onTemplateClick && (
              <EvaTooltip title="Use Template">
                <button htmlFor="load_template" className="btn-cmd icons icons-flexed template"
                        onClick={onTemplateClick}/>
              </EvaTooltip>
            )}

            <EvaTooltip title="Add Photo">
              <button className="btn-cmd icons icons-flexed camera" htmlFor="load_image" onClick={onGalleryClick}/>
            </EvaTooltip>

            <EvaTooltip title="Bold">
              <button
                className="btn-cmd icons icons-flexed format-bold"
                onClick={evt => {
                  evt.preventDefault(); // Avoids loosing focus from the editable area
                  document.execCommand('bold', false); // Send the command to the browser
                }}
              />
            </EvaTooltip>

            <EvaTooltip title="Italic">
              <button
                className="btn-cmd icons icons-flexed format-italic"
                onClick={evt => {
                  evt.preventDefault();
                  document.execCommand('italic', false);
                }}
              />
            </EvaTooltip>

            <EvaTooltip title="Underline">
              <button
                className="btn-cmd icons icons-flexed format-underline"
                onClick={evt => {
                  evt.preventDefault();
                  document.execCommand('underline', false);
                }}
              />
            </EvaTooltip>

            <div className="button-with-popup-wrapper link-wrapper">
              <EvaTooltip title="Add a link" interactive={!isTextSelected}>
                <button
                  disabled={!isTextSelected}
                  className={`btn-cmd icons icons-flexed link ${this.state.linkEditorVisible ? 'active' : ''}`}
                  onClick={this.toggleLinkEditor}
                />
              </EvaTooltip>

              {this.renderLinkPopup()}
            </div>
            <div className="button-with-popup-wrapper emoji-wrapper">
              <div className={`toggled-visible-content popup-content ${this.state.emojiPickerVisible ? 'active' : ''}`}>
                <NimblePicker
                  data={data}
                  set="apple"
                  onSelect={this.handleEmojiSelect}
                  showPreview={false}
                  emojisToShowFilter={this.filterEmojis}
                />
              </div>
              <EvaTooltip title="Add Emoji">
                <button
                  className={`btn-cmd icons icons-flexed happy ${this.state.emojiPickerVisible ? 'active' : ''}`}
                  onClick={this.toggleEmojiPicker}
                />
              </EvaTooltip>
            </div>
          </div>
          <div className="right-side">
            {!nextButtonText && (
              <EvaTooltip title="Preview">
                <button
                  className={`icon-button next ${isLoading ? 'wait-group-load' : ''}`}
                  onClick={this.handleNextButtonClick}
                />
              </EvaTooltip>
            )}

            {nextButtonText && (
              <ButtonSimple
                mini
                buttonStyle="fill"
                text={nextButtonText}
                onClick={this.handleNextButtonClick}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

ComposerToolbar.propTypes = ComposerToolbarPropType;

ComposerToolbar.defaultProps = {
  counterTooltip: null,
  isLoading: false,
  nextButtonText: null,
  selectionRange: null,
  limit: SMS_TEXT_LENGTH_LIMIT,
  onTemplateClick: null,
};

export default ComposerToolbar;
