import PropTypes from 'prop-types';

const ScheduleMessagePropType = {
  isLoading: PropTypes.bool.isRequired,
  messageTimeZone: PropTypes.string.isRequired,
  orgTimeZone: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSendMessage: PropTypes.func.isRequired,
  onTimezonePick: PropTypes.func.isRequired,
};

export default ScheduleMessagePropType;
