import PropTypes from 'prop-types';

const LoginPropTypes = {
  email: PropTypes.string,
  password: PropTypes.string,
  error: PropTypes.string,
  login: PropTypes.func,
};

export default LoginPropTypes;
