import PropTypes from 'prop-types';

const PhoneInputPropType = {
  onInputPhoneChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default PhoneInputPropType;
