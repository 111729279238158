import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

const redirect = () => {
  window.location = '/';
  return 0;
};

const NotFound = ({ classes }) => (
  <div className={classes.container}>
    <div className={classes.contentContainer}>
      <div className={classes.content}>
        <img src="https://s3.amazonaws.com/diocesan-eva/shorterner/group-33x.png" alt="Evangelus logo" />
        <h2 className="oops">Oops!</h2>
        <p className="message">The page you <br /> requested could not be found.</p>
        <button onClick={redirect}>
          Go back to Evangelus
        </button>
      </div>
    </div>
    <div className={classes.bubbles} />
  </div>
);

export default withStyles(styles)(NotFound);
