import { CHARACTERS_LIMIT_FB_MESSAGE, URL_PLACEHOLDER } from '../../../Config/env';

/**
 * If the content contains more than
 * @param {string} message
 */

export default function FBCutter(text, isMultiImg, isContainLinks) {
  let result = text;
  if (isMultiImg || isContainLinks || (text && text.length > CHARACTERS_LIMIT_FB_MESSAGE)) {
    const tailText = `${text.length > 0 ? '... ' : ''}Read more at ${URL_PLACEHOLDER}`;
    const cutMessage = text.substr(0, CHARACTERS_LIMIT_FB_MESSAGE - tailText.length);
    result = `${cutMessage}${tailText}`;
  }

  return result
    .replace(/^\s+|\s+$/g, '') // remove spacings / line breaks on the start/end of the string
    .replace(/\n{2,}/g, '\n'); // remove extra line breaks
}
