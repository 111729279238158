import getMemberInfo from './getMemberInfo';

export const displayMemberName = member => {
  if (typeof member !== 'object') {
    return '';
  }

  const { mainPhoneNumber, phoneNumber, hasEmail } = member;
  const email = member.email || (member.user && member.user.email);
  let { firstName, lastName } = member;

  const phone = mainPhoneNumber || phoneNumber || '';

  if (!firstName && !lastName) {
    if (hasEmail) {
      lastName = email || phone;
    } else {
      lastName = phone;
    }
  }

  if (!firstName && lastName) {
    firstName = '';
  }

  return {
    firstName,
    lastName,
  };
};

export const compareMembers = (a, b) => {
  const memberAFullName = getMemberInfo(a).sortString;
  const memberBFullName = getMemberInfo(b).sortString;

  if (memberAFullName < memberBFullName) {
    return -1;
  }
  if (memberBFullName > memberAFullName) {
    return 1;
  }
  return 0;
};
