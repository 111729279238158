import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoaderOverlay from '../LoaderOverlay';
import { closeCustomModal } from '../../Actions/customModalActions';
import { releaseMessageLockedMember } from '../../Actions/messageActions';
import './index.sass';

class CustomModal extends Component {
  handleCustomClose = () => {
    const { lockedByMember, closeHandler } = this.props;
    if (lockedByMember || !closeHandler) {
      this.props.closeCustomModal();
    } else {
      closeHandler();
    }
  }

  render = () => {
    const { backHandler, content, isLoader, isOpen, size, title } = this.props;

    if (!isOpen) return null;

    return (
      <div className="modal-wrapper">
        {isLoader && <LoaderOverlay/>}
        <div className={`modal size-${size} ${!title ? 'no-title' : ''}`} id="scroll">
          <header className="modal-header">
            <button className={`modal-back-button ${!backHandler ? 'invisible' : ''}`} onClick={backHandler ? backHandler : null} />
            <div className="modal-title">{title}</div>
            <button className="modal-close-button" onClick={this.handleCustomClose}/>
          </header>

          <div className="modal-content-wrapper">{content}</div>

        </div>
      </div>
    )
  }
}

const mS = state => ({
  backHandler: state.customModalReducer.backHandler,
  closeHandler: state.customModalReducer.closeHandler,
  content: state.customModalReducer.content,
  isLoader: state.customModalReducer.isLoader,
  isOpen: state.customModalReducer.isOpen,
  size: state.customModalReducer.size,
  title: state.customModalReducer.title,
  lockedByMember: state.messageReducer.lockedByMember,
})

const mD = { closeCustomModal, releaseMessageLockedMember };

export default connect(mS, mD)(CustomModal);
