import AXIOS from '../Config/AxiosConfig';
import headers from './Helper';

const Gallery = {
  getFoldersContent(orgId) {
    return AXIOS.get(`/eva/folder/images?org=${orgId}`, { headers: headers() });
  },

  getFolderImage(publicId, page, sort, term, recent) {
    const limitPage = 10;
    const nextPage = page ? `&page=${page}` : '&page=1';
    const sortImage = sort ? `&sortOrder=${sort}` : '';
    const termImage = term ? `&term=${term}` : '';
    const recentImage = recent ? `&sort=${recent}` : '';
    if (publicId) {
      return AXIOS.get(
        `/v2/eva/folder/${publicId}/image?limit=${limitPage}${nextPage}${sortImage}${termImage}${recentImage}`,
        { headers: headers() },
      );
    }
    return new Promise();
  },

  uploadPicture(data) {
    const { image, name, folderId } = data;
    return AXIOS.post(
      `/eva/folder/${folderId}/image`,
      { images: [{ image, name }] },
      { headers: headers() },
    );
  },

  createFolder(folderName, selectedFolder) {
    const folderId = selectedFolder !== 'newFolder' ? selectedFolder : null;
    return AXIOS.post(
      '/eva/folderImage',
      {
        name: folderName,
        global: false,
        folder_parent: folderId,
      },
      { headers: headers() },
    );
  },
  removeImage(data) {
    return AXIOS.post('/eva/image/delete-several-images', { data },
      { headers: headers() },
    );
  },
  moveImagesToFolder(currentFolder, data, targetFolder) {
    return AXIOS.put(`/eva/folder/${currentFolder}/images/move-to/${targetFolder}`, { data },
      { headers: headers() },
    );
  },
};

export default Gallery;
