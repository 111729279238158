import queryString from 'query-string';
import { ADMIN_FRONT_URL } from '../Config/env';

export const redirectToAdmin = orgId => {
  const params = {
    authToken: localStorage.getItem('sso_token'),
  };

  if (orgId) {
    params.redirectTo = `/organizations/${orgId}`;
  }

  const query = queryString.stringify(params);

  window.location.href = `${ADMIN_FRONT_URL}/login-check?${query}`;
};
