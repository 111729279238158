import React from 'react';

import TreeNavigatorItemPropTypes from '../../PropTypes/TreeNavigatorItemPropTypes';
import TreeNavigatorMultipleItem from './TreeNavigatorMultipleItem';
import TreeNavigatorSingleItem from './TreeNavigatorSingleItem';

const TreeNavigatorItem = props => {
  const { treeItem, selectedTreeItem } = props;
  const itemHasChildren = treeItem.childrens.length > 0;
  const TreeItem = itemHasChildren ? TreeNavigatorMultipleItem : TreeNavigatorSingleItem;
  return (
    <div className="tree-navigator-container">
      <TreeItem
        selectedTreeItem={selectedTreeItem}
        treeItem={treeItem}
        handleTreeItemClick={props.handleTreeItemClick || null}
      />
    </div>
  );
};

TreeNavigatorItem.propTypes = TreeNavigatorItemPropTypes;

export default TreeNavigatorItem;
