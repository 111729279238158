import { ACTIONS } from '../Actions/socialShareActions';
// fromGroup = true (action from a group) and fromGroup = false (action from the compose button)
const initialState = {
  twitter: {
    logged: false,
  },
  twitterProfile: null,
  facebookProfile: null,
  isTwitterLoading: false,
  isFacebookLoading: false,
};
const socialShareReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_TWITTER_ACCESS_TOKEN:
      return {
        ...state,
        twitter: action.payload,
      };

    case ACTIONS.SAVE_TWITTER_AUTHORIZATION_TOKEN:
      return {
        ...state,
        twitter: action.payload,
      };

    case ACTIONS.SET_STATUS_TWITTER_AUTHORIZATION:
      return {
        ...state,
        ...action.payload,
      };

    case ACTIONS.UPDATE_STATUS_TWITTER_AUTHORIZATION:
      return {
        ...state,
        ...action.payload,
      };

    case ACTIONS.SET_TWITTER_ACCOUNT_INFO:
      return {
        ...state,
        twitterProfile: action.payload,
      };

    case ACTIONS.SET_FACEBOOK_ACCOUNT_INFO:
      return {
        ...state,
        facebookProfile: action.payload,
      };

    case ACTIONS.REMOVE_TWITTER_ACCOUNT:
      return {
        ...state,
        twitterProfile: null,
      };

    case ACTIONS.SET_TWITTER_LOADING:
      return {
        ...state,
        isTwitterLoading: action.payload,
      };

    case ACTIONS.SET_FACEBOOK_LOADING:
      return {
        ...state,
        isFacebookLoading: action.payload,
      };

    default:
      return state;
  }
};

export default socialShareReducer;
