import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { InputPhone } from '../../InputPhone';
import InputStandard from '../../InputIcon';
import EvaButton from '../../EvaButton';
import { emailRegex } from '../../../Helpers/regex';
import snackBarStatus from '../../../Actions/snackbarActions';
import './index.sass';

class SearchMember extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      phone: '',
    };
  }

  shouldComponentUpdate = (nextProps, nextState) =>
    this.state.email !== nextState.email ||
    this.state.phone !== nextState.phone;

  handlePhoneChange = (phoneNumber) => {
    this.setState({ phone: phoneNumber });
  };

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };

  handleClickNext = () => {
    const { phone, email } = this.state;
    this.props.nextStep(phone, email);
  };

  render() {
    const { email, phone } = this.state;
    return (
      <div id="search-member-container">
        <div className="user-tips">
          Enter a phone number or email address below.
        </div>

        <div className="underlined-border-bottom form-row">
          <InputPhone
            name="phone"
            placeholder="Phone number"
            value={phone}
            onInputPhoneChange={this.handlePhoneChange}
            useV3
          />
        </div>

        <div className="form-row">
          <InputStandard
            name="email"
            placeholder="Email address"
            value={email}
            type="email"
            icon="email"
            onChange={this.handleEmailChange}
            color="transparent"
            tooltipTitle="Email"
            useId="special"
            appendVoidContainer
          />
        </div>
        <div>
          <EvaButton onClick={this.handleClickNext}>Continue</EvaButton>
        </div>

      </div>
    );
  }
}

// TODO: refactoring
SearchMember.propTypes = {
  nextStep: PropTypes.func.isRequired,
};

const mD = {
  snackBarStatus,
};

// TODO: switch to presentational component.
export default connect(null, mD)(SearchMember);
