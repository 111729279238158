import PropTypes from 'prop-types';

const ThreadMainMessagePropType = {
  saveAsTemplate: PropTypes.func.isRequired,
  messageHtml: PropTypes.string.isRequired,
  messagePublicId: PropTypes.string.isRequired,
  userImage: PropTypes.string,
  date: PropTypes.string.isRequired,
  isUncertainMessage: PropTypes.bool,
  isDirect: PropTypes.bool,
  files: PropTypes.arrayOf(PropTypes.object),
  showButtons: PropTypes.bool,
  onStatisticsToggle: PropTypes.func,
  activeStatistics: PropTypes.bool,
  statisticsThreadMessages: PropTypes.instanceOf(Object), // TODO: specify
  onForward: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired,
  onResend: PropTypes.func.isRequired,
  userName: PropTypes.string,
  onStatisticsModalCall: PropTypes.func.isRequired,
};

export default ThreadMainMessagePropType;
