import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import EvaTooltip from '../../Components/EvaTooltip';
import './../../Containers/MessagePreview/index.sass';
import './index.sass';
import SmsPostview from './SmsPostview';
import MyParishAppPostPostview from './MyParishAppPostPostview';
import EmailPostview from './EmailPostview';
import FacebookPostview from './FaccebookPostview';
import TwitterPostPostview from './TwitterPostPostview';
import WebMessagePostview from './WebMessagePostview';
import { displayMemberName } from '../../Helpers/Members';
import moment from 'moment';

const EMAIL_PREVIEW_TAB = 0;
const SMS_PREVIEW_TAB = 1;
const MY_PARISH_PREVIEW_TAB = 2;
const FACEBOOK_PREVIEW_TAB = 3;
const TWITTER_PREVIEW_TAB = 4;
const WEB_PREVIEW_TAB = 5;

const TabContainer = props => (
  <Typography component="div">{props.children}</Typography>
);

const getDisplayMemberNameFromEmail = username => {
  if (username.length > 0) return username.split('@')[0];
  return '';
};

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  wrapper: {},
  tab: {
    color: theme.palette.background.paper,
  },
  tabsIndicator: {
    background: 'linear-gradient(to right, #9966bf 0%, #6174c9 98%)',
  },
  tabsRoot: {
    textTransform: 'initial',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'GothamSSm-Book',
    color: '#a9a9a9',
    width: '97%',

    '&:focus': {
      color: '#9a66bf',
    },
  },
  tabSelected: { color: '#9a66bf' },
});

class SentMessagePostview extends PureComponent {
  state = {
    value: 0,
    emailTab: 'desktop',
    tabValue: 0,
  };

  componentWillUnmount() {
    this.setState({
      value: 0,
      emailTab: 'desktop',
      tabValue: 0,
    });
  }

  handleChange = (event, value) => {
    this.setState({
      value,
    });
  };
  handleTabChange = (event, tabValue) => {
    this.setState({
      tabValue,
    });
  };
  generateTitleContent = (title = '', content = '') => {
    let finalContent = '';
    if (title !== undefined && title.length > 0 && content.length > 0) {
      finalContent = `<h3 id="title-content-postview">${title}</h3>${content}`;
    }
    return finalContent;
  };

  handleClose = () => {
    const { handleClose } = this.props;
    this.setState({
      value: 0,
      emailTab: 'desktop',
      tabValue: 0,
    });
    handleClose();
  };

  renderLayoutButtons = () => {
    const { classes } = this.props;
    const { value, tabValue } = this.state;
    if (value === 0) {
      return (
        <div className="switch-container icons-postview">
          <div className="responsive-preview-icons">
            <Tabs
              onChange={this.handleTabChange}
              classes={{
                root: classes.tabsRoot,
                indicator: classes.tabsIndicator,
              }}
              value={tabValue}
              style={{
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}
            >
              <Tab
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
                label=""
                icon={
                  <EvaTooltip title="Desktop Preview">
                    <i className="desktop-icon"/>
                  </EvaTooltip>
                }
                onClick={() => this.setState({ emailTab: 'desktop' })}
              />
              <Tab
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
                label=""
                icon={
                  <EvaTooltip title="Mobile Phone Preview">
                    <i className="mobile-icon"/>
                  </EvaTooltip>
                }
                onClick={() => this.setState({ emailTab: 'mobile' })}
              />
            </Tabs>
          </div>
        </div>
      );
    }
    return '';
  };

  render() {
    const { value, emailTab } = this.state;
    const {
      classes, message,
      isAllMemberGroupSelected,
      message: {
        subject, groups, createdAt, sentByMember,
        sendByEmail, sendByFacebook, sendByMpa, sendBySms, sendByTwitter, sendByWeb,
        messageContent: {
          type, emailContent, facebookContent, mpaContent, smsContent, twitterContent, webContent
        },
      },
    } = this.props;

    const memberName = displayMemberName(sentByMember);
    const profileName = `${getDisplayMemberNameFromEmail(memberName.firstName)} ${memberName.lastName ? memberName.lastName : ''}`;
    const profilePic = sentByMember.profilePictureUrl;
    const twitterProfileName = `@${getDisplayMemberNameFromEmail(memberName.firstName) || memberName.lastName || ''}`;
    return (
      <div className={`${classes.root} message-preview-container `}>
        <div className="tabs-buttons-container">
          <div className="preview-buttons-container">
            {this.renderLayoutButtons()}
          </div>

          <div className="tabs-container">
            <Tabs
              classes={{
                root: classes.tabsRoot,
                indicator: classes.tabsIndicator,
              }}
              value={value}
              onChange={this.handleChange}
            >
              <Tab
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
                label=""
                icon={
                  <EvaTooltip title="Email">
                    <i className="email-icon"/>
                  </EvaTooltip>
                }
                disabled={!sendByEmail}
              />
              <Tab
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
                label=""
                icon={
                  <EvaTooltip title="Texting">
                    <i className="sms-icon"/>
                  </EvaTooltip>
                }
                disabled={!sendBySms}
              />
              <Tab
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
                label=""
                icon={
                  <EvaTooltip title="myParish App">
                    <i className="t-icon"/>
                  </EvaTooltip>
                }
                disabled={!sendByMpa}
              />
              <Tab
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
                label=""
                icon={
                  <EvaTooltip title="Facebook">
                    <i className="facebook-icon"/>
                  </EvaTooltip>
                }
                disabled={!sendByFacebook}
              />
              <Tab
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
                label=""
                icon={
                  <EvaTooltip title="X">
                    <i className="x-icon"/>
                  </EvaTooltip>
                }
                disabled={!sendByTwitter}
              />
              <Tab
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
                label=""
                icon={
                  <EvaTooltip title="Web">
                    <i className="web-icon"/>
                  </EvaTooltip>
                }
                disabled={!sendByWeb}
              />
            </Tabs>
          </div>
        </div>
        <div/>
        {value === EMAIL_PREVIEW_TAB && (
          <TabContainer>
            <EmailPostview
              title={subject}
              content={this.generateTitleContent(subject, emailContent.text)}
              viewport={emailTab}
            />
          </TabContainer>
        )}
        {value === SMS_PREVIEW_TAB && (
          <TabContainer>
            <SmsPostview content={smsContent.prefix + smsContent.text}/>
          </TabContainer>
        )}
        {value === MY_PARISH_PREVIEW_TAB && (
          <TabContainer>
            <MyParishAppPostPostview
              toAllMembers={isAllMemberGroupSelected}
              content={mpaContent.text}
              subject={subject}
              profilePic={profilePic}
              profileName={profileName}
            />
          </TabContainer>
        )}
        {value === FACEBOOK_PREVIEW_TAB && (
          <TabContainer>
            <FacebookPostview
              enabled={sendByFacebook}
              content={facebookContent.text}
              profileName={profileName}
              profilePic={profilePic}
              image={facebookContent.image}
            />
          </TabContainer>
        )}
        {value === TWITTER_PREVIEW_TAB && (
          <TabContainer>
            <TwitterPostPostview
              type={type}
              date={moment(createdAt).format('MMM. D') || new Date()}
              enabled={sendByTwitter}
              content={twitterContent.text}
              profileName={profileName}
              twitterProfileName={twitterProfileName}
              profilePic={profilePic}
              image={twitterContent.image}
              message={message}
            />
          </TabContainer>
        )}
        {value === WEB_PREVIEW_TAB && (
          <TabContainer>
            <WebMessagePostview
              content={this.generateTitleContent(subject, webContent.text)}
              type={type}
            />
          </TabContainer>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(connect(null)(SentMessagePostview));
