export const MESSAGE_COMPOSER_TYPE = 'compose';
export const MESSAGE_DESIGNER_TYPE = 'designer';

export const APP_MESSAGE_TYPES = [MESSAGE_COMPOSER_TYPE, MESSAGE_DESIGNER_TYPE];
export const SCHEDULED_MSG_API_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
export const SCHEDULED_MSG_API_REQUEST_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const SCHEDULED_MSG_API_REQUEST_TIME_FORMAT = 'HH:mm:ss';
export const SCHEDULED_MSG_DATE_FORMAT = 'M/D/YYYY - hh:mma';
export const SCHEDULED_MSG_TIMES_SETTINGS = {
  morning: '09:00:00',
  lunch: '12:00:00',
  afternoon: '16:00:00',
  evening: '20:00:00',
};
export const SCHEDULED_MSG_CUSTOM_TIME = 'customTime';
export const MSG_STATUSES = {
  SENT: 'sent',
  SCHEDULED: 'scheduled',
  PENDING: 'pending',
  DRAFT: 'draft',
  UNCERTAIN: 'uncertain',
};

export const LINE_BREAK_REGEX = /(?:\\r\\n|\\r|\\n)/g;
export const LINE_BREAK_TAG = '<br />';

export const SMS_TEXT_LENGTH_LIMIT = 160;
export const SMS_TEXT_LENGTH_WITH_EMOJIS_LIMIT = 70;

export const DRAFT_AUTO_SAVE_DELAY = 1900;

export const SEARCH_IN_GALLERY_DELAY = 500;
export const SEARCH_MEMBERS_DELAY = 500;
export const SEARCH_TEMPLATES_DELAY = 500;
