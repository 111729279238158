import React from 'react';
import Avatar from '../Avatar';
import './index.sass';
import MessageThreadReplyPropType from '../../PropTypes/MessageThreadReplyPropType';
import EvaTooltip from '../EvaTooltip';

const MessageThreadReply = ({ counter, userImage, date, hideReplyButton, onReply }) => (
  <div className="thread-reply">
    {counter > 0 && (
      <div className="left-side">
        <Avatar
          borderless
          image={userImage}
          className="reply-img-threads"
        />
        <button
          className="counter"
          onKeyPress={onReply}
          onClick={onReply}
        >
          {counter} reply
        </button>
        <span className="date-message-threads">{date}</span>
      </div>
    )}
    {!hideReplyButton && (
      <div className="right-side">
        <div className="buttons-message-reply">
          <EvaTooltip title="Reply">
            <button
              className="button-reply"
              onClick={onReply}
            />
          </EvaTooltip>
        </div>
      </div>
    )}
  </div>
);

MessageThreadReply.propTypes = MessageThreadReplyPropType;

MessageThreadReply.defaultProps = {
  userImage: '',
  hideReplyButton: false,
};

export default MessageThreadReply;
