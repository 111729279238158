// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../Theme/Icons/mountain.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../Theme/Icons/link.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../Theme/Icons/upload.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".gallery-container{position:relative;min-height:300px}.gallery-container .loader-overlay{position:absolute;left:0;top:0;right:0;bottom:0;background-color:rgba(255,255,255,0.7)}.gallery-container .hide-drawer{display:none}.gallery-container .container-icon-title{display:-ms-flexbox;display:flex;-ms-flex-pack:start;justify-content:flex-start;margin-left:10px}.gallery-container .container-icon-title .drawer-title{padding-top:12px}.gallery-container .gallery-container__tools{text-align:center;display:-ms-flexbox;display:flex;-ms-flex-pack:end;justify-content:flex-end}.gallery-container .gallery-container__tools .icon-selected{background-color:#9a66bf !important}.gallery-container .gallery-container__tools .design-title{float:right;padding-top:7px !important;background-color:#fff !important}.gallery-container .gallery-container__tools .mountain-icon{background-color:#a9a9a9;mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;-webkit-mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;padding:13px 13px;width:10px;margin-right:20px;margin-left:40px}.gallery-container .gallery-container__tools .link-icon{background-color:#a9a9a9;mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;-webkit-mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;padding:13px 13px;width:10px;margin-right:20px}.gallery-container .gallery-container__tools .upload-icon{background-color:#a9a9a9;mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat;-webkit-mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat;padding:13px 13px;width:10px;margin-right:20px}.gallery-container .right-drawer-content{width:100%}@media (max-width: 767px){.gallery-container .gallery-container__tools .mountain-icon{margin-left:35px}}\n", ""]);
// Exports
module.exports = exports;
