import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LoginPropTypes from '../../PropTypes/LoginPropType';
import { ACTIONS, logout, refreshDataFromSso, } from '../../Actions/loginActions';
import { navigationControllerRedirect } from '../../Actions/navigationControllerActions';

import LoadingScreen from '../LoadingScreen';
import { isValuesFromArrayInArray } from '../../Helpers/isValuesFromArrayInArray';
import { ROLES_ADMIN } from '../../Config/Constants';
import { DIOCESAN_EVA_URL } from '../../Config/env';
import { ROOT_ROUTE_ADMIN, ROOT_ROUTE_MEMBER } from '../../Config/Routes';

import queryString from 'query-string';

export class LoginCheckSso extends Component {
  async componentDidMount() {
    const paramsString = this.props.location.search;
    const params = queryString.parse(paramsString);
    const { authToken, source, redirect } = params;
    const response = await this.props.refreshDataFromSso(authToken);

    if (response.status === 200) {
      const roles = response.data.data.roles;
      const isAdmin = !!roles && roles.length > 0 && isValuesFromArrayInArray(roles, ROLES_ADMIN);

      let url = isAdmin ? ROOT_ROUTE_ADMIN : ROOT_ROUTE_MEMBER;

      if (source) {
        url = source;
      } else if (redirect && redirect.length > 3) {
        url = redirect;
      }

      this.props.navigationControllerRedirect(url);

    } else {
      // Error
      let shouldLogout = true;

      switch (response.status) {
        case 401:
          dispatch({
            type: ACTIONS.ERROR,
            payload: {
              error: 'Invalid Token',
              message: error.message,
            },
          });
          break;

        case 403:
          shouldLogout = false;
          window.location.href = DIOCESAN_EVA_URL;
          break;

        default:
          // dispatch({ type: ACTIONS.ERROR, payload: { error: error.message } });
      }

      // Prevent redirection to /login when a 403 error has been received.
      if (shouldLogout) {
        this.props.logout();
      }
    }
  }

  render() {
    return (
      <div className="container-center login">
        <LoadingScreen/>
      </div>
    );
  }
}

const mS = null;
const mD = {
  refreshDataFromSso,
  navigationControllerRedirect,
  logout,
};
LoginCheckSso.propTypes = LoginPropTypes;

export default withRouter(
  connect(
    mS,
    mD,
  )(LoginCheckSso),
);
