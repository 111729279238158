import {
  ROLE_SUPER_ADMIN,
  ROLE_EVA_GROUP_ADMIN,
  ROLE_ORGANIZATION_ADMIN,
} from './UserRoles';

export const ALL_ORGS_IDENTIFIER = 'All Organizations';
export const ALL_GROUPS_IDENTIFIER = 'All Groups';
export const NO_GROUPS_IDENTIFIER = 'No Groups';

export const EDITABLE_FOLDERS_SETTINGS = {
  [ROLE_EVA_GROUP_ADMIN]: [
    'My Templates',
    'Favorites',
  ],
  [ROLE_ORGANIZATION_ADMIN]: [
    'My Templates',
    'Favorites',
  ],
  defaultFolders: [
    'My Templates',
    'Favorites',
    'Featured',
  ],
};

export const EDITABLE_FOLDERS = {
  [ROLE_SUPER_ADMIN]: [
    'My Templates',
    'Favorites',
  ],
  defaultFolders: [
    'My Templates',
    'Favorites',
  ],
};
