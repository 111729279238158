import { ACTIONS } from '../Actions/templateActions';
import { TEMPLATE_PAGE_SIZE_GRID } from '../Config/Constants';

const initialState = {
  saveAndViewFolder: null,
  folders: [],
  templateFolderSelected: [],
  template: {},
  templateSelected: [],
  tree: [],
  folder: {},
  templateSettings: {},
  folderSelected: {},
  displayOptions: false,
  statusDialog: false,
  statusDialogTemplate: false,
  shareAllOrg: false,
  shareAllGroup: false,
  shareWithAllGroups: false,
  templateListSelected: [],
  featuredFolder: [],
  showButton: false,
  templatesLoaded: false,
  templatesLoading: false,
  templateAllList: [],
  editTemplateFlag: false,
  submitTemplate: false,
  folderMoveList: [],
  templateShown: false,
  page: null,
  pageSize: TEMPLATE_PAGE_SIZE_GRID,
  numberOfItems: null,
  nextPage: null,
  previousPage: null,
  totalPages: null,
  templateFolderUpdated: null,
  checkedTemplates: [],
};

const templateReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_FOLDERS:
      return {
        ...state,
        folders: [...action.payload],
      };
    case ACTIONS.GET_ALL_FOLDER_TEMPLATE_LIST:
      return {
        ...state,
        templateAllList: [...action.payload],
      };
    case ACTIONS.GET_FOLDER_TEMPLATE_ID_PAGED:
      return {
        ...state,
        templateFolderSelected: action.payload.templates,
        numberOfItems: action.payload.numberOfItems,
        nextPage: action.payload.nextPage,
        previousPage: action.payload.previousPage,
        page: action.payload.page,
        totalPages: action.payload.totalPages,
        pageSize: action.payload.pageSize,
      };
    case ACTIONS.CLEAR_SELECTED_TEMPLATE:
      return {
        ...state,
        template: {},
      };
    case ACTIONS.GET_FOLDER_TEMPLATE_ID:
      return {
        ...state,
        templateFolderSelected: action.payload,
      };
    case ACTIONS.GET_FOLDER_TEMPLATE:
      return {
        ...state,
        folderSelected: action.payload,
      };
    case ACTIONS.GET_TEMPLATE_SETTINGS:
      return {
        ...state,
        templateSettings: action.payload,
      };
    case ACTIONS.SELECT_TEMPLATE:
      return {
        ...state,
        template: action.payload,
      };
    case ACTIONS.SAVE_AND_VIEW_FOLDER:
      return {
        ...state,
        saveAndViewFolder: action.payload,
      };
    case ACTIONS.SET_TREE:
      return {
        ...state,
        tree: action.payload,
      };
    case ACTIONS.SELECT_FOLDER:
      return {
        ...state,
        folder: action.payload,
      };
    case ACTIONS.CHANGE_STATE:
      return {
        ...state,
        templateFolderSelected: state.templateFolderSelected.map(tpl =>
          tpl.publicId === action.payload ? {
            ...tpl,
            statusCheck: !tpl.statusCheck,
          } : tpl)
      };
    case ACTIONS.UPDATE_DIALOG:
      return {
        ...state,
        statusDialog: action.payload,
      };
    case ACTIONS.UPDATE_DIALOG_TEMPLATE:
      return {
        ...state,
        statusDialogTemplate: action.payload,
      };
    case ACTIONS.UPDATE_SHARE_ALL_ORG:
      return {
        ...state,
        shareAllOrg: action.payload,
      };
    case ACTIONS.UPDATE_SHARE_ALL_GROUP:
      return {
        ...state,
        shareAllGroup: action.payload,
      };
    case ACTIONS.UPDATE_SHARE_WITH_ALL_GROUPS:
      return {
        ...state,
        shareWithAllGroups: action.payload,
      };
    case ACTIONS.GET_TEMPLATE_LIST:
      return {
        ...state,
        templateListSelected: action.payload,
      };
    case ACTIONS.CLEAR_TEMPLATE_SELECTED_PROPS:
      return {
        ...state,
        templateListSelected: [],
      };
    case ACTIONS.SAVE_FEATURED_DATA:
      return {
        ...state,
        featuredFolder: action.payload,
      };
    case ACTIONS.SHOW_BUTTON_ANIMATION:
      return {
        ...state,
        showButton: action.payload,
      };
    case ACTIONS.TEMPLATES_LOADED:
      return {
        ...state,
        templatesLoaded: action.payload,
      };
    case ACTIONS.TEMPLATES_LOADING:
      return {
        ...state,
        templatesLoading: action.payload,
      };
    case ACTIONS.SELECTED_TEMPLATE:
      return {
        ...state,
        templateSelected: action.payload,
      };
    case ACTIONS.EDIT_TEMPLATE_FLAG:
      return {
        ...state,
        editTemplateFlag: action.payload,
      };
    case ACTIONS.SET_FOLDER_MOVE_LIST:
      return {
        ...state,
        folderMoveList: action.payload,
      };
    case ACTIONS.TEMPLATE_SHOWN_STATUS: {
      return {
        ...state,
        templateShown: action.payload,
      };
    }
    case ACTIONS.UPDATE_FOLDER_NAME: {
      return {
        ...state,
        templateFolderUpdated: action.payload,
      };
    }
    case ACTIONS.DELETE_TEMPLATES: {
      return {
        ...state,
        templateFolderSelected: state.templateFolderSelected
          .filter(tpl => action.payload.indexOf(tpl.publicId) === -1),
      };
    }

    case ACTIONS.CLEAR_TEMPLATES: {
      return {
        ...state,
        templateFolderSelected: [],
        templatesLoaded: false,
      };
    }

    default:
      return state;
  }
};

export default templateReducer;
