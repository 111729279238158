import PropTypes from 'prop-types';

const TemplateSettingsFormPropType = {
  classes: PropTypes.object.isRequired,
  handleChangeError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  foldersList: PropTypes.array,
  handleCharactersCount: PropTypes.func.isRequired,
  subFoldersList: PropTypes.array,
  loadingGroups: PropTypes.bool.isRequired,
  addShareEnabled: PropTypes.bool.isRequired,
  checkCharacters: PropTypes.func.isRequired,
};

export default TemplateSettingsFormPropType;
