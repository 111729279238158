import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import ErrorMessage from '../ErrorMessage';
import SuccessMessage from '../SuccessMessage';
import WarningMessage from '../WarningMessage';
import ButtonStandard from '../Button';
import {
  addMembers,
  clearMembersList,
  clearSelectedMembers,
  getMembersToInvite,
  getMoreMembers,
  searchInMembersListWithDebounce,
  selectMember,
} from '../../Actions/membersActions';
import { changeMembersSearchTerm, resetMembersFilters } from '../../Actions/membersFiltersActions';
import { statusLoading } from '../../Actions/loadingActions';
import { submitMembersActions } from '../../Actions/groupInviteMemberActions';
import snackBarStatus from '../../Actions/snackbarActions';
import { changeTab } from '../../Actions/middleMenuActions';
import { getOrganizations } from '../../Actions/organizationActions';
import {
  getGroupsActivity,
  increaseGroupsMembersCounters,
  searchGroups,
  selectGroup
} from '../../Actions/groupsActions';
import MembersPropTypes from '../../PropTypes/MembersPropType';
import MemberList from '../MemberList';
import MembersPicker from '../MembersPicker';
import { isExualSimpleArray } from '../../Helpers/isEqualSimpleArray';
import PermissionsConfirmation from '../PermissionsConfirmation';
import './index.sass';

/* eslint-disable */
class MemberAdd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showError: false,
      showMessage: false,
      showWarningMessage: false,
      message: '',
      checked: false,
    };
  }


  componentDidMount() {
    this.props.clearSelectedMembers();

    this.fetchFilteredByGroups()
  }

  componentWillUnmount = () => {
    this.props.clearMembersList();
    this.props.resetMembersFilters();
  }

  fetchFilteredByGroups = () => {
    const { inviteDestinationGroups } = this.props;

    if (inviteDestinationGroups.length > 0) {
      this.props.getMembersToInvite(inviteDestinationGroups);
    }
  }

  componentDidUpdate(prevProps) {
    if (!isExualSimpleArray(prevProps.inviteDestinationGroups, this.props.inviteDestinationGroups)) {
      this.fetchFilteredByGroups();
    }
  }

  handleSearchTermChange = value =>
    this.props.changeMembersSearchTerm(value).then(() => {
      this.props.searchInMembersListWithDebounce();
    });

  addMembers = () => {
    const {
      selectedMembersHash,
      selectedOrg,
      userMainRole,
      inviteDestinationGroups,
    } = this.props;

    if (this.state.checked) {
      const selectedMembers = Object.keys(selectedMembersHash);
      if (selectedMembers.length) {
        this.props.statusLoading(true);
        this.props.submitMembersActions(inviteDestinationGroups, selectedMembers)
          .then(res => {
            if (res.status === 200) {
              this.props.increaseGroupsMembersCounters(inviteDestinationGroups, selectedMembers.length);
              this.props.changeTab(<MemberList />, 1);
              this.props.getGroupsActivity(selectedOrg.public_id, userMainRole);
            }
          })
          .finally(() => this.props.statusLoading(false));
      } else {
        this.setState({
          showWarningMessage: true,
          showError: false,
          showMessage: false,
          message: 'Needed Choose Members',
        });
      }
    } else {
      this.setState({
        showWarningMessage: true,
        showError: false,
        showMessage: false,
        message: 'You need to accept terms and conditions',
      });
    }
  };

  renderMessage = () => {
    const { inviteDestinationGroups } = this.props;
    return (
      <div>
        {this.state.showError && (
          <ErrorMessage
            error={'Some members already exist in the group'}
            hideMessage={this.closeErrorMessage}
          />
        )}
        {this.state.showMessage && (
          <SuccessMessage
            success={'Successfull Registration'}
            hideMessage={this.closeErrorMessage}
          />
        )}
        {this.state.showWarningMessage && (
          <WarningMessage
            warning={this.state.message}
            hideMessage={this.closeErrorMessage}
          />
        )}
        {!inviteDestinationGroups.length && (
          <WarningMessage
            warning={'No groups have been selected yet.'}
            hideMessage={this.closeErrorMessage}
          />
        )}
      </div>
    );
  };

  closeErrorMessage = () => {
    this.setState({
      showError: false,
      showMessage: false,
      showWarningMessage: false,
      message: '',
    });
  };

  handleLoadMore = () => {
    const { loading, loadingMore } = this.props;
    if (!loading && !loadingMore) {
      this.props.getMoreMembers();
    }
  }

  render() {
    const {
      inviteDestinationGroups,
      loading,
      loadingMore,
      members,
      searchTerm,
      selectMember,
      selectedMembersHash,
    } = this.props;

    const inModalAndNoGroups = !inviteDestinationGroups.length;
    const selectedMembersCount = Object.keys(selectedMembersHash).length;

    return (
      <Grid container id="members" className="show-members">
        <Grid item sm={12} md={12}>
          {this.renderMessage()}
        </Grid>

        <div
          className="members-panel"
          style={inModalAndNoGroups ? { display: 'none' } : {}}
        >
          <div>
            <div className="members-list">
              <MembersPicker
                isMemberAddMode
                searchTerm={searchTerm}
                members={members}
                selectedMembersHash={selectedMembersHash}
                isLoading={loading}
                isLoadingMore={loadingMore}
                onSelectMember={selectMember}
                onSearchInputChange={this.handleSearchTermChange}
                onScrollFinished={this.handleLoadMore}
              />

              <div className="check-member-container">
                <div>
                  <h2 className="name-group">
                    {selectedMembersCount} Members to be added
                  </h2>
                </div>

                <PermissionsConfirmation isChecked={this.state.checked}
                                         onChange={bool => this.setState({ checked: bool })} />

                <Grid item sm={12} md={12} />
                <div className="button-create-member">
                  <ButtonStandard
                    title={`Add Member${(selectedMembersCount > 1) ? 's' : ''}`}
                    disabled={!this.state.checked || selectedMembersCount === 0}
                    onClick={this.addMembers}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    );
  }
}

/* eslint-enable */
const mS = state => ({
  members: state.membersReducer.membersList,
  selectedMembersHash: state.membersReducer.selectedMembersHash,
  loading: state.membersReducer.loading,
  loadingMore: state.membersReducer.loadingMore,

  searchTerm: state.membersFiltersReducer.searchTerm,

  selectedGroup: state.groupsReducer.selectedGroup,
  selectedOrg: state.organizationReducer.organization,

  userMainRole: state.userProfileReducer.main_role,

  inviteDestinationGroups: state.groupInviteMemberReducer.groups,
});

const mD = {
  getMembersToInvite,
  getMoreMembers,
  searchInMembersListWithDebounce,
  changeMembersSearchTerm,
  clearMembersList,
  addMembers,
  submitMembersActions,
  clearSelectedMembers,
  getOrganizations,
  changeTab,
  resetMembersFilters,
  selectGroup,
  statusLoading,
  snackBarStatus,
  getGroupsActivity,
  searchGroups,
  selectMember,
  increaseGroupsMembersCounters,
};

MemberAdd.propTypes = MembersPropTypes;
export default connect(
  mS,
  mD,
)(MemberAdd);
