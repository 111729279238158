import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FiltersPosedWrapper } from '../FilterPosedWrapper';
import './index.sass';

class MembersFilters extends Component {

  isStatusFilterEnabled = status => this.props.filters.status === status;

  activeClassGenerator = status => this.isStatusFilterEnabled(status) ? 'active' : '';

  render = () => (
    <FiltersPosedWrapper className="filters-pad group-filters-pad" pose={this.props.isOpen ? 'open' : 'closed'}>
      <div className="filters-inner-wrapper">
        <section className="filters-group">
          <div className="filters-group-title">Member Status</div>
          <button className="btn-all" onClick={() => {
            this.props.onFiltersChange({ status: null })
          }}>
            All
          </button>

          <div className="filters-wrapper">
            <button
              className={`filter-button ${this.activeClassGenerator('active')}`}
              onClick={() => this.props.onFiltersChange({ status: 'active' })}
            >
              Active
              <i className="icon-filter-hidden"/>
            </button>
            <button
              className={`filter-button ${this.activeClassGenerator('opt-out')}`}
              onClick={() => this.props.onFiltersChange({ status: 'opt-out'})}
            >
              Opted Out
            </button>
          </div>
        </section>
      </div>
    </FiltersPosedWrapper>
  )
}

MembersFilters.propTypes = {
  filters: PropTypes.instanceOf(Object).isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default MembersFilters;
