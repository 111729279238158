// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../Theme/Icons/borderless-phone.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../Theme/Icons/phone.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".phone-icon-img{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:center center;background-size:contain;width:40px;height:40px;padding:10px}.phone-icon{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-color:transparent;background-repeat:no-repeat;background-position:-10% 50%;width:100%;height:45px;margin-left:7px}.react-phone-number-input{width:90%}.react-phone-number-input .react-phone-number-input__row input{background-color:transparent;border-style:none}.react-phone-number-input .react-phone-number-input__row .react-phone-number-input__country--native{margin:1px}.react-phone-number-input .react-phone-number-input__row .react-phone-number-input__country--native .react-phone-number-input__icon{line-height:0px;margin-top:13px}.react-phone-number-input .react-phone-number-input__row .react-phone-number-input__country--native .react-phone-number-input__country-select-arrow{margin-top:13px}.react-phone-number-input .react-phone-number-input__row .react-phone-number-input__phone{max-width:100%;padding-left:145px;margin:5px 0px 0px -54px;font-family:\"GothamSSm-Medium\",Arial,sans-serif;font-size:16px;color:#4a4a4a;width:100%;height:40px;outline:0;border-width:0 0 2px;border-color:#e3e3e3;text-align:center;margin-top:0.65vw}.react-phone-number-input .react-phone-number-input__row input[name=\"phoneNumber\"]::-webkit-input-placeholder{padding-left:10px;color:#aaaaaa !important}\n", ""]);
// Exports
module.exports = exports;
