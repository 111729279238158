export const ACTIONS = {
  ENABLE: 'rightDrawer/enable',
  SET_ENFORCE_FOCUS: 'rightDrawer/set_enforce_focus',
};

export const enableRightDrawer = value => dispatch => {
  dispatch({ type: ACTIONS.ENABLE, ...value });
  if (window.innerWidth >= 1024) {
    document.querySelector(
      '.right-drawer.drawer-standard > .drawer-md-up',
    ).style.display = 'block';
  }
};

export const setEnforceFocus = enforceFocus => ({
  type: ACTIONS.SET_ENFORCE_FOCUS,
  payload: { enforceFocus },
});
