import PropTypes from 'prop-types';

const InputPropType = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  icon: PropTypes.string,
  focused: PropTypes.string,
};

export default InputPropType;
