import React from 'react';
import PropTypes from 'prop-types';
import ButtonDrop from '../ButtonDrop';
import SelectListDataPropType from '../../PropTypes/SelectListDataPropType';

class ButtonSelect extends React.Component {
  state = {
    selected: {
      text: '',
      value: '',
    },
  };

  componentDidMount() {
    const selected = this.props.values.find(v => v.value === this.props.defaultValue);
    if (selected) {
      this.setState({ selected });
    }
  }

  handleChange(item) {
    this.setState({
      selected: item,
    });
    this.props.onChange(item.value);
  }

  render() {
    const { values, className, listAlign } = this.props;
    return (
      <ButtonDrop
        className={`button-select ${className}`}
        buttonStyle="plain"
        listAlign={listAlign}
        title={this.state.selected ? this.state.selected.text : ''}
      >
        <ul>
          {
            values.map(d =>
              <li
                key={d.value}
                onKeyPress={() => this.handleChange(d)}
                role="menuitem"
                tabIndex="0"
                onClick={() => this.handleChange(d)}
              >
                {d.text}
              </li>,
            )
          }
        </ul>
      </ButtonDrop>
    );
  }
}

//
ButtonSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  values: PropTypes.arrayOf(SelectListDataPropType).isRequired,
  listAlign: PropTypes.oneOf(['left', 'right']),
};
//
ButtonSelect.defaultProps = {
  className: '',
  listAlign: 'left',
};

export default ButtonSelect;
