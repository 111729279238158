import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getOrganizationsLookup,
  clearOrganizationsSuggestions,
  searchOrganizationUpdateValue,
  searchOrganizationUpdateValueId,
  setOrganizationsSuggestions,
} from '../../Actions/organizationActions';
import {
  ROLE_EVA_GROUP_ADMIN,
  ROLE_ADMIN,
  ROLE_ORGANIZATION_ADMIN,
  ROLE_SUPER_ADMIN,
} from '../../Config/Constants';
import { Select } from '@mobiscroll/react';
import { mbscResponsiveOptions } from '../../Config/Constants/mobiscroll-props';

const ALL_ORGS_IDENTIFIER = 'all_orgs';
const NEXT_TIC = 0;

class SearchOrganization extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      displaySelect: true,
    };
  }

  componentDidMount() {
    this.props.clearOrganizationsSuggestions();
    // load all visible orgs

    // if the user is a ROLE_GROUP_ADMIN use the currently selected org
    const { roles, currentOrg } = this.props;
    if (roles.includes(ROLE_EVA_GROUP_ADMIN)
      && !roles.includes(ROLE_ADMIN)
      && !roles.includes(ROLE_ORGANIZATION_ADMIN)
      && !roles.includes(ROLE_SUPER_ADMIN)) {
      if (currentOrg && currentOrg.name && currentOrg.public_id) {
        this.props.setOrganizationsSuggestions([
          { name: currentOrg.name, publicId: currentOrg.public_id },
        ]);
      }
    } else { // if not a ROLE_GROUP_ADMIN then search the orgs
      this.props.getOrganizationsLookup()
        .then(org => {
          // in this form the mobiscroll select is not displaying
          // the data that comes from the reducer in the first update
          // which is why I remount it to make the data appear
          if (org) {
            this.setState({ displaySelect: false });
            setTimeout(() => {
              this.setState({ displaySelect: true });
            }, NEXT_TIC);
          }
        });
    }
  }

  handleSuggestionSelect = ({ value }) => {
    const { organizationsSuggestions } = this.props;

    const selectedOrg = value === ALL_ORGS_IDENTIFIER ?
      {
        publicId: null,
        name: 'All Organizations',
      } : organizationsSuggestions.find(suggestion => suggestion.publicId === value);

    if (selectedOrg) {
      this.props.searchOrganizationUpdateValue(selectedOrg.name);
      this.props.searchOrganizationUpdateValueId(selectedOrg.publicId);
      if (selectedOrg.publicId === ALL_ORGS_IDENTIFIER) {
        // this is due to TemplateSettings logic
        this.props.input.onChange(null);
      } else {
        this.props.input.onChange(selectedOrg.publicId);
      }
    }
  };

  render() {
    const {
      selectedOrganization,
      organizationsSuggestions,
    } = this.props;

    const { displaySelect } = this.state;

    const orgSuggestions = [{
      value: ALL_ORGS_IDENTIFIER,
      text: 'All Organizations',
    }].concat(
      organizationsSuggestions
        .map(suggestion => ({
            value: suggestion.publicId,
            text: suggestion.name,
          }),
        ),
    );

    if (displaySelect) {
      return (
        <Select
          data={orgSuggestions}
          value={selectedOrganization}
          onChange={this.handleSuggestionSelect}
          responsive={mbscResponsiveOptions}
          placeholder="Select an Org"
          filterPlaceholderText="Search"
          buttons={['cancel']}
        />
      );
    }
    return null;
  }
}

SearchOrganization.propTypes = {
  organizationsSuggestions: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedOrganization: PropTypes.string,
  getOrganizationsLookup: PropTypes.func.isRequired,
  clearOrganizationsSuggestions: PropTypes.func.isRequired,
  searchOrganizationUpdateValue: PropTypes.func.isRequired,
  searchOrganizationUpdateValueId: PropTypes.func.isRequired,
  setOrganizationsSuggestions: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
  }),
  currentOrg: PropTypes.shape({
    name: PropTypes.string,
    public_id: PropTypes.string,
  }).isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

SearchOrganization.defaultProps = {
  selectedOrganization: null,
  onChange: undefined,
  input: undefined,
};

const mapStateToProps = state => ({
  organizationsSuggestions:
  state.organizationReducer.organizationsSuggestions,
  currentOrg: state.organizationReducer.organization,
  roles: state.loginReducer.roles,
});

const mapDispatchToProps = {
  getOrganizationsLookup,
  clearOrganizationsSuggestions,
  searchOrganizationUpdateValue,
  searchOrganizationUpdateValueId,
  setOrganizationsSuggestions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchOrganization);
