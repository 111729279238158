import PropTypes from 'prop-types';

const MessageThreadReplyPropType = {
  counter: PropTypes.number.isRequired,
  userImage: PropTypes.string,
  date: PropTypes.string.isRequired,
  hideReplyButton: PropTypes.bool,
};

export default MessageThreadReplyPropType;
