export const bannedEmojisCodes = [
  '1F472',
  '1F473',
  '1F473-200D-2642-FE0F',
  '1F473-200D-2640-FE0F',
  '1F482',
  '1F482-200D-2642-FE0F',
  '1F482-200D-2640-FE0F',
  '1F479',
  '1F47A',
  '1F648',
  '1F649',
  '1F64A',
  '1F4A9',
  '1F46C',
  '1F46D',
  '1F46F',
  '1F46F-200D-2642-FE0F',
  '1F46F-200D-2640-FE0F',
  '1F459',
  '1F9E1',
  '1F49B',
  '1F499',
  '1F49C',
  '1F49A',
  '1F5A4',
  '1F48C',
  '1F48B',
  '1F469-200D-2764-FE0F-200D-1F48B-200D-1F468',
  '1F468-200D-2764-FE0F-200D-1F48B-200D-1F468',
  '1F469-200D-2764-FE0F-200D-1F48B-200D-1F469',
  '1F468-200D-2764-FE0F-200D-1F468',
  '1F469-200D-2764-FE0F-200D-1F469',
  '1F468-200D-1F468-200D-1F466',
  '1F468-200D-1F468-200D-1F467',
  '1F468-200D-1F468-200D-1F467-200D-1F466',
  '1F468-200D-1F468-200D-1F466-200D-1F466',
  '1F468-200D-1F468-200D-1F467-200D-1F467',
  '1F469-200D-1F469-200D-1F466',
  '1F469-200D-1F469-200D-1F467',
  '1F469-200D-1F469-200D-1F467-200D-1F466',
  '1F469-200D-1F469-200D-1F466-200D-1F466',
  '1F469-200D-1F469-200D-1F467-200D-1F467',
  '1F49D',
  '1F38B',
  '1F38C',
  '1F3F3-FE0F-200D-1F308',
  '1F52E',
  '1F6C1',
  '1F6C0',
  '1F6BF',
  '1F6BD',
  '1F4A3',
  '1F52B',
  '1F52A',
  '1F48A',
  '1F489',
  '1F376',
  '1F3E9',
  '1F5FC',
  '1F5FE',
  '1F5FB',
  '1F201',
  '1F22F',
  '1F233',
  '1F235',
  '1F234',
  '1F232',
  '1F250',
  '1F239',
  '1F23A',
  '1F236',
  '1F21A',
  '1F237-FE0F',
  '1F238',
  '1F202-FE0F',
  '1F251',
  '3299-FE0F',
  '3297-FE0F',
  '1F191',
  '2648',
  '2649',
  '264A',
  '264B',
  '264C',
  '264D',
  '264E',
  '264F',
  '2650',
  '2651',
  '2652',
  '2653',
  '26CE',
  '1F518',
  '303D-FE0F',
  '1F531',
  '1F911',
  '1F922',
  '1F92E',
  '1F92C',
  '1F595',
  '1F485',
  '1F9D5',
  '1F9D6',
  '1F9D6-200D-2640-FE0F',
  '1F9D6-200D-2642-FE0F',
  '1F9D8',
  '1F9D8-200D-2640-FE0F',
  '1F9D8-200D-2642-FE0F',
  '1F9DF',
  '1F9DF-200D-2642-FE0F',
  '1F9DF-200D-2640-FE0F',
  '1F54C',
  '26E9-FE0F',
  '1F54B',
  '1F38B',
  '1F38D',
  '1F38E',
  '1F38F',
  '1F390',
  '1F391',
  '1F9E7',
  '1F52B',
  '1F5E1-FE0F',
  '1F549-FE0F',
  '2638-FE0F',
  '262F-FE0F',
  '262A-FE0F',
];

