import { AllHtmlEntities } from 'html-entities';
import htmlToText from 'html-to-text';

const removeTextFromLinks = (html) => {
  const temporalElementHTML = document.createElement('div');
  temporalElementHTML.innerHTML = html;
  const links = Array.from(temporalElementHTML.getElementsByTagName('a'));
  links.forEach(link => {
    const a = link;
    a.innerText = '';
  });
  return temporalElementHTML.innerHTML;
}

// const sanitize = (message) => {
//   /* eslint-disable */
//   message = !message ? message : message.replace(/\n/g, ' ');
//
//   // unscape HTML entitiees to plain text
//   message = unescape(message);
//   /* eslint-enable */
//
//   return message;
// }

export const getTextFromHtml = (srcHtml, ignoreHref = false) => {
  if (srcHtml) {
    const htmlWithoutLinks = removeTextFromLinks(srcHtml);
    const htmlSpec = htmlWithoutLinks
      .replace(/<div>/g, ' ')
      .trim()
      .replace(/<div><br><\/div>/g, '<br />')
      .replace(/<\/div>/g, '</div><br />');

    let html = htmlToText.fromString(htmlSpec, {
      ignoreImage: true,
      ignoreHref,
      preserveNewlines: true,
      hideLinkHrefIfSameAsText: true,
      noLinkBrackets: true,
      format: {
        // eslint-disable-next-line
        heading: function (element, fn, options) {
          const val = fn(element.children, options);
          return `${val}\n`;
        },
        // // eslint-disable-next-line
        // text: function (element, fn, options) {
        //   // eslint-disable-next-line
        //   const cleanVal = element.data.replace(/\&nbsp\;/g, ' ');
        //   return cleanVal;
        // },
      },
    });
    // html = !preserveNewlines ? sanitize(html) : html;
    return AllHtmlEntities.decode(html);
  }

  return '';
}
