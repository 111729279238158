import PropTypes from 'prop-types';

const ScheduledMessagePropTypes = {
  cancel: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  edit: PropTypes.bool,
  message: PropTypes.object.isRequired,
  onCancelClick: PropTypes.func,
  onEditClick: PropTypes.func,
};

export default ScheduledMessagePropTypes;
