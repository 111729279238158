import htmlToText from 'html-to-text';
import axios from 'axios';
import snackBarStatus from '../Actions/snackbarActions';

const transforToHtml = html =>
  htmlToText.fromString(html, {
    ignoreImage: true,
    preserveNewlines: true,
  });
/* eslint-disable */
export const isUrlImage = url =>
  axios
    .get(url)
    .then(res => {
      const response = res.headers['content-type'];
      let image = response.substring(0, 5);
      if (image === 'image') {
        return true;
      }
      return false;
    })
    .catch(err => {
      snackBarStatus({
        payload: {
          title: err.message,
          type: 'error',
          enable: true,
        },
      });
    });

/* eslint-enable */
export default transforToHtml;
