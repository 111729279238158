import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import loginReducer from './loginReducer';
import passwordReducer from './passwordReducer';
import rightDrawerReducer from './rightDrawerReducer';
import rightDrawerShortReducer from './rightDrawerShortReducer';
import memberReducer from './memberReducer';
import membersReducer from './membersReducer';
import groupsReducer from './groupsReducer';
import groupsDictionaryReducer from './groupsDictionaryReducer';
import groupInviteMemberReducer from './groupInviteMemberReducer';
import completeProfileReducer from './completeProfileReducer';
import middleMenuReducer from './middleMenuReducer';
import privacyPolicyReducer from './privacyPolicyReducer';
import userProfileReducer from './userProfileReducer';
import rightDrawerMessageReducer from './rightDrawerMessageReducer';
import rightDrawerGroupMessagesReducer from './rightDrawerGroupMessagesReducer';
import rightDrawerTemplatesReducer from './rightDrawerTemplatesReducer';
import messagesReducer from './messagesReducer';
import messageReducer from './messageReducer';
import notificationsReducer from './notificationsReducer';
import snackBarReducer from './snackBarReducer';
import mainTabsReducer from './mainTabsReducer';
import socialShareReducer from './socialShareReducer';
import loaderReducer from './loaderReducer';
import imageGalleryReducer from './imageGalleryReducer';
import templateReducer from './templateReducer';
import organizationReducer from './organizationReducer';
import navigationControllerReducer from './navigationControllerReducer';
import invitationReducer from './invitationReducer';
import modalReducer from './modalReducer';
import composeReducer from './composeReducer';
import customModalReducer from './customModalReducer';
import loadingReducer from './loadingReducer';
import mainLoadingReducer from './mainLoadingReducer';
import responsiveReducer from './responsiveReducer';
import rightDrawerManageTemplateReducer from './rightDrawerManageTemplateReducer';
import avatarReducer from './AvatarReducer';
import messagesFiltersReducer from './messagesFiltersReducer';
import membersFiltersReducer from './membersFiltersReducer';
import groupsFiltersReducer from './groupsFiltersReducer';
import threadReducer from './threadReducer';
import statesDictionaryReducer from './statesDictionaryReducer';
import localeReducer from './localeReducer';
import countriesDictionaryReducer from './countriesDictionaryReducer';

// add reducers in alphabetical order
const rootReducer = combineReducers({
  avatarReducer,
  completeProfileReducer,
  composeReducer,
  countriesDictionaryReducer,
  customModalReducer,
  form,
  groupsReducer,
  groupsDictionaryReducer,
  groupInviteMemberReducer,
  groupsFiltersReducer,
  imageGalleryReducer,
  invitationReducer,
  loaderReducer,
  loadingReducer,
  localeReducer,
  loginReducer,
  mainLoadingReducer,
  mainTabsReducer,
  memberReducer,
  membersReducer,
  membersFiltersReducer,
  messageReducer,
  messagesFiltersReducer,
  messagesReducer,
  middleMenuReducer,
  modalReducer,
  navigationControllerReducer,
  notificationsReducer,
  organizationReducer,
  passwordReducer,
  privacyPolicyReducer,
  responsiveReducer,
  rightDrawerManageTemplateReducer,
  rightDrawerMessageReducer,
  rightDrawerGroupMessagesReducer,
  rightDrawerReducer,
  rightDrawerShortReducer,
  rightDrawerTemplatesReducer,
  snackBarReducer,
  socialShareReducer,
  statesDictionaryReducer,
  templateReducer,
  threadReducer,
  userProfileReducer,
});

export default rootReducer;
