import posed from 'react-pose';

const AnimatedParagraph = posed.p({
  fullHeight: {
    applyAtEnd: {
      // at the end of the animation change width to a responsive one
      width: props => {
        const maxWidth =
          props.textLength < props.maxWidth ? `${props.textLength}px` : 'calc(100% - 38px)';
        return maxWidth;
      },
    },
  },
  visible: {
    y: 0,
    scale: 1,
    width: props => {
      // consistent animation velocity
      const maxWidth =
        props.textLength < props.maxWidth ? `${props.textLength}px` : `${props.maxWidth}px`;
      return maxWidth;
    },
    transition: props => {
      // consistent animation velocity
      const duration =
        props.textLength < props.maxWidth
          ? (props.textLength / props.maxWidth) * 1200
          : (props.maxWidth / 1366) * 1200;
      return { duration };
    },
  },
  appearing: {
    y: 0,
    scale: 1,
    width: '20px',
    transition: {
      type: 'spring',
      stiffness: 100,
      damping: 12,
      duration: 10,
    },
  },
  hidden: {
    y: '10px',
    scale: 0,
    width: '10px',
  },
});

export default AnimatedParagraph;
