/* eslint-disable */
const style = {
  paperClass: {
    margin: '48px',
    display: 'flex',
    outline: 'none',
    position: 'relative',
    borderRadius: '10px',
    'overflow-y': 'auto',
    'flex-direction': 'column',
    '@media only screen and (max-width: 414px)': {
      margin: '0px !important',
    },
  },
  containerConfirmation: {
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    backgroundColor: '#efe3f7',
    '@media only screen and (min-width: 1024px)': {
      width: 620,
      height: 235,
    },
    '@media only screen and (min-width: 415px) and (max-width: 1023px)': {
      width: 585,
      height: 235,
    },
    '@media only screen and (max-width: 414px)': {
      width: 335,
      height: 255,
    },
    '& .warning-message': {
      'font-family': "GothamSSm-Book",
      'font-size': '16px',
      'font-weight': 'normal',
      'font-style': 'normal',
      'font-stretch': 'normal',
      'line-height': 'normal',
      'letter-spacing': 'normal',
      color: '#9a66bf',
      padding: '0px !important',
      'text-align': 'center',
      '@media only screen and (min-width: 1024px)': {
        width: '459px',
        height: '60px',
        marginBottom: 20,
      },
      '@media only screen and (min-width: 415px) and (max-width: 1023px)': {
        width: '459px',
        height: '60px',
        marginBottom: 20,
      },
      '@media only screen and (max-width: 414px)': {
        width: '295px',
        height: '80px',
        marginBottom: 20,
      },
    },
    '& .button-warning-decision': {
      width: '346px',
      height: '40px',
      border: 'solid 2px #9a66bf',
      'border-radius': '100px',
      'background-color': '#efe3f7',
      color: '#9a66bf',
      'font-family': 'GothamSSm-Bold',
      'font-size': '16px',
      'font-weight': 'bold',
      'font-style': 'normal',
      'font-stretch': 'normal',
      'line-height': 'normal',
      'letter-spacing': 'normal',
      'text-align': 'center',
      'margin-bottom': '15px',
      '@media only screen and (max-width: 414px)': {
        width: 292,
      },
    },
    '& .button-warning-decision.no-margin-bottom': {
      marginBottom: 0,
    },
  },
  containerNotice: {
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    backgroundColor: '#efe3f7',
    '@media only screen and (min-width: 1024px)': {
      width: 620,
      height: 167,
    },
    '@media only screen and (min-width: 415px) and (max-width: 1023px)': {
      width: 585,
      height: 160,
    },
    '@media only screen and (max-width: 414px)': {
      width: 335,
      height: 200,
    },
    '& .warning-message': {
      'font-family': "GothamSSm-Book",
      'font-size': '16px',
      'font-weight': 'normal',
      'font-style': 'normal',
      'font-stretch': 'normal',
      'line-height': 'normal',
      'letter-spacing': 'normal',
      color: '#9a66bf',
      padding: '0px !important',
      'text-align': 'center',
      '@media only screen and (min-width: 1024px)': {
        width: '483px',
        height: '47px',
        marginBottom: 20,
      },
      '@media only screen and (min-width: 415px) and (max-width: 1023px)': {
        width: '480px',
        height: '40px',
        marginBottom: 20,
      },
      '@media only screen and (max-width: 414px)': {
        width: '295px',
        height: '80px',
        marginBottom: 20,
      },
    },
    '& .button-warning-decision': {
      width: '225px',
      height: '40px',
      border: 'solid 2px #9a66bf',
      'border-radius': '100px',
      'background-color': '#efe3f7',
      color: '#9a66bf',
      'font-family': 'GothamSSm-Bold',
      'font-size': '16px',
      'font-weight': 'bold',
      'font-style': 'normal',
      'font-stretch': 'normal',
      'line-height': 'normal',
      'letter-spacing': 'normal',
      'text-align': 'center',
      'margin-bottom': '15px',
      '@media only screen and (max-width: 414px)': {
        width: 165,
      },
    },
  }

}
/* eslint-enable */

export default style;
