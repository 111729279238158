
import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import './index.sass';
import '../LoadingScreen/index.sass';
import logo from '../LoadingScreen/ripple-loading.svg';
import LoadingScreen from '../LoadingScreen';


/**
 * Custom Generic Modal
 *
 * @param {boolean} status Status Loading
 *
 * behavior :
 *
 * import { statusLoading } from 'Actions/loadingActions';
 *
 * this.props.statusLoading(status) Open / Close
 *
 */

const styles = theme => ({
  progress: {
    margin: theme.spacing(2),
  },
});

const Loading = ({ isLoading }) => (
  <div className={`loading-container ${isLoading ? 'loading-container--enable' : 'loading-container--disable'}`}>
    <LoadingScreen loadingText="Loading..." />
  </div>
);

const mS = ({ loadingReducer: { isLoading } }) => ({ isLoading });

export default withStyles(styles)(connect(mS, null)(Loading));
