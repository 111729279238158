import React, { useState } from 'react';
import Files from '../../Api/Files';
import './index.sass';

// TODO: implement
const isSuccessResponse = () => true;

const defaultAcceptedExtensions = [
  '.bmp', '.doc', '.docx', '.gif', '.indd', '.jpeg', '.jpg', '.png', '.pdf', '.ppt', '.pptm', '.pptx', '.psd', '.pub', '.tiff', '.txt',
  '.zip', '.xls','.xlsx', '.csv', '.xlxs', '.svg', '.mp4', '.mpg', '.mpeg', '.avi', '.wmv'
];

export const FilesManager = ({ accept = defaultAcceptedExtensions, data, filesCount = 3, onChange, onError }) => {
  const [uploadingStatus, setUploadingStatus] = useState(0);
  const [uploadingState, setUploadingState] = useState(false);

  const handleFileUploadError = () => {
    onError('File upload failed');
    setUploadingStatus(0);
  };

  const uploadFile = (file) => {
    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        // console.log(`${loaded}kb of ${total}kb | ${percent}%`);

        if (percent <= 100) {
          setUploadingStatus(percent);
        }
      }
    };

    setUploadingState(true);
    Files.upload(file, options)
      .then(response => {
        if (isSuccessResponse(response)) {
          onChange(data.concat(response.data.data));
          setUploadingStatus(0)
        } else {
          handleFileUploadError();
        }
      })
      .catch(() => handleFileUploadError())
      .finally(() => setUploadingState(false));
  };

  const handleFileSelect = (e) => {
    const files = e.target.files;

    if (!files?.length) {
      return;
    }

    const file = files[0];
    const extensionSearch = /[^.]+$/.exec(file.name);
    const extension = extensionSearch ? extensionSearch[0] : null;

    if (!extension || !accept.find(ext => ext === `.${extension}`)) {
      onError(`File type is not allowed: ${file.name}`);
      return;
    }

    try {
      uploadFile(file);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemove = (publicId) =>
    onChange(data.filter((f) => f.publicId !== publicId));

  return (
    <div className="files-manager">
      {data.map((file) => (
        <div className="inner-file-wrapper">
          <div className="file-name"><span>{file.name}</span></div>
          <button className="icon-button remove-button" onClick={() => handleRemove(file.publicId)} />
        </div>
      ))}

      {data.length < filesCount && (
        <div className="file-button-wrapper">
          {uploadingState && (
            <div className="progress-bar">
              {uploadingStatus}%
              <span style={{ width: `${uploadingStatus}%` }} />
            </div>
          )}

          {!uploadingState && (
            <label className="button-primary">
              <span className="choose-file-link">Attach File</span>
              <input
                accept={accept.join(',')}
                type="file"
                onChange={(e) => handleFileSelect(e)}
              />
            </label>
          )}
        </div>
      )}
    </div>
  );
};

export default FilesManager;
