const styles = {
  customSelect: {
    '&>.custom-select-container': {
      marginBottom: '10px',
      '&>.mbsc-custom-select-input': {
        paddingLeft: '7px',
      },
      '&>.right-icon>img': {
        height: '17%',
      },
    },
  },
};

export default styles;
