import React from 'react';
import './index.sass';
import EvaTooltip from '../EvaTooltip';

export const FilterButton = ({ title, isActive, isFiltersSelected, onClick }) => (
  <EvaTooltip title={title}>
    <button
      className={`btn-filter ${isActive ? 'active' : ''} ${isFiltersSelected ? 'filters-selected' : ''}`}
      onClick={onClick}
    >
      <i/>
    </button>
  </EvaTooltip>
);
