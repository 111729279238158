import React, { Component } from 'react';
import ContentEditable from 'react-contenteditable';
import data from 'emoji-mart/data/messenger.json';
import { debounce } from '../../Helpers/debounce';
import ComposerToolbar from '../ComposerToolbar';
import { getSelectionText, replaceSelectedText } from '../../Helpers/selection';
import sanitizeHtml from 'sanitize-html';
import { SMS_TEXT_LENGTH_LIMIT, SMS_TEXT_LENGTH_WITH_EMOJIS_LIMIT } from '../../Config/Constants';
import { emojisRegex } from '../../Helpers/regex';
import MessageEditorPropType from '../../PropTypes/MessageEditorPropType';
import './index.sass';

// Parent class for all composers
class MessageEditor extends Component {
  constructor(props) {
    super(props);
    this.editableRef = React.createRef();
    this.editableInnerRef = React.createRef();

  }

  state = {
    selectedText: '',
  };

  handleCaretChange = () => {
    this.setState({ selectedText: getSelectionText() });
  };

  handleClickNext = () => {
    const { onNextButtonClick, loadingMessage } = this.props;
    if (loadingMessage) return;
    onNextButtonClick();
  };

  handleInsertEmoji = emojiCode => {
    this.editableInnerRef.current.focus();
    replaceSelectedText(emojiCode);
    this.editableRef.current.emitChange(); // Trigger change to update a store.
    window.getSelection().collapseToEnd(); // Remove selection and set caret after emoji
  };

  handleChange = async evt => {
    const { html } = this.props;
    let targetValue = evt.target.value;
    if (html === '') {
      targetValue = `<div>${targetValue}</div>`;
    }
    await this.props.onHtmlSet(targetValue);
  };

  sanitizeConf = {
    allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'h1', 'img', 'u', 'br'],
    allowedSchemes: ['http', 'https', 'ftp', 'mailto'],
    allowedAttributes: { a: ['href'], img: ['src'] },
    allowedSchemesByTag: {
      img: ['data'],
    },
    selfClosing: ['img', 'br'],
  };

  sanitize = () => {
    const { html, onHtmlSet } = this.props;
    onHtmlSet(sanitizeHtml(html, this.sanitizeConf));
  };

  render() {
    const { contentLength, counterTooltip, html, nextButtonText, loadingMessage, onGalleryOpen, onTemplatesOpen, placeholder } = this.props;
    const isHasEmojis = emojisRegex.test(html);

    return (
      <div className="composer">
        <ContentEditable
          ref={this.editableRef}
          innerRef={this.editableInnerRef}
          className="editable"
          tagName="p"
          placeholder={placeholder}
          html={html} // innerHTML of the editable div
          onChange={this.handleChange}
          onKeyUp={debounce(this.handleCaretChange, 50)}
          onMouseUp={debounce(this.handleCaretChange, 50)}
          onClick={this.handleCaretChange}
          id="cEdit"
        />
        <ComposerToolbar
          contentLength={contentLength}
          counterTooltip={counterTooltip}
          isTextSelected={!!this.state.selectedText}
          isLoading={loadingMessage}
          nextButtonText={nextButtonText}
          onTemplateClick={onTemplatesOpen}
          onGalleryClick={onGalleryOpen}
          onEmojiSelect={this.handleInsertEmoji}
          onNextButtonClick={this.handleClickNext}
          limit={isHasEmojis ? SMS_TEXT_LENGTH_WITH_EMOJIS_LIMIT : SMS_TEXT_LENGTH_LIMIT}
        />
      </div>
    );
  }
}

MessageEditor.propTypes = MessageEditorPropType;


export default MessageEditor;
