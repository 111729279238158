import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import './index.sass';
import '../LoadingScreen/index.sass';
import LoadingScreen from '../LoadingScreen';

/**
 * Custom Generic Modal
 *
 * @param {boolean} status Status Loading
 *
 * behavior :
 *
 * import { mainStatusLoading } from 'Actions/mainLoadingActions';
 *
 * this.props.mainStatusLoading(status) Open / Close
 *
 */

const styles = theme => ({
  progress: {
    margin: theme.spacing(2),
  },
});

const MainLoading = ({ isLoading }) => (
  <div className={`loading-container ${isLoading ? 'loading-container--enable' : 'loading-container--disable'}`}>
    <LoadingScreen loadingText="Loading data..."/>
  </div>
);

const mS = ({ mainLoadingReducer: { isLoading } }) => ({ isLoading });

export default withStyles(styles)(connect(mS, null)(MainLoading));
