// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../Theme/Icons/cancel-icon.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../Theme/Icons/back-icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".modal-wrapper{position:fixed;z-index:1400;width:100vw;height:100vh;left:0;top:0;background-color:rgba(0,0,0,0.6)}.modal-wrapper .modal{position:relative;width:100%;-webkit-box-sizing:border-box;box-sizing:border-box;padding:20px;background-color:white;height:100%}.modal-wrapper .modal .modal-header{margin-bottom:20px;display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between}.modal-wrapper .modal .modal-header button{width:40px;height:40px}.modal-wrapper .modal .modal-header button.modal-close-button{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center}.modal-wrapper .modal .modal-header button.modal-back-button{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat center}.modal-wrapper .modal .modal-header button.invisible{visibility:hidden;pointer-events:none}.modal-wrapper .modal .modal-header .modal-title{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;padding:0 20px;font-family:\"GothamSSm-Medium\",Arial,sans-serif;font-size:20px;color:#4a4a4a;word-break:break-all}.modal-wrapper .modal.no-title .modal-header{margin-bottom:0}@media only screen and (min-width: 960px){.modal-wrapper{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}.modal-wrapper .modal{height:auto;max-height:calc(100vh - 40px);margin:0 auto;-webkit-box-shadow:0 3px 7px rgba(0,0,0,0.6);box-shadow:0 3px 7px rgba(0,0,0,0.6);border-radius:8px}.modal-wrapper .modal.size-small{max-width:500px}.modal-wrapper .modal.size-default{max-width:660px}}\n", ""]);
// Exports
module.exports = exports;
