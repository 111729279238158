export const getMemberNotificationPreference = (memberBelongToGroupsList = [], groupPublicId) => {
  const notificationPreference = {
    isNotifyByEmail: true,
    isNotifyBySms: false
  };

  const group = memberBelongToGroupsList.find(g => g.publicId === groupPublicId);

  if (!group) {
    return notificationPreference;
  }

  return { isNotifyByEmail: group.isNotifyByEmail, isNotifyBySms: group.isNotifyBySms }
};
