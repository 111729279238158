import PropTypes from 'prop-types';

const FolderSettingsPropType = {
  classes: PropTypes.object.isRequired,
  comments: PropTypes.string.isRequired,
  create: PropTypes.bool.isRequired,
  folderName: PropTypes.string.isRequired,
  characters: PropTypes.number.isRequired,
  share: PropTypes.bool.isRequired,
  shareAllOrg: PropTypes.bool.isRequired,
  loadingGroups: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleShareAction: PropTypes.func.isRequired,
  handleSaveSettings: PropTypes.func.isRequired,
  selectedParent: PropTypes.string,
  selectedOrganization: PropTypes.string,
  selectedGroup: PropTypes.string,
  viewType: PropTypes.string.isRequired,
};

export default FolderSettingsPropType;
