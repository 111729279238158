// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../Theme/Icons/active.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".image-gallery-list-container{margin-top:6px;margin-bottom:20px;display:-ms-flexbox;display:flex;width:100%;-ms-flex-wrap:wrap;flex-wrap:wrap}@media screen and (min-width: 819px) and (max-height: 670px){.image-gallery-list-container{overflow:auto;height:232px}}.image-gallery-list-container .item-button{margin-bottom:20px;display:block;position:relative}@media only screen and (max-width: 767px){.image-gallery-list-container .item-button{width:calc(50% - 10px)}.image-gallery-list-container .item-button:nth-child(2n+1){margin-right:20px}}@media only screen and (min-width: 768px){.image-gallery-list-container .item-button{width:calc(20% - 16px)}.image-gallery-list-container .item-button:nth-child(5n+1){margin-right:20px}.image-gallery-list-container .item-button:nth-child(5n+2){margin-right:20px}.image-gallery-list-container .item-button:nth-child(5n+3){margin-right:20px}.image-gallery-list-container .item-button:nth-child(5n+4){margin-right:20px}}.image-gallery-list-container .item-button:after{content:\"\";display:block;padding-bottom:100%}.image-gallery-list-container .item-button .image-prev{position:absolute;left:0;top:0;right:0;bottom:0;border-radius:10px;background-size:cover;background-position:center}.image-gallery-list-container .item-button .check-container{width:20px;height:20px;position:absolute;top:10px;left:10px;background-color:#e3e3e3;border-radius:5px}.image-gallery-list-container .item-button.active .image-prev{opacity:0.5}.image-gallery-list-container .item-button.active .check-container{background:white url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat -12px}#image-gallery-container .image-arrows{margin:0% 2%}#image-gallery-container #button-send-message .button-eva{margin-top:5px !important}\n", ""]);
// Exports
module.exports = exports;
