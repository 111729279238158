import PropTypes from 'prop-types';

const ViewMemberPropType = {
  enableRightDrawer: PropTypes.func.isRequired,
  handleClick: PropTypes.func,
  groups: PropTypes.instanceOf(Array),
  listMembers: PropTypes.instanceOf(Array),
  selectedGroup: PropTypes.instanceOf(Object) || null,
  error: PropTypes.string,
};

export default ViewMemberPropType;
