/* eslint-disable camelcase */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import GenericMessage from '../GenericMessage';
import LoadingScreen from '../LoadingScreen';
import { getMessage } from '../../Actions/messageActions';
import { navigationControllerRedirect } from '../../Actions/navigationControllerActions';
import './index.sass';

class MessageFromThread extends Component {
  state = {
    isNoLongerExist: false,
  };

  componentDidMount() {
    const { messageId } = this.props.match.params;
    this.props.getMessage(messageId).then(res => {
      if (res.code === 200) {
        this.props.navigationControllerRedirect(`/messages/${res.data.threadParentMessage}`);
      } else {
        this.setState({ isNoLongerExist: true });
      }
    });
  }

  render = () => this.state.isNoLongerExist ?
    <div className="no-exist-wrapper">
      <GenericMessage
        type="info"
        title="Oops!"
        message=" Seems like the message that you're looking for is no longer available"
      />
    </div> :
    <LoadingScreen loadingText="Loading message..."/>
}

const mD = {
  getMessage,
  navigationControllerRedirect,
};

export default connect(
  null,
  mD,
)(MessageFromThread);
