// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../Theme/Icons/new-message-white.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../Theme/Icons/user-add.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../Theme/Icons/help-group.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../Theme/Icons/designer-icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, ".button-compose .buttons-wrapper .btn-title{padding-left:48px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 15px center}.button-compose .button-drop-list ul li{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}.button-compose .button-drop-list ul li.item-ref{display:block;padding:0}.button-compose .button-drop-list ul li.item-ref a{display:-ms-flexbox;display:flex;padding:5px 0;text-decoration:none;font:inherit;color:inherit}.button-compose .button-drop-list ul li .add-member{padding:16px 1px 9px 32px;margin-top:1px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-repeat:no-repeat}.button-compose .button-drop-list ul li .help{padding:25px 11px 0 21px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");background-repeat:no-repeat}.button-compose .button-drop-list ul li .designer-icon{padding:16px 1px 9px 32px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");background-repeat:no-repeat}\n", ""]);
// Exports
module.exports = exports;
