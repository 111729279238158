import { ACTIONS } from '../Actions/countriesDictionaryActions';

const initialState = {
  list: [],
  isLoaded: false,
  isLoading: false,
};

const countriesDictionaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_LIST:
      return {
        ...state,
        list: action.payload,
        isLoaded: true
      };
    case ACTIONS.LOADING:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};

export default countriesDictionaryReducer;
