import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

const Group = ({
  group,
  date,
  description,
  membersCount,
  activityStatus,
  status,
  subGroupQuantity,
}) => (
  <Grid container className="group-detail-container">
    <Grid container className="title-date-container">
      <Grid item xs={6} className="title">
        {group}
      </Grid>
      <Grid item xs={6} className="date">
        {date}
      </Grid>
    </Grid>
    <Grid item xs={12} className="description">
      {description}
    </Grid>
    <Grid container className="icon-counter-container">
      <Grid item xs={12} sm={12} className="icon-container">
        <i
          className={`icon ${activityStatus} ${status === 'inactive' &&
            'status-disable'}`}
        />
        <div className="counter">
          {membersCount} members | {`${subGroupQuantity} sub-groups`}
        </div>
      </Grid>
    </Grid>
  </Grid>
);
Group.propTypes = {
  date: PropTypes.string.isRequired,
};

export default Group;
