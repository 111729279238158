import PropTypes from 'prop-types';

const MoveTemplatesPropTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func.isRequired,
  onSet: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default MoveTemplatesPropTypes;
