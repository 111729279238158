import React from 'react';
import InputForSearch from '../InputForSearch';
import SearchInputPropTypes from '../../PropTypes/SearchInputPropType';

const SearchInput = React.forwardRef(({ value, onChange, onClear, clear, disabled }, ref) => (
  <div className="search-input search-cancel">
    <InputForSearch
      ref={ref}
      name="searchTerm"
      type="text"
      placeholder="Search"
      icon="search"
      value={value}
      onChange={onChange}
      onClear={onClear}
      clear={clear}
      disabled={disabled}
    />
  </div>
));

SearchInput.propTypes = SearchInputPropTypes;

export default SearchInput;
