import React, { Component } from 'react';
import { connect } from 'react-redux';
import EvaTooltip from '../EvaTooltip';
import { clearCompose } from '../../Actions/composeActions';
import { openCustomModal } from '../../Actions/customModalActions';
import { clearMessage, updateRecipientGroups } from '../../Actions/messageActions';
import { SORT_RECENT_MESSAGES } from '../../Config/Constants';
import ButtonSelect from '../ButtonSelect';
import MessageModalManager, { MESSAGE_MODAL_MANAGER_VIEWS } from '../../Containers/MessageModalManager';

class MessageSearchDesktop extends Component {
  /* eslint-disable */
  handleNewMessage = () => {
    const { selectedGroup } = this.props;

    this.props.clearMessage();
    if (selectedGroup) {
      this.props.updateRecipientGroups([selectedGroup.public_id]);
    }
    this.props.clearCompose();

    this.props.openCustomModal({
      content: <MessageModalManager view={MESSAGE_MODAL_MANAGER_VIEWS.EDITOR} />
    });
  };

  /* eslint-enable */
  render() {
    const { messagesListSelected, onSort, onArchive, onDelete, onUnArchive } = this.props;
    const selectedIds = messagesListSelected.map(m => m.publicId);

    const hasSelected = messagesListSelected?.length > 0;
    const isAllSelectedArchived = messagesListSelected.every(m => m.isArchived);
    const isAllSelectedNotArchived = messagesListSelected.every(m => !m.isArchived);

    return (
      <div className="actions-sort-pad">
        <div className="group-active-select" id="select-data-message">
          <ButtonSelect className="button-sort" values={SORT_RECENT_MESSAGES} defaultValue="recent" onChange={onSort}/>
        </div>
        <div className="message-actions">
          {hasSelected && isAllSelectedNotArchived && (
            <EvaTooltip title="Archive Message">
              <button className="icon-button message-archive" onClick={() => onArchive(selectedIds)}/>
            </EvaTooltip>
          )}
          {hasSelected && isAllSelectedArchived && (
            <EvaTooltip title="Delete Message">
              <button className="icon-button message-delete" onClick={() => onDelete(selectedIds)} />
            </EvaTooltip>
          )}
          {hasSelected && isAllSelectedArchived && (
            <EvaTooltip title="Unarchive Message">
              <button className="icon-button message-unarchive" onClick={() => onUnArchive(selectedIds)}/>
            </EvaTooltip>
          )}
          <EvaTooltip title="New Message">
            <button className="icon-button message-create" onClick={this.handleNewMessage}/>
          </EvaTooltip>
        </div>
      </div>
    );
  }
}

const mS = state => ({
  messagesListSelected: state.messagesReducer.messagesListSelected,

  selectedGroup: state.groupsReducer.selectedGroup,
});

const mD = {
  openCustomModal,
  clearCompose,
  updateRecipientGroups,
  clearMessage,
};

export default connect(mS, mD)(MessageSearchDesktop);
