import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ContentEditable from 'react-contenteditable';
import PropTypes from 'prop-types';
import emailPreviewstyles from '../../Components/MessageEmailPreview/styles';
import EmailPostviewPropType from '../../PropTypes/EmailPostviewPropType';
import './../../Components/MessageEmailPreview/index.sass';
import { Scrollbars } from 'react-custom-scrollbars';
import './index.sass';

const TabContainer = props => (
  <Typography component="div">{props.children}</Typography>
);

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => emailPreviewstyles(theme);

const EmailPostview = ({ content, viewport }) => (
  <div className="message-email-container post-view" id="message-email-container">
    <div className="page-container designerContent" id="email-page-container">
      <TabContainer>
        <ContentEditable
          className={`content ${viewport === 'mobile' ? 'content-mobile-preview' : ''}`}
          id="scroll"
          tagName="p"
          html={content}
          disabled
        />
      </TabContainer>
    </div>
  </div>
);

EmailPostview.propTypes = EmailPostviewPropType;

export default withStyles(styles)(EmailPostview);
