import React from 'react';
import Proptypes from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

function RejectionNoticeComponent({ handleCloseModal, classes: { containerNotice } }) {
  return (
    <DialogContent className={containerNotice}>
      <div className="warning-message">
        <div className="single-line">
          ATTENTION: You do not have permission to cancel this message.
          <strong> Please contact your Org Admin for assistance.</strong>
        </div>
      </div>
      <button
        onClick={handleCloseModal}
        className="button-warning-decision "
      >
        <span className="text-warning">Close</span>
      </button>
    </DialogContent>
  );
}

RejectionNoticeComponent.propTypes = {
  handleCloseModal: Proptypes.func.isRequired,
  classes: Proptypes.instanceOf(Object),
};

RejectionNoticeComponent.defaultProps = {
  classes: {
    containerNotice: 'container-warning-delete',
  },
};

export default withStyles(styles)(RejectionNoticeComponent);
