export const getSelectionRange = () => {
  if (window.getSelection) {
    const sel = window.getSelection();
    if (sel.getRangeAt && sel.rangeCount) {
      const text = sel.toString();
      const range = sel.getRangeAt(0);

      const startOffset = text.length - text.trimStart().length;
      const endOffset = text.length - text.trimEnd().length;

      if (startOffset) {
        range.setStart(range.startContainer, range.startOffset + startOffset);
      }

      if (endOffset) {
        range.setEnd(range.endContainer, range.endOffset - endOffset);
      }

      return range;
    }
  } else if (document.selection && document.selection.createRange) {
    return document.selection.createRange();
  }
  return null;
};

export const setSelectionRange = (range) => {
  if (range) {
    if (window.getSelection) {
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    } else if (document.selection && range.select) {
      range.select();
    }
  }
};

export const getSelectionText = () => {
  let text = '';
  if (window.getSelection) {
    text = window.getSelection().toString();
  } else if (document.selection && document.selection.type !== 'Control') {
    text = document.selection.createRange().text;
  }
  return text;
};

export const getSelectionParentElement = () => {
  let parentEl = null;
  let sel;
  if (window.getSelection) {
    sel = window.getSelection();
    if (sel.rangeCount) {
      parentEl = sel.getRangeAt(0).commonAncestorContainer;
      if (parentEl.nodeType !== 1) {
        parentEl = parentEl.parentNode;
      }
    }
  } else if (document.selection.type !== 'Control') {
    parentEl = document.selection.createRange().parentElement();
  }
  return parentEl;
};

export const getParentLink = (node, depth = 10) => {
  let elementForCheck = node;
  let parentLink = '';
  let counter = 0;
  while (elementForCheck && !parentLink && counter < depth) {
    if (elementForCheck.nodeName === 'A') {
      parentLink = elementForCheck.getAttribute('href');
    } else {
      elementForCheck = elementForCheck.parentNode;
      counter++;
    }
  }
  return parentLink;
};

export const replaceSelectedText = (replacementText) => {
  let sel;
  let range;
  if (window.getSelection) {
    sel = window.getSelection();
    if (sel.rangeCount) {
      range = sel.getRangeAt(0);
      range.deleteContents();
      range.insertNode(document.createTextNode(replacementText));
    }
  } else if (document.selection && document.selection.createRange) {
    range = document.selection.createRange();
    range.text = replacementText;
  }
};
