import { parsePhoneNumberFromString } from 'libphonenumber-js';
// EVA HELPERS ==================================================
import { PasswordCheck } from './password';

// eslint-disable-next-line
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PASSWORD_REQUIREMENTS = [
  'lowercase',
  'uppercase',
  'length',
  'specialCharacterOrNumber',
];

/**
 * Validator factory that returns a function to verify if a value is
 * empty (or considered empty) that returns undefine when such value
 * is defined and an error message when it is not.
 * @param {string} message The custom message of the error.
 * @returns {string | undefined} An error string or nothing (undefined)
 */
export function rfRequiredField(message) {
  // eslint-disable-next-line
  return function(...values) {
    const value = values[0];
    return value ? undefined : message;
  };
}

/**
 * Validator factory that returns a function to verify if a value is
 * en email or not. This returned function should be compatible with Redux-form
 * validator return values.
 * @param {string} message Custom messate of error
 * @returns {string | undefined} An error string or nothing (undefined)
 */
export function rfValidateEmail(message) {
  // eslint-disable-next-line
  return function(value) {
    if (!value) {
      return undefined;
    }
    return emailRegex.test(value) ? undefined : message;
  };
}

/**
 * Validator factory that returns a function to fverify if a value
 * is a valid phone number or not. This returned function should be compatible with
 * Redux-Form validator functions. (See documentation)
 * @param {string} message Custom message error
 */
export function rfValidatePhoneNumber(message) {
  // eslint-disable-next-line
  return function(value) {
    if (!value) {
      return undefined;
    }
    const parsedNumber = parsePhoneNumberFromString(value);
    if (!parsedNumber || !parsedNumber.isValid()) {
      return message;
    }
  };
}

/**
 *
 * @param {string} message Custom message to display as error
 * @param {string[]} terms vaidation to execute
 */
export function rfPasswordValidator(
  message = 'Your password must meet all the requirements listed below.',
  terms = PASSWORD_REQUIREMENTS,
) {
  // eslint-disable-next-line
  return function(value) {
    if (!value) {
      return undefined;
    }
    const keys = terms;
    const newState = {};
    keys.forEach(term => {
      newState[term] = PasswordCheck(term, value);
    });
    if (keys.every(term => newState[term])) {
      return undefined;
    }
    return message;
  };
}
