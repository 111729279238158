export const ACTIONS = {
  ENABLE_SHORT: 'rightDrawerShort/enable',
};

export const enableRightDrawer = value => dispatch => {
  dispatch({ type: ACTIONS.ENABLE_SHORT, ...value });
  if (window.innerWidth >= 1024) {
    document.querySelector(
      '.right-drawer-edit-member > .drawer-md-up',
    ).style.display = 'block';
  }
};
