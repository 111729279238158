import PropTypes from 'prop-types';
import React from 'react';

const MessagePreviewToolbarPropType = {
  previewImages: PropTypes.arrayOf(PropTypes.string),
  selectedPreviewImage: PropTypes.string,
  imagePicker: PropTypes.bool,
  text: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  isPreventChange: PropTypes.bool,
  children: PropTypes.node,
  onImageSelect: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

export default MessagePreviewToolbarPropType;
