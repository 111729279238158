import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import Color from '../../Theme/Colors';
import './index.sass';

const styles = theme => ({
  CustomIcon: {
    color: Color.amethyst,
    width: '20px',
    height: '20px',
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
    backgroundColor: Color.evaSoft,
    color: Color.amethyst,
    border: `solid 2px ${Color.amethyst}`,
    fontFamily: 'GothamSSm-Book',
    fontSize: '16px',
    height: '23px',
  },
});

const AddedMembers = ({ members, classes, removeCandidate }) => (
  <Grid container className="added-members-container">
    <h3>Members ready to add</h3>
    <Grid item xs={10}>
      {members.map((member, index) => {
        const id = Math.random();
        if (!member.firstName && !member.lastName) {
          return (
            <Chip
              key={id}
              label={`${member.phone || member.email}`}
              className={classes.chip}
              onDelete={() => removeCandidate(index)}
              deleteIcon={<CloseIcon className={classes.CustomIcon} />}
            />
          );
        }
        return (
          <Chip
            key={id}
            label={`${member.firstName} ${member.lastName}`}
            className={classes.chip}
            onDelete={() => removeCandidate(index)}
            deleteIcon={<CloseIcon className={classes.CustomIcon} />}
          />
        );
      })}
    </Grid>
  </Grid>
);

AddedMembers.defaultProps = {
  members: [],
};
AddedMembers.propTypes = {
  members: PropTypes.instanceOf(Array),
  classes: PropTypes.instanceOf(Object).isRequired,
  removeCandidate: PropTypes.func.isRequired,
};

export default withStyles(styles)(AddedMembers);
