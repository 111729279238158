import { LINE_BREAK_REGEX, LINE_BREAK_TAG, MSG_STATUSES } from '../../../../Config/Constants';
import getMemberInfo from '../../../getMemberInfo';

/**
 * Wraps the Message data (obvious)
 */
export default class MessageWrapper {
  constructor(message) {
    this.message = message;
  }

  getSubject(): string {
    return this.message && this.message.subject ? this.message.subject : '';
  }

  // TODO: refactor, switch to 'toMember'.
  getSendToMembers() {
    return this.message.sendToMembers;
  }

  hasGroup(): boolean {
    return (
      this.message &&
      this.message.groups &&
      Array.isArray(this.message.groups) &&
      this.message.groups.length > 0
    );
  }

  getGroupsNames(): string {
    return this.message.groups.map(g => g.group.name).join(', ');
  }

  getGroupsShortNames(): string {
    return this.message.groups.map(g => g.group.shortName).join(', ');
  }

  getStatus(): string {
    return this.message && this.message.status;
  }

  isScheduled(): boolean {
    return (
      this.message &&
      this.message.status &&
      this.message.status === MSG_STATUSES.SCHEDULED
    );
  }

  isDirect(): boolean {
    return !!this.message.sentByMember &&
      (!this.message.groups || this.message.groups.length === 0);
  }

  getDirectRecipient(): boolean {
    const sendToMembers = this.getSendToMembers();
    let fullName = '';
    if (sendToMembers[0]) {
      fullName = getMemberInfo(sendToMembers[0].member).displayedFullName;
    }
    return fullName;
  }

  getWebContent(): string {
    const { message } = this;
    return message
      && message.webContent
      && message.webContent.replace(LINE_BREAK_REGEX, LINE_BREAK_TAG);
  }

  getResponseMessagesCount(): Number {
    return (
      this.message.responseMessages && this.message.responseMessages.length
    );
  }
}
