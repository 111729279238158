import Colors from '../../Theme/Colors';

export default {
  root: {
    border: `${Colors.greyCheck} solid 1px`,
    borderRadius: 5,
    height: 18,
    padding: 0,
    width: 18,
    '&:not(.filled)': {
      borderColor: `${Colors.bgSidebarHover}`,
    },
  },
  checked: {
    borderColor: Colors.white,
    '&.outlined': {
      borderColor: `${Colors.bgSidebarHover}`,
    },
  },
  hiddenIcon: {
    display: 'none',
  },
};
