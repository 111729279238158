import React, { PureComponent } from 'react';
import Lottie from 'react-lottie';
import PropTypes from 'prop-types';
import './index.sass';

class EvaAnimation extends PureComponent {
  state = {
    isStopped: !this.props.autoPlay,
    isPaused: !this.props.autoPlay,
    isComplete: false,
  };

  handleClick = () => {
    this.setState({ isPaused: !this.state.isPaused });
  };

  handleEvent = obj => {
    if (!this.props.loop) {
      if (obj.currentTime === obj.totalTime - 1) {
        if (this.state.isComplete) {
          this.setState({ isStopped: true, isComplete: false });
        } else {
          this.setState({ isStopped: false, isComplete: true });
        }
      }
    }
  };

  render() {
    const defaultOptions = {
      loop: this.props.loop,
      autoplay: this.props.autoPlay,
      animationData: this.props.animation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
    const makeValidNumber = value => {
      if (value.substr(value.length - 1) === '%') {
        return value;
      }
      return Number(value);
    };

    return (
      <div className="Animation">
        <Lottie
          onClick={this.handleClick}
          options={defaultOptions}
          width={makeValidNumber(this.props.width)}
          height={makeValidNumber(this.props.height)}
          isStopped={this.state.isStopped}
          isPaused={this.state.isPaused}
          eventListeners={[
            {
              eventName: 'enterFrame',
              callback: obj => this.handleEvent(obj),
            },
          ]}
        />
      </div>
    );
  }
}

EvaAnimation.propTypes = {
  animation: PropTypes.instanceOf(Object).isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  loop: PropTypes.bool,
  autoPlay: PropTypes.bool,
};

EvaAnimation.defaultProps = {
  width: '100%',
  height: '100%',
  loop: false,
  autoPlay: true,
};

export default EvaAnimation;
