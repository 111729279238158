export default class TreeItem {
  constructor() {
    this.id = -1;
    this.label = null;
    this.summary = null;
    this.iconDefault = null;
    this.iconActive = null;
    this.iconHover = null;
    this.isSelected = false;
    this.childrens = [];
    this.createdAt = null;
  }
}
