/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FolderSettingsForm from '../../Components/FolderSettingsForm';
import { setEnforceFocus } from '../../Actions/rightDrawerTemplatesActions';
import { enableRightDrawerTemplates } from '../../Actions/rightDrawerTemplatesActions';
import {
  createFolder,
  updateFolder,
  getFoldersList,
  getFolderById,
  getFolderByIdPaged,
  updateDialog,
  deleteFolder,
  updateFolderListMove,
  updateFolderName,
} from '../../Actions/templateActions';
import snackBarStatus from '../../Actions/snackbarActions';
import { getAllGroups, allGroupsGet } from '../../Actions/groupsActions';
import {
  clearOrganizationsSuggestions,
  getOrganizationGroup,
  getOrganization,
  searchOrganizationUpdateValue,
} from '../../Actions/organizationActions';
import FolderSettingsPropType from '../../PropTypes/FolderSettingsPropType';
import {
  ALL_ORGS_IDENTIFIER,
  ALL_GROUPS_IDENTIFIER,
  NO_GROUPS_IDENTIFIER,
} from '../../Config/Constants/FoldersSettings';
import { ROLE_SUPER_ADMIN } from '../../Config/Constants/UserRoles';

import './index.sass';
import { getFlatFolders } from '../../Helpers/getFlatFolders';

class FolderSettings extends Component {
  constructor(props) {
    super(props);
    if (props.folder !== undefined) {
      const { comments, label, publicId, publicIdParentFolder } = props.folder;
      this.state = {
        publicId: publicId || '',
        folderName: label || '',
        parentFolder: publicIdParentFolder || '',
        share: false,
        maxCharacters: 140,
        currCharacters: 0,
        comments: comments || '',
        organization: '',
        group: '',
        groups: [],
        loadingGroups: false,
        create: false,
        shares: [],
        addShareEnabled: false,
        shareAllOrg: false,
      };
    } else {
      this.state = {
        folderName: '',
        parentFolder: '',
        share: false,
        maxCharacters: 140,
        currCharacters: 0,
        comments: '',
        organization: '',
        group: '',
        groups: [],
        loadingGroups: false,
        create: true,
        shares: [],
        addShareEnabled: false,
        shareAllOrg: false,
      };
    }
  }
  componentDidMount() {
    let shares = [];
    this.props.clearOrganizationsSuggestions();
    if (this.state.publicId) {
      const currentFolder = getFlatFolders(this.props.folders).find(i => i.publicId === this.state.publicId);

      if (currentFolder) {
        const { shared, sharedWith } = currentFolder;

        shares = sharedWith.map(shareItem => {
          if (shareItem.shareToGroup) {
            return {
              title: shareItem.shareToGroup.name,
              description: shareItem.shareToGroup.description,
              publicId: shareItem.shareToGroup.publicId,
              groupId: shareItem.shareToGroup.publicId,
              orgId: '',
            };
          } else if (shareItem.shareToOrg) {
            return {
              title: shareItem.shareToOrg.name,
              description: shareItem.shareToOrg.description,
              publicId: shareItem.shareToOrg.publicId,
              groupId: NO_GROUPS_IDENTIFIER,
              orgId: shareItem.shareToOrg.publicId,
            };
          }
          return null;
        });
        this.setState({ shares, share: shared });
      }
    }
  }

  getFolderList = () => {
    const { userMainRole } = this.props;
    this.props.getFoldersList().then(rawFoldersList => {
      this.props.updateFolderListMove(rawFoldersList, userMainRole);
    });
  };

  handleSaveSettings = () => {
    const {
      publicId,
      folderName,
      parentFolder,
      share,
      organization,
      group,
      comments,
      create,
    } = this.state;
    const { shares } = this.state;
    let sharesArray = [];
    let shareAllGrp = [];
    let shareWithAllGroups = false;
    let shareAllOrg = false;

    const allOrgsOption = shares.find(share => share.orgId === ALL_ORGS_IDENTIFIER);

    if (allOrgsOption && allOrgsOption.groupId === ALL_GROUPS_IDENTIFIER) {
      // CASE 1:
      // + 'All Organizations' selected
      // + 'All Groups' option Selected
      // shareAllOrg = true;
      // shareWithAllGroups= true;
      // sharesArray = [];
      // shareAllGrp = [];
      shareAllOrg = true;
      shareWithAllGroups = true;
    } else {
      shares.forEach(share => {
        if (share.orgId === ALL_ORGS_IDENTIFIER && allOrgsOption.groupId === NO_GROUPS_IDENTIFIER) {
          // CASE 2:
          // + 'All Organizations' selected
          // + 'No Groups' option Selected
          // shareAllOrg = true;
          // shareWithAllGroups = false;
          // sharesArray = [ ... ];
          // shareAllGrp = [ ... ];
          shareAllOrg = true;
        } else if (share.groupId === ALL_GROUPS_IDENTIFIER) {
          // CASE 3:
          // + Some Org Selected
          // + 'All Groups' option Selected
          // shareAllOrg = false;
          // shareWithAllGroups = false;
          // sharesArray = [ ... ];
          // shareAllGrp = [{ org: share.publicId } ... ];
          shareAllGrp.push({ org: share.publicId });
        } else if (share.groupId === NO_GROUPS_IDENTIFIER) {
          // CASE 4:
          // Some Org selected
          // 'No Groups' selected
          // shareAllOrg = false;
          // shareWithAllGroups = false;
          // sharesArray = [{ share_to_org: share.publicId } ...];
          // shareAllGrp = [ ... ];
          sharesArray.push({ share_to_org: share.publicId });
        } else {
          // CASE 5:
          // + Some Org Selected
          // + Some Group Selected
          // shareAllOrg = false;
          // sharesArray = [{ share_to_group: share.publicId } ...];
          // shareAllGrp = [ ... ];
          sharesArray.push({ share_to_group: share.publicId });
        }
      });
    }

    if (parentFolder !== '') {
      const folder = {
        publicId,
        name: folderName || null,
        parent: parentFolder,
        organization,
        shared: share,
        group,
        comments,
        sharesArray,
        shareAllGrp,
        shareAllOrg,
        shareWithAllGroups,
      };
      if (create) {
        this.props.createFolder(folder).then(result => {
          if (result) {
            this.closeSettings();
            this.getFolderList();
          }
        });
      } else {
        this.props.updateFolder(folder).then(result => {
          if (result) {
            this.closeSettings();
            this.getFolderList();
            this.props.updateFolderName(folder);
          }
        });
      }
    } else {
      if (create) {
        this.props.snackBarStatus({
          payload: {
            enable: true,
            title: 'Parent folder has not been selected',
            type: 'error',
          },
        });
      }
    }
  };

  closeSettings = deleteFolder => {
    const { mountComponent } = this.props;
    if (mountComponent) mountComponent(deleteFolder);
    if (this.props.cleaner) {
      this.props.cleaner();
    }
    this.props.enableRightDrawerTemplates({
      payload: { enable: false, element: <div /> },
    });
  };

  handleShareAction = () => {
    this.setState({ share: !this.state.share });
  };

  handleChangeOrg = orgPublicId => {
    this.setState({
      organization: orgPublicId,
      group: '',
      groups: [],
      addShareEnabled: false,
      loadingGroups: true,
    });
    if (orgPublicId === ALL_ORGS_IDENTIFIER) {
      this.loadWithAllGroup();
    } else {
      this.loadGroups(orgPublicId);
    }
  };

  handleChangeParentFolder = ({ value }) => this.setState({ parentFolder: value });

  handleChangeSelectedGroup = ({ value }) => this.setState({ group: value, addShareEnabled: true });

  handleChange = e => {
    switch (e.target.name) {
      case 'folder_name':
        this.setState({ folderName: e.target.value });
        break;

      case 'selected_organizations':
        this.setState({ organization: e.target.value, group: '' });
        this.loadGroups(e.target.value);
        break;

      case 'parent_folder':
        this.setState({ parentFolder: e.target.value });
        break;

      default:
        if (e.target.value.length <= 140) {
          this.setState({
            currCharacters: e.target.value.length,
            comments: e.target.value,
          });
        }
        break;
    }
  };

  loadWithAllGroup = () => {
    this.props.allGroupsGet().then(groups => {
      const shortGroupList = [];
      Object.keys(groups).forEach(key =>
        shortGroupList.push({ public_id: groups[key], name: groups[key] }),
      );
      // prevent the overlapping of different API calls
      // that fetch Groups of different Orgs
      if (this.state.organization === ALL_ORGS_IDENTIFIER) {
        this.setState({ groups: shortGroupList, loadingGroups: false });
      }
    });
  };

  loadGroups = organizationPublicId =>
    this.props.getAllGroups(organizationPublicId, false).then(groups => {
      let shortGroupList = [];
      // add 'No Groups' option
      shortGroupList.push({
        public_id: NO_GROUPS_IDENTIFIER,
        name: NO_GROUPS_IDENTIFIER,
      });
      // add 'All Group' option
      shortGroupList.push({
        public_id: ALL_GROUPS_IDENTIFIER,
        name: ALL_GROUPS_IDENTIFIER,
      });

      shortGroupList = shortGroupList.concat(groups);

      this.setState({ groups: shortGroupList, loadingGroups: false });
    });

  handleCharactersCount = () => this.state.maxCharacters - this.state.currCharacters;

  handleShareTemplate = () => {
    const { shares, group, organization } = this.state;

    // any of the shares have the selected group or org?
    const shareAlreadyExists = shares.some(share => {
      if (group !== ALL_GROUPS_IDENTIFIER && group !== NO_GROUPS_IDENTIFIER) {
        return share.groupId === group && share.orgId === '';
      } else {
        return share.groupId === group && share.orgId === organization;
      }
    });

    if (!shareAlreadyExists && organization && group) {
      if (organization === ALL_ORGS_IDENTIFIER) {
        if (group === ALL_GROUPS_IDENTIFIER) {
          // CASE 1:
          // shareAllOrg is removed when a share option is removed
          this.setState({
            shareAllOrg: true,
            shares: [
              {
                title: ALL_ORGS_IDENTIFIER,
                description: ALL_GROUPS_IDENTIFIER,
                publicId: '',
                groupId: ALL_GROUPS_IDENTIFIER,
                orgId: ALL_ORGS_IDENTIFIER,
              },
            ],
            // clean form share section data
            organization: '',
            groups: [],
            group: '',
            addShareEnabled: false,
          });
        } else if (group === NO_GROUPS_IDENTIFIER) {
          // CASE 2:
          // shareAllOrg is removed when a share option is removed
          this.setState({
            shareAllOrg: false,
            shares: [
              ...shares,
              {
                title: ALL_ORGS_IDENTIFIER,
                description: NO_GROUPS_IDENTIFIER,
                publicId: '',
                groupId: NO_GROUPS_IDENTIFIER,
                orgId: ALL_ORGS_IDENTIFIER,
              },
            ],
            // clean form share section data
            organization: '',
            groups: [],
            group: '',
            addShareEnabled: false,
          });
        }
      } else {
        if (group === ALL_GROUPS_IDENTIFIER) {
          // CASE 3:
          this.props.getOrganization(organization).then(response => {
            if (response) {
              this.setState({
                shares: [
                  ...shares,
                  {
                    title: response.name,
                    description: ALL_GROUPS_IDENTIFIER,
                    publicId: response.public_id,
                    groupId: ALL_GROUPS_IDENTIFIER,
                    orgId: organization,
                  },
                ],
                // clean form share section data
                organization: '',
                groups: [],
                group: '',
                addShareEnabled: false,
              });
            }
          });
        } else if (group === NO_GROUPS_IDENTIFIER) {
          // CASE 4:
          this.props.getOrganization(organization).then(response => {
            if (response) {
              this.setState({
                shares: [
                  ...shares,
                  {
                    title: response.name,
                    description: NO_GROUPS_IDENTIFIER,
                    publicId: response.public_id,
                    groupId: NO_GROUPS_IDENTIFIER,
                    orgId: organization,
                  },
                ],
                // clean form share section data
                organization: '',
                groups: [],
                group: '',
                addShareEnabled: false,
              });
            }
          });
        } else {
          // CASE 5:
          this.props
            .getOrganizationGroup({
              groupId: group,
              organizationId: organization,
            })
            .then(response => {
              if (response.name && response.public_id) {
                this.setState({
                  shares: [
                    ...shares,
                    {
                      title: response.name,
                      description: response.description || '',
                      publicId: response.public_id,
                      groupId: group,
                      orgId: '',
                    },
                  ],
                  // clean form share section data
                  organization: '',
                  groups: [],
                  group: '',
                  addShareEnabled: false,
                });
              }
            });
        }
      }
    } else {
      this.props.snackBarStatus({
        payload: {
          enable: true,
          title: 'That organization or group has already been selected',
          type: 'error',
        },
      });
    }
  };

  formatParentFolderOptions = () => {
    const { folders, mainUserRole } = this.props;
    let parentFolderOptions = [];

    if (folders && Array.isArray(folders)) {
      folders.forEach(f => {
        // display system folders only
        if (f.isSystemFolder) {
          if (
            f.selectable ||
            // the only global folder should be Featured folder
            (f.global && mainUserRole === ROLE_SUPER_ADMIN)
          ) {
            parentFolderOptions.push({
              text: f.name,
              value: f.publicId,
            });
          }
        }
      });
    }

    return parentFolderOptions;
  };

  handleShareRemove = shareId =>
    this.setState({ shares: this.state.shares.filter(share => share.publicId !== shareId) });

  handleCleanGroups = () => {
    this.setState({ groups: '' });
  };

  handleDelete = () => {
    this.props.updateDialog(true);
  };

  handleDeleteFolder = () => {
    const { folderPublicId } = this.props;
    this.props.deleteFolder(folderPublicId).then(result => {
      if (result) {
        this.closeSettings(true);
        this.props.updateDialog(false);
      }
    });
  };

  handleClose = () => {
    this.props.updateDialog(false);
  };

  render() {
    const { statusDialog, viewType } = this.props;
    const { groups, shares, addShareEnabled, shareAllOrg, loadingGroups } = this.state;

    const parentFolderOptions = this.formatParentFolderOptions();

    const groupOptions =
      groups && groups instanceof Array
        ? groups.map(group => ({ text: group.name, value: group.public_id }))
        : [];

    return (
      <React.Fragment>
        <FolderSettingsForm
          viewType={viewType}
          create={this.state.create}
          parentFolderOptions={parentFolderOptions}
          folderName={this.state.folderName}
          share={this.state.share}
          characters={this.handleCharactersCount()}
          comments={this.state.comments}
          selectedParent={this.state.parentFolder}
          selectedGroup={this.state.group}
          groupOptions={groupOptions}
          handleChangeParentFolder={this.handleChangeParentFolder}
          handleChangeSelectedGroup={this.handleChangeSelectedGroup}
          handleChange={this.handleChange}
          organizationsSuggestions={this.props.organizationsSuggestions}
          selectedOrganization={this.state.organization}
          handleChangeOrg={this.handleChangeOrg}
          addShareEnabled={addShareEnabled}
          shareAllOrg={shareAllOrg}
          loadingGroups={loadingGroups}
          handleShareAction={this.handleShareAction}
          organizationHandleChange={this.organizationHandleChange}
          handleSaveSettings={this.handleSaveSettings}
          organizationsSearched={this.props.organizationsSearched}
          handleShareTemplate={this.handleShareTemplate}
          onRemoveShare={this.handleShareRemove}
          shares={shares}
          handleCleanGroups={this.handleCleanGroups}
          handleDelete={this.handleDelete}
          handleClose={this.handleClose}
        />
        <Dialog
          open={statusDialog}
          onClose={this.handleClose}
          className="return-to-edit"
          maxWidth="sm"
          style={{
            backgroundColor: 'transparent',
            borderRadius: '10px',
          }}
        >
          <DialogContent className="container-warning-button">
            <div className="warning-Settings">
              <strong> WARNING:</strong> Deleting this folder will also delete all of its contents
              including templates and subfolders.
              <div>
                <strong>Are you sure you want to delete this folder?</strong>
              </div>
            </div>
            <div className="modal-actions">
              <button onClick={this.handleDeleteFolder} className="button-warning">
                <span className="text-warning">
                  Yes, delete this folder and all of its contents
                </span>
              </button>
              <button onClick={this.handleClose} className="button-warning">
                <span className="text-warning">No, I want to keep this folder</span>
              </button>
            </div>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mS = state => ({
  groups: state.groupsReducer.groups,
  organizationsSearched: state.organizationReducer.searchedOrg,
  folders: state.templateReducer.folders,
  folderPublicId: state.templateReducer.folder.publicId,
  statusDialog: state.templateReducer.statusDialog,
  cleaner: state.rightDrawerTemplatesReducer.cleaner,
  organizationsSuggestions: state.organizationReducer.organizationsSuggestions,
  currentOrganizationId: state.organizationReducer.organization.public_id,
  mainUserRole: state.userProfileReducer.main_role,
  viewType: state.responsiveReducer.viewType,
  userMainRole: state.userProfileReducer.main_role,
  templateFolderSelected: state.templateReducer.templateFolderSelected,
});

const mD = {
  enableRightDrawerTemplates,
  getFoldersList,
  createFolder,
  updateFolder,
  getAllGroups,
  allGroupsGet,
  getOrganizationGroup,
  getOrganization,
  searchOrganizationUpdateValue,
  getFolderById,
  getFolderByIdPaged,
  snackBarStatus,
  updateDialog,
  deleteFolder,
  setEnforceFocus,
  clearOrganizationsSuggestions,
  updateFolderListMove,
  updateFolderName,
};

FolderSettings.propTypes = FolderSettingsPropType;
export default connect(mS, mD)(FolderSettings);
