import React from 'react';
import moment from 'moment';
import htmlToText from 'html-to-text';
import Grid from '@material-ui/core/Grid';
import ContentEditable from 'react-contenteditable';
import publicIcon from '../../Theme/Icons/public.svg';
import moreIcon from '../../Theme/Icons/more_horiz.svg';
import memberImg from '../../Theme/Icons/member.svg';
import TemplateMessagePreviewPropType from '../../PropTypes/TemplateMessagePreviewPropType';
import './index.sass';

const parseHtml = html => {
  const elem = document.createElement('div');
  elem.style.display = 'none';
  elem.innerHTML = html;

  const text = htmlToText.fromString(html, {
    ignoreImage: true,
    preserveNewlines: true,
    format: {
      // eslint-disable-next-line
      heading: function(element, fn, options) {
        const res = fn(element.children, options);
        return ` ${res} `;
      },
    },
  });
  const image = elem.querySelector('img') ? elem.querySelector('img').src : '';

  return { text, image };
};

const TemplateFacebookPreview = ({ content, picture, profilePicture, profileName }) => {
  const facebookContent = parseHtml(content);

  return (
    <div>
      <div className="send-facebook-container">
        <div className="message-container">
          <div className="facebook-content" id="scroll">
            <div className="facebook-message show">
              <div className="facebook-header">
                <Grid item xs={1} className="member-message-facebook">
                  <img src={profilePicture || memberImg} alt="member" />
                </Grid>
                <div className="facebook-header-text">
                  <p className="facebook-name">{profileName}</p>
                  <div className="facebook-lower-header">
                    <p className="facebook-date">{moment().format('MMMM Do, h:mm:ss a')}</p>
                    <img src={publicIcon} alt="public" className="facebook-public" />
                  </div>
                </div>
                <img src={moreIcon} alt="more" className="facebook-more" />
              </div>
              <ContentEditable
                className="facebook-body-text"
                id="scroll"
                tagName="p"
                disabled
                html={content || ''}
              />
              {!!picture && (
                <div className="facebook-images">
                  <img src={picture} alt="gallery" className="gallery-image" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TemplateFacebookPreview.propTypes = TemplateMessagePreviewPropType;

export default TemplateFacebookPreview;
