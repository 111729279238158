import colors from '../../Theme/Colors';

const style = {
  calendarCustomStyle: {
    '& .mbsc-cal-day-date.mbsc-cal-cell-txt': {
      fontSize: '12px',
    },
    '& .mbsc-cal-month': {
      fontSize: '12px',
      lineHeight: '3.222223em',
    },
    '& .mbsc-cal-year': {
      fontSize: '12px',
      lineHeight: '3.222223em',
    },
    '& .mbsc-cal-cell-i.mbsc-cal-cell-txt': {
      fontSize: '12px',
    },
  },
  datePickerBorder: {
    borderBottom: 'none !important',
  },
  root: {
    '& input.datepicker-custom-input': {
      color: '#4a4a4a',
      padding: '0 !important',
      maxWidth: '100% !important',
      textIndent: '20px',
    },
  },
  rootMobiscrollSelect: {
    '& input.mbsc-control::placeholder': {
      textIndent: '20px',
    },
    '& input.mbsc-control': {
      textIndent: '20px',
      color: colors.placeholder,
      fontSize: '16px',
      fontFamily: 'GothamSSm-Medium',
      '& option': {
        textAlign: 'center',
      },
    },
  },
  rootInputPhone: {
    '& input.react-phone-number-input__input.react-phone-number-input__phone': {
      textIndent: '16px',
      textAlign: 'left',
    },
    '& input.react-phone-number-input__input.react-phone-number-input__phone::placeholder': {
      textIndent: '16px',
      textAlign: 'left',
    },
  },
  passwordFieldSpecialMargin: {
    marginTop: '10px',
  },
};

export default style;
