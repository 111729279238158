export const ROLE_USER = 'ROLE_USER';
export const ROLE_MEMBER = 'ROLE_MEMBER';
export const ROLE_EVA_MEMBER = 'ROLE_EVA_MEMBER';
export const ROLE_EVA_GROUP_ADMIN = 'ROLE_EVA_GROUP_ADMIN';
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_ORGANIZATION_ADMIN = 'ROLE_ORGANIZATION_ADMIN';
export const ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN';

export const ROLES_ADMIN = [ROLE_EVA_GROUP_ADMIN, ROLE_ADMIN, ROLE_ORGANIZATION_ADMIN, ROLE_SUPER_ADMIN];
export const ROLES_MEMBER = [ROLE_USER, ROLE_MEMBER, ROLE_EVA_MEMBER];
