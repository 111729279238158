import React from 'react';
import EvaTooltip from '../EvaTooltip';
import MessageSelectedHeaderPropType from '../../PropTypes/MessageSelectedHeaderPropType';
import './index.sass';

const MessageSelectedHeader = props => (
  <header className="message-selected-header">
    <div className="content-inner-wrapper">
      <div className="left-side">
        <h3 className="message-selected-title" dangerouslySetInnerHTML={{ __html: props.title }} />
        {!!props.subtitle && (<div className="message-selected-group-name full-view">{props.subtitle}</div>)}
        {!!props.subtitleMobile && (
          <div className="message-selected-group-name mobile-view">{props.subtitleMobile}</div>)}
      </div>
      <div className="right-side">
        {props.isArchived
          ? (
            <>
              <EvaTooltip title="Delete Message">
                <button className="icon-button message-delete" onClick={props.onDelete} />
              </EvaTooltip>
              <EvaTooltip title="Unarchive Message">
                <button className="icon-button message-unarchive" onClick={props.onUnArchive} />
              </EvaTooltip>
            </>
          )
          : (
            <EvaTooltip title="Archive Message">
              <button className="icon-button message-archive" onClick={props.onArchive} />
            </EvaTooltip>
          )}
      </div>
    </div>
  </header>
);

MessageSelectedHeader.propTypes = MessageSelectedHeaderPropType;

MessageSelectedHeader.defaultProps = {
  title: '',
  subtitle: '',
  subtitleMobile: '',
  isArchived: false,
};

export default MessageSelectedHeader;
