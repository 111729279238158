const styles = {
  customRightDrawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    width: '75px',
    marginRight: '22px',
    '&>.member-img': {
      width: '35px',
      height: '35px',
      borderRadius: '50%',
      overflow: 'hidden',
      '&>img': {
        height: '100%',
      },
    },
  },
};

export { styles };
