import Organizations from '../Api/Organizations';
import Groups from '../Api/Groups';
import snackBarStatus from './snackbarActions';
import { mainStatusLoading } from './mainLoadingActions';
import { getFirstAvailableOrg } from '../Helpers/organizations';
import { getUserProfile } from './userActions';
import { logout } from './loginActions';
import Facebook from '../Api/Facebook';
import { removeGroupsFbConnections } from './groupsActions';

// TODO: divide, add organizationsDictionary
export const ACTIONS = {
  SELECT: '[Organization] Select',
  CLEAR_ORGANIZATION_SUGGESTIONS: '[Organization] Clear organizations suggestions',
  ERROR: '[Organization] Error',
  LOAD: '[Organization] Load organization',
  LOAD_ALL: '[Organization] Load organizations',
  LOAD_ORGANIZATION_GROUP: '[Organization] Load organization group',
  LOAD_ORGANIZATION_GROUPS: '[Organization] Load organization groups',
  LOAD_ORGANIZATION_MEMBER: '[Organization] Load organization member',
  LOADING_GET_ALL: '[Organization] Loading list',
  LOADING_FB_CONNECTION: '[Organization] Loading FB connection',
  LOAD_ORGANIZATION_LOOKUP: '[Organization] Set organizations lookup',
  SEARCH_ORGANIZATION_UPDATE_VALUE: '[Organization] Search organization update value',
  SEARCH_ORGANIZATION_UPDATE_VALUE_ID: '[Organization] Search organization update value id',
  SET_ORGANIZATION_SUGGESTIONS: '[Organization] Set organizations suggestions',
  SET_ORG_ADMINS: '[Organization] Set org admins',
  UPDATE_ORG_ADMIN_REPLY_NOTIFICATION: '[Organization] Update org admin reply notification',
  ORG_FB_UPDATE: '[Organization] Update organization facebook links data',
};

export const getOrganizations = () => async (dispatch, getState) => {
  mainStatusLoading(true)(dispatch);
  const { organizations, organizationsListLoaded } = getState().organizationReducer;
  try {
    dispatch({ type: ACTIONS.LOADING_GET_ALL });

    if (!organizationsListLoaded) {
      const response = await Organizations.get();
      let organizationsList = [];
      if (response.status === 200) {
        organizationsList = response.data.data;
        dispatch({
          type: ACTIONS.LOAD_ALL,
          payload: organizationsList,
        });
      }
      mainStatusLoading(false)(dispatch);
      return organizationsList;
    } else {
      mainStatusLoading(false)(dispatch);
      return Promise.resolve(organizations);
    }
  } catch (error) {
    mainStatusLoading(false)(dispatch);
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const getOrgAdmins = groupId => async dispatch => {
  try {
    const response = await Groups.getOrgAdmins(groupId);

    if (response.status === 200) {
      dispatch({ type: ACTIONS.SET_ORG_ADMINS, payload: response.data.data });
      return response.data.data;
    }
    return [];
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    throw error;
  }
}

export const updateOrgAdminReplyNotification = (groupId, adminId, isReplyNotification) => async dispatch => {
  try {
    const response = await Groups.updateOrgAdminReplyNotification(groupId, adminId, isReplyNotification);
    if (response.status === 200) {
      dispatch({
        type: ACTIONS.UPDATE_ORG_ADMIN_REPLY_NOTIFICATION,
        payload: adminId,
      });
      snackBarStatus({
        payload: {
          title: response.data.message,
          type: 'success',
          enable: true,
        },
      })(dispatch);
    }
    return response.data;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
}

export const getOrganizationsLookup = () => async dispatch => {
  try {
    let organizations = [];
    const response = await Organizations.getOrganizationsLookup();
    if (response.status === 200) {
      organizations = response.data.data.organizations;
      dispatch({
        type: ACTIONS.LOAD_ORGANIZATION_LOOKUP,
        payload: organizations,
      });
    }
    return organizations;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export function searchOrganizationUpdateValue(value) {
  if (typeof value === 'object' && value.length > 0) {
    return dispatch => {
      dispatch({
        type: ACTIONS.SEARCH_ORGANIZATION_UPDATE_VALUE,
        payload: value[0],
      });
    };
  }
  return dispatch => {
    dispatch({
      type: ACTIONS.SEARCH_ORGANIZATION_UPDATE_VALUE,
      payload: value,
    });
  };
}

export function searchOrganizationUpdateValueId(value) {
  return dispatch => {
    dispatch({
      type: ACTIONS.SEARCH_ORGANIZATION_UPDATE_VALUE_ID,
      payload: value,
    });
  };
}

export function clearOrganizationsSuggestions() {
  return dispatch => {
    dispatch({
      type: ACTIONS.CLEAR_ORGANIZATION_SUGGESTIONS,
    });
  };
}

export const setOrganizationsSuggestions = suggestions => ({
  type: ACTIONS.SET_ORGANIZATION_SUGGESTIONS,
  payload: { suggestions },
});

export const getOrganization = organizationId => async dispatch => {
  try {
    const response = await Organizations.getOrg(organizationId);
    let organization = {};
    if (response.status === 200) {
      organization = response.data.data;
      dispatch({
        type: ACTIONS.LOAD,
        payload: { ...organization },
      });
    }
    return organization;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const getOrganizationGroup = ({ organizationId, groupId }) => async dispatch => {
  try {
    const response = await Organizations.getGroup(organizationId, groupId);
    let group = {};
    if (response.status === 200) {
      group = response.data.data;
      dispatch({
        type: ACTIONS.LOAD_ORGANIZATION_GROUP,
        payload: group,
      });
    }
    return group;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const resyncCaminoGroups = (organizationId) => async dispatch => {
  try {
    const response = await Organizations.resyncCaminoGroups(organizationId);
    let group = {};
    if (response.status === 200) {
      snackBarStatus({
        payload: {
          enable: true,
          title: response.data.message,
          type: 'success',
        },
      })(dispatch);
    }
    return group;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    return error;
  }
};

export const getOrganizationMember = (organization, member) => async dispatch => {
  if (!organization || !member) return null;

  try {
    const response = await Organizations.getOrganizationMember(organization, member);
    const { status } = response;
    let { data: { data } } = response;

    if (status === 200) {
      if (!data) {
        data = { temporary_admin: false, already_exist_admin: false };
      }

      dispatch({ type: ACTIONS.LOAD_ORGANIZATION_MEMBER, payload: data });
    }

    return data;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);

    return error;
  }
};

export const selectOrganization = selectedOrganization => dispatch => {
  if (selectedOrganization && selectedOrganization.status !== 'inactive') {
    localStorage.setItem('selectedOrgId', selectedOrganization.id);
    localStorage.setItem('selectedOrgPublicId', selectedOrganization.public_id);
    dispatch({
      type: ACTIONS.SELECT,
      payload: selectedOrganization,
    });

    getUserProfile(selectedOrganization.public_id)(dispatch);

  } else {
    localStorage.removeItem('selectedOrgId');
    localStorage.removeItem('selectedOrgPublicId');
    logout()(dispatch);
  }
};

export const selectFirstAvailableOrg = () => (dispatch, getState) => {
  const { organizations } = getState().organizationReducer;
  selectOrganization(getFirstAvailableOrg(organizations))(dispatch);
};

export const selectOrganizationById = id => (dispatch, getState) => {
  const { organizations } = getState().organizationReducer;

  if (!organizations || organizations.length === 0) {
    console.error('No organizations fetched');
    return;
  }

  const selectedOrg = organizations.find(o => o.organization.id === Number(id));
  if (!selectedOrg || (selectedOrg && selectedOrg.organization.status === 'inactive')) {
    selectFirstAvailableOrg()(dispatch, getState);
  } else {
    selectOrganization(selectedOrg.organization)(dispatch);
  }
};

export const getOrganizationFacebookLinking = () => async (dispatch, getState) => {
  const { organizationReducer: { organization: { public_id } } } = getState();

  try {
    updateFbLoading(true)(dispatch);
    const response = await Facebook.getOrgFbPages(public_id);
    if (response.status === 200) {

      const data = response.data.data;

      dispatch({
        type: ACTIONS.ORG_FB_UPDATE,
        payload: {
          isFbLinked: !!data,
          isFbExpired: data ? data.status === 'expired' : false,
          fbPages: data ? data.pages : [],
        },
      });
    }
    updateFbLoading(false)(dispatch);
    return response.data;
  } catch (error) {
    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);
    updateFbLoading(false)(dispatch);
    return error;
  }
};

export const submitOrgFbToken = accessToken => async (dispatch, getState) => {

  const { organizationReducer: { organization: { public_id } } } = getState();

  try {
    const response = await Facebook.submitOrgFbToken(public_id, accessToken);
    if (response.status === 200) {
      const { status, pages } = response.data.data;
      dispatch({
        type: ACTIONS.ORG_FB_UPDATE,
        payload: {
          isFbLinked: true,
          isFbExpired: status === 'expired',
          fbPages: pages,
        },
      });
    }

    return response;
  } catch (error) {

    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);

    return error;
  }
};

export const updateFbLoading = bool => dispatch => {
  dispatch({
    type: ACTIONS.LOADING_FB_CONNECTION,
    payload: bool,
  });
}

export const removeOrgFbConnection = () => async (dispatch, getState) => {

  const { organizationReducer: { organization: { public_id } } } = getState();

  try {

    updateFbLoading(true)(dispatch);
    const response = await Facebook.removeOrgFbConnection(public_id);

    if (response.status === 200) {
      dispatch({
        type: ACTIONS.ORG_FB_UPDATE,
        payload: {
          isFbLinked: false,
          isFbExpired: false,
          fbPages: [],
        },
      });

      removeGroupsFbConnections()(dispatch);
      updateFbLoading(false)(dispatch);
    }

    return response;
  } catch (error) {

    snackBarStatus({
      payload: {
        title: error.message,
        type: 'error',
        enable: true,
      },
    })(dispatch);

    updateFbLoading(false)(dispatch);

    return error;
  }
};
