import { ACTIONS } from '../Actions/messagesActions';

const initialState = {
  selectedMessage: null,
  messages: [],
  isMessageLoadings: false,
  isLoadedMessages: false,
  isLoadingMoreMessages: false,
  messagesListSelected: [],
  error: '',
  code: '',
  makeFeatured: '',
  publicToAdmin: '',
  typeMessageSelected: '',
  typeSelected: '',
  optionsFilter: {
    draft: { text: 'Draft', isSelected: false },
    archived: { text: 'Archived', isSelected: false },
    schelduled: { text: 'Schelduled', isSelected: false },
  },
  replyNotificationsCounter: null,
  messagesSevenDays: null,
  statisticsThreadMessages: null,
  messagesPagination: [],
};

/* eslint-disable */
function reducerFunctionGen(key) {
  return function (prev, elem) {
    const { _id, lastMessage } = elem;
    if (!lastMessage) {
      prev.push(elem);
      return prev;
    }
    if (_id === key) {
      const newElem = { ...elem };
      newElem.lastMessage.updatedAt = new Date().toISOString();
      prev.push(newElem);
      return prev;
    }
    prev.push(elem);
    return prev;
  };
}

const messagesReducers = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_MESSAGES:
      return { ...state, messages: action.payload };

    case ACTIONS.GET_MORE_MESSAGES:
      return { ...state, messages: state.messages.concat(action.payload) }

    case ACTIONS.CLEAR_MESSAGES:
      return { ...state, messages: [], isMessageLoadings: false, isLoadedMessages: false };
    case ACTIONS.MESSAGES_LOADING:
      return { ...state, isMessageLoadings: action.payload };
    case ACTIONS.MESSAGES_LOADED:
      return { ...state, isLoadedMessages: action.payload };
    case ACTIONS.MESSAGES_MORE_LOADING:
      return { ...state, isLoadingMoreMessages: action.payload };

    case ACTIONS.UPDATE_IN_LIST:
      return {
        ...state,
        messages: state.messages.map(m => m.publicId === action.payload.publicId ? { ...m, ...action.payload } : m)
      };

    case ACTIONS.CANCEL_SCHEDULED_IN_LIST:
      return {
        ...state,
        messages: state.messages.map(m => m.publicId === action.payload ? { ...m, status: 'draft' } : m)
      }

    case ACTIONS.CHECK: {

      let messagesListSelected = [...state.messagesListSelected];
      if (messagesListSelected.find(m => m.publicId === action.payload)) {
        messagesListSelected = messagesListSelected.filter(m => m.publicId !== action.payload)
      } else {
        const fullMessage = state.messages.find(m => m.publicId === action.payload);
        const { publicId, isArchived } = fullMessage;
        messagesListSelected.push({ publicId, isArchived });
      }

      return {
        ...state,
        messagesListSelected,
      };
    }
    case ACTIONS.CLEAR_CHECKED:
      return { ...state, messagesListSelected: [] };
    case ACTIONS.CLEAR_SELECTED_MESSAGE:
      return { ...state, selectedMessage: initialState.selectedMessage };
    case ACTIONS.ERROR:
      return { ...state, error: action.payload };
    case ACTIONS.SET_CODE:
      return { ...state, code: action.payload };
    case ACTIONS.SELECT:
      return {
        ...state,
        messages: state.messages.map(m => m.publicId === action.payload.publicId ? { ...m, isUnread: false } : m), // set as read
        selectedMessage: action.payload
      };
    case ACTIONS.SEND_MESSAGE:
      return { ...state };
    case ACTIONS.SELECT_TYPE:
      return { ...state, typeMessageSelected: action.payload };
    case ACTIONS.SELECT_TYPE_MESSAGE:
      return { ...state, typeSelected: action.payload };
    case ACTIONS.UPDATE_FILTER_MESSAGE:
      return { ...state, optionsFilter: action.payload };
    case ACTIONS.SENT_MESSAGES_LAST_7_DAYS:
      return { ...state, messagesSevenDays: action.payload };
    case ACTIONS.SET_PENDING_REPLIES:
      return { ...state, replyNotificationsCounter: action.payload };
    case ACTIONS.UPDATE_LAST_MESSAGE_DATE: {
      const { messages } = state;
      const {
        payload: { message },
      } = action;
      // eslint-disable-next-line
      const newMessages = messages.reduce(reducerFunctionGen(message._id), []);
      return { ...state, messages: newMessages };
    }

    // case ACTIONS.CANCEL_SCHEDULED_MESSAGE: {
    //   const { payload: newMessage } = action;
    //   const { selectedMessage } = state;
    //   const newSelectedMessage = {
    //     ...selectedMessage,
    //     status: 'draft',
    //   };
    //   const newMessages = state.messages.map(updateMessageList(newMessage));
    //   return {
    //     ...state,
    //     selectedMessage: newSelectedMessage,
    //     messages: newMessages,
    //   };
    // }
    case ACTIONS.GET_STATISTICS_MESSAGE:
      return { ...state, statisticsThreadMessages: action.payload };
    case ACTIONS.MESSAGES_PAGINATION:
      return { ...state, messagesPagination: action.payload };
    default:
      return state;
  }
};

export default messagesReducers;
