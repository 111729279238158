import React, { Component } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import TreeNavigatorMultipleItemPropTypes from '../../PropTypes/TreeNavigatorMultipleItemPropTypes';
import TreeNavigatorSingleItem from './TreeNavigatorSingleItem';

import TreeNavigator from '.';

import './styles.sass';

class TreeNavigatorMultipleItem extends Component {
  state = {
    isSelected: this.props.treeItem.isSelected,
    treeItem: this.props.treeItem,
  };

  // Open parent folder if child selected.
  static getDerivedStateFromProps = (nextProps) => ({
    isSelected: nextProps.treeItem.isSelected ||
      nextProps.treeItem.childrens.some(item => item.isSelected),
  });

  handleChange = () => {
    const nextState = !this.state.isSelected;
    this.setState({
      isSelected: nextState,
    });
    this.state.treeItem.isSelected = nextState;
  };

  render = () => {
    const { treeItem, selectedTreeItem } = this.props;
    const { isSelected } = this.state;

    return (
      <Accordion expanded={isSelected} className="tree-navigator-expansion-panel">
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              className={`expand-icon-tree ${isSelected ? 'icon-expanded' : ''}`}
            />
          }

          className="tree-navigator-expansion-panel-summary"
        >
          <TreeNavigatorSingleItem
            selectedTreeItem={selectedTreeItem}
            treeItem={treeItem}
            handleTreeItemClick={this.props.handleTreeItemClick || null}
            className="tree-navigator-item-wrapper"
          />
        </AccordionSummary>

        {treeItem.childrens && treeItem.childrens.length > 0 && (
          <AccordionDetails className="tree-navigator-expansion-panel-details">
            <TreeNavigator
              childrenTree
              className="tree-navigator-panel"
              tree={treeItem.childrens}
              handleTreeItemClick={this.props.handleTreeItemClick || null}
            />
          </AccordionDetails>
        )}
      </Accordion>
    );
  };
}

TreeNavigatorMultipleItem.propTypes = TreeNavigatorMultipleItemPropTypes;

export default TreeNavigatorMultipleItem;
