import { ACTIONS } from '../Actions/memberActions';

// fromGroup = true (action from a group) and fromGroup = false (action from the compose button)
const initialState = {
  hasEmail: true,
  error: '',
  success: '',
  fromGroup: true,
  listMembers: [],
  selectedMember: {
    address: null,
    birthDate: null,
    cityText: null,
    email: null,
    firstName: null,
    groups: [],
    lastName: null,
    phoneNumber: null,
    postalCode: null,
    profilePictureUrl: null,
    publicId: null,
    countryPublicId: '',
    statePublicId: '',
  },
  invitationId: '',
  type: 'group',

  memberGroups: [],
  loading: false,
  showChangePassword: true,
  displayEditMemberForm: false,
  /* Intended to be used as a read only value. (David Klie)
    Used In EditMember.jsx components to avoid dirty reads when editing
    data.
  */
};

const memberReducer = (state = initialState, action) => {
  const {
    firstName,
    lastName,
    birthday,
    phoneNumber,
    email,
    hasEmail,
    mainPhoneNumber,
    countryPublicId,
    statePublicId,
    error,
    success,
  } = initialState;
  switch (action.type) {
    case ACTIONS.CREATE:
      return { ...state, ...action.payload };

    case ACTIONS.UPDATE_MEMBER:
      return {
        ...state,
        selectedMember: {
          ...state.selectedMember,
          ...action.payload,
        },
      };

    case ACTIONS.GET_MEMBER_FULL_PROFILE:
      return {
        ...state,
        selectedMember: {
          ...initialState.selectedMember,
          ...action.payload,
        },
      };

    case ACTIONS.ERROR:
      return { ...state, error: action.payload };

    case ACTIONS.SUCCESS:
      return {
        ...state,
        firstName,
        lastName,
        birthday,
        phoneNumber,
        mainPhoneNumber,
        hasEmail,
        email,
        countryPublicId,
        statePublicId,
        error,
      };
    case ACTIONS.SUCCESS_CLEAR:
      return {
        ...state,
        firstName,
        lastName,
        birthday,
        phoneNumber,
        mainPhoneNumber,
        hasEmail,
        email,
        countryPublicId,
        statePublicId,
        error,
        success,
      };
    case ACTIONS.CLEAR:
      return {
        ...state,
        firstName,
        lastName,
        birthday,
        phoneNumber,
        mainPhoneNumber,
        hasEmail,
        email,
        countryPublicId,
        statePublicId,
        error,
        success,
      };
    case ACTIONS.UPDATE_PREFERENCE: {
      const { groupId, options } = action.payload;
      return {
        ...state,
        selectedMember: {
          ...state.selectedMember,
          groups: state.selectedMember.groups.map(group =>
            group.publicId === groupId ? ({
              ...group,
              ...options,
            }) : group
          ),
        }
      };
    }
    case ACTIONS.CLEAR_SHOW_MEMBER:
      return initialState;
    case ACTIONS.GET_MEMBER_PROFILE:
      return {
        ...state, selectedMember: {
          ...state.selectedMember,
          ...action.payload
        },
      };

    case ACTIONS.JOIN_GROUP:
      return {
        ...state,
        invitationId: { ...action.payload },
      };
    case ACTIONS.UPDATE_MEMBER_GROUPS:
      return {
        ...state, selectedMember: {
          ...state.selectedMember,
          groups: action.payload,
        }
      };
    case ACTIONS.UPDATE_MEMBER_PICTURE:
      return {
        ...state, selectedMember: {
          ...state.selectedMember,
          profilePictureUrl: action.payload,
        }
      };

    case ACTIONS.STATUS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ACTIONS.DISPLAY_CHANGE_PASSWORD:
      return {
        ...state,
        showChangePassword: action.payload,
      };
    case ACTIONS.DISPLAY_EDIT_MEMBER_FORM:
      return {
        ...state,
        displayEditMemberForm: action.payload,
      };
    case ACTIONS.REVOKE_TEMPORARY_ADMIN_PRIVILEGES:
      return { ...state, ...action.payload };
    /* eslint-disable */
    case ACTIONS.REMOVE_MEMBER_FROM_GROUP:
      return {
        ...state,
        selectedMember: {
          ...state.selectedMember,
          groups: state.selectedMember.groups.filter(g => g.publicId !== action.payload),
        }
      };

    case ACTIONS.RESTORE_SELECTED_MEMBER:
      return {
        ...state,
        selectedMember: {
          ...state.selectedMember,
          ...action.payload,
        }
      }

    case ACTIONS.UPDATE_EMAIL_BOUNCED_STATUS:
      return {
        ...state,
        selectedMember: {
          ...state.selectedMember,
          isEmailBounced: action.payload.isEmailBounced,
          bouncedMessage: action.payload.bouncedMessage,
        }
      };

    /* eslint-enable */
    default:
      return state;
  }
};

export default memberReducer;
