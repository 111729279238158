import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment';
import {
  // getStatistics,
  getStatisticsMetrics } from '../../Actions/groupsActions';
import './index.sass';
import ButtonSelect from '../../Components/ButtonSelect';
import { SORT_STATS } from '../../Config/Constants';
import { Datepicker } from '@mobiscroll/react';

const getPercentage = (value, total) => ((value && total) ? Math.round((value / total) * 100) : 0);

class Statistics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rangeValue: [],
      date: null,
      displayCalRange: false,
      itemSelected: 'month',
    };
  }

  UNSAFE_componentWillMount() {
    const { selectedGroup } = this.props;
    const { itemSelected } = this.state;
    // this.props.getStatistics(selectedGroup.public_id);
    this.props.getStatisticsMetrics(itemSelected, selectedGroup.public_id);
  }

  handleClick = type => {
    const { selectedGroup } = this.props;
    this.setState({
      itemSelected: type,
      displayCalRange: type === 'custom',
    });
    if (type !== 'custom') {
      this.props.getStatisticsMetrics(type, selectedGroup.public_id);
    }
  };

  handleCalendar = ({ value }) => {
    const { selectedGroup } = this.props;
    const { itemSelected } = this.state;
    this.setState({ rangeValue: value });
    this.props.getStatisticsMetrics(itemSelected, selectedGroup.public_id, {
      startDate: moment(value[0]).format('YYYY-MM-DD'),
      endDate: moment(value[1]).format('YYYY-MM-DD'),
    });
  };

  render() {
    const { displayCalRange, rangeValue } = this.state;
    const {
      selectedGroup,
      statisticsMetrics,
    } = this.props;
    const WindowHeight = window.innerHeight - 140;

    /* eslint-disable */
    const data = statisticsMetrics.statisticsMetrics;
    /* eslint-enable */
    if (statisticsMetrics && data) {
      const emails = data && data.emails;
      const sms = data && data.sms;
      const members = data && data.members;
      const clickRates = data && data.clickRates;
      const unsubscribes = data && data.unsubscribes;
      const emailsOpened =
        emails && emails.openend > 0 && emails.sent > 0
          ? ((emails.openend * 100) / emails.sent).toFixed(0)
          : 0;
      const totalClickRates = clickRates ? Object.values(clickRates).reduce((a, b) => a + b) : 0;

      const clickPercentageEmail = getPercentage(clickRates.email, emails.sent);
      const clickPercentageSms = getPercentage(clickRates.sms, sms.sent);
      return (
        <Scrollbars autoHeight autoHide autoHeightMin={WindowHeight} autoHeightMax={WindowHeight}>
          <section id="statistics" className="statistics">
            <div className="title-wrapper subtitle-with-icon">
              <h3 className="title">Statistics</h3>
              <div className="subtitle">
                {/*<i className={`status-statistics status-${selectedStatistics.activityStatus} `}/>*/}
                <div>{selectedGroup.name}</div>
              </div>
            </div>

            <div className="select-statistics">
              <ButtonSelect
                className="button-sort"
                values={SORT_STATS}
                defaultValue="month"
                onChange={this.handleClick}
              />
            </div>
            <Grid item xs={12}>
              {displayCalRange && (
                <Datepicker
                  value={rangeValue}
                  controls={['calendar']}
                  select="range"
                  onChange={this.handleCalendar}
                  headerText="{value}"
                  placeholder="MM/DD/YY - MM/DD/YY"
                  buttons={['set', 'cancel']}
                  returnFormat="iso8601"
                  max={new Date()}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <h3 className="title-sub-eva">Messages</h3>
            </Grid>
            <Grid item xs={12} className="messages-content">
              <Grid container spacing={0}>
                <Grid item xs={4}>
                  <i className="message-statistics-icon"/>
                </Grid>
                <Grid item xs={8}>
                  <p className="total-messages">Total Messages</p>
                  <p className="total-messages-result">
                    {data.totalMessages ? data.totalMessages : 0}
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className="messages-groups">
              <Grid container spacing={0}>
                <Grid item xs={6} className="messages-title">
                  Messages sent
                </Grid>
                <Grid item xs={6} className="messages-info">
                  {data.messagesSent ? data.messagesSent : 0}
                </Grid>
                <Grid item xs={6} className="messages-title">
                  Member replies
                </Grid>
                <Grid item xs={6} className="messages-info">
                  {data.memberReplies ? data.memberReplies : 0}
                </Grid>
                <Grid item xs={12} className="message-title">
                  Email
                </Grid>
                <Grid item xs={6} className="messages-title">
                  Emails sent
                </Grid>
                <Grid item xs={6} className="messages-info">
                  {emails && emails.sent ? emails.sent : 0}
                </Grid>
                <Grid item xs={6} className="messages-title">
                  Emails delivered
                </Grid>
                <Grid item xs={6} className="messages-info">
                  {emails && emails.delivered ? emails.delivered : 0}
                </Grid>
                {/* <Grid item xs={6} className="messages-title"> */}
                {/* Emails undelivered */}
                {/* </Grid> */}
                {/* <Grid item xs={6} className="messages-info"> */}
                {/* {emails && emails.undelivered ? emails.undelivered : 0} */}
                {/* </Grid> */}
                <Grid item xs={6} className="messages-title">
                  Emails bounced
                </Grid>
                <Grid item xs={6} className="messages-info">
                  {emails && emails.bounced ? emails.bounced : 0}
                </Grid>
                <Grid item xs={6} className="messages-title">
                  Emails opened
                </Grid>
                <Grid item xs={6} className="messages-info">
                  {emails && emails.openend ? emails.openend : 0}{' '}
                  <span className="select-stadistics">|</span> {emailsOpened}%
                </Grid>
                <Grid item xs={12} className="message-title">
                  SMS
                </Grid>
                <Grid item xs={6} className="messages-title">
                  SMS sent
                </Grid>
                <Grid item xs={6} className="messages-info">
                  {sms && sms.sent ? sms.sent : 0}
                </Grid>
                <Grid item xs={6} className="messages-title">
                  SMS delivered
                </Grid>
                <Grid item xs={6} className="messages-info">
                  {sms && sms.delivered ? sms.delivered : 0}
                </Grid>
                <Grid item xs={6} className="messages-title">
                  SMS undelivered
                </Grid>
                <Grid item xs={6} className="messages-info">
                  {sms && sms.undelivered ? sms.undelivered : 0}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <h3 className="title-sub-eva">Members</h3>
            </Grid>
            <Grid item xs={12} className="users-content">
              <Grid container spacing={0}>
                <Grid item xs={4}>
                  <i className="user-statistics-icon"/>
                </Grid>
                <Grid item xs={8}>
                  <p className="total-messages">Current Members</p>
                  <p className="total-messages-result">{members && members.currentMembers}</p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className="users-groups">
              <Grid container spacing={0}>
                <Grid item xs={6} className="users-title">
                  New members
                </Grid>
                <Grid item xs={6} className="users-info">
                  {members && members.newMembers}
                </Grid>
                <Grid item xs={6} className="users-title">
                  Members previous
                </Grid>
                <Grid item xs={6} className="users-info">
                  {members && members.memberPrevious}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <h3 className="title-sub-eva">Click rates</h3>
            </Grid>
            <Grid item xs={12} className="clicks-rates">
              <Grid container spacing={0}>
                <Grid item xs={6} className="clicks-title">
                  Email click through
                </Grid>
                <Grid item xs={6} className="clicks-info">
                  {clickRates && clickRates.email}<span className="select-stadistics"> | </span>{clickPercentageEmail}%
                </Grid>
                <Grid item xs={6} className="clicks-title">
                  SMS click through
                </Grid>
                <Grid item xs={6} className="clicks-info">
                  {clickRates && clickRates.sms}<span className="select-stadistics"> | </span>{clickPercentageSms}%
                </Grid>
                <Grid item xs={6} className="clicks-title">
                  Web click through
                </Grid>
                <Grid item xs={6} className="clicks-info">
                  {clickRates && clickRates.html}
                </Grid>
                <Grid item xs={6} className="clicks-title">
                  Total click throughs
                </Grid>
                <Grid item xs={6} className="clicks-info">
                  {totalClickRates}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <h3 className="title-sub-eva">Unsubscribes</h3>
            </Grid>
            <Grid item xs={12} className="unsubscribes">
              <Grid container spacing={0}>
                <Grid item xs={6} className="unsubscribes-title">
                  Email
                </Grid>
                <Grid item xs={6} className="unsubscribes-info">
                  {unsubscribes && unsubscribes.email}
                </Grid>
                <Grid item xs={6} className="unsubscribes-title">
                  SMS
                </Grid>
                <Grid item xs={6} className="unsubscribes-info">
                  {unsubscribes && unsubscribes.sms}
                </Grid>
              </Grid>
            </Grid>
          </section>
        </Scrollbars>
      );
    }
    return null;
  }
}

const mS = state => ({
  selectedGroup: state.groupsReducer.selectedGroup,
  // selectedStatistics: state.groupsReducer.statisticsGroups.groupStat,
  // activeSubGroups: state.groupsReducer.statisticsGroups.activeSubGroups,
  // engagedSubGroups: state.groupsReducer.statisticsGroups.engagedSubGroups,
  // staticSubGroups: state.groupsReducer.statisticsGroups.staticSubGroups,
  // inactiveSubGroups: state.groupsReducer.statisticsGroups.inactiveSubGroups,
  periodTypeSelected: state.groupsReducer.periodTypeSelected,
  statisticsMetrics: state.groupsReducer.statisticsMetrics,
});

const mD = {
  // getStatistics,
  getStatisticsMetrics,
};

export default connect(
  mS,
  mD,
)(Statistics);
