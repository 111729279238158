import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import AvatarPropTypes from '../../PropTypes/AvatarPropTypes';
import memberImg from '../../Theme/Icons/member.svg';
import styles from './styles';
import { DEFAULT_SIZE } from '../../Config/Constants';

const Avatar = ({
  classes,
  image,
  className = '',
  defaultImg = memberImg,
  borderless = false,
  borderSize = DEFAULT_SIZE,
  bigAvatar = false,
  forceReloadValue,
  preventForceReload = false, // TODO: refactoring
}) => {
  let queryString = '';
  if (!preventForceReload && forceReloadValue) {
    queryString = `?val=${forceReloadValue}`;
  }
  const { avatar, avatarImg, defaultBg, noBorder, bigAvatar: bigAvatarClass } = classes;
  const bgColor = !image && defaultBg;
  const border = borderless && noBorder;
  const borderWidth = classes[borderSize];
  const src = !image ? defaultImg : `${image}${queryString}`;
  const avatarClassName = `member-avatar ${className} ${
    bigAvatar ? bigAvatarClass : avatar
  } ${bgColor} ${border} ${borderWidth}`;

  const handleError = e => {
    e.target.src = defaultImg;
  };

  return (
    <div className={avatarClassName}>
      <img alt="Avatar" className={avatarImg} onError={handleError} src={src} />
    </div>
  );
};

Avatar.propTypes = AvatarPropTypes;

const mapStateToProps = ({ avatarReducer: { forceReload } }) => ({
  forceReloadValue: forceReload,
});

const ConnectedAvatar = connect(mapStateToProps)(Avatar);

export default withStyles(styles)(ConnectedAvatar);
