import { ACTIONS } from '../Actions/rightDrawerGroupMessagesActions';

const initialState = {
  enable: false,
  element: null,
  title: 'Drawer Title',
  secondTitle: 'Second Title',
  cleaner: null,
  isBackButton: false,
  BackElement: null,
  BackSecondTitleElement: null,
  backTitle: '',
  handleDrawer: null,
  disableEnforceFocus: false,
};

const rightDrawerMessageReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.ENABLE_MESSAGE:
      return {
        ...state,
        ...action.payload,
      };
    case ACTIONS.SET_ENFORCE_FOCUS:
      return {
        ...state,
        disableEnforceFocus: !action.payload.enforceFocus,
      };
    default:
      return state;
  }
};

export default rightDrawerMessageReducer;
