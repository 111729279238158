export * from './Avatars';
export * from './FoldersSettings';
export * from './Dropdowns';
export * from './GroupSettings';
export * from './Messages';
export * from './URLs';
export * from './UserRoles';
export * from './ViewMember';
export * from './Templates';
export * from './Responsive';

export const MAX_IMAGE_FILE_SIZE = 10485760; // 10 MiB
export const ALPHABET = 'abcdefghijklmnopqrstuvwxyz#'.split('');

export const MEMBERS_COUNT_FOR_DEBOUNCE = 200; // count of members more than debounce start working
export const REQUEST_CANCELLED_MESSAGE = 'Operation canceled due to new request.';
export const defaultCountryId = '39a02f9eaad0f969641b4d072b8540da40fda7cb'; // TODO: avoid hardcoded value
