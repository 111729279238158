import PropTypes from 'prop-types';

const MessageThreadItemPropType = {
  highlighted: PropTypes.bool,
  rtl: PropTypes.bool,
  fullWidth: PropTypes.bool,
  date: PropTypes.string.isRequired,
  userName: PropTypes.string,
  userImage: PropTypes.string,
  messageHtml: PropTypes.string.isRequired,
};

export default MessageThreadItemPropType;
