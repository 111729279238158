import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import Proptypes from 'prop-types';
import { connect } from 'react-redux';
import snackBar from '../../Actions/snackbarActions';
import ConfirmationMessage from './ConfirmationMessage';
import RejectionNotice from './RejectionNotice';
import {
  handleDialogStatus,
  cancelScheduledMessage,
  canCancelScheduledMessage,
  changeConfirmCancelStatus,
} from '../../Actions/messageActions';
import { navigationControllerRedirect } from '../../Actions/navigationControllerActions';
import styles from './styles';

class CancelScheduledMessageDialog extends React.Component {
  static propTypes = {
    dialogOpen: Proptypes.bool,
    messageObject: Proptypes.instanceOf(Object).isRequired,
    handleDialogStatus: Proptypes.func.isRequired,
    classes: Proptypes.instanceOf(Object).isRequired,
  };

  /**
   * By default the dialog component should be closed.
   */
  static defaultProps = {
    dialogOpen: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      // Displays the Yes/No Dialog
      promptConfirmation: true,
      // Displays the Warning notice indicating the operation was
      // not authorized and a button to acknowledge this status. (Closes the dialog.)
      promptRequestRejection: false,
    };
  }

  /**
   * Sets the dialog state to false to hide the dialog/modal
   */
  handleClose = () => {
    this.props.handleDialogStatus(false);
    setTimeout(() => {
      this.setState({ promptRequestRejection: false, promptConfirmation: true });
      this.props.changeConfirmCancelStatus(true);
    }, 100);
  };

  /**
   * Executed when the user wants to cancel the scheduled message.
   */
  handlePromptConfirmation = async () => {
    const {
      messageObject,
      snackBar: snackBar_,
      changeConfirmCancelStatus: changeConfirmCancelStatus_,
      cancelScheduledMessage,
      canCancelScheduledMessage,
    } = this.props;
    try {
      let result = await canCancelScheduledMessage(messageObject.publicId);
      if (!result) {
        this.setState({ promptRequestRejection: true, promptConfirmation: false });
        changeConfirmCancelStatus_(false);
        return;
      }
      result = await cancelScheduledMessage(messageObject.publicId);
      this.props.navigationControllerRedirect('/messages');
      if (result) {
        snackBar_({
          payload: {
            enable: true,
            title: 'Message has been removed from scheduled messages.',
            type: 'success',
          },
        });
        this.handleClose();
      } else {
        this.setState({ promptRequestRejection: true, promptConfirmation: false });
        changeConfirmCancelStatus_(false);
      }
    } catch (err) {
      const { code } = err;
      if (code === 400) {
        this.setState({ promptRequestRejection: true, promptConfirmation: false });
        changeConfirmCancelStatus_(false);
        return;
      }
      snackBar_({
        payload: {
          enable: true,
          title: 'Message could not be canceled.',
          type: 'error',
        },
      });
      this.handleClose();
    }
  };

  render() {
    const { promptConfirmation, promptRequestRejection } = this.state;
    const {
      dialogOpen,
      classes,
      changeConfirmCancelStatus: changeConfirmCancelStatus_,
    } = this.props;
    let retComponent;
    if (promptConfirmation) {
      retComponent = (
        <ConfirmationMessage
          handleCloseModal={this.handleClose}
          cancelScheduledMessage={this.handlePromptConfirmation}
        />
      );
    } else if (promptRequestRejection) {
      retComponent = <RejectionNotice handleCloseModal={this.handleClose} />;
    }
    const retval = (
      <Dialog
        open={dialogOpen}
        onClose={this.handleClose}
        maxWidth="sm"
        onExited={() => changeConfirmCancelStatus_(true)}
        style={{
          backgroundColor: 'transparent',
          borderRadius: '10px',
        }}
        classes={{
          paper: classes.paperClass,
          rounded: classes.roundedClass,
        }}
      >
        {retComponent}
      </Dialog>
    );
    return retval;
  }
}

const mS = state => ({ dialogOpen: state.messageReducer.cancelScheduledMessageDialogStatus });

const mD = {
  snackBar,
  handleDialogStatus,
  cancelScheduledMessage,
  canCancelScheduledMessage,
  changeConfirmCancelStatus,
  navigationControllerRedirect,
};

const reduxConnectedComponent = connect(mS, mD)(CancelScheduledMessageDialog);

export default withStyles(styles)(reduxConnectedComponent);
