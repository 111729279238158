export const ACTIONS = {
  SEARCH_TERM_CHANGE: '[Members Filters] Change search term',
  TOGGLE: '[Members Filters] Toggle',
  CHANGE: '[Members Filters] Change',
  RESET: '[Members Filters] Reset',
};

export const changeMembersSearchTerm = value => dispatch => {
  dispatch({
    type: ACTIONS.SEARCH_TERM_CHANGE,
    payload: value,
  });
  return Promise.resolve();
};

export const toggleMembersFilters = bool => dispatch => {
  console.log('toggle', bool);
  dispatch({ type: ACTIONS.TOGGLE, payload: bool });
}

export const changeMembersFilters = filters => dispatch => {
  dispatch({
    type: ACTIONS.CHANGE,
    payload: filters,
  });
  return Promise.resolve();
};

export const resetMembersFilters = () => dispatch =>
  dispatch({ type: ACTIONS.RESET });
