import React from 'react';
import { connect } from 'react-redux';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import LoaderOverlay from '../LoaderOverlay';
import './index.sass';

/**
 * Generic Modal
 *
 * @param {boolean} status Status Modal
 * @param {html} element Children Component
 * @param {boolean} isLoader Status Loader for asynchronous task
 *
 * behavior :
 *
 * import { enableModal } from 'Actions/modalActions';
 *
 * this.props.enableModal({ payload : { status : true , element : <Component/> } }) Open Modal
 *
 */

const Transition = React.forwardRef((props, ref) => <Slide direction="down" {...props} ref={ref} />);

const styles = theme => ({
  [theme.breakpoints.only('xs')]: {
    paper: {
      maxWidth: window.innerWidth,
      width: window.innerWidth,
      maxHeight: window.innerHeight,
      height: window.innerHeight,
      margin: '0 auto',
      borderRadius: '0px !important',
    },
  },
  [theme.breakpoints.only('sm')]: {
    paper: {
      maxWidth: window.innerWidth,
      width: window.innerWidth,
      maxHeight: window.innerHeight,
      height: window.innerHeight,
      margin: '0 auto',
      borderRadius: '0px',
    },
  },
  paper: {
    borderRadius: '8px',
  },
});

const Modal = ({ classes, status, element, isLoader }) => (
  <Dialog
    open={status}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    TransitionComponent={Transition}
    classes={{
      paper: classes.paper,
      root: classes.root,
    }}
  >
    <DialogContent>
      <div className="modal-container">
        <div className={`modal-container__loader ${isLoader ? 'modal-container__active' : ''}`}>
          <LoaderOverlay className="modal-loader-overlay" />
        </div>
        <div className="modal-container__children">{element}</div>
      </div>
    </DialogContent>
  </Dialog>
);

const mS = state => ({
  status: state.modalReducer.status,
  element: state.modalReducer.element,
  isLoader: state.modalReducer.isLoader,
});

export default connect(mS)(withStyles(styles)(Modal));
