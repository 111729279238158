import React from 'react';
import ContentEditable from 'react-contenteditable';
import WebPostviewPropType from '../../PropTypes/WebPostviewPropType';
import './../../Components/MessageWebPreview/index.sass';
import './index.sass';

const MessageWebPostview = ({ content }) => (
  <div className={`message-web-container post-view`}>
    <div className="page-container box-sizing">
      <ContentEditable
        className="content postview-web-content box-sizing"
        id="scroll"
        tagName="p"
        html={content}
        disabled
      />
    </div>
  </div>
);

MessageWebPostview.propTypes = WebPostviewPropType;

export default MessageWebPostview;
