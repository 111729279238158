import React from 'react';
import Avatar from '../Avatar';
import './index.sass';
import MessageThreadItemPropType from '../../PropTypes/MessageThreadItemPropType';

const MessageThreadItem = ({ userName, date, messageHtml, userImage, rtl, highlighted, fullWidth }) => {
  let classes = rtl ? 'rtl' : 'ltr';
  classes += highlighted ? ' highlighted' : '';
  classes += fullWidth ? ' full-width' : '';

  return (
    <section className={`message-thread-item designerContent ${classes}`}>
      <header className="thread-info">
        {userName && <div className="user-name">{userName}</div>}
        <div className="thread-date">{date}</div>
      </header>
      <article className="user-message-wrapper">
        <Avatar className="user-image" borderless image={userImage}/>
        <div className="user-message" dangerouslySetInnerHTML={{ __html: messageHtml }}/>
      </article>
    </section>
  );
};

MessageThreadItem.propTypes = MessageThreadItemPropType;

MessageThreadItem.defaultProps = {
  highlighted: false,
  rtl: false,
  fullWidth: false,
  userName: '',
  userImage: '',
};

export default MessageThreadItem;
