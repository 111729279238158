import React from 'react';
import ActionButtonPropTypes from '../../PropTypes/ScheduledMessage/ActionButtonPropTypes';
import EvaTooltip from '../EvaTooltip';

const ActionButton = ({ classes, icon, onClick, size = 'lg', tooltipTitle }) => {
  let actionButton = (
    <button className={classes.actionButton} onClick={onClick}>
      <img alt="action icon" className={`${classes.actionButtonIcon} ${size}`} src={icon} />
    </button>
  );
  if (tooltipTitle) {
    actionButton = <EvaTooltip title={tooltipTitle}>{actionButton}</EvaTooltip>;
  }
  return actionButton;
};

ActionButton.propTypes = ActionButtonPropTypes;

export default ActionButton;
