// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../Theme/Icons/email-group.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../Theme/Icons/mobile-phone-group.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../Theme/Icons/channel-sms-email.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".channel-picker{display:-ms-flexbox;display:flex}.channel-picker .type-email{mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;-webkit-mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;background-color:#a9a9a9}.channel-picker .type-sms{mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat center;-webkit-mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat center;background-color:#a9a9a9}.channel-picker .type-all{mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat center;-webkit-mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat center;background-color:#a9a9a9}.channel-picker .active{background-color:#9a66bf}.channel-picker button:disabled{opacity:1;cursor:default}\n", ""]);
// Exports
module.exports = exports;
