import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import style from './styles';
import evangelusLogo from '../../Theme/Icons/evangelusLogo.svg';
import { SUPPORT_PHONE } from '../../Config/Constants';
import { SUPPORT_PHONE_NUMBER } from '../../Config/env';

function NotFoundComponent({ classes }) {
  return (
    <React.Fragment>
      <div className={classes.imageBox}>
        <img src={evangelusLogo} alt="EVANGELUS" />
      </div>
      <div className={classes.textsBox}>
        <h3 className={`${classes.common} not-subscribed`}>
          You are not currently subscribed to this group.
        </h3>
        <h3 className={classes.common}>
          Please contact support at {SUPPORT_PHONE_NUMBER} if you need further assistance.
        </h3>
      </div>
    </React.Fragment>
  );
}
export default withStyles(style)(NotFoundComponent);
