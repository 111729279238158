import React from 'react';
import ButtonSimplePropType from '../../PropTypes/ButtonSimplePropType';
import './index.sass';

const ButtonSimple = ({ text, buttonStyle, className, mini, onClick, ...props }) => (
  <button
    {...props}
    className={`button-simple ${className ? className : ''} ${buttonStyle} ${mini ? 'mini' : ''}`}
    onClick={onClick}
  >
    {text}
  </button>
);

ButtonSimple.propTypes = ButtonSimplePropType;

ButtonSimple.defaultProps = {
  className: '',
  buttonStyle: 'stroke',
  mini: false,
}

export default ButtonSimple;
