import { ACTIONS } from '../Actions/mainLoadingActions';

const initialState = {
  isLoading: true,
};

const mainLoadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.STATUS:
      return { ...state, isLoading: action.payload };

    default:
      return state;
  }
};

export default mainLoadingReducer;
