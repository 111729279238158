export const PasswordCheck = (option, text) => {
  switch (option) {
    case 'lowercase':
      return /^(?=.*[a-z]).+$/.test(text);
    // case 'number':
    // return /^(?=.*\d).+$/.test(text);
    case 'specialCharacterOrNumber':
      return /^(?=.*[_\W\d]).+$/.test(text);
    case 'uppercase':
      return /^(?=.*[A-Z]).+$/.test(text);
    case 'length':
      return text.length > 7;
    case 'focus':
      return 'input-eva-focused';
    default:
      return false;
  }
};

export const ValidatePassword = ({
  lowercase,
  specialCharacterOrNumber,
  uppercase,
  length,
  first,
  second,
  currentPassword,
  password,
  confirmationPassword,
}) =>
  lowercase &&
  specialCharacterOrNumber &&
  uppercase &&
  length &&
  currentPassword !== '' &&
  first !== '' &&
  first === second &&
  password === confirmationPassword &&
  password !== '';
