export const ACTIONS = {
  CHANGE_TAB: 'menu/change_tab',
  CHANGE_STATUS_BUTTON: 'menu/change_status_button',
};

export const OPTIONS = {
  BUBBLE_MESSAGE_OPTION: -1,
  BACK_MIDDLE_MENU: 0,
  MEMBERS_OPTION: 1,
  MESSAGES_OPTION: 2,
  STATS_OPTION: 3,
  SETTINGS_OPTION: 4,
};

export const changeTab = (selectedTab, value) => dispatch => {
  dispatch({
    type: ACTIONS.CHANGE_TAB,
    payload: { element: selectedTab, tab: value },
  });
};

export const changeStatusButton = status => ({
  type: ACTIONS.CHANGE_STATUS_BUTTON,
  ...status,
});
