import React from 'react';
import './index.sass';

const NotAvaileable = () => (
  <div className="not-available">
    <p>This feature is Not Available yet!</p>
    <p>Coming Soon !!!</p>
  </div>
);

export default NotAvaileable;
