const content = {
  text: `<h2>Welcome to the Diocesan Privacy Policy</h2>
  <p>We appreciate your trust in us, especially when it comes to your information. Our Privacy Policy will help you understand the data we collect, why we collect it, and how we use it.</p><p>This document was last updated on August 13, 2017.</p><h2>Introduction</h2><p>Diocesan Publications, Inc. (the “Company” or “we”) wants you to be familiar with how we collect, use and disclose information from and about you. This Privacy Policy describes our practices in connection with information collected through services such as websites, mobile sites, applications including without limitation the website located at Diocesan.com (collectively, the “Company Services”). By clicking “I Agree” or otherwise using Company Services, you agree to the terms and conditions of this Privacy Policy.</p><p>The Company is affiliated or under common control with following printing and publishing companies:</p>
  <ul><li>DioTrin LLC</li><li>Diocesan Publications, Inc. (OH)</li><li>Diocesan Publications, Ltd. (PA)</li><li>Polack Printing, Inc. (MI)</li><li>Trinity National Corp. (TX)</li></ul
  p>Collectively, these companies, with the Company, and their respective parent, subsidiary and affiliate entities are referred to in this Privacy Policy as the “Company Affiliates”.</p>
  <p>As set forth in our Terms of Use, the Company Services are predominantly for a general audience, are not targeted to children, and do not knowingly collect personal information from children under 13 years of age.</p>
  <p>This Privacy Policy applies to all users, including both those who use the Company Services without being registered with or subscribing to a Company Service and those who have registered with or subscribed to a Company Service.  This Privacy Policy applies to the Company’s collection and use of your personal information (i.e., information that identifies a specific person, such as full name or email address). It also describes generally the Company’s practices for handling non-personal information (for example, interests, demographics and services usage).</p>
  <h2>Collection of information</h2><p>Company and our service providers collect the following information from and about you:</p><p><b>“Registration Information”</b> is the information you submit to register for a Company Service, for example, to create an account, post comments, receive a newsletter, or enter a contest or sweepstakes. Registration Information may include, for example, name, email address, gender, zip code and birthday.</p><p><b>“Public Information and Posts”</b> consist of comments or content that you post to the Company Services and the information about you that accompanies those posts or content, which may include information such as a name, user name, comments, likes, status, profile information and picture. Public Information and Posts are always public, which means they are available to everyone and may be displayed in search results on external search engines.</p><p><em>Information from Social Media.</em>  If you access or log-in to a Company Service through a social media service or connect a Company Service to a social media service, the information we collect may also include your user ID and/or user name associated with that social media service, any information or content you have permitted the social media service to share with us, such as your profile picture, email address or friends lists, and any information you have made public in connection with that social media service.  When you access the Company Services through social media services or when you connect a Company Service to social media services, you are authorizing the Company to collect, store, and use such information and content in accordance with this Privacy Policy.</p>
  <p><em>Activity Information.</em>  When you access and interact with the Company Services, we may collect certain information about those visits.  For example, in order to permit your connection to the Company Services, our servers receive and record information about your computer, device, and browser, including potentially your IP address, browser type, and other software or hardware information. If you access the Company Services from a mobile or other device, we may collect a unique device identifier assigned to that device, geolocation data, or other transactional information for that device.</p><p>Cookies and other tracking technologies (such as browser cookies, pixels, beacons, and Adobe Flash technology including cookies) are comprised of small bits of data that often include a de-identified or anonymous unique identifier. Websites, apps and other services send this data to your browser when you first request a web page and then store the data on your computer so the website can access information when you make subsequent requests for pages from that service. These technologies may also be used to collect and store information about your usage of the Company Services, such as pages you have visited, content you have viewed, search queries you have run and advertisements you have seen.</p>
  <p>Third parties that support the Company Services by serving advertisements or providing services, such as allowing you to share content or tracking aggregate Company Services usage statistics, may also use these technologies to collect similar information.  Company does not control these third-party technologies and their use is governed by the privacy policies of third parties using such technologies. [For more information about third-party advertising networks and similar entities that use these technologies, see www.aboutads.info/consumers, and to opt-out of such ad networks’ and services’ advertising practices, go to www.aboutads.info/choices.]</p>
  <p>Most browsers are initially set to accept cookies, but you can change your settings to notify you when a cookie is being set or updated, or to block cookies altogether. Please consult the “Help” section of your browser for more information. Users can manage the use of Flash technologies, with the Flash management tools available at Adobe’s website, seehttp://www.adobe.com/products/flashplayer/security/privacy_policy/faq.html. Please note that by blocking any or all cookies you may not have access to certain features, content or personalization available through the Company Services.</p>
  <p><em>Information from Other Sources.</em> We may supplement the information we collect with information from other sources, such as publicly available information from social media services, commercially available sources and information from our Company Affiliates or business partners.</p><h2>Use and Disclosure</h2><p>We use the information we collect from and about you to provide the Company Services and features to you, including: to measure and improve those Company Services and features; to improve your experience with both online and off-line Company Services by delivering content you will find relevant and interesting, including advertising and marketing messages; to allow you to comment on content, and participate in online games, contests, or rewards programs; to provide you with customer support and to respond to inquiries. When the information collected from or about you does not identify you as a specific person, we may use that information for any purpose or share it with third parties.</p><p>We use the information we collect from and about you for these additional purposes:</p>
  <p><em>To allow service providers to assist us in providing and managing the Company Services.</em>  Company may make your information available to certain third party service providers, such as contractors, agents or sponsors, who help us manage or provide the Company Services.</p><p><em>To allow social sharing functionality.</em> If you log in with or connect a social media service account with Company Services, we may share your user name, picture, and likes, as well as your activities and comments with other Company Services users and with your friends associated with your social media service.  We may also share the same information with the social media service provider.  By logging in with or connecting your Company Services account with a social media service, you are authorizing us to share information we collect from and about you with the social media service provider, other users and your friends and you understand that the social media service’s use of the shared information will be governed by the social media service’s privacy policy.  If you do not want your personal information shared in this way, please do not connect your social media service account with your Company Services account and do not participate in social sharing on Company Services.</p>
  <p><em>To provide co-branded services and features.</em>  We may offer co-branded services or features, such as contests, sweepstakes or other promotions together with a third party (“Co-Branded Services”).  These Co-Branded Services may be hosted on Company Services or on the third party’s service.  By virtue of these relationships, we may share the information you submit in connection with the Co-Branded Service with the third party.  The third party’s use of your information will be governed by the third party’s privacy policy.</p>
  <p><em>To deliver relevant advertisements.</em>  Company and our service providers may use the information we collect from and about you to deliver relevant ads to you when you use the Company Services or another service.  As noted above, third-party advertising networks and advertisers may use cookies and similar technologies to collect and track information such as demographic information, inferred interests, aggregated information, and activity to assist them in delivering advertising that is more relevant to your interests.  In order to deliver advertising from local providers, we may collect geolocation data from your device.  If you would like to disable this collection, you should turn off “Location Services” or any similar service in the settings of your device.  Please note that if you do turn off “Location Services” we may be unable to provide local information and advertisements to you.  [To find out more about third-party advertising networks and similar entities that use these technologies, see www.aboutads.info, and to opt-out of such advertising networks’ and services’ advertising practices, go towww.aboutads.info/choices.]</p>
  <p><em>To contact you.</em>  Company may periodically send promotional materials or notifications related to the Company Services.  If you want to stop receiving promotional materials, you can follow the unsubscribe instructions at the bottom of each email.  There are certain service notification emails that you may not opt-out of, such as notifications of changes to the Company Services or policies.  If you have installed a mobile app and you wish to stop receiving push notifications, you can change the settings either on your mobile device or through the app.</p>
  <p><em>To share with our Company Affiliates.</em>  Company may share your information with Company Affiliates for the purposes described in this Privacy Policy.  Users who visit Company Affiliates’ services should still refer to their separate privacy policies, which may differ in some respects from this Privacy Policy.</p>
  <p><em>To share with business partners.</em>  The Company may share your information with business partners to permit them to send you marketing communications consistent with your choices.</p>
  <p>em>To protect the rights of Company and others.</em>  There may be instances when the Company may disclose your information, including situations where Company has a good faith belief that such disclosure is necessary in order to: (i) protect, enforce, or defend the legal rights, privacy, safety, or property of the Company, our Company Affiliates or their employees, agents and contractors (including enforcement of our agreements and our terms of use); (ii) protect the safety, privacy, and security of users of the Company Services or members of the public; (iii) protect against fraud or for risk management purposes; (iv) comply with the law or legal process; or (v) respond to requests from public and government authorities.</p>
  <p><em>To complete a merger or sale of assets.</em>  If the owners of the Company sell all or part of their ownership in the Company or otherwise make a sale or transfer of its assets, or the Company is otherwise involved in a merger or transfer of all or a material part of its business, the sellers may transfer your information to the party or parties involved in the transaction as part of that transaction.</p>
  <h2>Security</h2>
  <p>The Company uses commercially reasonable administrative, technical, personnel and physical measures to safeguard information in its possession against loss, theft and unauthorized use, disclosure or modification. However, no one can guarantee the complete safety of your information.</p>
  <h2>User access and control</h2>
  <p>If you would like to access, review, correct, update, suppress, or otherwise limit our use of your personal information you have previously provided directly to us, you may contact us at info@diocesan.com.  In your request, please (i) include your email address, name, address, telephone number, and the Company Service to which you provided the information and (ii) specify clearly what information you would like to access, change, update, or suppress.  We will try to comply with your request as soon as reasonably practicable.</p>
  <p>You may request that we not share your personal information on a going-forward basis with Company Affiliates or unaffiliated third parties for their direct marketing purposes by sending an email to info@Diocesan.com with “Diocesan Services: Disclosure Opt-out” in the subject line and your full name, email address and postal address in the body of the email.</p>
  <h2>Other important information</h2>
  <p><em>Updates to Privacy Policy.</em>  The Company may modify this Privacy Policy.  Please look at the Effective Date at the top of this Privacy Policy to see when this Privacy Policy was last revised.  Any changes to this Privacy Policy will become effective when we post the revised Privacy Policy on the Company Services.  However, after you click “I Agree” to this Privacy Policy, the Company will not modify this Privacy Policy with respect to you in a manner that we reasonably determine materially modifies your rights or obligations under this Privacy Policy without providing prominent notice of the modification on the apps or asking you again to review and demonstrate your consent to the modified terms.</p>
  <p><em>Location of Data.</em>  The Company Services are hosted in and managed from the United States. If you are a user located outside the United States, you understand and consent to having any personal information processed in the United States.  United States data protection and other relevant laws may not be the same as those in your jurisdiction.  This includes the use of cookies and other tracking technologies as described above.  As a result, please read this Privacy Policy with care.</p>
  <p><em>Linked Services.</em>  The Company Services may also be linked to sites operated by unaffiliated companies, and may carry advertisements or offer content, functionality, games, newsletters, contests or sweepstakes, or applications developed and maintained by unaffiliated companies. Company is not responsible for the privacy practices of unaffiliated companies and once you leave the Company Services or click an advertisement you should check the applicable privacy policy of the other service.</p>
  <p><em>Collection of Personal Financial Information by a Payment Service.</em>  In some cases, we may use an unaffiliated payment service to allow you to purchase a product or make payments (“Payment Service”).  If you wish to purchase a product or make a payment using a Payment Service, you will be directed to a Payment Service webpage.  Any information that you provide to a Payment Service will be subject to the applicable Payment Service’s privacy policy, rather than this Privacy Policy.  We have no control over, and are not responsible for, any Payment Service’s use of information collected through any Payment Service.</p>
  <p><em>Data Retention.</em>  We will retain your information for the period necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or allowed by law.</p>
  <p>Remember that even after you cancel your account, copies of some information from your account may remain viewable in some circumstances where, for example, you have shared information with social media or other services.  Because of the nature of caching technology, your account may not be instantly inaccessible to others.  We may also retain backup information related to your account on our servers for some time after cancelation for fraud detection or to comply with applicable law or our internal security policies.  It is not always possible to completely remove or delete all of your information due to technical constraints, contractual, financial or legal requirements.</p>
  <p>If you have questions about how we use your data or would like to request that we delete your data to the extent feasible, please contact us at info@Diocesan.com.  We will use commercially reasonable efforts to respond within thirty (30) days.</p>
  <p><em>Sensitive Information.</em>  We ask that you not send us, and you not disclose, any sensitive personal information (such as social security numbers, information related to racial or ethnic origin, political opinions, health, criminal background or trade union membership) on or through the Company Services or otherwise.</p>
  <p><em>Disputes.</em>  This Privacy Policy will be governed by, and construed in accordance with, the laws of the State of Texas, without regard to its conflict of law provisions.  Any disputes relating to or arising from this Privacy Policy shall be resolved by arbitration in accordance with the arbitration provisions of our Terms of Use, which are incorporate herein by reference and available here:  [insert URL for Terms of Use].  You and we agree that any dispute resolution proceedings will be conducted only on an individual basis and not in a class, consolidated or representative action. If for any reason a claim proceeds in court rather than in arbitration we each waive any right to a jury trial and agree to the exclusive jurisdiction and venue of courts located in Travis County, Texas. We also both agree that you or we may bring suit in court to enjoin infringement or other misuse of intellectual property rights.</p>
  <h2>Contact us</h2>
  <p>If you have questions about this Privacy Policy, please contact us at:</p>
  <p>Email: <a href="mailto:info@Diocesan.com">info@Diocesan.com</a></p>
  <p>Mail: Legal Department/Privacy Policy</p>
  <p>Diocesan Publications, Inc.<br />
  500 S. Lake Destiny Road<br />
  Orlando, Florida 32810</p>`,
};

export default content;
