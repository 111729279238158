import React from 'react';
import Grid from '@material-ui/core/Grid';
import InputPropType from '../../PropTypes/InputPropType';
import './index.sass';
import EvaTooltip from '../EvaTooltip';

const InputStandard = ({
  className,
  name,
  type,
  placeholder,
  value,
  onChange,
  icon,
  focused,
  maxLength,
  onBlur,
  disable,
  color,
  tooltipTitle,
  useId,
  useDivContainer = false,
  appendVoidContainer = false,
}) => {
  const iconClass = icon ? `input-eva-${icon}-icon` : '';
  let inputIcon;
  if (tooltipTitle) {
    inputIcon = useDivContainer ? (
      <div className={`${iconClass}`} />
    ) : (
      <i className={`${iconClass}`} alt="" />
    );
    inputIcon = <EvaTooltip title={tooltipTitle}>{inputIcon}</EvaTooltip>;
  }
  const inputField = (
    <input
      name={name}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={event => onChange(event, name)}
      onBlur={onBlur}
      className={`form-field ${className} ${iconClass} ${focused ||
        ''} input-eva-border-bottom input-eva`}
      maxLength={maxLength}
      disabled={disable ? 'disabled' : ''}
      autoComplete="off"
    />
    // style={{ backgroundColor: color || '#FFF' }}
  );
  /* eslint-disable */
  if (useId !== undefined || useId !== null) {
    return (
      <Grid container id={useId} className="input-div" alignItems="center">
        <Grid item xs={2} sm={1}>
          {inputIcon}
        </Grid>
        <Grid
          item
          xs={10 - 2 * Number(appendVoidContainer)}
          sm={11 - Number(appendVoidContainer)}
        >
          {inputField}
        </Grid>
        {appendVoidContainer ? <Grid item xs={1} className="void-box" /> : undefined}
      </Grid>
      /*  <div id={useId} className="input-div">
          </div> */
    );
  }
  /* eslint-enable */
  return (
    <div>
      {inputIcon}
      <input
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={event => onChange(event, name)}
        onBlur={onBlur}
        className={`form-field ${className} ${iconClass} ${focused ||
          ''} input-eva-border-bottom input-eva`}
        maxLength={maxLength}
        disabled={disable ? 'disabled' : ''}
        style={{ backgroundColor: color || '#FFF', borderBottom: '2px solid #e3e3e3' }}
        autoComplete="off"
      />
    </div>
  );
};

InputStandard.propTypes = InputPropType;

export default InputStandard;
