import AbstractAdapter from '../AbstractAdapter';
import TreeItemAdapter from './TreeItemAdapter';
import TreeItem from '../../Components/TreeNavigator/TreeItem';

export default class TreeNavigationAdapter extends AbstractAdapter<TreeItem[]> {
  adapt(selected): TreeItem[] {
    if (Array.isArray(this.object) && this.object.length > 0) {
      return this.object.map(item => new TreeItemAdapter(item).adapt(selected));
    }

    return [];
  }
}
