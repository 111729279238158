import PropTypes from 'prop-types';

const ActionButtonPropTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
};

export default ActionButtonPropTypes;
