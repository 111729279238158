import React, { Component } from 'react';

import MainMenu from '../../Components/MainMenu';
import TemplatesPage from '../../Components/Templates';

import './styles.sass';

class Templates extends Component {
  state = {
    width: window.innerWidth,
  };

  render = () => (
    <MainMenu
      value={2}
      content={
        <TemplatesPage
          width={this.state.width}
        />
      }
    />
  );
}

export default Templates;
