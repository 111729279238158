import PropTypes from 'prop-types';

const MessagePropType = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  hideMessage: PropTypes.func,
  style: PropTypes.object,
};

export default MessagePropType;
