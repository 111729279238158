import PropTypes from 'prop-types';
import React from 'react';
import Avatar from '../Avatar';
import './index.sass';
import EvaTooltip from '../EvaTooltip';

/* eslint-disable no-underscore-dangle */
const MemberItem = (
  { id, firstName, lastName, pictureUrl, isAvatarVisible, isChecked, viewMode = "list", onEditMember, onSelectMember, isEmailBounced },
) => (
  <div
    className={`member-item ${viewMode}-view ${onEditMember ? 'member-clickable' : ''}`}
    role="button"
    tabIndex="0"
    onClick={() => { if (onEditMember) onEditMember(id); }}
    onKeyPress={() => { if (onEditMember) onEditMember(id); }}
  >
    <button
      className={`pseudo-checkbox ${isChecked ? 'active' : ''}`}
      onClick={(e) => { e.stopPropagation(); onSelectMember(id); }}
    >
      <i/>
    </button>
    {isAvatarVisible && (
      <Avatar
        borderless
        image={pictureUrl}
      />
    )}
    <div className="member-full-name">
      {!!firstName && <span className="first-name">{firstName}</span>}
      <b>{lastName}</b>
      {isEmailBounced && (
        <EvaTooltip title="Bounced email">
          <span className="icon-mark exclamation" />
        </EvaTooltip>
      )}
    </div>
  </div>
);

MemberItem.propTypes = {
  id: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  pictureUrl: PropTypes.string || null,
  isChecked: PropTypes.bool.isRequired,
  viewMode: PropTypes.oneOf(['grid', 'list']).isRequired,
  onEditMember: PropTypes.func,
  onSelectMember: PropTypes.func.isRequired,
};

MemberItem.defaultProps = {
  firstName: '',
  lastName: '',
  pictureUrl: null,
  onEditMember: null,
};

export default MemberItem;
