import React, { Component } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { Scrollbars } from 'react-custom-scrollbars';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import ClearIcon from '@material-ui/icons/Clear';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { enableRightDrawer } from '../../Actions/rightDrawerShortActions';
import RightDrawerPropType from '../../PropTypes/RightDrawerPropType';
import EvaTooltip from '../EvaTooltip';
import './index.sass';
import { forceReload } from '../../Actions/AvatarActions';

const drawerWidth = window.innerWidth > 768 ? 422 : window.innerWidth;

const styles = theme => ({
  [theme.breakpoints.only('xs')]: {
    drawerPaper: {
      width: `${window.innerWidth}px !important`,
      height: `${window.innerHeight}px !important`,
    },
  },
  [theme.breakpoints.only('sm')]: {
    drawerPaper: {
      width: '100% !important',
      height: '100% !important',
    },
  },
  drawerPaper: {
    position: 'absolute',
    width: drawerWidth,
    marginTop: 0,
    minHeight: 100,
    overflow: 'hidden',
    height: 'calc(100vh - 132)',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    minHeight: 40,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  'content-left': {
    marginLeft: -drawerWidth,
  },
  'content-right': {
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  'contentShift-left': {
    marginLeft: 0,
  },
  'contentShift-right': {
    marginRight: 0,
  },
});

class RightDrawerShort extends Component {
  state = {
    side: 'right',
  };

  handleDrawerClose = () => {
    if (this.props.cleaner) {
      this.props.cleaner();
    }
    this.props.enableRightDrawer({
      payload: { enable: false, element: <div /> },
    });
    if (window.innerWidth >= 1024) {
      document.querySelector('.right-drawer-edit-member > .drawer-md-up').style.display = 'none';
    }
  };

  handleChangeAnchor = event => {
    this.setState({
      side: event.target.value,
    });
  };

  render() {
    const { classes, element, status, title, secondTitle } = this.props;
    const { side } = this.state;
    return (
      <div className={`right-drawer-edit-member ${!status ? 'disable' : ''}`}>
        <Hidden mdUp>
          <Drawer
            anchor={side}
            open={status}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <Scrollbars autoHeight autoHide autoHeightMin={0} autoHeightMax="100vh">
              <div className={classes.drawerHeader}>
                <IconButton onClick={this.handleDrawerClose}>
                  <EvaTooltip title="Close">
                    <ClearIcon />
                  </EvaTooltip>
                </IconButton>
                <div className="drawer-title" />
              </div>
              <div>{element}</div>
            </Scrollbars>
          </Drawer>
        </Hidden>
        <Hidden smDown>
          <div className="drawer-md-up">
            <Drawer
              anchor={side}
              open={status}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <Scrollbars autoHeight autoHide autoHeightMin={0} autoHeightMax="100vh">
                <div className={classes.drawerHeader}>
                  <IconButton onClick={this.handleDrawerClose}>
                    <EvaTooltip title="Close">
                      <ClearIcon />
                    </EvaTooltip>
                  </IconButton>
                  <div className="drawer-title">{title}</div>
                  {secondTitle && <div className="drawer-short-second-title">{secondTitle}</div>}
                </div>
                <div>{element}</div>
              </Scrollbars>
            </Drawer>
          </div>
        </Hidden>
      </div>
    );
  }
}

const mS = state => ({
  status: state.rightDrawerShortReducer.enable,
  element: state.rightDrawerShortReducer.element,
  title: state.rightDrawerShortReducer.title,
  secondTitle: state.rightDrawerShortReducer.secondTitle,
  cleaner: state.rightDrawerShortReducer.cleaner,
});

const mD = {
  enableRightDrawer,
  forceReload,
};

RightDrawerShort.propTypes = RightDrawerPropType;

export default connect(
  mS,
  mD,
)(withStyles(styles, { withTheme: true })(RightDrawerShort));
