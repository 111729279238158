import { ACTIONS } from '../Actions/mainTabsActions';

const initialState = {
  value: 3, // Set hidden tab by default
};

const mainTabsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.CHANGE_TAB:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default mainTabsReducer;
