import AXIOS from '../Config/AxiosConfig';
import headers from './Helper';

const Auth = {
  login(username, password) {
    return AXIOS.post('/login', { username, password }, { headers: headers() });
  },
  refreshDataFromSso(token) {
    return AXIOS.post('/login/check-sso', { token }, { headers: headers(false) });
  },
  logout() {
    const token = localStorage.getItem('sso_token');
    return AXIOS.post('/logout', { token }, { headers: headers() });
  },
  getRoles() {
    return AXIOS.get('/user/roles', { headers: headers() });
  },
  resetPassword(token, plainPassword, phone) {
    const body = { token, plainPassword };

    if (phone) {
      body.phone = phone;
      body.type = 'sms';
    }

    return AXIOS.put(`/public/password/reset/confirm/${token}`, body, { headers: headers() });
  },
  requestPassword(username) {
    return AXIOS.post('/public/password/reset/request', username, { headers: headers() });
  },

  checkPin(phone, token) {
    return AXIOS.get(`public/password/validate/${token}/${phone}`, { headers: headers() });
  },
  checkSsoLoginIsAlive(token) {
    return AXIOS.get(`/sso/session?token=${token}`, { headers: headers() });
  },
  resetPasswordUser(data) {
    return AXIOS.put('/user/password', data, { headers: headers() });
  },
};

export default Auth;
