import React, { Component } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import DialogPropType from '../../PropTypes/DialogPropType';
import './index.sass';

class DialogDraftSent extends Component {
  render() {
    return <Dialog
      open={this.props.isOpen}
      onClose={this.props.onCancel}
      className="dialog-draft-sent"
      maxWidth="sm"
    >
      <DialogContent className="container-warning-delete">
        <div className="warning-message">
          <div className="single-line">
            <strong> ATTENTION:</strong>
            The message you were editing has already been sent from another instance of Evangelus.
            This window will be closed.
          </div>
        </div>

        <button
          onClick={this.props.onConfirm}
          className="button-warning-decision "
        >
          <span className="text-warning">Ok</span>
        </button>
      </DialogContent>
    </Dialog>
  }
}

DialogDraftSent.propTypes = DialogPropType;

export default DialogDraftSent;
