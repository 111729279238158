import Colors from '../../Theme/Colors';
import { LG_SIZE, SM_SIZE } from '../../Config/Constants';

export default {
  avatar: {
    backgroundColor: Colors.lightGrey,
    borderColor: 'white',
    borderStyle: 'solid',
    borderRadius: '50%',
    height: 30,
    overflow: 'hidden',
    width: 30,
    '&.px-35': {
      height: 35,
      width: 35,
      '@media only screen and (min-width: 600px) and (max-width: 960px)': {
        marginRight: '51px',
      },
      '@media only screen and (max-width: 599px)': {
        marginRight: '24px',
      },
    },
  },
  bigAvatar: {
    backgroundColor: Colors.lightGrey,
    borderColor: 'white',
    borderStyle: 'solid',
    borderRadius: '50%',
    height: 100,
    overflow: 'hidden',
    width: 100,
    display: 'inline-block',
  },
  avatarImg: {
    borderRadius: '50%',
    height: '100%',
    margin: '0 !important',
    width: '100%',
  },
  defaultBg: {
    backgroundColor: 'white',
  },
  noBorder: {
    border: 'none',
  },
  [LG_SIZE]: {
    borderWidth: '4px',
  },
  [SM_SIZE]: {
    borderWidth: '2px',
  },
};
