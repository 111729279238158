import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import SimpleTabs from './SimpleTabs';
import ActionButton from '../ActionButton';
import { getUserProfile } from '../../Actions/userActions';
import switchImg from '../../Theme/Icons/switch-icon.svg';
import { navigationControllerRedirect } from '../../Actions/navigationControllerActions';
import changeTab from '../../Actions/mainTabsActions';
import Avatar from '../Avatar';
import Settings from '../Settings';
import SideMenu from '../SideMenu';
import EvaTooltip from '../EvaTooltip';
import MainLoading from '../MainLoading';
import TempAdminBanner from '../TempAdminBanner';
import AdminEvaSwitchButton from './AdminEvaSwitchButton';
import { DESKTOP, TABLET, MOBILE } from '../../Config/Constants';
import snackBarStatus from '../../Actions/snackbarActions';
import SuspendedOverlay from '../SuspendedOverlay';
import { withRouter } from 'react-router-dom';
import './index.sass';
import { redirectToAdmin } from '../../Helpers/redirectToAdmin';

function arrowGenerator(color) {
  return {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.95em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${color} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.95em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${color} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.95em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${color} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.95em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${color}`,
      },
    },
  };
}

const styles = theme => ({
  arrowPopper: arrowGenerator(theme.palette.grey[700]),
  arrow: {
    position: 'absolute',
    fontSize: 6,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  bootstrapPopper: arrowGenerator(theme.palette.common.black),
  bootstrapTooltip: {
    backgroundColor: theme.palette.common,
  },
  bootstrapPlacementLeft: {
    margin: '0 8px',
  },
  bootstrapPlacementRight: {
    margin: '0 8px',
  },
  bootstrapPlacementTop: {
    margin: '8px 0',
  },
  bootstrapPlacementBottom: {
    margin: '8px 0',
  },
});

class EvaAppBar extends Component {
  state = {
    visibility: '',
    displaySettings: false,
    arrowRef: null,
    showMenu: false,
    switchLoading: false,
  };

  componentDidMount() {
    window.addEventListener('message', this.handleMenuClose);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleMenuClose);
  }

  handleGoToAdminAction = async event => {
    event.preventDefault();
    this.setState({ switchLoading: true });
    redirectToAdmin();
    this.setState({ switchLoading: false });
  };

  toggleOpenMenuBodyClass = () => document.body.classList.toggle('open-menu');

  handleMenuToggle = event => {
    this.setState({ showMenu: event.target.checked }, this.toggleOpenMenuBodyClass);
  };
  handleMenuClose = event => {
    const { data } = event;

    if (data && data.close) {
      this.setState({ showMenu: false }, this.toggleOpenMenuBodyClass);
    }
  };

  handleArrowRef = node => {
    this.setState({
      arrowRef: node,
    });
  };

  displaySettingsView = displaySettings => {
    this.setState({ displaySettings });
  };

  redirectToMain = () => {
    const url = '/';
    this.props.changeTab({ payload: { value: null } });
    this.props.navigationControllerRedirect(url);
  };

  renderEvaApp = () => {
    const { displaySettings } = this.state;
    const { userProfile, selectedOrg, classes, viewType, organization,
      navigationControllerRedirect, notificationsTotal } = this.props;
    const isVisibleAdminEvaSwitchButton = userProfile
      && userProfile.member
      && userProfile.member.roles
      && userProfile.member.roles.indexOf('ROLE_SUPER_ADMIN') >= 0;
    const isSuspended = organization && organization.status === 'suspended';
    const isProfilePage = this.props.location.pathname === '/my-profile';
    const isShowSuspendedModal = isSuspended && !isProfilePage;

    return (
      <React.Fragment>
        <TempAdminBanner/>
        <div className="logo-container">
          <div className="content-inner-wrapper">
            <input
              type="checkbox"
              id="drawer-toggle"
              name="drawer-toggle"
              checked={this.state.showMenu}
              onChange={this.handleMenuToggle}
            />
            <label htmlFor="drawer-toggle" className="drawer-toggle-label"/>
            {/* This Header/button probably wont require a tooltip so it will be left as it is */}
            <header
              className="header-evangelus"
              onClick={this.redirectToMain}
              role="button"
              tabIndex="0"
            >
              EVANGELUS
            </header>
            <Tooltip
              title={
                <React.Fragment>
                  {selectedOrg.name}
                  <span className={classes.arrow} ref={this.handleArrowRef}/>
                </React.Fragment>
              }
              classes={{
                tooltip: classes.bootstrapTooltip,
                popper: classes.bootstrapPopper,
                tooltipPlacementLeft: classes.bootstrapPlacementLeft,
                tooltipPlacementRight: classes.bootstrapPlacementRight,
                tooltipPlacementTop: classes.bootstrapPlacementTop,
                tooltipPlacementBottom: classes.bootstrapPlacementBottom,
              }}
              PopperProps={{
                popperOptions: {
                  modifiers: {
                    arrow: {
                      enabled: Boolean(this.state.arrowRef),
                      element: this.state.arrowRef,
                    },
                  },
                },
              }}
              placement="top"
            >
              <div className="name-org-eva"/>
            </Tooltip>
            {(viewType === TABLET || viewType === DESKTOP || viewType === MOBILE) && (
              <div className="action-buttons-container-change">
                <div className="org-name-container">
                  <EvaTooltip
                    title={selectedOrg.name}
                  >
                    <div className="name-org-eva">{selectedOrg.name}</div>
                  </EvaTooltip>
                </div>
                {!!isVisibleAdminEvaSwitchButton &&
                <EvaTooltip
                  title="Super Admin"
                >
                  <span>
                    <AdminEvaSwitchButton
                      handleAction={this.handleGoToAdminAction}
                      icon={switchImg}
                      isLoading={this.state.switchLoading}
                    />
                  </span>
                </EvaTooltip>
                }
              </div>
            )}
            <EvaTooltip title="Home">
              <button
                className="eva-logo"
                onClick={this.redirectToMain}
              />
            </EvaTooltip>
            <div className="action-buttons-container">
              <button className="points-button" onClick={this.displaySettingsView}>
                <Avatar borderless image={userProfile.member.profile_picture_url}/>
                {notificationsTotal > 0 && (
                  <div className="notifications-counter">{notificationsTotal}</div>
                )}
              </button>
            </div>
            {displaySettings && <Settings displaySettingsView={this.displaySettingsView}/>}
          </div>
        </div>
        <div className="tabs-wrapper">
          <SimpleTabs value={this.props.value} content={this.props.content}/>
        </div>
        <ActionButton groupsDictionaryList={this.props.groupsDictionaryList}/>
        <SideMenu
          active={this.state.showMenu}
          authToken={localStorage.getItem('sso_token')}
          env="eva"
          org={this.props.selectedOrg}
          onClose={this.handleMenuToggle}
        />
        {isShowSuspendedModal ?
          <SuspendedOverlay
            onNavigateToMyProfile={() => navigationControllerRedirect('/my-profile')}
            onMenuToggle={() => {
              this.handleMenuToggle({ target: { checked: !this.state.showMenu } })
            }}
          /> : ''}
      </React.Fragment>
    );
  };

  render() {
    const { isLoading } = this.props;
    return (
      <div className="eva-app-bar">{isLoading ? <MainLoading/> : this.renderEvaApp()}</div>
    );
  }
}

const mS = state => ({
  selectedOrg: state.organizationReducer.organization,
  userProfile: state.userProfileReducer,
  isLoading: state.mainLoadingReducer.isLoading,
  viewType: state.responsiveReducer.viewType,
  groupsDictionaryList: state.groupsDictionaryReducer.list,
  notificationsTotal: state.notificationsReducer.total,
});

const mD = {
  getUserProfile,
  navigationControllerRedirect,
  changeTab,
  snackBarStatus,
};

export default connect(
  mS,
  mD,
)(withRouter(withStyles(styles)(EvaAppBar)));
