import Colors from '../../Theme/Colors';

export const messageContentStyles = {
  root: {
    padding: '0 0 20px',
    position: 'relative',
    '@media (max-width: 767px)': {
      paddingRight: 20,
      paddingLeft: 20,
    },
  },
  avatar: {
    height: 40,
    position: 'absolute',
    right: 0,
    top: 0,
    width: 40,
    '@media (max-width: 767px)': {
      right: 20,
    },
  },
  content: {
    backgroundColor: 'transparent',
    margin: '0 auto',
    '@media (max-width: 1023px)': {
      maxWidth: 287,
    },
    '@media only screen and (max-width: 414px) and (min-width: 320px)': {
      '& .designer-container': {
        padding: 0,
      },
    },
    '& img': {
      maxWidth: '100%',
    },
    '& .designer-container': {
      margin: 0,
    },
    '& .designerContent center, & .designerContent th.large-12 center': {
      minWidth: 'auto',
    },
    '& .designerContent table.container, & .designerContent th.large-12': {
      width: 'auto',
    },
  },
  dateTime: {
    color: Colors.grey,
    fontFamily: 'GothamSSm-Book',
    fontSize: 12,
    lineHeight: '15px',
    marginB: '0 0 5px',
  },
};

export const topBarStyles = {
  root: {
    alignItems: 'flex-start',
    display: 'flex',
    marginBottom: 30,
    padding: '40px 0 20px',
    '@media (max-width: 1023px)': {
      marginBottom: 20,
    },
    '@media (max-width: 767px)': {
      boxShadow: '0 4px 12px 0 rgba(0, 0, 0, 0.1)',
      marginBottom: 27,
      paddingBottom: 19,
      paddingTop: 20,
      paddingRight: 20,
      paddingLeft: 20,
    },
  },
  actions: {
    alignItems: 'center',
    display: 'flex',
    flexShrink: 0,
  },
  actionButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    lineHeight: 0,
    margin: 0,
    padding: 0,
    '&:not(first-child)': {
      marginLeft: 18,
    },
  },
  actionButtonIcon: {
    '&.lg': {
      maxHeight: 24,
      maxWidth: 24,
    },
    '&.md': {
      maxHeight: 20,
      maxWidth: 20,
    },
  },
  info: {
    flexGrow: 1,
  },
  infoContent: {
    fontFamily: 'GothamSSm-Medium',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  infoGroups: {
    color: Colors.grey,
    fontSize: 16,
    lineHeight: '20px',
    margin: '5px 0 0',
    '@media (max-width: 767px)': {
      fontSize: 15,
      lineHeight: '18px',
      margin: '2px 0 0',
    },
  },
  infoSubject: {
    color: Colors.evaGrey,
    fontSize: 20,
    lineHeight: '24px',
    margin: 0,
    '@media (max-width: 767px)': {
      fontSize: 19,
      lineHeight: '23px',
    },
  },
};

export const styles = {
  root: {
    margin: '0 auto',
    padding: '0 22px',
    '@media (max-width: 767px)': {
      padding: '0',
    },
    '@media (min-width: 768px)': {
      width: 'calc(100% - 180px)',
    },
    '@media (min-width: 1024px)': {
      width: 'calc(100% - 80px)',
    },
  },
};
